import { AC_PAYMENT } from '@/actions/payment';

const defaultState = {
    stripePkKey: null,
    stripeIsLoaded: false,
    methodsIsLoaded: false,
    methods: [],
    defaultMethod: null,
};

export default (state = defaultState, action) => {
    switch(action.type) {
        case AC_PAYMENT.SET_STRIPE_KEY: {
            let newState= Object.assign({}, state);
            newState.stripePkKey = action.payload;
            newState.stripeIsLoaded = true;
            return newState;
        }
        case AC_PAYMENT.FLUSH_PAYMENTS: {
            let newState= Object.assign({}, state);
            newState.methodsIsLoaded = false;
            newState.methods = [];
            newState.defaultMethod = null;
            return newState;
        }
        case AC_PAYMENT.SET_PAYMENTS: {
            let newState= Object.assign({}, state);
            const { defaultMethodId, methods } = action.payload;            
            newState.methods = methods
            newState.defaultMethod = defaultMethodId;
            newState.methodsIsLoaded = true;
            return newState;
        }   

        default: {
            return state;
        }
    }
}
