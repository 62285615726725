import { AC_AUTH } from '@/actions/auth';
import emptyAvatar from '@/assets/images/default-avatar.png';
import { getFullName } from '@/utils/words';

const setUserData = (oldState, payload) => {
    let state = Object.assign({}, oldState);
    payload.displayName = payload.profile.first_name;
    payload.avatar = payload.profile.avatar_url || emptyAvatar;
    payload.profile.fullName = getFullName(payload.profile);
    state.user = payload;
    state.isAuth = true;
    state.isLoading = false;
    state.isChecked = true;
    return state;
}

const defaultState = {
    isAuth: false,
    user: null,
    isLoading: false,
    isChecked: false,
    userOrderCnt: null,
};

const updatePartUserData = (oldState, payload, part) => {  
    let state = Object.assign({}, oldState);
    switch(part) {
        case 'profile': {
            state.user.profile = Object.assign(state.user.profile, payload);
            state.user.displayName = state.user.profile.first_name; 
            state.user.profile.fullName = getFullName(state.user.profile);
            break;
        }
        case 'avatar': { 
            state.user.profile = Object.assign(state.user.profile, payload);            
            state.user.avatar = state.user.profile.avatar_url || emptyAvatar;
            //console.log('state', state, payload);
            break;
        }
        default: 
        {}
    }
    return state;
}
export default (state = defaultState, action) => {

    switch(action.type) {
        case AC_AUTH.FETCHING: {
            return Object.assign({}, state, {isLoading: true}); 
        }
        case AC_AUTH.SET_USER: {
            return setUserData(state, action.payload, action.part); 
        }
        case AC_AUTH.LOGIN: {
            return state;            
        }
        case AC_AUTH.PARTUAL_UPDATE: {
            return updatePartUserData(state, action.payload, action.part);            
        }
        case AC_AUTH.LOGOUT: { 
            let updated = Object.assign({}, defaultState); 
            updated.isChecked = true;
            return updated;
        } 
        case AC_AUTH.FETCH_ORDERS : {
            return Object.assign({}, state, {userOrderCnt: action.payload}); 
        }
        //      
        default: {
            return state;
        }
    }   
   
}
