export const cardsIcons = {
    "visa": {
        key: 'visa',
        name: 'Visa',
        icon: 'as-icon_card-visa'
    },
    "mastercard": {
        key: 'mastercard',
        name: 'Mastercard',
        icon: 'as-icon_card-mc'
    },
    "amex": {
        key: 'amex',
        name: 'Amex',
        icon: 'as-icon_card-amex'
    },
    "diners": {
        key: 'diners',
        name: 'Diners',
        icon: 'as-icon_card-default'
    },
    "discover": {
        key: 'discover',
        name: 'Discover',
        icon: 'as-icon_card-discover'
    },
    "jcb": {
        key: 'jcb',
        name: 'Jcb',
        icon: 'as-icon_card-default'
    },
    "unionpay": {
        key: 'unionpay',
        name: 'Unionpay',
        icon: 'as-icon_card-default'
    },    
    "default": {
        key: 'default',
        name: '',
        icon: 'as-icon_card-default'
    },   
};