import React, { Component } from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
//import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import FormField from '@/components/forms/form-field';
import MultiSelectField from '@/components/forms/multi-select-field';
import DatePickerField from '@/components/forms/date-picker';
import ManyAutocompleteField from '@/components/forms/many-autocomplete';
//import { FORMS } from '@/static/common.js';
import api, { setErrors, arrangeData, convertDateToDb } from '@/middlewares/api';



class AddCrateStep2 extends Component {

    constructor(props) {
        super(props);
        this.stepSubmit = this.stepSubmit.bind(this);
        this.goBack = this.goBack.bind(this);
    }
    goBack() {
        const {prevStep, rawValues} = this.props;
        prevStep(rawValues);
    }

    stepSubmit(data) {
        //let formData = arrangeData(data, null, ["song_writers", "song_publishers"]);               
        let formData = arrangeData(data, null, ["publishers", "labels"]);
        formData.release_date = convertDateToDb(formData.release_date); 
        return new Promise((resolve, reject) => {
            api.post('crate/step/two', formData)
            .then((response) => {               
                this.props.nextStep(formData, data);
                return resolve(null);
            })
            .catch((err) => {
                if(! err.response) return;
                if(err.response.status === 422) {
                    return reject(setErrors(err.response));                            
                }            
            });
        });
    }
// produced_by
    // artist_manager
    // publishers[]
    // labels[]
    // video_url
    // genres[]
    // release_date

    render() {        
        const { handleSubmit, crateGenresOptions, submitting, title, image } = this.props;
        const secStyle = { backgroundImage: "url(" +  image  + ")"};
        return (
            <section className="entity-sec banner-filter banner-filter_fw" style={ secStyle }>
                <div className="content">
                    <div className="entity-sec__top">  
                        <h2 className="head-shadow">Sample to { title }</h2>
                        <h3 className="head">
                            Step 2 of 3: Who did it? 
                        </h3>
                        <div className="form-step__top-desc">                            
                        </div>
                    </div>
                    <div className="form-step__body">
                        <form onSubmit={handleSubmit((data) => { 
                                return this.stepSubmit(data);
                            }) } className="form-layout"
                            noValidate>
                            <div className="form-row">
                                <div className="form-col_2">                                
                                    <Field                            
                                        name="produced_by"
                                        component={FormField}                                        
                                        type="text"
                                        placeholder="Set Producer's Name"
                                        label="Produced By"                            
                                    />
                                    <Field                            
                                        name="video_url"
                                        component={FormField}
                                        type="text"
                                        required={true}
                                        placeholder="Set URL to music video"
                                        label="YouTube URL"                            
                                    />
                                </div>                                
                                <div className="form-col_2">
                                    <Field                            
                                        name="artist_manager"
                                        component={FormField}                                        
                                        type="text"
                                        placeholder="Set manager's first and last name"
                                        label="Artist Manager"                            
                                    />                                    
                                    <Field                            
                                        name="genres"
                                        required={true}
                                        component={MultiSelectField}
                                        options={crateGenresOptions}
                                        placeholder="Select Genre"
                                        label="Genre"                            
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-col_2">
                                    <Field                            
                                        name="publishers"
                                        required={true}                                        
                                        label="Published By"
                                        component={ManyAutocompleteField}
                                        url="clear/publisher/search"                                       
                                        placeholder="Set Publisher(s)"                            
                                    />   
                                </div>
                                <div className="form-col_2"> 
                                    <Field                            
                                        name="labels"
                                        required={true}
                                        component={ManyAutocompleteField}
                                        url="clear/label/search"                                       
                                        placeholder="Set label"  
                                        label="Record Label"                            
                                    />  
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-col_2">
                                    <Field                            
                                        name="release_date"
                                        component={DatePickerField}                                       
                                        placeholder="MM/DD/YY"
                                        label="Release Date"
                                        required={true} 
                                        note="State the date your project will be released."                                                               
                                    />   
                                </div>
                            </div>
                            <div className="submit-row submit-row_txt">  
                                    <button className="btn btn-second" onClick={ this.goBack }
                                        type="button">Back</button>                                  
                                    <button className="btn btn-primary" disabled={ submitting }
                                        type="submit">Next</button>
                            </div>
                        </form>                        
                    </div>
                </div>
            </section>            
        )
    }
}

const AddCrateStep2Form =  reduxForm({
    form: 'add-crate-sample-step2',
    enableReinitialize: true  
  })(AddCrateStep2);

//export default AddCrateStep2Form;
const mapState = (state) => {
    return {
        rawValues: getFormValues('add-crate-sample-step2')(state)
    }
}
export default connect(mapState)(AddCrateStep2Form);
