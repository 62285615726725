import img1 from '@/assets/images/brands/art_of_war.png';
import img2 from '@/assets/images/brands/atlantic.png';
import img3 from '@/assets/images/brands/def.png';
import img4 from '@/assets/images/brands/shady.png';
import img5 from '@/assets/images/brands/sony.png';



const brands = [
    {
        id: 'br1',
        image: img1,
        alt: 'art_of_war',
        url: 'https://www.artatwarent.com/portfolio/the-label'
    },
    {
        id: 'br2',
        image: img2,
        alt: 'atlantic',
        url: 'https://www.atlanticrecords.com'
    },
    {
        id: 'br3',
        image: img3,
        alt: 'def',
        url: 'https://www.defjam.com/#/'
    },
    {
        id: 'br4',
        image: img4,
        alt: 'shady',
        url: 'https://shadyrecords.com/'
    },
    {
        id: 'br5',
        image: img5,
        alt: 'sony',
        url: 'https://www.sonymusic.com/'
    }
];
export default brands