import { StatusesClassesMap } from '@/static/statuses.js';
import { extractDataWithMap } from './common';

export const extractDasboardCard = (item) => { 
    const {id, clearance, clearance_status, order_status, song_new_name} = item;
    const clearance_status_class = (StatusesClassesMap[clearance_status]) ? 
        StatusesClassesMap[clearance_status] : "";
    const order_status_class = (StatusesClassesMap[order_status]) ? 
        StatusesClassesMap[order_status] : "";    
    return {
        id,
        song_new_name,        
        clearance, 
        clearance_status, 
        order_status,
        clearance_status_class,
        order_status_class,        
    }
};

const samplesMap = [
    {server: 'type_use', label: 'Type of Use', type: 'string'},
    {server: 'clearance_status', label: 'CLEARANCE Status', type: 'status'},
    {server: 'order_status', label: 'Order Status', type: 'status'},
    {server: 'artist_name', label: 'ARTIST', type: 'string'},
    {server: 'song_new_name', label: 'Song TITLE', type: 'string'},
    {server: 'song_writers', label: 'SONG WRITER(S)', type: 'arr_names', key: 'name'},
    {server: 'song_publishers', label: 'PUBLISHER', type: 'arr_names', key: 'name'},
    //{server: 'splits', label: 'SONG SPLITS', type: 'string'},
    {server: 'sampled_name', label: 'SAMPLED SONG', type: 'string'},
    {server: 'sampled_writers', label: 'Sampled Song Writer', type: 'arr_names', key: 'name'},
    {server: 'labels', label: 'SAMPLED SONG RECORD LABEL', type: 'arr_names', key: 'name'},
    {server: 'sampled_publishers', label: 'SAMPLED SONG PUBLISHERS', type: 'arr_names', key: 'name'},
    {server: 'description', label: 'Description of Use', type: 'raw'},    
];

const getUse = (data) => {
  //master_sampled_used //publishing_sampled_used
  if( data.master_sampled_used && data.publishing_sampled_used) {
    return 'Mixed use';
  } else if (data.master_sampled_used) {
    return 'Master use';
  } else if (data.publishing_sampled_used) {
    return 'Publishing use';
  }
  return '';
}

export const arrangeOneInfo = (data) => {
    const {id, song_new_name} = data;    
    //console.log(data);
    data.type_use = getUse(data);
    return {
        id: id,
        title: song_new_name,
        features: extractDataWithMap(data, samplesMap)
    }    
};


/*
{
    "id": 1,
    "name": "test_1 test_1",
    "email": "test_1@test.com",
    "business_address": "we wqe wqe wer",
    "phone_number": "3434354657",
    "party_responsible_payment": "test_1",
    "author_sign_sample_agreement": "freer",
    "song_url": "/storage/2021/05/06/MQq5ffhJUHtcj0J9cXPLnCtwR9mYApADUUSd3H4a.mp4",
    "artist_name": "wq wq q",
    "song_new_name": "qwwqewqw",
    "splits": "w q",
    "sampled_name": "2 21321",
    "sampled_link": "https://www.youtube.com/watch?v=L2rSl8C-e0w",
    "description": "wwqewqe ewqe",
    "master_sampled_used": 1,
    "publishing_sampled_used": 0,
    "clearance_status": "New Project",
    "order_status": "1st Half Independent (Not Paid)",
    "phase": "First Half",
    "artist_id": 1,
    "user_id": 2,
    "label_id": 36,
    "distribution_id": 1,
    "created_at": "2021-05-06T14:23:27.000000Z",
    "updated_at": "2021-05-10T09:15:41.000000Z",
    "distribution": {
      "id": 1,
      "name": "test_1"
    },
    "label": {
      "id": 36,
      "name": "test_1"
    },
    "song_publishers": [
      {
        "id": 20,
        "name": "qqwq",
        "created_at": "2021-05-06T14:23:27.000000Z",
        "updated_at": "2021-05-06T14:23:27.000000Z",
        "pivot": {
          "clear_sample_id": 1,
          "publisher_id": 20
        }
      }
    ],
    "sampled_publishers": [
      {
        "id": 17,
        "name": "Mass Appeal-Wixen",
        "created_at": "2021-05-06T13:53:55.000000Z",
        "updated_at": "2021-05-06T13:53:55.000000Z",
        "pivot": {
          "clear_sample_id": 1,
          "publisher_id": 17
        }
      }
    ],
    "labels": [
      {
        "id": 37,
        "name": "wqewqeewq",
        "created_at": "2021-05-06T14:23:27.000000Z",
        "updated_at": "2021-05-06T14:23:27.000000Z",
        "pivot": {
          "clear_sample_id": 1,
          "label_id": 37
        }
      }
    ],
    "song_writers": [
      {
        "id": 1,
        "name": "wwww qwq",
        "created_at": "2021-05-06T14:23:27.000000Z",
        "updated_at": "2021-05-06T14:23:27.000000Z",
        "pivot": {
          "clear_sample_id": 1,
          "writer_id": 1
        }
      }
    ],
    "sampled_writers": [
      {
        "id": 2,
        "name": "wqewqewqewq",
        "created_at": "2021-05-06T14:23:27.000000Z",
        "updated_at": "2021-05-06T14:23:27.000000Z",
        "pivot": {
          "clear_sample_id": 1,
          "writer_id": 2
        }
      }
    ]
  }
  */