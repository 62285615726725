import React, { Component } from 'react'

export default class TermsPage extends Component {
    render() {
        return (
            <main className="page text-page">
                <div className="content">                    
                    <h3 className="head">Terms and Conditions</h3>
                    <div className="text-page__body">
                        <p>The Terms of Use (“Terms”) apply to your access to and use of the website, 
                        mobile application and other online services or mediums not yet invented (collectively, 
                        our “Services”) provided by Ample Samples, Inc.  (“Ample Samples,” “we,” “us” or “our”). 
                        By accessing or using our Services, you agree to these Terms. If you do not agree to 
                        these Terms, including the mandatory arbitration provision and class action waiver in 
                        Section 19, do not access or use our Services.
                        </p><p>
                        These Terms do not alter in any way the terms or conditions of any other agreement 
                        you may have with Ample Samples for products, services or otherwise. 
                        If you are accessing or using our Services on behalf of another person or entity, you represent and warrant 
                        that you are authorized to accept these Terms on such person or entity's behalf, and that such person 
                        or entity will be responsible to Ample Samples if you violate these Terms.
                        </p>
                        <h4>1. Eligibility, Registration and Account</h4>
                        <p>By using our Services, you represent and warrant that you (a) are 18 years of age or older, (b) 
                            have not been previously suspended or removed from our Services, or engaged in any activity that could 
                            result in suspension or removal from our Services, (c) will not offer access from more than one Ample 
                            Samples account without our express permission, and (d) have full power and authority to enter into 
                            these Terms and in so doing will not violate any other agreement to which you are a party.
                        </p><p>In order to access or use certain areas and features of our Services, you may need to register for 
                            an account. By registering for an account, you agree to (i) provide accurate, truthful, current and 
                            complete account information; (ii) maintain and promptly update your account information to maintain 
                            its accuracy and completeness; (iii) maintain the security of your account by not sharing your password 
                            with others and restricting access to your account, computer and device; (iv) promptly notify 
                            Ample Samples if you discover or otherwise suspect any security breaches related to our Services or your 
                            account; and (v) take responsibility for all activities that occur under your account and accept all risks of unauthorized access.
                        </p>
                        <h4>2. Communications Preferences</h4>
                        <p>By creating an Ample Samples account, you consent to receive electronic communications from Ample Samples 
                        (e.g., via email or by posting notices on our Services). These communications may include notices about 
                        your account (e.g., payment authorizations, password changes and other transactional information) and 
                        are part of your relationship with us. You agree that any notices, agreements, disclosures or other 
                        communications that we send to you electronically will satisfy any legal communication requirements, 
                        including, but not limited to, that such communications be in writing. You should maintain copies of 
                        electronic communications from us by printing a paper copy or saving an electronic copy. We may also send 
                        you promotional communications via email, including, but not limited to, newsletters, special offers, 
                        surveys and other news and information we think will be of interest to you. You may opt out of 
                        receiving these promotional emails at any time by following the unsubscribe instructions provided in 
                        these communications.</p>
                        <h4>3. Our Services Offer a Venue</h4>
                        <p>Our Services offers tools, resources and a venue to connect those seeking educational tutoring 
                        and/or instructions with those seeking to provide such services. Ample Samples: (a) does not employ, 
                        recommend or endorse any users and has no control over the acts or omissions of any users; (b) 
                        is not responsible or liable in any manner for the performance or conduct of any users or other 
                        third parties online or offline; (c) makes no representations or warranties about the quality of the 
                        services provided by any user or about your interactions or dealings with other users except as expressly 
                        stated on our Services; and (d) does not screen users or conduct any kind of identity or background checks 
                        except as otherwise expressly stated in these Terms or on or in our Services. Regardless of whether Ample 
                        Samples screens users or performs a background check, you should exercise caution and perform your own 
                        screening before connecting or meeting with any other user through our Services, entering into a contract 
                        or agreement with any other user or obtaining any services. Ample Samples hereby expressly disclaims, and 
                        you hereby expressly release Ample Samples from, any and all liability whatsoever for any controversies, 
                        claims, suits, injuries, loss, harm or damages arising from or related to our Services or your interactions 
                        or dealings with other users, including any acts or omissions of users online or offline. All use of our 
                        Services is at your sole and exclusive risk.</p>
                        <h4>4. User Transactions; Release</h4>
                        <p>Our Services may be used to help obtain or offer access provided by users and to facilitate payment 
                        for such services. However, users transact solely between themselves and Ample Samples is not a party to 
                        any transactions between users. Ample Samples hereby expressly disclaims, and you hereby expressly release 
                        Ample Samples from, any and all liability whatsoever for any controversies, claims, suits, injuries, loss, 
                        harm or damages arising from or related to disputes, dealings, or interactions between you and any other 
                        users or third parties.
                        </p><p>If you offer access, the first and all future bookings with Ample Samples users must be booked 
                            via our Services. Failure to abide by this policy may result in suspension from our Services.
                        </p>
                        <h4>5. Payment and Billing Information</h4>
                        <p>By providing a credit card or other payment method that we accept, you represent and warrant that you are 
                        authorized to use the designated payment method and that you authorize us (or our third-party payment 
                        processor) to charge your payment method for the total amount of your purchase (including any applicable 
                        taxes and other charges) (collectively, as applicable, an “Order”). If the payment method cannot be 
                        verified, is invalid or is otherwise not acceptable, your Order may be suspended or cancelled. 
                        You must resolve any problem we encounter in order to proceed with your Order. In the event you want 
                        to change or update payment information associated with your Ample Samples account, you can do so at 
                        any time by logging into your account and editing your payment information.
                        </p><p>You acknowledge that the amount billed may vary due to promotional offers, changes to your access 
                        or changes in applicable taxes or other charges, and you authorize us (or our third-party payment 
                        processor) to charge your payment method for the corresponding amount.
                        </p>
                        <h4>5.1. Pricing and Availability</h4>
                        <p>All prices are shown in U.S. dollars and applicable taxes and other charges, if any, are additional. 
                        Prices may be adjusted at any time and for any reason (or no reason) and without providing you prior notice.
                        </p><p>Access is subject to availability, and we reserve the right to cancel all or part of an access 
                        and to discontinue making certain access available through our Services without prior notice.
                        </p>
                        <h4>5.2. Returns and Refunds</h4>
                        <p>If you are dissatisfied with a  for any reason, please contact us within one (1) day of the date you 
                        completed your first access and we will provide you a full refund of the purchase price for that 
                        instruction (unless otherwise specified during a promotion or special offer). If you do not comply with the 
                        terms of this section, you will be ineligible to receive a refund. Any access left incomplete after 90 
                        days from purchase date will be eligible for a merchant credit but ineligible for a refund.    
                        </p>
                        <h4>6. Copyright and Limited License</h4>
                        <p>Unless otherwise indicated in writing by us, our Services and all content and other materials on our 
                        Services, including the Ample Samples logo and all designs, text, graphics, pictures, information, data, 
                        software, sound files, other files and the selection and arrangement thereof (collectively, the “Service 
                        Materials”) are the proprietary property of Ample Samples or our licensors or users, as applicable, and 
                        are protected by U.S. and international copyright laws.
                        </p><p>Subject to these Terms, you are granted a limited, non-exclusive, non-transferable, non-sublicensable 
                        license to access and use our Services and Service Materials. However, such license is revocable at any 
                        time and does not include any right to: (a) sell or resell our Services or the Service Materials; (b) 
                        collect and use any service listings, pictures or descriptions; (c) distribute, publicly perform or 
                        publicly display any of the Service Materials; (d) modify or otherwise make any derivative uses of our 
                        Services or the Service Materials, or any portion thereof; (e) use any data mining, robots or similar 
                        data gathering or extraction methods; (f) download (other than the page caching) any portion of our 
                        Services, the Service Materials or any information contained therein, except as expressly permitted by 
                        Ample Samples in writing; or (g) use our Services or the Service Materials in violation of these Terms 
                        or for any purposes other than their intended purposes. Your right to use our Services and the Service 
                        Materials is conditioned upon your use of our Services and the Service Materials in accordance with 
                        these Terms. Accordingly, any use of the Services or the Service Materials other than as specifically 
                        authorized in these Terms, without the prior written permission of Ample Samples, will automatically 
                        terminate the license granted above. Such unauthorized use may also violate applicable laws, including, 
                        without limitation, copyright and trademark laws and applicable communications regulations and statutes. 
                        Except for the license granted above, no additional rights are granted and nothing in these Terms will 
                        be construed as conferring any license to intellectual property rights, whether by estoppel, 
                        implication or otherwise.
                        </p><p>Notwithstanding anything to the contrary in these Terms, our Services and the Service Materials 
                        may include software components provided by Ample Samples or a third party that are subject to separate 
                        license terms, in which case those license terms will govern such software components.
                        </p>
                        <h4>7. Copyright Complaints</h4>
                        <p>We have a policy of limiting access to our Services and terminating the accounts of users who infringe the intellectual 
                        property rights of others. If you believe that anything on our Services infringes any copyright that you 
                        own or control, you may notify us as set forth below.
                        </p>
                        <p>
                          Ample Samples Inc.<br/>
                          18-20 Lackawanna Plaza<br/>
                          Montclair, NJ 07042
                        </p>
                        <p>Please see <a href="#512" target="_blank" rel="noopener noreferrer">17 U.S.C. §512(c)(3)</a> for the requirements of a proper notification. You should note that if you knowingly 
                        misrepresent in your notification that the material or activity is infringing, you may be liable for any 
                        damages, including costs and attorneys’ fees, incurred by Ample Samples or the alleged infringer as the 
                        result of Ample Samples’ relying upon such misrepresentation in removing or disabling access to the 
                        material or activity claimed to be infringing.
                        </p>
                        <h4>8. Trademarks</h4>
                        <p>“Ample Samples,” the Ample Samples logos and any other product or service name or slogan contained on our Services are trademarks of Ample Samples 
                        and its suppliers or licensors and may not be copied, imitated or used, in whole or in part, without the prior written permission of Ample Samples 
                        or the applicable trademark holder.
                        </p><p>You may not use any metatags or any other hidden text utilizing “Ample Samples” or any other name, 
                        trademark or product or service name of Ample Samples without Ample Samples’ prior written permission. 
                        In addition, the look and feel of our Services, including all page headers, custom graphics, button icons 
                        and scripts, is the service mark, trademark and/or trade dress of Ample Samples and may not be copied, 
                        imitated or used, in whole or in part, without Ample Samples’ prior written permission. All other trademarks, 
                        registered trademarks, product names and company names or logos mentioned on our Services are the property of 
                        their respective owners. Reference to any products, services, processes or other information, by trade name, trademark, 
                        manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship or recommendation thereof by Ample Samples.
                        </p>
                        <h4>9. Third-Party Content</h4>
                        <p>Our Services may include links to websites or content owned or operated by third parties as well as other third-party content, including advertisements, 
                        promotional offers, and social “widgets” (collectively, “Third-Party Content”). Ample Samples does not own, control or endorse any Third-Party Content 
                        and makes no representation or warranties of any kind regarding the Third-Party Content, including, without limitation, regarding its accuracy or completeness. 
                        You acknowledge and agree that Ample Samples is not responsible or liable in any manner for any Third-Party Content and undertakes no responsibility to update 
                        or review any Third-Party Content. You access and use such Third-Party Content at your own risk. The inclusion of Third-Party Content on our Services does not imply 
                        affiliation, endorsement or adoption by Ample Samples of any information contained therein. In addition, your business dealings or correspondence with, or participation in 
                        the promotional offers of, any third party responsible for Third-Party Content, and any terms, conditions, warranties or representations associated with such dealings or promotional 
                        offers, are solely between you and such third party. Ample Samples is not responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or promotions 
                        or as the result of the presence of such Third-Party Content on our Services. When you navigate away from or otherwise leave our Services, you should understand that Ample Samples’ terms and 
                        policies no longer govern and that the terms and policies of those third-party sites will now apply. You should review the applicable terms and policies, including privacy and data gathering practices, 
                        of any site or services to which you navigate to from our Services.
                        </p>
                        <h4>10. User Content and Interactive Areas</h4>
                        <p>Our Services include interactive areas and features (“Interactive Areas”) that allow you or other users to create, post, share or store text, 
                        photos, videos and other materials (collectively, “User Content"). In the event you decide to share your User Content with others through our 
                        Services or third-party platforms, you understand that this User Content will be viewable by others. You are solely responsible for your User 
                        Content and for your use of any Interactive Areas.
                        </p><p>You will not post, upload to, transmit, distribute, store, create or otherwise publish or send through our Services 
                            any of the following:
                        <ul>
                        <li>User Content that is unlawful, libelous, defamatory, obscene, pornographic, indecent, lewd, suggestive, 
                        harassing, threatening, invasive of privacy or publicity rights, abusive, inflammatory, fraudulent, false, 
                        misleading or otherwise objectionable;
                        </li><li>User Content that would constitute, encourage or provide instructions for a criminal offense, 
                        violate the rights of any party or otherwise create liability or violate any local, state, national or 
                        international law;
                        </li><li>User Content that may infringe any patent, trademark, trade secret, copyright or other proprietary 
                        right of any party;
                        </li><li>User Content that contains or depicts any statements, remarks or claims that do not reflect your 
                        honest views and experiences;
                        </li><li>User Content that contains personal information about any person, including, without limitation, 
                        names, addresses, email address or credit card information without that person’s permission;
                        </li><li>User Content that impersonates or misrepresents your affiliation with any person or entity;
                        </li><li>Unsolicited promotions, political campaigning, advertising or solicitations;
                        </li><li>Viruses, corrupted data or other harmful, disruptive or destructive files or content; or
                        </li><li>User Content that, in the sole judgment of Ample Samples, is objectionable or which restricts or 
                        inhibits any other person from using or enjoying the Interactive Areas or our Services, or which may 
                        expose Ample Samples or its users to any harm or liability of any type.
                        </li></ul></p><p>Ample Samples does not control, take responsibility for or assume liability for any User 
                        Content posted, stored or uploaded by you or any third party, or for any loss or damage to such User 
                        Content. When you participate in Interactive Areas, you understand that certain User Content you choose 
                        to post may be displayed publicly or to select users. You are solely responsible for your use of our 
                        Services and the Interactive Areas and use them at your own risk.    
                        </p>
                        <h4>11. Rights to User Content</h4>
                        <p>Ample Samples claims no ownership or control over any User Content, except as otherwise expressly provided in these 
                        Terms or a separate agreement between you and Ample Samples. However, if you submit or post User Content to our Services, 
                        unless Ample Samples indicates otherwise, you grant Ample Samples a worldwide, nonexclusive, perpetual, irrevocable, 
                        royalty-free, fully-paid up, and sub-licensable license to use, reproduce, modify, adapt, publish, translate, create derivative 
                        works from, distribute, publicly perform and display your User Content in any media format and channel now known or later developed 
                        without compensation to you, including in connection with Ample Samples’ marketing and promotional activities. You further grant Ample 
                        Samples and Ample Samples’ sublicensees the right to use the name that you submit in connection with User Content, if they choose.
                        </p><p>By submitting or posting User Content to our Services, you represent and warrant that: (a) such User Content is non-confidential; 
                        (b) you own and control all of the rights to the User Content that you post or you otherwise have all necessary rights to post such 
                        User Content on our Services; (c) the User Content is accurate and not misleading or harmful in any manner; and (d) the User Content, 
                        and your use and posting thereof in connection with our Services, does not and will not violate these Terms or any applicable law, rule, 
                        regulation or third-party right.
                        </p>
                        <h4>12. Acceptable Use of Our Services</h4>
                        <p>Your use of our Services will not violate any law, contract, intellectual property or other third-party 
                        right or constitute a tort, and you are solely responsible for your conduct while on our Services. You  will not:
                        <ul><li>Use our Services in any manner that could interfere with, disrupt, negatively affect or inhibit other users 
                        from fully enjoying our Services, or that could damage, disable, overburden or impair the functioning of 
                        our Services in any manner;
                        </li><li>Engage in any discriminatory, defamatory, hateful, harassing, abusive, obscene, threatening, 
                        physically dangerous, or otherwise objectionable conduct;
                        </li><li>Attempt to indicate in any manner that you have a relationship with Ample Samples or that 
                        Ample Samples has endorsed you or any services for any purpose unless Ample Samples has provided 
                        written permission to do so;
                        </li><li>Send any unsolicited or unauthorized advertising, solicitations, promotional materials, spam, 
                        junk mail, chain letters or pyramid schemes, or harvest or collect the email addresses or other contact 
                        information of other users for the purpose of sending spam or other commercial messages;
                        </li><li>Attempt to reverse engineer any aspect of our Services or do anything that might discover source 
                        code or bypass or circumvent measures employed to prevent or limit access to any area, content or code 
                        of our Services (except as otherwise expressly permitted by law);
                        </li><li>Access, use or attempt to access or use another's account without authorization from such user 
                        and Ample Samples;
                        </li><li>Develop any third-party application that interacts with User Content or our Services without 
                        Ample Samples’ prior written consent;
                        </li><li>Use any robot, iframe, spider, crawler, scraper or other automated means or interface not provided 
                        or authorized by Ample Samples to access out Services, including, without limitation, for the purpose 
                        of copying, extracting, aggregating, displaying, publishing or distributing any content or data made 
                        available via our Services; or
                        </li><li>Use our Services for any illegal or unauthorized purpose, or engage in, encourage or promote any 
                        activity that violates these Terms.
                        </li></ul></p>
                        <h4>13. Feedback</h4>
                        <p>Separate and apart from User Content, you can submit questions, comments, feedback, suggestions, ideas, 
                        plans, notes, drawings, original or creative materials or other information, regarding our Services or 
                        Ample Samples (collectively, “Feedback”). Feedback is non-confidential and will become the sole property 
                        of Ample Samples. Ample Samples will own, and you hereby assign to Ample Samples, all right, title, and 
                        interest, including all intellectual property rights, in and to such Feedback, and Ample Samples will be 
                        entitled to the unrestricted use and dissemination of Feedback for any purpose, commercial or otherwise, 
                        without acknowledgment or compensation to you. You agree to execute any documentation required by Ample 
                        Samples to confirm such assignment to Ample Samples.
                        </p>
                        <h4>14. Indemnification</h4>
                        <p>You will defend, indemnify and hold harmless Ample Samples, its independent contractors, service providers 
                        and consultants, and their respective directors, officers, employees and agents (collectively, 
                        “Ample Samples Parties”), from and against any claims, damages, costs, liabilities and expenses 
                        (including, but not limited to, reasonable attorneys' fees) arising out of or related to (a) your access 
                        to or use of our Services; (b) any User Content or Feedback you provide; (c) your violation of these 
                        Terms; (d) your violation, misappropriation or infringement of any rights of another (including 
                        intellectual property rights or privacy rights); or (e) your conduct in connection with our Services.
                        </p>
                        <h4>15. Disclaimers</h4>
                        <p>We do not control, endorse or take responsibility for any third-party content available on or linked 
                        to by our Services, including User Content.
                        </p><p>Your use of our Services is at your sole risk. Our Services are provided “as is” and “as available” 
                        without warranties of any kind, either express or implied, including, but not limited to, implied 
                        warranties of merchantability, fitness for a particular purpose, title, and non-infringement. 
                        In addition, Ample Samples does not represent or warrant that our Services are accurate, complete, 
                        reliable, current or error-free. While Ample Samples attempts to make your access to and use of our 
                        Services safe, we cannot and do not represent or warrant that our Services or servers are free of 
                        viruses or other harmful components. You assume the entire risk as to the quality and performance of 
                        the Services.
                        </p>
                        <h4>16. Limitation of Liability</h4>
                        <p>Ample Samples and the other Ample Samples Parties will not be liable to you under any theory of 
                        liability—whether based in contract, tort, negligence, strict liability, warranty, or otherwise—for any 
                        indirect, consequential, exemplary, incidental, special or punitive damages or lost profits, to the 
                        fullest extent permitted by the applicable law, even if Ample Samples or the other Ample Samples Parties 
                        have been advised of the possibility of such damages. The limitations of liability set for in this 
                        section will apply even if the remedies otherwise provided under these Terms, at law or in equity, fail 
                        of their essential purpose.
                        </p><p>The total liability of Ample Samples and the other Ample Samples Parties, for any claim arising out of 
                        or relating to these Terms or our Services, regardless of the form of the action, is limited to the 
                        greater of (a) the amount paid, if any, by you to access or use our Services or (b) $50.
                        </p><p>The limitations set forth in this section will not limit or exclude liability for the gross 
                        negligence, fraud or intentional misconduct of Ample Samples or the other Ample Samples Parties.
                        </p>
                        <h4>17. Assumption of Risk</h4>
                        <p>You assume all risks when using our Services, including all of the risks associated with interactions 
                        with other users. You agree to take all necessary precautions when interacting with other users.
                        </p>
                        <h4>18. Modifications to Our Services</h4>
                        <p>Ample Samples reserves the right to modify or discontinue, temporarily or permanently, our Services, 
                        or any features or portions thereof, without prior notice. You agree that Ample Samples will not be 
                        liable for any modification, suspension or discontinuance of our Services, or any part thereof.
                        </p> 
                        <h4>19. Dispute Resolution; Arbitration</h4>
                        <p>PLEASE READ THE FOLLOWING SECTION CAREFULLY BECAUSE IT REQUIRES YOU TO ARBITRATE CERTAIN DISPUTES AND 
                        CLAIMS WITH AMPLE SAMPLES AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM US.
                        </p>
                        <h4>19.1. Binding Arbitration</h4>
                        <p>Except for any disputes, claims, suits, actions, causes of action, demands or proceedings (collectively, 
                        “Disputes”) arising out of or related to a violation of Sections 10 or 12 or Disputes in which either 
                        party seeks to bring an individual action in small claims court or seeks injunctive or other equitable 
                        relief for the alleged unlawful use of intellectual property, including, without limitation, copyrights, 
                        trademarks, trade names, logos, trade secrets or patents, you and Ample Samples agree (a) to waive your 
                        and Ample Samples’ respective rights to have any and all Disputes arising from or related to these Terms, 
                        our Services or the products and services available on our Services, resolved in a court, and (b) to 
                        waive your and Ample Samples’ respective rights to a jury trial. Instead, you and Ample Samples agree 
                        to arbitrate Disputes through binding arbitration (which is the referral of a Dispute to one or more 
                        persons charged with reviewing the Dispute and making a final and binding determination to resolve it 
                        instead of having the Dispute decided by a judge or jury in court).
                        </p>
                        <h4>19.2. No Class Arbitrations, Class Actions or Representative Actions</h4>
                        <p>You and Ample Samples agree that any Dispute arising out of or related to these Terms, our Services or 
                        the products and services available on our Services is personal to you and Ample Samples and that such 
                        Dispute will be resolved solely through individual arbitration and will not be brought as a class 
                        arbitration, class action or any other type of representative proceeding. You and Ample Samples agree 
                        that there will be no class arbitration or arbitration in which an individual attempts to resolve a 
                        Dispute as a representative of another individual or group of individuals. Further, you and Ample 
                        Samples agree that a Dispute cannot be brought as a class or other type of representative action, 
                        whether within or outside of arbitration, or on behalf of any other individual or group of individuals.
                        </p><h4>19.3. Federal Arbitration Act</h4>
                        <p>You and Ample Samples agree that these Terms affect interstate commerce and that the enforceability of 
                        this Section 19 will be both substantively and procedurally governed by and construed and enforced in 
                        accordance with the Federal Arbitration Act, 9 U.S.C. § 1 et seq. (the “FAA”), to the maximum extent 
                        permitted by applicable law.
                        </p>
                        <h4>19.4. Notice; Informal Dispute Resolution</h4>
                        <p>You and Ample Samples agree that each party will notify the other party in writing of any arbitrable or 
                        small claims Dispute within thirty (30) days of the date it arises, so that the parties can attempt in 
                        good faith to resolve the Dispute informally. Notice to Ample Samples will be sent by certified mail or 
                        courier to 1107 Castle Drive, Union, NJ 07083. Your notice must include (a) your name, postal address, 
                        telephone number, the email address you use or used for your Ample Samples account and, if different, an 
                        email address at which you can be contacted, (b) a description in reasonable detail of the nature or basis 
                        of the Dispute, and (c) the specific relief that you are seeking. Our notice to you will be sent electronically 
                        and will include (x) our name, postal address, telephone number and an email address at which we can be 
                        contacted with respect to the Dispute, (y) a description in reasonable detail of the nature or basis of 
                        the Dispute, and (z) the specific relief that we are seeking. If you and Ample Samples cannot agree how 
                        to resolve the Dispute within thirty (30) days after the date notice is received by the applicable party, 
                        then either you or Ample Samples may, as appropriate and in accordance with this Section 19, commence an 
                        arbitration proceeding or, to the extent specifically provided for in Section 19.1, file a claim in court.
                        </p>
                        <h4>19.5. Process</h4>
                        <p>Except for Disputes arising out of or related to a violation of Sections 10 or 12 or Disputes in which 
                        either party seeks to bring an individual action in small claims court or seeks injunctive or other 
                        equitable relief for the alleged unlawful use of intellectual property, including, without limitation, 
                        copyrights, trademarks, trade names, logos, trade secrets or patents, you and Ample Samples agree that 
                        any Dispute must be commenced or filed by you or Ample Samples within one (1) year of the date the 
                        Dispute arose, otherwise the underlying claim is permanently barred (which means that you and Ample 
                        Samples will no longer have the right to assert such claim regarding the Dispute). You and Ample Samples 
                        agree that (a) any arbitration will occur in Union, New Jersey, (b) arbitration will be conducted 
                        confidentially by a single arbitrator in accordance with the rules of the Judicial Arbitration and 
                        Mediation Services (“JAMS”), which are hereby incorporated by reference, and (c) that the state or 
                        federal courts of the State of New Jersey and the United States, respectively, sitting in Union, 
                        New Jersey, have exclusive jurisdiction over any appeals and the enforcement of an arbitration award. 
                        You may also litigate a Dispute in the small claims court located in the county of your billing address 
                        if the Dispute meets the requirements to be heard in small claims court.
                        </p>
                        <h4>19.6. Authority of Arbitrator</h4>
                        <p>As limited by the FAA, these Terms and the applicable JAMS rules, the arbitrator will have (a) the 
                        exclusive authority and jurisdiction to make all procedural and substantive decisions regarding a 
                        Dispute, including the determination of whether a Dispute is arbitrable, and (b) the authority to 
                        grant any remedy that would otherwise be available in court; provided, however, that the arbitrator 
                        does not have the authority to conduct a class arbitration or a representative action, which is 
                        prohibited by these Terms. The arbitrator may only conduct an individual arbitration and may not 
                        consolidate more than one individual’s claims, preside over any type of class or representative 
                        proceeding or preside over any proceeding involving more than one individual.
                        </p>
                        <h4>19.7. Rules of JAMS</h4>
                        <p>The rules of JAMS and additional information about JAMS are available on the 
                        <a href="http://www.jamsadr.com/" target="_blank" rel="noopener noreferrer">JAMS website</a>. By agreeing to 
                        be bound by these Terms, you either (a) acknowledge and agree that you have read and understand the rules 
                        of JAMS, or (b) waive your opportunity to read the rules of JAMS and any claim that the rules of JAMS are 
                        unfair or should not apply for any reason.   
                        </p>
                        <h4>19.8. Severability</h4>
                        <p>If any term, clause or provision of this Section 19 is held invalid or unenforceable, it will be so held 
                        to the minimum extent required by law, and all other terms, clauses and provisions of this Section 19 
                        will remain valid and enforceable. Further, the waivers set forth in Section 19.2 are severable from the 
                        other provisions of these Terms and will remain valid and enforceable, except as prohibited by applicable law.
                        </p>
                        <h4>19.9 Opt-Out Right</h4>
                        <p>You have the right to opt out of binding arbitration within thirty (30) days of the date you first 
                        accepted the terms of this Section 19 by writing to: 1107 Castle Drive, Union, NJ 07083. In order to be 
                        effective, the opt out notice must include your full name and clearly indicate your intent to opt out of 
                        binding arbitration. By opting out of binding arbitration, you are agreeing to resolve Disputes in 
                        accordance with Section 20.
                        </p>
                        <h4>20. Applicable Law and Venue</h4>
                        <p>These Terms and your use of our Services will be governed by and construed in accordance with the laws of 
                        New Jersey, without resort to its conflict of law provisions. To the extent the arbitration provision in 
                        Section 19 does not apply, you agree that any action at law or in equity arising out of or relating to 
                        these Terms will be filed only in the state and federal courts located in Union, New Jersey and you 
                        hereby irrevocably and unconditionally consent and submit to the exclusive jurisdiction of such courts 
                        over any suit, action or proceeding arising out of these Terms.
                        </p>
                        <h4>21. Termination </h4>
                        <p>Ample Samples reserves the right, without notice and in its sole discretion, to terminate your license to 
                        access and use our Services and to block or prevent your future access to and use of our Services.
                        </p>
                        <h4>22. Changes to These Terms</h4>
                        <p>We may make changes to these Terms from time to time. If we make changes, we will post the amended Terms 
                        to our Services and update the “Last Updated” date above. We may also attempt to notify you by sending 
                        an email notification to the address associated with your account or providing notice through our 
                        Services. Unless we say otherwise in our notice, the amended Terms will be effective immediately and 
                        your continued access to and use of our Services after we provide notice will confirm your acceptance of 
                        the changes. If you do not agree to the amended Terms, you must stop accessing and using our Services.
                        </p>
                        <h4>23. Severability</h4>
                        <p>If any provision of these Terms will be deemed unlawful, void or unenforceable, then that provision will 
                        be deemed severable from these Terms and will not affect the validity and enforceability of any remaining 
                        provisions.
                        </p><h4>24. Notice for California Users</h4>
                        <p>Under California Civil Code Section 1789.3, California users of our Services are entitled to the 
                        following consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of 
                        the California Department of Consumer Affairs may be contacted in writing at 1625 N. Market Blvd., 
                        Suite N-112, Sacramento, California 95834-1924, or by telephone at (800) 952-5210.
                        </p>
                        <h4>25. Miscellaneous</h4>
                        <p>These Terms constitute the entire agreement between you and Ample Samples relating to your access to 
                        and use of our Services and your order, receipt and use of products or services made available through 
                        our Services. These Terms, and any rights and licenses granted hereunder, may not be transferred or 
                        assigned by you without the prior written consent of Ample Samples. No waiver of any provision of these 
                        Terms will constitute a waiver of such provision in any prior, concurrent or subsequent circumstance, 
                        and Ample Samples’ failure to assert any right or provision under these Terms will not constitute a 
                        waiver of such right or provision. Except as otherwise provided herein, these Terms are intended solely 
                        for the benefit of the parties and are not intended to confer third party beneficiary rights upon any 
                        other person or entity.                     
                        </p>
                        <h4>Questions & Contact Information</h4>
                        <p>Questions or comments about our Services may be directed to <a href="mailto:info@thesampl.es">info@thesampl.es</a></p>                      

                    </div>
                </div>
            </main>
        )
    }
}
