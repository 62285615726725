import React, { Component } from 'react';

import FinishModal from '@/components/modals/finish-modal';
import history from '@/store/history';
import { ROUTESLIST } from '@/static/urls';

import UploadMechStep1 from './step1';
import UploadMechStep2 from './step2';



export default class AddMechPage extends Component {
    constructor(props) {
        super(props)        
        this.state = {
          page: 1,
          isOpenModal: false,
        }
        this.nextStep =  this.nextStep.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    closeModal() {
        this.setState({isOpenModal: false});
        history.push(ROUTESLIST.LOGIN);
    }
    nextStep() {
        this.setState((prevState) => {
            if(prevState.page < 2) {
                return {
                    page: prevState.page + 1
                }
            }
            return {isOpenModal: true};
        });
    }

    render() {         
        const content = this.renderContent();   
        return (
            <main className="page upload-page">
                {
                    content
                } 
                <FinishModal 
                    open={this.state.isOpenModal}
                    onClose={this.closeModal}
                />    
            </main>
        )
    }

    renderContent() {
        const step = this.state.page;  
        switch(step) {
            case 1 :
            return (<UploadMechStep1 nextStep={ this.nextStep } /> );
            case 2 :
            return (<UploadMechStep2 nextStep={ this.nextStep } /> ); 
            default:  return null;         
        }   
        
    }
}