import { AC_SELECTS} from '@/actions/selects';

const defaultState = {
   loaded: {},
   options: {}
};

export default (state = defaultState, action) => {

    switch(action.type) {
        case AC_SELECTS.UPLOAD: {
            let opts = Object.assign({}, state.options, action.payload);
            let loaded = Object.assign({}, state.loaded);
            loaded[action.key] = true;
            return {
                loaded: loaded,
                options: opts
            };
        }       
        default: {
            return state;
        }
    }
}
