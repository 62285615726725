export const ROUTESLIST = {
    'HOME': '/',    
    'SAMPLE' : '/sample',
    'SEARCH' : '/search',
    'SIGNUP' : '/signup',
    'LOGIN' : '/login', 
    'ADD_CRATE' : '/add-crate',
    'ALBOM' : '/albom',
    'CLEAR_SAMPLE' : '/clear-sample',    
    'SHOP' : '/shop',
    'SHOP_ITEM' : '/shop-item',
    'AFTER_REGISTER' : '/register/success',
    'EMAIL_VERIFY' : '/verify/success',
    //crates
    'CRATES' : '/crates',
    'CRATES_CRATE' : '/crate/',
    'CRATES_SAMPLED' : '/crate-sample/',
    //'CRATE' : '/crate/',
    //dashboard
    'DASHBOARD' : '/dashboard',
    'DASHBOARD_SAMPLES' : '/dashboard/samples',
    'DASHBOARD_ONE_SAMPLE' : '/dashboard/samples/',
    'DASHBOARD_SYNC' : '/dashboard/sync',
    'DASHBOARD_ONE_SYNC' : '/dashboard/sync/',
    'DASHBOARD_MECHANICAL' : '/dashboard/mechanical',
    'DASHBOARD_PROFILE' : '/dashboard/profile',
    'DASHBOARD_PAYMENTS' : '/dashboard/payments',
    'DASHBOARD_SECURITY' : '/dashboard/security',
    'DASHBOARD_INVOICES' : '/dashboard/invoices',
    'DASHBOARD_INVOICE' : '/dashboard/invoices/',
    'DASHBOARD_CRATES' : '/dashboard/crates',
    //forms 
    'UPLOAD_SAMPLE': '/upload/sample', 
    'UPLOAD_SYNC': '/upload/sync', 
    'UPLOAD_MECH': '/upload/mech',
    'UPLOAD_ADD_CRATE': '/add-crate',
    'UPLOAD_ADD_CRATE_SAMPLE': '/add-crate/',
    'UPLOAD_REVIEW': '/upload/review',
    
    //account
    'ACCOUNT_FORGOT_PASSWORD': '/account/forgot-password',
    'ACCOUNT_RESET_PASSWORD': '/reset-password/',
    //static pages 
    'STATIC_TERMS': '/terms-and-conditions',
    'STATIC_CONTACT': '/contact',
    'STATIC_TESTIMONIALS' : '/testimonials',
    'CLOSED_PAGE': '/pages/',
    'FAQ': '/'
};


