import React, { Component } from 'react';

import FormFieldBottom from './field-bottom';

const getCountWords = (val) => {
    if(! val) return 0;
    const words = val.trim().split(/\s+/);
    return words.length;
}

export default class WordsLimitedField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countWords: 0
        }
        this.handlerChange = this.handlerChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    handlerChange(event) {  
        this.props.input.onChange(event.target.value); 
    }

    checkValue(value) {
        let val = value;
        let cnt = getCountWords(val);
        let isFixed = false;
        if(cnt > this.props.maxWords) {
            const words = val.trim().split(/\s+/).slice(0, this.props.maxWords);
            val = words.join(' ');
            cnt = words.length;
            isFixed = true;
        }
        return {
            cnt: cnt,
            val: val,
            isFixed: isFixed
        };
    }

    handleKeyDown(event) {
        const cnt = getCountWords(event.target.value);
        const keyCode = event.keyCode;             
        if (cnt === this.props.maxWords) {                       
            if ([32, 9, 13].indexOf(keyCode) > -1) {
                event.preventDefault();
                event.stopPropagation();
            }
        } else if (cnt > this.props.maxWords) {                            
            if ( keyCode !== 46 && keyCode !== 8 ) {
                event.preventDefault(); 
                event.stopPropagation();
            }                          
        }   
        
    }
    componentDidMount() {
        const cnt = getCountWords(this.props.input.value);
        this.setState({countWords: cnt});

    }

    componentDidUpdate(prevProps) {
        if(prevProps.input.value !== this.props.input.value ) {
            let checked =  this.checkValue(this.props.input.value);
            if(this.state.countWords !== checked.cnt) {
                this.setState({countWords: checked.cnt});
            }
            if(checked.isFixed) {
                this.props.input.onChange(checked.val); 
            }           
        }
    }

    render() {
        const { label, maxWords, input, required, meta, note, correct, placeholder } = this.props
        //console.log(this.props)
        const countWords = this.state.countWords;
        const classes = (meta.error) ? "form-field form-field_words-limit error" : "form-field form-field_words-limit";
        
        return (
            <div className={ classes }>
                <div className="label-row">
                    <label className={ (required) ? "label label_required" : "label"}>{ label }</label>
                    <div className="label-note"><span className="red">{ countWords }</span><span>/{maxWords} Words</span></div>
                </div>                
                <textarea className="textarea" onKeyDown={ this.handleKeyDown } placeholder={placeholder}
                    onChange={this.handlerChange}  value={ input.value}></textarea>                
                <FormFieldBottom 
                    meta={meta}
                    note={note}
                    correct={correct}
                />   
            </div>
        )
    }

}
// label, type, input, placeholder, required, note, correct