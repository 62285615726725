import React, { Component } from 'react';
import { connect } from 'react-redux';

import  ChangePasswordForm from './password-form';

class DashboardSecurityPage extends Component {
    render() { 
        const { userEmail } = this.props;      
        return (
            <div>
            <h3 className="dashboard-header">Security Settings</h3> 
            <div className="dashboard-blc">
                <h4 className="dashboard-blc__head">Login Email</h4>
                <div className="security-email">
                    <span className="security-email__email">{ userEmail }</span>                    
                </div>
            </div>
            <div className="dashboard-blc">
                 <h4 className="dashboard-blc__head">Change Password</h4>
                 <div className="dashboard-blc__desc">Please enter your password.</div>
                 <ChangePasswordForm />
            </div>
            
         </div>
        )
    }
    //<button type="button" className="btn link">Change</button>
}

function mapStateToProps(state) {
  
  if( state.auth.user) {     
      return {
        userEmail: state.auth.user.email, 
      }
  }    
  else {
      return {          
          userEmail: null,         
      };
  }
}

export default connect(mapStateToProps, null)(DashboardSecurityPage);