import React from 'react';
import PropTypes from 'prop-types';

import MainModal from './main';

export default function FinishModal(props) {
    const {open, onClose } = props;
    return (
        <MainModal open={open} onClose={onClose} >
            <h4 className="main-modal__head">Your information has been received.</h4>
                <p className="main-modal__msg single">Please login in order to proceed with your submission</p>                        
            <div className="main-modal__act">
                <button className="btn btn-primary" type="button" onClick={ onClose }>Go to Login</button>
            </div>
        </MainModal>
    )
}

FinishModal.propTypes = {
    onClose: PropTypes.func.isRequired,    
    open: PropTypes.bool.isRequired,
}