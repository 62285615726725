import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';

import { ROUTESLIST } from '@/static/urls';
import api, { setErrors, arrangeData, getFormDataDeep} from '@/middlewares/api';
import FormField from '@/components/forms/form-field';
import ImgUpload from '@/components/forms/img-upload';
import OneWithImagesAutocompleteField from '@/components/forms/one-with-image-autocomplete';
import ManyWithImagesAutocompleteField from '@/components/forms/many-with-image-autocomplete';

import bgImg from './crate-step1.jpg';
import { maxImageSize, maxImageSizeErrorMsg } from '../static';


class AddCrateStep1 extends Component {

    constructor(props) {
        super(props);
        this.stepSubmit = this.stepSubmit.bind(this);
    }

    stepSubmit(data) {    
        const formData = arrangeData(data, ['artist_name'], ['feats']); console.log(formData);
        return new Promise((resolve, reject) => {
            api.post('crate/step/one', getFormDataDeep(formData))
            .then((response) => {               
                this.props.nextStep(formData, data);
                return resolve(null);
            })
            .catch((err) => {
                if(! err.response) return;
                if(err.response.status === 422) {
                    if(err.response.data.crate_id) {
                        this.props.existCrate(err.response.data.crate_id);
                        return resolve(null);
                    } else {
                        return reject(setErrors(err.response)); 
                    }                                        
                }            
            });
        });
        
    }
    // image
    // artist_name
    // song_title
    // written_by
    // executive_producer
    //feats
    
    render() {
        const secStyle = { backgroundImage: "url(" +  bgImg  + ")"};
        const { handleSubmit, submitting } = this.props;
        return (
            <section className="entity-sec banner-filter banner-filter_fw" style={ secStyle }>
                <div className="content">
                    <div className="entity-sec__top">  
                        <h2 className="head-shadow">ADD A NEW SONG</h2>
                        <h3 className="head">
                            Step 1 of 3: Main information
                        </h3>
                        <div className="form-step__top-desc">
                            
                        </div>
                    </div>
                    <div className="form-step__body">
                        <form onSubmit={handleSubmit((data) => { 
                                return this.stepSubmit(data);
                            }) } className="form-layout" 
                            noValidate>                         
                            <div className="form-row">
                                <div className="form-col_2 full-height">
                                <Field                            
                                        name="image"
                                        required={true}
                                        component={ImgUpload}  
                                        label="Include Project Artwork"
                                        maxSize={ maxImageSize } 
                                        maxSizeError={ maxImageSizeErrorMsg }                           
                                    />
                                </div>
                                <div className="form-col_2">                                   
                                     <Field                            
                                        name="artist_name"                                        
                                        required={true}                                       
                                        component={OneWithImagesAutocompleteField}
                                        url="artist/search" 
                                        placeholder="Set Artist First and Last Name"
                                        label="Artist Name"                                                                  
                                    />      
                                     <Field                            
                                        name="song_title"
                                        component={FormField}
                                        required={true}
                                        type="text"
                                        placeholder="Set Song Name"
                                        label="Song Title"                            
                                    />                                
                                    <Field                            
                                        name="written_by"
                                        component={FormField}
                                        type="text"
                                        required={true}
                                        placeholder="Set author first and last name"
                                        label="Written By"                            
                                    />
                                    <Field                            
                                        name="executive_producer"
                                        component={FormField}
                                        type="text" 
                                        required={true}
                                        placeholder="Executive Producer"                                      
                                        label="Executive Producer"                            
                                    />
                                </div>
                            </div>
                            <Field                            
                                name="feats"                                                                  
                                component={ManyWithImagesAutocompleteField}
                                url="artist/search"                                                                
                                placeholder="Set featured artist name(s)"
                                label="Featured Artist(s)"                            
                            />
                            <div className="submit-row_txt">
                                <p className="as-text">
                                    By clicking Confirm button you are agreeing to the 
                                    <Link to={ ROUTESLIST.STATIC_TERMS } className="link-thin"
                                        target="_blank" rel="noopener noreferrer"
                                        >Terms and Conditions.
                                    </Link>
                                </p>
                                <div className="submit-row__btn-col">
                                    <button className="btn btn-primary" disabled={ submitting }
                                        type="submit">Next</button> 
                                </div>
                            </div>
                        </form>                        
                    </div>
                </div>
            </section>            
        )
    }
}

const AddCrateStep1Form =  reduxForm({
    form: 'add-crate-step1',
    enableReinitialize: true  
  })(AddCrateStep1);

export default AddCrateStep1Form;
//export default AddCrateStep1;

// <p>
//                                 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut mattis sed ante nec viverra. 
//                                 Donec vel arcu fringilla, lobortis diam vitae, ornare nisi. Ut congue quam lectus, 
//                                 eget condimentum nulla rutrum id. Vestibulum posuere risus a posuere bibendum. 
//                             </p>

/* <Field                            
name="artist_name"
component={FormField}
required={true}
type="text"
placeholder="Set Artist First and Last Name"
label="Artist Name"                            
/> */