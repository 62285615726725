import { formatDate } from './common';

const extractLabel = (item) => {
    let date = formatDate(item.date);
      let el = {
        id: item.id,
        name: item.label_name,
        files: []
      };
      if(item.label_file_one_link) {
        el.files.push({
            id: item.id + '_1',
            name: item.label_file_one_name,
            link: item.label_file_one_link,
            date: date,
        });
      }
      if(item.label_file_two_link) {
        el.files.push({
            id: item.id + '_2',
            name: item.label_file_two_name,
            link: item.label_file_two_link,
            date: date,
        });
      }
    return el;
}

const extractPublisher = (item) => {
    let date = formatDate(item.date);
      let el = {
        id: item.id,
        name: item.publisher_name,
        files: []
      };
      if(item.publisher_file_one_link) {
        el.files.push({
            id: item.id + '_1',
            name: item.publisher_file_one_name,
            link: item.publisher_file_one_link,
            date: date,
        });
      }
      if(item.publisher_file_two_link) {
        el.files.push({
            id: item.id + '_2',
            name: item.publisher_file_two_name,
            link: item.publisher_file_two_link,
            date: date,
        });
      }
    return el;    
}

const arrangeLicensors = (data) => {
    if (!data) return []
    if(! Array.isArray(data)) return [];
    let result = [];
    data.forEach((item) => {
      
      if(item.label_id) {
           result.push(extractLabel(item));
      } else if(item.publisher_id) {
            result.push(extractPublisher(item));
      }
      
    });
    return result;
  }


  const termsMap = [
    { server: 'master_quotes', label: 'MASTER QUOTES' },
    { server: 'publisher_quotes', label: 'PUBLISHERS QUOTES' },
    { server: 'credits', label: 'CREDITS' },
  ];

  const extractTermsQuotes = (data) => {
    let result = [];
    termsMap.forEach((item) => {
        result.push({
            id: item.server,
            title: item.label,
            body: (data[item.server]) ? data[item.server] : null,
        });
    });
    return result;
  };
  export const  arrangeTerms = (data) => {
        console.log('terms', data);
        //return exampleTerms;
        return {
            quotes: extractTermsQuotes(data),
            licensors: arrangeLicensors(data.licensors) 
        };      
  };

  /*
  const exampleTerms = {
    quotes: [
        {
            id: 's1',
            title: "MASTER QUOTES",
            body: `<p>2•22•20
            Advance: Two Thousand Dollars ($2,000)
            recoupable against</p>            
            <p>Royalty: (7%) [PPD] per unit and Thirty-Five
            Percent (35%) of receipts due Licensee’s artist for third-party licensing
            (excluding synchronization licenses) and Streaming.</p>`
        },
        {
            id: 's2',
            title: "PUBLISHERS QUOTES",
            body: `<p>2•22•20
            Advance: Two Thousand Dollars ($2,000)
            recoupable against</p>            
            <p>Royalty: (7%) [PPD] per unit and Thirty-Five
            Percent (35%) of receipts due Licensee’s artist for third-party licensing
            (excluding synchronization licenses) and Streaming.</p>`
        },
        {
            id: 's3',
            title: "CREDITS",
            body: `<p>2•22•20
            Advance: Two Thousand Dollars ($2,000)
            recoupable against</p>            
            <p>Royalty: (7%) [PPD] per unit and Thirty-Five
            Percent (35%) of receipts due Licensee’s artist for third-party licensing
            (excluding synchronization licenses) and Streaming.</p>`
        },
    ],
    licensors: [
        {
            id: 'l1',
            name: 'Motown Records - Universal Music Enterprises',
            files: [
                {
                    id: 'f1',
                    name: 'Basic_Confirmation_letter.pdf',
                    link: 'https://fanteractive.ourdevapps.com//assets/images/header-logo.png',
                    date: '15 Feb 2021',
                },
                {
                    id: 'f2',
                    name: 'Basic_Confirmation_letter.pdf',
                    link: 'https://fanteractive.ourdevapps.com//assets/images/header-logo.png',
                    date: '15 Feb 2021',
                },
            ]
        },
        {
            id: 'l2',
            name: 'Polygram - Universal Music Publishing',
            files: [
                {
                    id: 'f1',
                    name: 'Basic_Confirmation_letter.pdf',
                    link: 'https://fanteractive.ourdevapps.com//assets/images/header-logo.png',
                    date: '15 Feb 2021',
                },                
            ]
        },
    ]
    
  }
  */