import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import WithNotAuthRedirect from '@/components/hoc/with-not-auth-redirect';

//import history from '@/store/history';
import { ROUTESLIST } from '@/static/urls';
import api, { getFormDataDeep} from '@/middlewares/api';
import { fetchSelects, STORE_SELECTS_KEYS } from '@/actions/selects';
import { extractFields, cacheData } from '@/utils/forms';
//
import './index.scss';
//TODO

import { extractUnsavedCrate, extractSavedCrate } from '../crate-helpers.js';

import NoteModal from '@/components/modals/note';
import AddCrateStep1 from './step1';
import AddCrateStep2 from './step2';
import AddCrateStep3 from './step3';
import CrateFormModalCard from '@/components/crate/form-modal-card';
 

const stepsFields = {
    1: ['image', 'artist_name', 'song_title', 'written_by', 'executive_producer', 'feats'],
    2: ['produced_by', 'artist_manager', 'publishers',  'labels',  'video_url',  'genres',  'release_date'],
    3: ['mentions'],
};

class AddCratePage extends Component {
    constructor(props) {
        super(props);
        this.formData = {            
        };
        this.rawData = {};       
        this.state = {
          page: 1,
          isOpenWarn: false,
          isOpenFinish: false,
          crateId: null,
          cardItem: null,
        }
        this.prevStep = this.prevStep.bind(this);
        this.nextStep = this.nextStep.bind(this);
        this.saveData = this.saveData.bind(this);        
        this.closeFinish = this.closeFinish.bind(this);  
        this.closeWarn = this.closeWarn.bind(this);  
    }

    prevStep(rawData) {
        cacheData(rawData, this.rawData);
        this.setState((prevState) => {
            if(prevState.page > 1) {                    
                return {
                    page: prevState.page - 1
                }                
            } 
        });
    } 
    
    existCrate(crateId) {
        //TODO 
        api.post('crate/show-in-popup', { params: { id: crateId } })
        .then((response) => {
            const item = extractSavedCrate(response.data);
            this.setState({
                crateId: crateId,
                isOpenWarn: true,
                cardItem: item
            });
            
        })
        .catch((err) => {
            console.log(err.response);        
        });
    }    

    nextStep(data, rawData) {
        //TODO        
        cacheData(rawData, this.rawData);
        cacheData(data, this.formData);
        if(this.state.page < 3) {
            this.setState((prevState) => {
                return {
                    page: prevState.page + 1
                }
            });
        } else {
           this.saveData()
        }        
        console.log('this.formData', this.formData);  
        console.log('this.rawData', this.rawData);       
    }

    openFinish(cardItem, crateId) {
        this.setState({
            isOpenFinish: true,
            cardItem: cardItem,
            crateId: crateId
        });
    }
    saveData() {
        //TODO
        //this.setState({isOpenOffer: false});
        const data = getFormDataDeep(this.formData);
        for(let pair of data.entries()) {
            console.log(pair[0]+ ', '+ pair[1]);
        }        
        api.post('crate/save', data)
        .then((response) => { console.log(response);
            const crateId = response.data.crate_id;
            extractUnsavedCrate(this.rawData, this.props.crateGenres)
            .then((cardItem) => {
               this.openFinish(cardItem, crateId);
            })
            .catch((err) => {
                this.openFinish(null, crateId);
            }); 
        })
        .catch((err) => {
            console.log(err.response); 
            //TODO       
        });        
    }

    closeWarn() {
        this.setState({isOpenWarn: false});        
    }

    closeFinish() {
        this.setState({isOpenFinish: false});        
    }

    componentDidMount() {
        if(! this.props.selectsIsLoaded) {
            this.props.fetchSelects();
        }
    }
    render() {        
        const content = this.renderContent();  
        const { isOpenWarn, isOpenFinish, crateId, cardItem} = this.state; 
        return (
            <main className="page add-crate-page">
                {
                    content
                } 
                <NoteModal open={ isOpenFinish } onClose={this.closeFinish} >                    
                    <h4 className="main-modal__head">
                        Your Crates Sucessfuly added to Ample Sample!
                    </h4>
                    <CrateFormModalCard item={cardItem} />
                    <div className="note-modal__btn-cols">                        
                        <Link className="btn btn-primary" to={ ROUTESLIST.UPLOAD_ADD_CRATE_SAMPLE + crateId } >Add Samples</Link>
                        <Link className="btn btn-second" to={ ROUTESLIST.CRATES_CRATE + crateId }>Complete</Link>
                    </div>
                </NoteModal>   
                <NoteModal open={ isOpenWarn } onClose={this.closeWarn} >                    
                    <h4 className="main-modal__head">Song Already Exist On Ample Sample</h4>
                    <CrateFormModalCard item={cardItem} />
                    <div className="note-modal__btns">                        
                        <Link className="btn btn-second" to={ ROUTESLIST.UPLOAD_ADD_CRATE_SAMPLE + crateId }>Add Samples</Link>
                        <Link className="btn btn-primary" to={ ROUTESLIST.CRATES_CRATE + crateId } >Go to this Song</Link>
                    </div>                    
                </NoteModal>                  
            </main>
        )
    }

    renderContent() {
        const step = this.state.page; 
        const { crateGenres } = this.props;
        let stepValues;     
        switch(step) {
            case 1 :
                stepValues = extractFields(this.rawData, stepsFields[1]); 
                return (<AddCrateStep1 nextStep={ this.nextStep }
                    existCrate={ this.existCrate }
                    initialValues={ stepValues }
                     /> );
            case 2 :  
                stepValues = extractFields(this.rawData, stepsFields[2]);               
                return (<AddCrateStep2 nextStep={ this.nextStep } 
                        prevStep={ this.prevStep }
                    crateGenresOptions={crateGenres}
                    initialValues={ stepValues }
                    /> );                
            case 3 :
                stepValues = extractFields(this.rawData, stepsFields[3]); 
                return (<AddCrateStep3 nextStep={ this.nextStep }
                    initialValues={ stepValues }
                    prevStep={ this.prevStep } /> );
            default: {
                return null;
            }
        }
       
    }
}
function mapStateToProps(state) {    
    return {      
        selectsIsLoaded: state.selects.loaded[STORE_SELECTS_KEYS.CRATES],
        crateGenres: (state.selects.options.crateGenres) ? state.selects.options.crateGenres : [],          
    };
}
function mapDispatchToProps(dispatch) {
    return {
        fetchSelects: () => {
            return dispatch( fetchSelects( STORE_SELECTS_KEYS.CRATES ));
        },     
    };
}

export default WithNotAuthRedirect(connect(mapStateToProps, mapDispatchToProps)(AddCratePage));

///////////////////////////
/* 
image
artist_name
song_title
written_by
executive_producer //step 1
produced_by
artist_manager
publishers[]
labels[]
genres[]
video_url
release_date //step 2
mentions [
 [
    content
    url
 ]
]
*/
// produced_by
    // artist_manager
    // publishers[]
    // labels[]
    // video_url
    // genres[]
    // release_date