import React, { Component } from 'react';
import YouTube from 'react-youtube';

export default class TestimonialBody extends Component {
    
    constructor(props) {
        super(props);  
        this.state = {
            videoVisible: false
        }  
        this.openVideo = this.openVideo.bind(this);
        this.endVideoHandler = this.endVideoHandler.bind(this);
        this.readyHandler = this.readyHandler.bind(this);
        this.player = null;           
    } 

    openVideo() {
        this.setState({
            videoVisible: true
        });
        if(this.player) this.player.playVideo();
    }

    endVideoHandler() {
        this.setState({
            videoVisible: false
        })
    }
    readyHandler(event) {
        this.player = event.target;
        
    }
    render() {
        // title quote sign text video
        const title = 'From our clients and peers:'
        const {testimonials, key} = this.props;
        const videoID = 'dj0H0Y_4kJI'; //'cUr7A0JAXjQ';
                
        return (
            <div className="testimonial-item" id={key}>
                <h4 className="testimonial-item__title">{ title }</h4>
                {
                    testimonials.map(item => 
                        <div className="testimonial-item__col testimonial-item__col_body">
                            <div className="testimonial-item__quote as-quote as-quote_inline">
                                { item.body.quote }
                            </div>
                            <p className="testimonial-item__sign">- { item.body.sign }</p>
                            <div className="testimonial-item__text">
                                { item.body.text }
                            </div>                   
                        </div>
                    )
                }

                <div className="testimonial-item__col testimonial-item__col_video">
                    <h4 className="testimonial-item__title">{ title }</h4>
                    <YouTube                            
                        videoId={videoID}
                        onEnd={ this.endVideoHandler } 
                        onReady={ this.readyHandler }
                        opts={{
                            width: '100%',
                            height: '450',
                            frameBorder: "0"
                        }}                             
                    />
                </div>               
            </div>
        )
    }
}
