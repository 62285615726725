import React from 'react';

const NotFoundBlock = () => {        
   
    return (            
        <div class="not-found-blc">
            <h3 className="head">404</h3>
            <p>Not found</p>
        </div>
    )
};

export default NotFoundBlock;