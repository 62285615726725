import React, { Component } from 'react';

export default class RadioField extends Component {
    render() {
        const { 
            input,
            label            
          } = this.props; 
        return (           
            <label className="radio-field">
                <input {...input} type="radio" className="radio-field__input" 
                 />       
                    <span className="radio-field__control" />
                    <span className="radio-field__label">{label}</span>
            </label> 
        )
    }
}
// {
//     meta.error &&
//     <div className="form-field__note error">{meta.error}</div>
// }
// {
//     input.value ?
//     <input {...input} type="checkbox" checked="checked" className="checkbox-field__input" />
//     :
//     <input {...input} type="checkbox"  className="checkbox-field__input" />
// }   