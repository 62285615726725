import React, { Component } from 'react';

import WithAccordion from '@/components/hoc/with-accordion';

// key: 'amex',
// name: 'Amex',
// icon: 'as-icon_card-amex'


class MethodCard extends Component {

    renderCardTop() {
        const { isDefault, toggleContent } = this.props;
        const { meta, card  } = this.props.item;
        const cardName = (meta.key === 'default') ? card.brand : meta.name;       

        return (
        <div className="pay-method__top">
            <div className="pay-method__icon">
                <i className={ 'as-icon ' + meta.icon }></i>
            </div>
            <div className="pay-method__info">
                <p className="pay-method__data">                    
                    <span className="user-info">
                        { cardName } ending in { card.last4 }
                    </span>
                    <span className="note">
                        Expires: { card.exp_month }/{ card.exp_year }
                    </span>                   
                </p>                        
                {
                    isDefault &&
                    <p className="pay-method__msg"> 
                        Default payment method                     
                    </p>
                }
                
            </div>
            <div className="pay-method__toggle" role="button" onClick={toggleContent}>
                <i className="as-icon as-icon_arrow-down2"></i>
            </div>
        </div>
        
        );
    }

    renderDescription() {
        const { address, name } = this.props.item.billing_details;

        return (
            <div className="pay-method__desc">
                <h6>Name on card</h6>
                <p>{ name }</p>
                <h6>Billing address</h6>
                <p>
                    { address.line1 }<br/>
                    { address.city }, { address.state } { address.postal_code }<br/>
                    { address.country }
                </p>
            </div>
        );
    }

    render() {
        const { isDefault, isCard, isContentOpen, setDefault, removeMethod, id  } = this.props;
        const cardClasses = (isContentOpen) ? 'pay-method pay-method_open' : 'pay-method';   
        const top = (isCard) ? this.renderCardTop() : null;
        const description = (isCard) ? this.renderDescription() : null;
        return (
            <div className={cardClasses}>
                 { top }
                  <div className="pay-method__body">
                    { description }
                    <div className="pay-method__actions"> 
                        <button className="btn btn-text pm-act pm-act_red" 
                            onClick={ () => removeMethod(id) }
                            type="button">DELETE</button>                       
                        {
                            (!isDefault) &&                            
                            <button className="btn btn-text pm-act pm-act_green" 
                                onClick={ () => setDefault(id) }
                                type="button">Make default payment method</button>
                        }
                        
                    </div>
                </div>
            </div>
        )
    }
}

export default WithAccordion(MethodCard);
