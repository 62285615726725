//import logo from '@/assets/images/logo.png';
///sample
export const items = [
    {
        id: 'i1',
        number: 1,
        title: "What We Talkin' About",
        duration: "07:17"
    },
    {
        id: 'i2',
        number: 2,
        title: "Thank You",
        duration: "07:17"
    },
    {
        id: 'i3',
        number: 3,
        title: "D.O.A. (Death of Auto-Tune)",
        duration: "07:17"
    },
    {
        id: 'i4',
        number: 4,
        title: "Run This Town (ft. Rihanna and Kanye West)",
        duration: "07:17"
    },
    {
        id: 'i5',
        number: 5,
        title: "Empire State of Mind (ft. Alicia Keys)",
        duration: "07:17"
    },
    {
        id: 'i6',
        number: 6,
        title: "Real as It Gets (ft. Jeezy)",
        duration: "07:17"
    },
    {
        id: 'i7',
        number: 7,
        title: "On to the Next One (ft. Swizz Beatz)",
        duration: "07:17"
    },
    {
        id: 'i8',
        number: 8,
        title: "Off That (ft. Drake)",
        duration: "07:17"
    },
    {
        id: 'i9',
        number: 9,
        title: "A Star Is Born (ft. J. Cole)",
        duration: "07:17"
    },
    {
        id: 'i10',
        number: 10,
        title: "Venus vs. Mars",
        duration: "07:17"
    },
];


export const videos = [    
    {
        id: 'vd1',
        src: "https://www.youtube.com/embed/RM7lw0Ovzq0",
        title: 'Compatible Inkjet Cartridge',
        author: 'Jay-Z',
        desc: 'Over time, even the most sophisticated, memory packed computer can begin to run slow if we don’t do something to prevent it. The reason why has less to do with how computers are made and how',       
    },
    {
        id: 'vd2',
        src: "https://www.youtube.com/embed/RM7lw0Ovzq0",
        title: 'Compatible Inkjet Cartridge',
        author: 'Jay-Z',
        desc: 'Over time, even the most sophisticated, memory packed computer can begin to run slow if we don’t do something to prevent it. The reason why has less to do with how computers are made and how', 
    },
    {
        id: 'vd3',
        src: "https://www.youtube.com/embed/RM7lw0Ovzq0",
        title: 'Compatible Inkjet Cartridge',
        author: 'Jay-Z',
        desc: 'Over time, even the most sophisticated, memory packed computer can begin to run slow if we don’t do something to prevent it. The reason why has less to do with how computers are made and how', 
    },
    {
        id: 'vd4',
        src: "https://www.youtube.com/embed/RM7lw0Ovzq0",
        title: 'Compatible Inkjet Cartridge',
        author: 'Jay-Z',
        desc: 'Over time, even the most sophisticated, memory packed computer can begin to run slow if we don’t do something to prevent it. The reason why has less to do with how computers are made and how', 
    },

]; 