import React, { Component } from 'react';

import history from '@/store/history';

import { ROUTESLIST } from '@/static/urls';
import ResetPasswordForm from  './form';
import WithAuthRedirect from '@/components/hoc/with-auth-redirect';

class ResetPasswordPage extends Component { 
    constructor(props) {
        super(props);        
        this.hideForm = this.hideForm.bind(this);
        this.closePage = this.closePage.bind(this);
        this.state = {
            isOpenForm: true,
            token: null,
            initialValues: {}
        }
    }

    componentDidMount() {
        console.log(this.props);
        const { location, match} = this.props;
        const token = match.params.token;
        const query =  new URLSearchParams( location.search );
        const email =  query.get('email');
        this.setState({
            initialValues: {email: email}, 
            token: token
        });
        console.log('email', email, this.token);
        //TODO

    }
    hideForm() {
        this.setState({ isOpenForm: false });
    }

    closePage() {
        this.setState({ isOpenForm: true });
        history.replace(ROUTESLIST.LOGIN);
    }

   
    render() {  
        
        const {isOpenForm, token, initialValues} = this.state;
        return (
            <main className="page">
               {isOpenForm &&
                <ResetPasswordForm 
                    token={ token }
                    initialValues={ initialValues }
                    hideForm={this.hideForm}
                />
               }
               {(! isOpenForm) &&
                <div className="content" style={{ padding: '80px 0' }}>
                    <h2 className="head-shadow">Ample Samples</h2>
                    <h3 className="head">Your password has been changed successfully<br/>                   
                    Thank you!</h3>  
                    <div className="form-submit-row">
                    <button className="btn btn-primary btn-fw" onClick={ this.closePage }
                        type="button">Ok</button> 
                    </div>                  
                </div>               
               }
               
            </main>
        );
       
    }
}

export default WithAuthRedirect(ResetPasswordPage);