import { createTextMask } from 'redux-form-input-masks';
import {  fillWithZero } from './common';

export const phonePattern = createTextMask({   
    pattern: '+1(999) 999-9999',
});

export const moneyPattern = createTextMask({   
    pattern: '$ 9999999999999999999',
    onChange: (val) => {console.log(val); return val + 1;}, 
    guide: false,
    maskDefinitions: {
        9: {
          regExp: /[0-9/,]/g,
        },       
      },    
});

/* eslint-disable eqeqeq */
export const formatMoneyFromString = (value) => {
    let tail = '';
    let res = '';    
    let rest;
    let val;
    const pointIndex = value.indexOf('.');
    if( pointIndex > -1 ) {
        tail = value.substring(pointIndex);        
        val = (pointIndex > 0) ?  value.substring(0, pointIndex) * 1  : 0;        
    } else {
        val = value * 1;
    }
    
    if(val == 0) return '0';
    
    while( val > 0) {
        rest = val % 1000;
        val = (val - rest) / 1000;        
        if(val > 0) {
            res =  fillWithZero(rest, 3) + res;
            res = ',' + res;
        } else {
            res =  rest + res;
        }
    }     
    return res + tail;
}
/* eslint-enable eqeqeq */

/* eslint-disable no-useless-escape */
export const normalizeMoney = (val) => {
    let proxy = val.toString().replace(/[^0-9\.]/g, ''); 
    return '$ ' +formatMoneyFromString(proxy);
};

export const cleanMoney = (val) => {
    return val.toString().replace(/[^0-9\.]/g, '');
    
};

export const cleanMoneyToCent = (val) => {
    if (! val) return 0;    
    let res = val.toString().replace(/[^0-9\.]/g, '');
    if (res.length === 0) return 0;
    let [intPart, decimalPart = ''] = res.split(".");
    if( decimalPart.length > 2) {
        decimalPart = decimalPart.substring(0, 2);
    }
    while(decimalPart.length < 2) {
        decimalPart += '0';
    }
    return intPart + decimalPart;
    
};
/* eslint-enable no-useless-escape */

export const splitPattern = createTextMask({   
    pattern: 'aaa 99$',
    stripMask: false,
    guide: false,
    allowEmpty: true
});

export const splitFormat = (value) => {
    if(! value) return '';
    const items = value.split(',');
    const last = items.length - 1;
    let amount = 0;
    let formattedItems = items.map((item, index) => {
        let val = item.trimLeft().split('-'); 
        let number, end;       
        if(index < last) {
            end = val[val.length - 1];
            number = parseInt(end);
            if(isNaN(number) || number < 0) number = 0;
            if((amount + number) > 100) {
                number = 100 - amount;
            }
            amount += number;
            val[val.length - 1] = number + '%';           
        } else if(val.length > 1) {
            end = val[val.length - 1].replace(/[^0-9%]/g,'');            
            number = parseInt(end);
            if(isNaN(number) || number < 0) number = 0;
            if((amount + number) > 100) {
                number = 100 - amount;
                end = number;
            }
            val[val.length - 1] = end;
        }
        
        return val.join('-');
    });
    const formatted = formattedItems.join(', ');
    return formatted;
}

export const stopEnterSubmit = (event) => {
    if (event.keyCode === 13) {
        event.preventDefault();
    }
};

export const getOptionLabel = (option) => {
    if(typeof option === "string") {
        return option;
    } else if( option.id) {
        return option.name;
    }
    return '';
}

export const extractFields = (data, keys) =>{
    let result = {};
    keys.forEach((key) => {
        if(typeof data[key] !== "undefined") {
            result[key] = data[key];
        }
    });
    return result;
}
export const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    
}

export function cacheData(data, obj) {
    if(data) {
        Object.keys(data).forEach((key) => {
            obj[key] = data[key];
        });
    }    
}