import axios from 'axios';
import { SubmissionError } from 'redux-form';
import moment from 'moment';
import {  isPlainObject } from 'lodash';

import store from '@/store';
import { showError } from '@/actions/notification';
import { authLogout } from '@/actions/auth';


const api = axios.create({
    baseURL: "/api/v1/",    
    responseEncoding: 'utf8',
    headers: {
        'Accept':  'application/json',
        'Content-Type': 'application/json',
    }
});

api.interceptors.response.use(function (response) {
    return response;
}, function (error) {    
    if (error.response.status > 499) {
       store.dispatch(showError('Some server error', 'Sorry'));
    }  else if (error.response.status === 401) {
        store.dispatch(authLogout());
    }
    return Promise.reject(error); 
        
});


export const testApi = axios.create({
    baseURL: "",    
    responseEncoding: 'utf8',
    headers: {
        'Accept':  'application/json',
        'Content-Type': 'application/json',
    }
});


const setToken = (token) => {
    localStorage.setItem('jwt', token);
    api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    //api.defaults.headers.common['Bearer Token'] = token;
    axios.defaults.withCredentials = true;
};

const getToken = () => {
    return localStorage.getItem('jwt');
};

const removeToken = () => {
    localStorage.removeItem('jwt');
    api.defaults.headers.common['Authorization'] = null;
    axios.defaults.withCredentials = false;
}

const checkToken = () => { 
    const token = localStorage.getItem('jwt'); 
    if(token) {
        api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.defaults.withCredentials = true;
        return true;
    } else {
        return false;
    }
}

export const JWTService = {
    setToken,
    getToken,
    removeToken,
    checkToken
};

const parseOS = (str) => {
    return parser('Windows Phone|Windows|Win|iPhone|iPad|Silk|Android|PlayBook|BlackBerry|Mac|Linux|Palm'
        , str);
};

const parseBrowser = (str) => {
    return parser('firefox|msie|chrome|safari|chromium|seamonkey|opera|trident|blackberry|CLDC'
    , str);
}

const parser = (rawRegex, str) => {
    try {
        const regexInstance = RegExp(rawRegex, 'ig');
        const match = regexInstance.exec(str);          
        return match ? match[0].toLocaleUpperCase() : "UNKNOWN";
      } catch {
        return "UNKNOWN";
      }
}


export const getUserDevice = () => {
    const device = navigator.userAgent||navigator.vendor||window.opera;
    return parseOS(device)  + '__' + parseBrowser(device);
};

const isNumeric = (str) => {
    return ! isNaN(parseInt(str));
}

const arrangeKey = (oldKey, errorObj, msg) => {
    if(oldKey.indexOf('.') === -1) {
        errorObj[oldKey] = msg;
    };
    const pairs = oldKey.split('.');    
    let obj = errorObj;
    for(let i = 1; i < pairs.length; i++) { 
        const prevKey = (isNumeric(pairs[i - 1])) ? parseInt(pairs[i - 1]) : pairs[i - 1];
        console.log(typeof obj[pairs[i - 1]]);
        if (typeof obj[prevKey] === "undefined") { 
            if(isNumeric(pairs[i])) {
                obj[prevKey] = [];
            } else {
               obj[prevKey] = {};
            }
        } 
        const currentKey = (isNumeric(pairs[i])) ? parseInt(pairs[i]) : pairs[i];
        console.log(obj);
        if((typeof obj[currentKey] === "undefined") && (i < (pairs.length - 1))) {
            const isNextArray =  (isNumeric(pairs[i + 1])) ? true : false;  
            if(Array.isArray(obj[prevKey])) {
                const start = obj[prevKey].length - 1;
                for(let i = start; i < currentKey; i++ ) {
                    obj[prevKey].push((isNextArray) ? [] : {});
                }
            } else {
                obj[currentKey] = (isNextArray) ? [] : {};
            }
        }        
        obj = obj[prevKey];        
    }
    const lastKey = pairs[pairs.length - 1];
    obj[lastKey] = msg.replace(oldKey, lastKey);    
}

export const setErrors = (response, common, isArrayKeys) => {
    let errors = {};
    if(response.data && response.data.errors) {
        console.log(response.data.errors);
        Object.keys(response.data.errors).forEach((key) =>{ 
            if(isArrayKeys) {
                arrangeKey(key, errors, response.data.errors[key][0]);
            } else {
                errors[key] = response.data.errors[key][0];
            }
        });   
    }
    errors._error = common || response.data.message;
    console.log('errors', errors);
    //return Promise.reject(new SubmissionError(errors));
    return new SubmissionError(errors);
}

export const arrangeData = (rawData, oneAutoKeys, manyAutoKeys, boolKeys) => {
    let data = Object.assign({}, rawData);
    if(oneAutoKeys && oneAutoKeys.length) {
        oneAutoKeys.forEach((key) => {
            if(typeof rawData[key] === "string") {  
                data[key] = rawData[key];              
            } else if(rawData[key] && rawData[key].id){
                data[key] = rawData[key].id 
            }  
        });
    }
    if(manyAutoKeys && manyAutoKeys.length) {
        manyAutoKeys.forEach((key) => {
            let vals = [];
            if(rawData[key] && rawData[key].length) {
                rawData[key].forEach((item) => {
                    if( typeof item === "string") {
                        vals.push(item)
                    } else if(item && item.id) {
                        vals.push(item.id);
                    }
                });
            }
            data[key] = vals;
        });
    }
    if(boolKeys && boolKeys.length) {
        boolKeys.forEach((key) => {
            data[key] = (!! rawData[key]) ? 1 : 0;
        });
    }
    return data;
};

const addArray = (formData, key, arr) => {
    if(! Array.isArray(arr)) return;
    if(arr.length === 0) {
       // formData.append(key + '[]', null);
        return;
    }
    arr.forEach((item, index) =>{
        const formKey = `${key}[${index}]`;
        if(Array.isArray(item)) {
            addArray(formData, formKey, item);
        // } else if(isPlainObject(item)) {
        //     addDataToForm(formData, key, data[key]);
        } else {
            addDataToForm(formData, formKey, item);
        }
    });
}
const addDataToForm = (formData, key, item) => {
    if(Array.isArray(item)) {
        addArray(formData, key, item);
    } else if(isPlainObject(item)) {        
        if (Object.prototype.toString.call(item).toLowerCase() === '[object file]') {
            formData.append(key, item);
        } else {
            Object.keys(item).forEach((itemKey) => {
                addDataToForm(formData, `${key}[${itemKey}]` ,item[itemKey]);
            });
        }       
    } else {
        formData.append(key, item);
    }
};
export const getFormData = (data) => {   
    let formData = new FormData();
    Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
    });
    return formData;
};

export const getFormDataDeep = (data) => {
    let formData = new FormData();
    Object.keys(data).forEach((key) => {
        addDataToForm(formData, key, data[key]);
    });
    return formData;
}

export const convertDateToDb = (date) => {
    if(! date) return '';
    const dateObj = moment(date, "MM/DD/YY");
    return (dateObj.isValid()) ? dateObj.format("YYYY-MM-DD") : '';
};

export const convertDbToDate = (date) => {
    if(! date) return '';
    const dateObj = moment(date, "YYYY-MM-DD");
    return (dateObj.isValid()) ? dateObj.format("MM/DD/YY") : '';
};

export default api;