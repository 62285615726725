import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import WithNotAuthRedirect from '@/components/hoc/with-not-auth-redirect';
import { fetchSelects, STORE_SELECTS_KEYS } from '@/actions/selects';

import history from '@/store/history';
import { ROUTESLIST } from '@/static/urls';
import { extractFields } from '@/utils/forms';
import api from '@/middlewares/api';
import MainModal from '@/components/modals/main';
import UploadSyncStep1 from './step1';
import UploadSyncStep2 from './step2';
import UploadSyncStep3 from './step3';
import UploadSyncStep4 from './step4';

//data for background-style for all steps:
import { bgIMGForStepsSync } from '../static';

const stepsFields = {
    1: [ 'name', 'email', 'your_company', 'distribution', 'business_address', 'phone_number', 
        'party_responsible_payment', 'author_sign_sample_agreement', 'party_payment_email' ],
    2: ['track_title', 'track_link', 'label', 'artists', 'project_title', 'project_budget', 
        'project_synopsis', 'description_of_use'],
    3: ['category', 'media', 'featured_background', 'length_use', 'term'],
    4: ['territory', 'release_air_date', 'option', 'proposed_free', 'publishing_or_master_sync_used', 'sync_other_used']
};

class AddSyncPage extends Component {
    constructor(props) {
        super(props)        
        this.state = {
          page: 1,
          isOpenModal: false,
          savingAll: false
        }
        this.formData = {};
        this.rawData = {};
        this.nextStep =  this.nextStep.bind(this);
        this.prevStep = this.prevStep.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.nextLoop = this.nextLoop.bind(this);
    }

    componentDidMount() {
        if(! this.props.selectsIsLoaded) {
            this.props.fetchSelects();
        }
    }

    saveRawValues(rawData) {
        if(rawData) {
            Object.keys(rawData).forEach((key) => {
                this.rawData[key] = rawData[key];
            });
        } 
        //console.log('rawData', this.rawData);
    }

    closeModal() {
        this.setState({isOpenModal: false});
        history.push(ROUTESLIST.DASHBOARD_SYNC);
    }

    prevStep(rawData) {
        this.saveRawValues(rawData);
        this.setState((prevState) => {
            if(prevState.page > 1) {                    
                return {
                    page: prevState.page - 1
                }                
            } 
        });
    }

    nextStep(data, rawData) {
        if(data) {
            Object.keys(data).forEach((key) => {
                this.formData[key] = data[key];
            });
        }
        this.saveRawValues(rawData);
        //console.log('saved',this.formData);
        if(this.state.page === 4)  {
            if (this.state.savingAll) return; 
            this.setState({savingAll: true});  
            api.post('clear/sync/save', this.formData)
            .then((response) => {                                    
                this.setState({isOpenModal: true, savingAll: false});
            })
            .catch((err) => {
                console.log(err.response);
                this.setState({savingAll: false});        
            });    

        } else {
            this.setState((prevState) => {
                if(prevState.page < 4) {                    
                    return {
                        page: prevState.page + 1
                    }                
                }               
                return {isOpenModal: true};
            });
        }       
    }

    nextLoop() {        
        const newFormData = {};
        stepsFields[1].forEach((key) => {
            newFormData[key] = this.formData[key];
        });
        this.formData = newFormData;
        this.rawData = extractFields(this.rawData, stepsFields[1]);
        this.setState({isOpenModal: false, page: 2});       
    }

    render() {         
        const content = this.renderContent(); 
        const { isOpenModal }  = this.state;
        return (
            <main className="page upload-page">               
                {
                    content
                } 
                <MainModal open={isOpenModal } onClose={this.closeModal} >                    
                    <h4 className="main-modal__head">Your sync request added successfully!</h4>
                    <div className="main-modal__btns">
                        <button type="button" className="btn btn-primary" onClick={this.nextLoop}>
                            Add more
                        </button>                         
                        <Link to={ROUTESLIST.DASHBOARD_SYNC} className="btn btn-second">
                            Go to dashboard
                        </Link>
                    </div>
                </MainModal>            
            </main>
        )
    }

    renderContent() {
        const step = this.state.page;
        let stepValues;  
        switch(step) {
            case 1 :               
                const { firstUserData } = this.props; 
                stepValues = Object.assign({},  firstUserData, extractFields(this.rawData, stepsFields[1]));   
                return (
                    <UploadSyncStep1 
                        nextStep={ this.nextStep }
                        initialValues={ stepValues } 
                        bgImg={ bgIMGForStepsSync.step1 } 
                    /> 
                );
            case 2 : 
                stepValues = extractFields(this.rawData, stepsFields[2]); 
                return (
                    <UploadSyncStep2 
                        nextStep={ this.nextStep }
                        initialValues={ stepValues  }
                        prevStep={ this.prevStep } 
                        bgImg={ bgIMGForStepsSync.step2 } 
                    /> 
                );
            case 3 :
                const { syncCategories, syncMedias, syncFeaturedBackground, syncTerms } = this.props;
                stepValues = extractFields(this.rawData, stepsFields[3]); 
                return (
                    <UploadSyncStep3 
                        nextStep={ this.nextStep } prevStep={ this.prevStep }
                        syncCategories={ syncCategories }
                        syncMedias={ syncMedias }
                        syncFeaturedBackground={ syncFeaturedBackground }
                        syncTerms={ syncTerms }
                        initialValues={ stepValues  }
                        bgImg={ bgIMGForStepsSync.step3 }
                    /> 
                );
            case 4 :
                const { syncOptions, syncTerritories } = this.props;
                stepValues = extractFields(this.rawData, stepsFields[4]);
                const savingAll = this.state.savingAll;      
                return (
                    <UploadSyncStep4 
                        nextStep={ this.nextStep } prevStep={ this.prevStep }
                        syncOptions={ syncOptions } 
                        syncTerritories={ syncTerritories }
                        initialValues={ stepValues  }
                        bgImg={ bgIMGForStepsSync.step4 }
                        savingAll={ savingAll }
                    /> 
                ); 
            default: 
                return null;        
        } 
    }
}

function mapStateToProps(state) {
    let firstUserData = {}; 
    if( state.auth.user) {
        const { fullName, personal_email, personal_phone, label_name, distribution_company,
            business_address, agreement_signer, payer_name, payer_email } = state.auth.user.profile;              
        firstUserData = {
            name: fullName,
            email: personal_email,
            business_address: business_address,
            phone_number: personal_phone,     
            author_sign_sample_agreement: agreement_signer,
            party_responsible_payment: payer_name,
            your_company: label_name,
            distribution: distribution_company,
            party_payment_email: payer_email
        }
    }    
    return { 
        firstUserData: firstUserData,
        selectsIsLoaded: state.selects.loaded[STORE_SELECTS_KEYS.SYNC],
        syncCategories: (state.selects.options.syncCategories) ? state.selects.options.syncCategories : [],
        syncMedias: (state.selects.options.syncMedias) ? state.selects.options.syncMedias : [],
        syncFeaturedBackground: (state.selects.options.syncFeaturedBackground) ? state.selects.options.syncFeaturedBackground : [],
        syncTerms: (state.selects.options.syncTerms) ? state.selects.options.syncTerms : [],
        syncOptions: (state.selects.options.syncOptions) ? state.selects.options.syncOptions : [],
        syncTerritories: (state.selects.options.syncTerritories) ? state.selects.options.syncTerritories : [],       
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchSelects: () => {
            return dispatch( fetchSelects( STORE_SELECTS_KEYS.SYNC ));
        }   
    };
}

export default WithNotAuthRedirect(connect(mapStateToProps, mapDispatchToProps)(AddSyncPage));
