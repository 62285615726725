import React, { Component } from 'react';
import { Select, MenuItem, FormControl}   from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';


import './filter-item.scss';

const itemClasses = {
    select : {
        root: 'filter-select',        
        //selectMenu: 'filter-select__opts',
    },   
    menu: {
        classes: {
            paper: 'filter-select__opts-wrap',
            list: 'filter-select__opts',
        }
    }
    
}
const emptyValue = '___1';



export default class FilterItem extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const val = (event.target.value === emptyValue) ? null : event.target.value;
        this.props.onChange(this.props.name, val);
    }
        
    render() {
        //
        //const handleChange = (event) => { console.log('changed', event.target.value);}
        const {label, name, options, value } = this.props;
        const currentVal = (value) ? value : emptyValue;
        return (
            <div className="shop-filter__item">
                <div className="shop-filter__item-label">{label}</div>
                <FormControl>
                <Select classes={itemClasses.select}                                                        
                    value={currentVal} 
                    variant="outlined"
                    name={name}                   
                    onChange={ this.handleChange } 
                    MenuProps={itemClasses.menu}
                    IconComponent={KeyboardArrowDownIcon} 
                    defaultValue={emptyValue}                                          
                    >
                    <MenuItem value={emptyValue}>
                        Any
                    </MenuItem>
                    {
                        options.map((item) => 
                            <MenuItem 
                            key={item.value}
                            value={item.value}>{item.label}</MenuItem>
                        )
                    }                    
                </Select>
                </FormControl>
            </div>
        )
    }
}
