import React, { Component } from 'react';
import CommentCard from './commentCard';
import AddCommentForm from './commentForm';

export default class SingleOrderComments extends Component {
    render() {
        const { items, orderId, forceUpdate } = this.props;
        return (
            <div> 
                <h3 className="dashboard-header dashboard-header_small ">
                    Conversation           
                </h3> 
                <div className="dashboard-blc">
                    {
                        items.map((item) => {
                            return  <CommentCard
                                key={ item.id }
                                item={ item }
                            />
                        })
                    }
                </div>
                <AddCommentForm orderId={ orderId } onSend={ forceUpdate} />
            </div>
        )
    }
}
