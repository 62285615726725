import React, { Component } from 'react';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { fetchUserOrdersCount }  from '@/actions/auth';

import DashboardTabs from '../tabs';
import SingleInvoceInfo from './components/singleInfo';
import SingleOrderComments from './components/singleComments';
import api from '@/middlewares/api';

import { arrangeInvoceData, arrangeCommentData } from './utils';

class DashboardSingleInvoicePage extends Component {
    constructor(props) {
        super(props);        
        //TODO setCount data can change
        this.state = {           
            tabs: [
                {id: 'body', isCount: false, label: 'Invoice'},
                {id: 'data', isCount: true, label: 'Comments', count: 0},
            ],
            tabsKey: 0,
            isLoaded: false,
            item: null,
            comments: []            
        } 
        this.forceFetch = this.forceFetch.bind(this);
        this.forceFetchComments = this.forceFetchComments.bind(this);   
    }
    componentDidMount() {
        this.fetchItem();
        this.fetchComments();
    }

    forceFetch() {
        this.fetchItem();
        this.props.fetchUserOrdersCount();
    }

    forceFetchComments() {
        this.fetchComments();
    }

    getId() {
        const { match } = this.props;
        return match.params.id;
    }

    fetchItem() {  
        const id = this.getId();      
        if(! id) return;
        api.get('user/dashboard/order/show/' + id)
        .then((response) => { //console.log('invoice', response.data);
            const item = arrangeInvoceData(response.data); 
            this.setState({item: item, isLoaded: true})
        })
        .catch((err) => {
            console.log(err.response);        
        });     
    }
    fetchComments() {
        const id = this.getId();      
        if(! id) return;
        api.get('user/dashboard/order/get-comments/' + id)
        .then((response) => { console.log('comments', response.data);
            let list = [], cnt = 0; 
            if(Array.isArray(response.data.comments)) {
                list = response.data.comments.map((item) => {
                    return arrangeCommentData(item);
                });
            }
            if(response.data.count_comments) {
                cnt = response.data.count_comments;
            }
            this.setState((prevState) => {
                prevState.tabs[1].count = cnt;
                return {
                    tabs: prevState.tabs,
                    comments: list,
                    tabsKey: Date.now(),
                }
            });
        })
        .catch((err) => {
            console.log(err);        
        });     
    }

    render() {        
        const { tabs, item, comments, tabsKey } = this.state;
        const id = this.getId(); 
        return (
            <div>
                <DashboardTabs                 
                    items={tabs}
                    updatedKey={ tabsKey }
                    panels={
                        [
                            <SingleInvoceInfo 
                                item={ item } 
                                forceUpdate={ this.forceFetch }
                            />,
                            <SingleOrderComments 
                                orderId={id}
                                items={ comments } 
                                forceUpdate={ this.forceFetchComments } 
                            />
                        ]
                    }
                />
            </div>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return {       
        fetchUserOrdersCount: () => {
            dispatch(fetchUserOrdersCount())
        }     
    };
}

export default connect(null, mapDispatchToProps)(withRouter(DashboardSingleInvoicePage));
//export default withRouter(DashboardSingleInvoicePage);