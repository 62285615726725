import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';

import logo from '@/assets/images/modal-logo.png';

export default class MainModal extends Component {
    render() {
        const {open, onClose, children} = this.props;
        return (
            <Modal
                open={open}
                onClose={onClose}                
            >
                <div className="main-modal">
                    <div className="main-modal__container">
                        <button type="button" className="btn btn-text main-modal__close" onClick={onClose}>
                            <i className="as-icon as-icon_close"></i>
                        </button> 
                        <div className="main-modal__content">
                            <div className="main-modal__logo">
                                <img src={logo} alt="Ample Sample"/>
                            </div>
                            {  children }
                        </div>                      
                    </div>                    
                </div>  
            </Modal>
        )
    }
}
