import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import UserHeader from './user';
//import HeaderSearch from './search';
import MainMenu from './navigation';
import './index.scss';
import logo from '@/assets/images/logo.png';

export default class Header extends Component {

    constructor(props) {
        super(props);
        this.toggleMobile = this.toggleMobile.bind(this);
        this.closeMobile = this.closeMobile.bind(this);        
        this.state = {
           isMobileOpen: false
        };
    }

    toggleMobile() {
        this.setState((prevState) => {
            return {
                isMobileOpen: !prevState.isMobileOpen
            };
        });
    }

    closeMobile() {
        this.setState({
            isMobileOpen: false
        });
    }
   
    render() {
        const classList = (this.state.isMobileOpen) ? 
            'page-header page-header_mob-open' : 'page-header';
        return (
            <header className={ classList }>
                <div className="page-header__top"> 
                    <div className="content">
                        <div className="page-header__row">
                            <div className="page-header__col page-header__col_logo">
                                <Link to="/" onClick={ this.closeMobile }><img src={logo} alt="Ample Samples" /></Link>                                
                            </div>
                            <div className="page-header__col page-header__col_user"> 
                                <UserHeader />                           
                            </div>
                            <div className="page-header__col page-header__col_burger"> 
                                <button type="button" className="btn btn-burger" onClick={this.toggleMobile}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>                    
                            </div>
                        </div>
                    </div>              
                </div>
                <div className="page-header__bottom">
                    <div className="content">  
                        <div className="page-header__mobile">
                                                    
                            <UserHeader onChangeUrl={ this.closeMobile }/>      
                        </div>                   
                        <nav>
                            <ul className="main-menu">
                                <MainMenu onChangeUrl={ this.closeMobile } />
                                <li className="main-menu__item main-menu__item_socials">
                                    <ul>
                                        <li><a href="https://twitter.com/theamplesamples" target="_blank" rel="noopener noreferrer"><i className="as-icon as-icon_sc-twitter"></i></a></li>
                                        <li><a href="https://www.instagram.com/theamplesamples/" target="_blank" rel="noopener noreferrer"><i className="as-icon as-icon_sc-instagram"></i></a></li>
                                        <li><a href="https://www.youtube.com/channel/UCX83YyEkoLn3RXnV5pnR0Dg" target="_blank" rel="noopener noreferrer"><i className="as-icon as-icon_sc-youtube"></i></a></li>
                                        <li><a href="https://www.linkedin.com/in/ample-samples%E2%84%A2-28b253160/" target="_blank" rel="noopener noreferrer"><i className="as-icon as-icon_sc-linkedin"></i></a></li>
                                    </ul>
                                </li>   

                            </ul>

                            
                        </nav>                        
                    </div>
                </div>
            </header>            
        )
    }
}

{/* hidden mobile search
    <div className="page-header__mob-item">
                                <HeaderSearch  onChangeUrl={ this.closeMobile }/>
                            </div>     */}