import React, { Component } from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';

import FormField from '@/components/forms/form-field';
import ManyAutocompleteField from '@/components/forms/many-autocomplete';
import OneWithImagesAutocompleteField from '@/components/forms/one-with-image-autocomplete';
import { FORMS } from '@/static/common.js';
import api, { setErrors, arrangeData} from '@/middlewares/api';

class UploadSampleStep3 extends Component {

    constructor(props) {
        super(props);
        this.stepSubmit = this.stepSubmit.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    goBack() {
        const {prevStep, rawValues} = this.props;
        prevStep(rawValues);
    }

    stepSubmit(data) {
        console.log('formData', data); 
        let formData = arrangeData(data, ["sampled_artist_name"], ["sampled_labels", "sampled_writers", "sampled_publishers"]);
        return new Promise((resolve, reject) => {
            api.post('clear/sample/step/three', formData)
            .then((response) => {                                    
                this.props.nextStep(formData, data);
                return resolve(null);
            })
            .catch((err) => {
                if(! err.response) return;
                if(err.response.status === 422) {
                    return reject(setErrors(err.response));                            
                }            
            });
        });
    } 
//     sampled_name
// sampled_artist_name
// sampled_link
// sampled_labels[]
// sampled_writers[]
// sampled_publishers[]
// party_responsible_payment
// author_sign_sample_agreement

    render() {        
        const { handleSubmit, submitting } = this.props;
        return (
            <section className="upload-form__sec" style={this.props.bgImg}>
                <div className="content">
                    <div className="upload-form__top">  
                        <h2 className="head-shadow">CLEAR SAMPLES</h2>
                        <h3 className="head">
                            Step 3 of 4: THE SAMPLED SONG
                        </h3>                       
                    </div>
                    <div className="form-step__body">
                        <form onSubmit={handleSubmit((data) => { 
                                return this.stepSubmit(data);
                            }) } className="form-layout"
                            noValidate>
                            <div className="form-row">
                                <div className="form-col_2">
                                    <Field                            
                                        name="sampled_name"
                                        component={FormField}
                                        required={true}
                                        type="text"
                                        placeholder=""
                                        label="Name of Sampled Song"                            
                                    />
                                    
                                </div>                               
                                <div className="form-col_2">
                                    <Field                            
                                        name="sampled_link"
                                        component={FormField}
                                        required={true}                                 
                                        type="text"
                                        placeholder="YouTube Link"
                                        label="YouTube Link to Sampled Song"                            
                                    />                                    
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-col_2">
                                    <Field                            
                                        name="sampled_labels"
                                        component={ManyAutocompleteField}
                                        required={true}                                 
                                        url="clear/label/search"                                       
                                        placeholder={ FORMS.PLACEHOLDER_TBD }
                                        label="Record Label of Sampled Song"                            
                                    />                                   
                                </div>                               
                                <div className="form-col_2">
                                    <Field                            
                                        name="sampled_writers"
                                        component={ManyAutocompleteField}
                                        required={true}                                 
                                        url="clear/writer/search"                                       
                                        placeholder={ FORMS.PLACEHOLDER_TBD }
                                        label="Writers of the Sampled Song"                            
                                    />                            
                                </div>
                            </div>                            
                            <div className="form-row">
                                <div className="form-col_2">
                                    <Field                            
                                        name="sampled_publishers"
                                        component={ManyAutocompleteField}
                                        required={true}                                 
                                        url="clear/publisher/search"                                       
                                        placeholder={ FORMS.PLACEHOLDER_TBD }
                                        label="Publisher(s) of Sampled Song"                            
                                    />
                                </div>
                                <div className="form-col_2">
                                    <Field                            
                                        name="sampled_artist_name"
                                        component={OneWithImagesAutocompleteField}
                                        url="artist/search" 
                                        required={true}
                                        placeholder="Set Artist First and Last Name"
                                        label="Sampled Artist Name"                            
                                    />     
                                </div> 
                            </div>                              
                            <div className="submit-row submit-row_txt">  
                                    <button className="btn btn-second" onClick={ this.goBack }
                                        type="button">Back</button>                                  
                                    <button className="btn btn-primary" disabled={ submitting }
                                        type="submit">Next</button>
                            </div>
                        </form>                        
                    </div>
                </div>
            </section>           
        )
    }
}

const UploadSampleStep3Form =  reduxForm({
    form: 'upload-sample-step3' 
  })(UploadSampleStep3);

const mapState = (state) => {
    return {
        rawValues: getFormValues('upload-sample-step3')(state)
    }
}
export default connect(mapState)(UploadSampleStep3Form);
//export default UploadSampleStep3Form;