import React, { Component } from 'react';

function  WithAccordion(WrappedComponent) {
    return class extends Component {
        constructor(props) {
          super(props);
          this.toggleContent = this.toggleContent.bind(this);
          this.state = {
                isContentOpen: false
          };
        }
        toggleContent() {
            this.setState((prevState) => {
                return {
                    isContentOpen: !prevState.isContentOpen
                }
            });
        }
    
        render() {          
          return <WrappedComponent isContentOpen={this.state.isContentOpen} 
            toggleContent={this.toggleContent} {...this.props} />;
        }
      };
}

export default WithAccordion;




