import {getYoutubeId, extractVideoURL} from './common.js';

const extractNameFromArray = (arr) => {
    let vals = [];
    arr.forEach((val) => {
        if(val.name) {
            vals.push(val.name);
        }
    })
    return vals.join(', ');
}

const crateList =  [
{
    key: 'artist',
    label: "ARTIST",
    value: '-'
},
{
    key: 'song_title',
    label: "SONG TITLE",
    value: '-'
},
{
    key: 'written_by',
    label: "WRITTEN BY",
    value: '-'
},
{
    key: 'executive_producer',
    label: "Executive Producer",
    value: '-'
},
{
    key: 'produced_by',
    label: "PRODUCER(S)",
    value: '-'
},
{
    key: 'artist_manager',
    label: "MANAGER",
    value: '-'
},
{
    key: 'labels',
    label: "RECORD LABEL(S)",
    value: '-'
},
{
    key: 'publishers',
    label: "PUBLISHER(S)",
    value: '-'
},   
{
    key: 'genres',
    label: "GENRE(S)",
    value: '-'
},
{
    key: 'user',
    label: "CONTRIBUTED BY",
    value: '-'   
}
];

const songList = [    
    {
        key: 'written_by',
        label: "WRITTEN BY",
        value: '-'
    },
    {
        key: 'executive_producer',
        label: "EXECUTIVE PRODUCER",
        value: '-'
    },
    {
        key: 'produced_by',
        label: "PRODUCER(S)",
        value: '-'
    },
    {
        key: 'artist_manager',
        label: "MANAGER",
        value: '-'
    },
    {
        key: 'labels',
        label: "RECORD LABEL(S)",
        value: '-'
    },
    {
        key: 'publishers',
        label: "PUBLISHER(S)",
        value: '-'
    }, 
];

const extractFeatures = (item, listPattern) => {
    let list = listPattern.map(a => Object.assign({}, a));
    list.forEach((el) => {
        if(! item[el.key]) return;
        if(typeof item[el.key] === 'string') {
            el.value = item[el.key];
        } else if( Array.isArray(item[el.key]) ) {           
            el.value = extractNameFromArray(item[el.key]);
        } else if((typeof item[el.key] === 'object') && (item[el.key].name) ) {
            el.value = item[el.key].name;            
        }
        //TODO
    })
    return list;
};


const extractSong = (item) => {
    let result = {
        id: item.id,
        image: (item.image_url) ? item.image_url : null,
        title: (item.song_title) ? item.song_title : null,
        artist: (item.artist && item.artist.name) ? item.artist.name : null,
        genres: ( Array.isArray(item.genres) ) ? extractNameFromArray(item.genres) : '',
        features: extractFeatures(item, songList),
    }
    return result;
};

const extractSongs = (item) =>{
    let result = [];
    if(item.sampled_songs && Array.isArray(item.sampled_songs)) {
        item.sampled_songs.forEach((el) => {
            result.push(extractSong(el));
        })
    }
    return result;
};

export const extractItem = (item) => {
    let result = {
        id: item.id,
        image: (item.image_url) ? item.image_url : null,
        video: extractVideoURL(item.video_url),
        artist: (item.artist && item.artist.name) ? item.artist.name : null,
        title: (item.song_title) ? item.song_title : null,
    };
    result.features = extractFeatures(item, crateList);
    result.songs = extractSongs(item);
    result.mentions = (item.mentions) ? item.mentions : [];
    return result;
}

export const extractDataForCommonCard = (item) => {
    let result = {
        id: item.id,
        video: getYoutubeId(item.video_url),
        image_url: item.image_url, 
        song_title: item.song_title, 
        artistName: (item.artist) ? item.artist.name : null
    }
    return result;
}