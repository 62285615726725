import React, { Component } from 'react';
import { connect } from 'react-redux';

import NoteModal from '@/components/modals/note';

class Notification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        }
        this.onClose = this.onClose.bind(this);
    } 
    componentDidUpdate(prevProps) {
        if(prevProps.timestamp !== this.props.timestamp) {
            this.setState({
                isOpen: true
            });
        }
    }
    onClose() {
        this.setState({
            isOpen: false
        });
    }
    render() {       
        const { status } = this.props;
        switch(status) {
            case 'error': {
                return this.renderError();
            }
            case 'success': {
                return this.renderSuccess();
            }
            default: return this.renderAlert();
        }
    }

    renderSuccess() {
        const { isOpen } = this.state;
        const { title, msg } = this.props;
        return (
        <NoteModal open={isOpen} onClose={ this.onClose  }>
                <div className="note-modal__icon as-success">
                    <i className="as-icon as-icon_check_cuicle"></i>
                </div>
                <h3 className="note-modal__title">
                    { title }
                </h3>
                <div className="note-modal__msg" dangerouslySetInnerHTML={{
                        __html: msg
                    }}>                   
                </div>
                <div  className="note-modal__btn">
                        <button className="btn btn-primary" type="button" onClick={ this.onClose }>OK</button>
                </div>
        </NoteModal>
        );
    }

    renderError() {
        const { isOpen } = this.state;
        const { title, msg } = this.props;
        return (
            <NoteModal open={isOpen} onClose={ this.onClose  }>
                <div className="note-modal__icon as-error">
                    <i className="as-icon as-icon_cancel_circle"></i>
                </div>
                <h3 className="note-modal__title">
                    { title }
                </h3>
                <div className="note-modal__msg" dangerouslySetInnerHTML={{
                        __html: msg
                    }}>                                    
                </div>
                <div  className="note-modal__btn">
                        <button className="btn btn-primary" type="button" onClick={ this.onClose }>OK</button>
                </div>
            </NoteModal>
        );
    }

    renderAlert() {
        const { isOpen } = this.state;
        const { title, msg } = this.props;
        return (
            <NoteModal open={isOpen} onClose={ this.onClose  }>
                <div className="note-modal__icon as-alert">
                    <i className="as-icon as-icon_high-risk"></i>
                </div>
                <h3 className="note-modal__title">
                    { title }                
                </h3>
                <div className="note-modal__msg" dangerouslySetInnerHTML={{
                        __html: msg
                    }}>                    
                </div>
                <div  className="note-modal__btn">
                        <button className="btn btn-primary" type="button" onClick={ this.onClose }>OK</button>
                </div>
            </NoteModal>
        );
    }
}

const MapState = (state) => {
    return {
        status: state.notification.status,
        title: state.notification.title,
        msg: state.notification.msg,
        timestamp: state.notification.timestamp
    }
}

export default connect(MapState)(Notification);

/*
<div  className="note-modal__btns">
<button className="btn btn-second" type="button" onClick={ this.onClose }>Cancel</button>
<button className="btn btn-primary" type="button" onClick={ this.onClose }>OK</button>
</div> */
