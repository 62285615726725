export const options = {
    style: {
        base: {
            fontFamily: "Renner, Jost, sans-serif",
            fontSize: '16px',
            fontWeight: 300,        
            lineHeight: 1.2,
            color: '#000000',
            backgroundColor: '#FFFFFF',            
            '::placeholder': {
                color: '#4A4A4A',
            },
        },
        invalid: {
            color: '#000000',
            //borderColor: '#FF2E2E',
        },
    }
};

export const fields = [
    {key: 'country', error: 'The Country is required.'},
    {key: 'postal_code', error: 'The Zip is required.'},
    {key: 'state', error: 'The State is required.'},
    {key: 'address', error: 'The Address is required.'},
    {key: 'city', error: 'The City field is required.'},
    {key: 'name', error: 'The Card Holder Name is required.'},
];