import React, { Component, Fragment } from 'react';

export default class CheckboxField extends Component {
    render() {
        const { 
            input,
            label,
            meta                  
          } = this.props; 
        return (
            <Fragment>
                <label className="checkbox-field">
                    <input {...input} type="checkbox"  className="checkbox-field__input" checked={ input.value } />       
                        <span className="checkbox-field__control" />
                        <span className="checkbox-field__label">{label}</span>
                </label>
                {
                    meta.error &&
                    <div className="form-field__note form-field__note_other error">{meta.error}</div>
                }            
            </Fragment>
        )
    }
}
// {
//     meta.error &&
//     <div className="form-field__note error">{meta.error}</div>
// }
// {
//     input.value ?
//     <input {...input} type="checkbox" checked="checked" className="checkbox-field__input" />
//     :
//     <input {...input} type="checkbox"  className="checkbox-field__input" />
// }   