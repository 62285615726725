import React, { Component } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import { stopEnterSubmit } from '@/utils/forms.js';
import { getOptionLabel, openList, closeList, getOptions, 
    changeInputHandler, changeHandler, OneBlurHandler }  from '@/utils/autocomplete.js';
import FormFieldBottom from './field-bottom';

const itemClasses = {
    input : {
        root: 'as-select as-one-autocomplete',
        paper: 'as-select__opts-wrap',       
    },
}

export default class OneAutocompleteField extends Component {
    constructor(props) {
        super(props);
        this.changeHandler = changeHandler.bind(this);
        this.openList = openList.bind(this);
        this.closeList = closeList.bind(this);
        this.changeInputHandler = changeInputHandler.bind(this);
        this.getOptions = getOptions.bind(this);
        this.blurHandler = OneBlurHandler.bind(this);        

        this.getOptionsDebonced =  debounce((query) => {
            this.getOptions(query);
        }, 200);       
        this.state= {
            options: [],
            open: false,
            processedQuery: ''            
        } 
              
    }    
   
    render() { 
        const { label, required, meta, note, correct, placeholder } = this.props;        
        const classes = (meta.error) ? "form-field form-autocomplete error" : "form-field form-autocomplete";        
        const val = this.props.input.value; 
        const { options, open, processedQuery } = this.state;
        const loading = open && options.length === 0; 
        const loadingText = (processedQuery.length > 0) ? 'Loading' :  'Start typing...';
        return (
            <div className={ classes }> 
                <label className={ (required) ? "label label_required" : "label"}>{ label }</label>                             
                <Autocomplete   
                    classes={itemClasses.input} 
                    autoSelect={false}               
                    freeSolo
                    value={ val }      
                    onChange={this.changeHandler}
                    getOptionLabel={ getOptionLabel }                                  
                    options={options}
                    loading={loading}
                    open={open}
                    onOpen={ this.openList }
                    onClose={ this.closeList }
                    loadingText={ loadingText }
                    renderInput={(params) => (
                    <TextField {...params} 
                        variant="outlined"
                        onChange={ this.changeInputHandler }  
                        onKeyDown={ stopEnterSubmit }
                        placeholder={ placeholder }
                        onBlur={ this.blurHandler }                     
                    />
                    )}
                />
                <FormFieldBottom 
                    meta={meta}
                    note={note}
                    correct={correct}
                />    
            </div>
        )
    }
}

OneAutocompleteField.propTypes = {
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    correct: PropTypes.string,
    note: PropTypes.string,
    label: PropTypes.string,
    url: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool                        
};
