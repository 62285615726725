import React, { Component, Fragment } from 'react'

export default class SyncInfoPanel extends Component {
    render() {
        const { features } = this.props.item;
        return (
            <Fragment>
                <h3 className="dashboard-header dashboard-header_small">
                    ABOUT THIS SYNC request            
                </h3>
                <div className="db-features-table__wrap">
                    <table className="db-features-table">
                        <tbody>
                            {
                                features.map((feat) => {
                                    const {id, value, label} = feat;
                                    return (
                                        <tr key={id}>
                                            <td className="db-features-table__label">{ label }</td>
                                            <td dangerouslySetInnerHTML={{__html: value}}></td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </Fragment>
        )
    }
}
