import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import icon from '@/assets/images/defaults/modal_save.png'; 

export default class SavedInfoModal extends Component {
  render() {
    const {open, onClose, message} = this.props;
    const msg = message || 'Changes Saved!';
        return (
            <Modal
                open={open}
                onClose={onClose}                
            >
                <div className="main-modal note-modal">
                    <div className="main-modal__container">
                        <button type="button" className="btn btn-text main-modal__close" onClick={onClose}>
                            <i className="as-icon as-icon_close"></i>
                        </button> 
                        <div className="main-modal__content">
                            <div className="note-modal__icon as-saved">
                                <img src={ icon } alt="" />
                            </div>
                            <h3 className="note-modal__title">
                                { msg }
                            </h3>                            
                            <div  className="note-modal__btn">
                                <button className="btn btn-primary" type="button" onClick={ onClose }>CONTINUE</button>
                            </div>                        
                        </div>                      
                    </div>                    
                </div>  
            </Modal>
        )
  }
}
