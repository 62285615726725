import React, { Component } from 'react';
import {connect} from "react-redux";
import { Redirect } from "react-router-dom";
import {ROUTESLIST} from '@/static/urls.js';

function  WithNotAuthRedirect(WrappedComponent) {
    class HocNotAuthRedirect extends Component { 
        render() {  
          const {isCurrentUserAuth, isCurrentUserChecked} = this.props;
          if(! isCurrentUserAuth){ 
              if(isCurrentUserChecked)  {         
                  return (
                      <Redirect to={ROUTESLIST.LOGIN} /> 
                  );
              }
          }          
          return <WrappedComponent  {...this.props} />;
        }
    };
    function mapStateToProps(state) {
      return {
          isCurrentUserAuth: state.auth.isAuth,
          isCurrentUserChecked: state.auth.isChecked        
      };
    }  
  return connect(mapStateToProps, null)(HocNotAuthRedirect);
}

export default WithNotAuthRedirect;




