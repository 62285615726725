import history from '@/store/history';
import { SubmissionError } from 'redux-form';
import api, { JWTService, setErrors, getUserDevice } from '@/middlewares/api';
import { ROUTESLIST } from '@/static/urls';


// const AuthyPathes = [
//     ROUTESLIST.ADD_CRATE,  ROUTESLIST.UPLOAD_SAMPLE,  ROUTESLIST.UPLOAD_SYNC,
//     ROUTESLIST.UPLOAD_MECH,  ROUTESLIST.DASHBOARD_SAMPLES,  ROUTESLIST.DASHBOARD_ONE_SAMPLE,
//     ROUTESLIST.DASHBOARD_SYNC,  ROUTESLIST.DASHBOARD_MECHANICAL,  ROUTESLIST.DASHBOARD_PROFILE,
//     ROUTESLIST.DASHBOARD_PAYMENTS,  ROUTESLIST.DASHBOARD_SECURITY,  ROUTESLIST.DASHBOARD_INVOICES, 
//     ROUTESLIST.DASHBOARD_CRATES  
// ];

export const AC_AUTH = {
    LOGIN: "AC_AUTH__LOGIN",
    LOGOUT: "AC_AUTH__LOGOUT",
    FETCHING: "AC_AUTH__FETCHING", 
    SET_USER: "AC_AUTH__SET_USER",
    PARTUAL_UPDATE: "AC_AUTH__PARTUAL_UPDATE",  
    FETCH_ORDERS: "AC_AUTH__FETCH_ORDERS", 
}

export const authLogin = (data, redirectUrl) => {    //TODO
    data['device_name'] = getUserDevice();    
    return (dispatch) => {        
        return api.post('login', data)           
            .then((response) => {               
                if(response.data.token) {                    
                    JWTService.setToken(response.data.token);                     
                    return fetchUserData(dispatch, true, redirectUrl);
                }          
            })
            .catch((err) => {  console.log(err);
                if(! err.response) return;
                if(err.response.status === 422) { 
                    throw setErrors(err.response); 
                } 
                if(err.response.status === 401) {
                    const error =  err.response.data.error;
                    throw new SubmissionError({ password: error, _error: error }); 
                } 
            });            
    };
}

export const checkUserAuth = () => {
    if(! JWTService.checkToken()) {
        return {
            type: AC_AUTH.LOGOUT,        
        }
    } else { 
        return (dispatch) => {  
            return fetchUserData(dispatch, false, false, {'save_last_visited': 1});
        };
    }
};
//save_last_visited=1

export const fetchUserData = (dispatch, isRedirect, redirectUrl, params) => {    
    dispatch({type: AC_AUTH.FETCHING}); 
    const options =  (params) ? { params: params} : {};
    return api.get('user', options)           
        .then((response) => { 
            dispatch({type: AC_AUTH.SET_USER, payload: response.data}); 
            if(isRedirect) {
                const url = (redirectUrl) ? redirectUrl : ROUTESLIST.DASHBOARD;
                history.replace(url);  
            }    
        })
        .catch((err) => {            
            console.log(err);
        });
}

export const authLogout = (isHomeRedirect) => { 
    JWTService.removeToken();  
    if(isHomeRedirect)  { 
        history.replace(ROUTESLIST.HOME); 
    } 
    // else if( AuthyPathes.indexOf(history.location.pathname) !== -1) {
    //     console.log(history.location.pathname);
    //     history.replace(ROUTESLIST.LOGIN);
    // }    
    return {
        type: AC_AUTH.LOGOUT,
    }
}

export const authUpdatePartual = (payload, part) => {   
    return {
        type: AC_AUTH.PARTUAL_UPDATE,
        payload: payload,
        part: part || 'profile'        
    }
}

export const fetchUserOrdersCount = () => {      
    return (dispatch) => {  
        return api.get('user/dashboard/get-count-invoices')           
        .then((response) => { 
            dispatch({type: AC_AUTH.FETCH_ORDERS, payload: response.data});            
        })
        .catch((err) => {            
            console.log(err);
        });
    };    
}
