import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchUserOrdersCount }  from '@/actions/auth';

import InvoiceCard from './components/invoiceCard.js';
import PayModal from './components/pay-modal.js';
import { extractPaninationMeta } from '@/utils/common';
import { extractInvoceCardData } from './utils';
import { initScrollRefs } from '@/utils/behavior';

import api from '@/middlewares/api';

import Pagination from '@/components/pagination/index.js';
import HeaderOrder from '@/components/tables/header-order.js';
import WithSortingAndPagination from '@/components/hoc/with-sorting.js';

import './index.scss';

class DashboardInvoicesPage extends Component {
    constructor(props) {
        super(props);        
        this.onPayClick = this.onPayClick.bind(this); 
        this.onClosePayModal = this.onClosePayModal.bind(this);        
        this.state = {                
            processingItem: null,
            items: [],
            pageInfo: null,
        };
        initScrollRefs(this);              
    } 
    componentDidMount() {
        this.props.fetchUserOrdersCount();
        this.fetchItems();
    }
    componentDidUpdate(prevProps){       
        if(prevProps.location.search !== this.props.location.search) {
            this.fetchItems();
        }
    }
    fetchItems() {
        const search = this.props.getSearchParams();
        api.get('user/dashboard/orders' + search)
        .then((response) => { 
            let items = [];
            let pageInfo = extractPaninationMeta( response.data );
            if(response.data ) {
                const {data } = response.data;
                if(Array.isArray(data)) {
                    data.forEach((item) => {
                        items.push(extractInvoceCardData(item));
                    });
                }                        
            }            
            this.setState({
                pageInfo,
                items
            }); 
        })
        .catch((err) => {
            console.log(err.response);        
        });     
    }
    onPayClick(id) {
        //TODO
        const items = this.state.items;
        const item = items.find((el) => {
            return (el.id === id);
        });
        if(! item) return;
        const { amount, balance_due	} = item; 
        this.setState({
            processingItem: {
                id , 
                number: id,
                balance: amount,
                baseAmount: balance_due	            
            }
        });
    }
    onClosePayModal(needUpdate) {
        this.setState({                    
            processingItem: null           
        });
        if(needUpdate) {
            this.fetchItems();
            this.props.fetchUserOrdersCount();
        }
    }

    render() { 
        const { nav, onSortingClick, onPageChange } = this.props;   
        const { processingItem }  = this.state; 
        const { items, pageInfo}  = this.state;
        return (
            <div>
                <h3 className="dashboard-header dashboard-header_small">Invoices</h3>
                <div className="top-scroll" ref={this.scrollTopElem} onScroll={this.scrollTopHandler}>
                    <div className="top-scroll__el top-scroll__el_dashboard-table"></div>
                </div> 
                <div className="dashboard-table__wrap" ref={this.scrollBottomElem} onScroll={this.scrollBottomHandler}>
                    <table className="dashboard-table">
                        <thead className="dashboard-table">
                            <tr>
                                <th>
                                    <HeaderOrder 
                                        label="No."
                                        value="number"
                                        onChange={onSortingClick}
                                        active={ (nav.sort_col === "number") ? nav.sort_direction : null}
                                    />
                                </th>
                                <th>
                                    DATE
                                </th>
                                <th>
                                    <HeaderOrder 
                                        label="Amount"
                                        value="amount"
                                        onChange={onSortingClick}
                                        active={ (nav.sort_col === "amount") ? nav.sort_direction : null}
                                    />
                                </th>
                                <th>
                                    UNITS
                                </th>
                                <th>
                                    <HeaderOrder 
                                        label="Status"
                                        value="status"
                                        onChange={onSortingClick}
                                        active={ (nav.sort_col === "status") ? nav.sort_direction : null}
                                    />
                                </th> 
                                <th>
                                    Pay
                                </th>
                                <th>
                                    detailed view
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            { 
                                items.map((item) => {
                                    return <InvoiceCard 
                                        key={ item.id }
                                        id={ item.id }
                                        item= { item }
                                        onProcessPay={ this.onPayClick}
                                    />
                                })
                            }
                        </tbody>
                    </table>                    
                </div>
                {  pageInfo &&
                    <Pagination  meta={pageInfo} key={ pageInfo.key } onPageChange={ onPageChange } />
                }
                <PayModal
                    onClose={this.onClosePayModal}                  
                    invoice={ processingItem }
                />               
            </div>        
        )
    }
}

const WrappedDashboardInvoicesPage =  WithSortingAndPagination(DashboardInvoicesPage);

function mapDispatchToProps(dispatch) {
    return {       
        fetchUserOrdersCount: () => {
            dispatch(fetchUserOrdersCount())
        }     
    };
}

export default connect(null, mapDispatchToProps)(WrappedDashboardInvoicesPage);