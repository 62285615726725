import React, { Component } from 'react';
import QueryString from 'query-string';

import history from '@/store/history';

import ShopFullCard from '@/components/shop/card-full';
import FilterItem from '@/components/shop/filter-item';

import './index.scss';

import { samples, filters, emptyFilters } from './data.js';

export default class ShopPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filter: {...emptyFilters}
        };
        this.changeFilterItem = this.changeFilterItem.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
    }
    changeFilterItem(key, value) {        
        this.setState((state) => {
            let filter = Object.assign({}, state.filter);
            filter[key] = value;
            this.updateRoute(filter);
            return {
                filter : filter
            };
        });        
    }
    resetFilter() {
        this.setState({ filter: {...emptyFilters} });
        this.updateRoute(emptyFilters);
    }
    componentDidMount() {        
        const query = this.props.location.search;
        if(query) {
            this.updateSearch(query);
        } 
    }
    updateRoute(filter) {
        let params = {};
        Object.keys(filter).forEach((key) => {
            if(filter[key]) {
                params[key] = filter[key];
            }
        });
        let query = QueryString.stringify(params);
        if(query) {
            query = '?' +  query;
        }
        history.push({
            pathname: this.props.location.pathname,
            search: query
        });       
    }

    updateSearch(query) {
        const params = QueryString.parse(query);
        const filter = Object.assign({}, emptyFilters, params);
        this.setState({ filter: filter });       
    }

    render() {
        const filter = this.state.filter;
        return (
            <main className="page page-shop">
                <section className="top-banner banner-filter banner-filter_fw" id="top-shop">
                    <div className="content">
                        <div className="top-banner__wrap">
                            <div className="top-banner__content">
                                <h2 className="head-shadow">Sample Exchange</h2>
                                <h3 className="head">
                                SAMPLE OR BE SAMPLED…A FAIR EXCHANGE IS NO ROBBERY
                                </h3>
                                
                            </div>
                        </div>
                    </div>
                </section>
                <div className="shop-content">
                    <div className="content">
                        <div className="shop-filter">
                            <div className="shop-filter__items">
                                {filters.map((item) =>
                                        <FilterItem key={ item.name } 
                                            value={ filter[item.name]}
                                            name={ item.name }
                                            label={ item.label }
                                            options={ item.options }
                                            onChange= { this.changeFilterItem }
                                        />                                               
                                    )} 
                            </div>
                            <div className="shop-filter__reset">
                                <button type="button" className="btn btn-reset" onClick={ this.resetFilter }>
                                    <i className="as-icon as-icon_close"></i>
                                    <span>Clear All Filter</span>
                                </button>
                            </div>    
                        </div>
                        <div className="shop-items">
                            {samples.map((item) =>
                                <ShopFullCard key={ item.id } 
                                    id={item.id}
                                    image={item.img}
                                    title={item.title}
                                    author={item.author}
                                    tags={item.tags}
                                    format={item.format}
                                    bpm={item.bpm}
                                    size={item.size}
                                    price={item.price}
                                        />                                               
                            )} 
                        </div>   
                    </div> 
                </div>                
            </main>
        )
    }
}



