import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { ROUTESLIST } from '@/static/urls';
import { authLogout }  from '@/actions/auth';

class UserHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
        this.toggleMenu = this.toggleMenu.bind(this);
        this.logout = this.logout.bind(this);
        this.onLinkClick = this.onLinkClick.bind(this);
    }
    onLinkClick() {
        this.setState({open: false});
        this.props.onChangeUrl();
    }
    toggleMenu() {
        this.setState((prevState) => {
            return {open: !prevState.open};
          });
    }
    logout() {
        this.setState({open: false});
        this.props.fetchLogout();
    }
    render() {
        return (this.props.isAuth) ? this.renderUser() : this.renderEmpty();       
    }

    renderUser() {        
        const { userName, userAvatar } = this.props;
        const wrapClasses = (this.state.open) ? 'header-user header-user_auth header-user_open' :
            'header-user header-user_auth';   

        return (
            <div className={wrapClasses}>
                <div className="header-user__wrap" role="button" onClick={this.toggleMenu}>                 
                    <span className="header-user__avatar" style={{ backgroundImage: `url(${userAvatar})` }}></span> 
                    <span className="header-user__name">Hi { userName}! </span>  
                    <i className="as-icon as-icon_arrow-down"></i>
                </div>
                <div className="header-user__menu-wrap">
                    <ul className="header-user__menu">
                        <li>
                            <Link to={ ROUTESLIST.DASHBOARD } onClick={ this.onLinkClick } className="header-user__menu-link">
                                Dashboard
                            </Link>
                        </li>
                        <li>
                            <button type="button" className="header-user__menu-link" onClick={this.logout}>Logout</button>
                        </li>
                    </ul>
                </div>      
            </div>
        );
    }
    renderEmpty() {
        return (
            <div className="header-user header-user_noauth">                
                <Link to={ ROUTESLIST.SIGNUP } onClick={ this.onLinkClick } className="header-user__link">Create an Account</Link>
                <Link to={ ROUTESLIST.LOGIN } onClick={ this.onLinkClick } className="header-user__link">Log in</Link>       
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isAuth: state.auth.isAuth,        
        userName: (state.auth.user) ? state.auth.user.displayName: null,
        userAvatar: (state.auth.user) ? state.auth.user.avatar: null
    };
}
function mapDispatchToProps(dispatch) {
    return {
        fetchLogout: () => {
            return dispatch(authLogout())
        },     
    };
}

UserHeader.propTypes = {
    onChangeUrl: PropTypes.func,
    isAuth: PropTypes.bool,        
    userName: PropTypes.string,
    userAvatar: PropTypes.string
}
  
UserHeader.defaultProps = {
    onChangeUrl: () => {}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserHeader);