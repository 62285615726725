import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import history from '@/store/history';
import { ROUTESLIST } from '@/static/urls';
import api, { getFormDataDeep} from '@/middlewares/api';
import { fetchSelects, STORE_SELECTS_KEYS } from '@/actions/selects';
import { extractFields, cacheData } from '@/utils/forms';
import WithNotAuthRedirect from '@/components/hoc/with-not-auth-redirect';

import { extractSavedCrate } from '../crate-helpers.js';
import './index.scss';
//TODO
//import MainModal from '@/components/modals/main';
import AddCrateStep1 from './step1';
import AddCrateStep2 from './step2';
import AddCrateStep3 from './step3';

const stepsFields = {
    1: ['image', 'artist_name', 'song_title', 'written_by', 'executive_producer', 'feats'],
    2: ['produced_by', 'artist_manager', 'publishers',  'labels',  'video_url',  'genres',  'release_date'],
    3: ['mentions'],
};

class AddCratePage extends Component {
    constructor(props) {
        super(props) 
        // this.maxSamples = 3; //old
        // this.currentSample = 0; //old
        this.formData = { }; 
        this.rawData = {};  
        this.state = {
          page: 1,          
          id: null,
          item: null,
        }
        this.prevStep = this.prevStep.bind(this);
        this.nextStep = this.nextStep.bind(this);
        this.saveData = this.saveData.bind(this);    
    }

    prevStep(rawData) {
        cacheData(rawData, this.rawData);
        this.setState((prevState) => {
            if(prevState.page > 1) {                    
                return {
                    page: prevState.page - 1
                }                
            } 
        });
    } 

    fetchCrate() {
        const id = (this.props.match.params.id) ? this.props.match.params.id : 0;
        console.log(id);
        if(! id) return;
        this.setState({
            id: id
        });
        api.get('crate/show-in-popup', { params: {
            id: id
        }})
        .then((response) => { 
        const item = extractSavedCrate(response.data); 
            this.setState({
                //id: id,
                item: item,
            });
        })
        .catch((err) => {                
        });
    }

    componentDidUpdate(prevProps) {
        if((prevProps.isAuth !== this.props.isAuth) && this.props.isAuth) {
            this.fetchCrate();
        }
    }

    //TODO
    nextStep(data, rawData) {
        //TODO
        let isNext = true;
        cacheData(rawData, this.rawData);
        cacheData(data, this.formData);
        if(this.state.page < 3) {           
        } else {
            isNext = false;
            this.saveData();
        } 
        if(isNext) {
            this.setState((prevState) => {
                return {
                    page: prevState.page + 1
                }
            });
        }
        console.log('this.formData', this.formData);
        console.log('this.rawData', this.rawData);
        
    }
    saveData() {    
        //TODO ROUTESLIST.LOGIN
        const id = this.state.id;  
        const objData = {
            crate_id: id,
            sample_songs: [
                this.formData
            ]
        } 
        const data = getFormDataDeep(objData);
        for(let pair of data.entries()) {
            console.log(pair[0]+ ', '+ pair[1]);
         }
        api.post('crate/save', data)
        .then((response) => {                                    
            const id = this.state.id;  
            history.push(ROUTESLIST.CRATES_CRATE + id);
            console.log('saved', response);
        })
        .catch((err) => {
            console.log(err.response);        
        });        
    }

    componentDidMount() {
        if(! this.props.selectsIsLoaded) {
            this.props.fetchSelects();
        }
        this.fetchCrate();
    }

    render() {        
        const content = this.renderContent();
        return (
            <main className="page add-crate-page">
                {
                    content
                }                        
            </main>
        )
    }

    renderContent() {
        const {page, item} = this.state; 
        const { crateGenres } = this.props; 
        const title = (item) ? item.title: null;
        const image = (item) ? item.image: null;
        let stepValues; 
        switch(page) {
            case 1 :
                stepValues = extractFields(this.rawData, stepsFields[1]);
                return (<AddCrateStep1 
                    title={ title }
                    image={ image }
                    initialValues={ stepValues }
                    nextStep={ this.nextStep } /> );
            case 2 : 
                stepValues = extractFields(this.rawData, stepsFields[2]);               
                return (<AddCrateStep2 
                    title={ title }
                    image={ image }
                    initialValues={ stepValues }
                    nextStep={ this.nextStep }
                    prevStep={ this.prevStep } 
                    crateGenresOptions={crateGenres}
                    /> );
            case 3 :
                stepValues = extractFields(this.rawData, stepsFields[3]);
                return (<AddCrateStep3 
                    title={ title }
                    image={ image }
                    initialValues={ stepValues }
                    prevStep={ this.prevStep }
                    nextStep={ this.nextStep } 
                    /> );
            default: {
                return null;
            }            
        }
    }
}
function mapStateToProps(state) {    
    return { 
        isAuth: state.auth.isAuth,
        // isChecked: state.auth.isChecked,     
        selectsIsLoaded: state.selects.loaded[STORE_SELECTS_KEYS.CRATES],
        crateGenres: (state.selects.options.crateGenres) ? state.selects.options.crateGenres : [],          
    };
}
function mapDispatchToProps(dispatch) {
    return {
        fetchSelects: () => {
            return dispatch( fetchSelects( STORE_SELECTS_KEYS.CRATES ));
        },     
    };
}

export default WithNotAuthRedirect(connect(mapStateToProps, mapDispatchToProps)(AddCratePage));

///////////////////////////
// image
// artist_name
// song_title
// written_by
// executive_producer
/*
image
artist_name
song_title
written_by
executive_producer  ///step 1
produced_by
artist_manager
publishers []
labels []
video_url
genres []
release_date
mentions [
 [
  content
  url
 ]
*/