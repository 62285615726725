import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { ROUTESLIST } from '@/static/urls';

import { authLogin }  from '@/actions/auth';

import WithAuthRedirect from '@/components/hoc/with-auth-redirect';
import FormField from '@/components/forms/form-field';
import PasswordField from '@/components/forms/password-field';
import CheckboxField from '@/components/forms/checkbox-field';

import BgImage from '@/assets/images/pages/signin_bg.jpg';

const bgStyles = {
	backgroundImage: `url(${BgImage})`,    
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	backgroundSize: 'cover'
}
 
class LoginPage extends Component {    
    render() {  
        const { handleSubmit, pristine, submitting, submitLogin } = this.props;
        return (
            <main className="page">
                <section className="form-section form-section--radial" style={bgStyles}>
                    <div className="content-tight">
                        <h3 className="head-middle">Log In to continue</h3>
                        <form onSubmit={ handleSubmit((data) => { 
                                return submitLogin(data);
                            }) } 
                            className="login-form" noValidate>
                        <Field                            
                            name="email"
                            component={ FormField }
                            type="text"
                            placeholder="some@email.com"
                            label="Username / Email"                            
                        />
                        <Field                            
                            name="password"
                            component={ PasswordField }
                            type="password"                           
                            label="Password"                            
                        />
                            <div className="form-row form-row_sided">
                                <div className="form-col form-col_half">
                                    <div className="form-field">
                                    <Field                            
                                        name="remember"
                                        component={ CheckboxField }                                                              
                                        label="Remember Me"                            
                                    />                                    
                                    </div>
                                </div>
                                <div className="form-col form-col_half">
                                    <div className="form-field">
                                    <Link to={ ROUTESLIST.ACCOUNT_FORGOT_PASSWORD } className="link">Forgot Password?</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="form-submit-row">
                                <button className="btn btn-primary btn-fw" disabled={ pristine || submitting }
                                    type="submit">Log In</button> 
                            </div>
                        </form>    
                        <div className="form-field center">
                            Don’t have an account yet? <Link to="/signup" className="link">Create One.</Link> 
                        </div>
                    </div>
                </section>   
            </main>
        );
        // <div className="lined-blc">
        //                     <span className="lined-blc__txt">Or Enter With</span>
        //                 </div>
    }
}

const loginFormPage =  reduxForm({
    form: 'login-form' 
  })(LoginPage);

function mapDispatchToProps(dispatch, ownProps){
    const url = (ownProps.location.state && ownProps.location.state.redirectUrl) ?
        ownProps.location.state.redirectUrl : null;
    return {
        submitLogin: (data) => { return dispatch(authLogin(data, url)); }
    }
}

export default WithAuthRedirect(connect(
  null, mapDispatchToProps
)(loginFormPage));
