import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import {ROUTESLIST} from '@/static/urls.js';
import api from '@/middlewares/api';
import { extractDasboardCard } from '@/utils/syncs';
import { extractPaninationMeta } from '@/utils/common';
import { initScrollRefs } from '@/utils/behavior';

import Pagination from '@/components/pagination/index.js';
import HeaderOrder from '@/components/tables/header-order.js';
import WithSortingAndPagination from '@/components/hoc/with-sorting.js';
import DashboardSyncCard from './syncCard.js';


import syncImg  from '@/assets/images/defaults/sync-sm1.png';

class DashboardSyncPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoading: false,
            pageInfo: null,
        };
        initScrollRefs(this);                 
    }   
    
    componentDidMount() {
        this.fetchItems();
    }
    componentDidUpdate(prevProps){       
        if(prevProps.location.search !== this.props.location.search) {
            this.fetchItems();
        }
    }
    
    fetchItems() {
        const search = this.props.getSearchParams();
        api.get('user/dashboard/clear-syncs' + search)
        .then((response) => {
            let items = [];
            let pageInfo = extractPaninationMeta( response.data );
            if(response.data ) {
                const {data} = response.data; 
                if(Array.isArray(data)) {
                    data.forEach((item) => {
                        items.push(extractDasboardCard(item));
                    })
                }           
            }            
            this.setState({
                pageInfo,
                items
            }); 
        })
        .catch((err) => {
            console.log(err.response);        
        });        
    }

    render() {  
        const { nav, onSortingClick, onPageChange } = this.props; 
        const { items, pageInfo }   = this.state;
        return (
            <div> 
                <h3 className="dashboard-header dashboard-header_small dashboard-header_row">
                    <span>My Sync</span>
                    <Link className="btn btn-primary"to={ ROUTESLIST.UPLOAD_SYNC }>Add Sync</Link>                    
                </h3> 
                <div className="top-scroll" ref={this.scrollTopElem} onScroll={this.scrollTopHandler}>
                    <div className="top-scroll__el top-scroll__el_dashboard-table"></div>
                </div>
                <div className="dashboard-table__wrap" ref={this.scrollBottomElem} onScroll={this.scrollBottomHandler}>
                    <table className="dashboard-table dashboard-table_imgs">
                        <thead className="dashboard-table">
                            <tr>
                                <th>
                                    <HeaderOrder 
                                        label="Track Name"
                                        value="name"
                                        onChange={onSortingClick}
                                        active={ (nav.sort_col === "name") ? nav.sort_direction : null}
                                    />
                                </th>                                
                                <th>
                                    <HeaderOrder 
                                        label="CLEARANCE"
                                        value="status"
                                        onChange={onSortingClick}
                                        active={ (nav.sort_col === "status") ? nav.sort_direction : null}
                                    />
                                </th>
                                <th>
                                    PAYMENT
                                </th>
                                <th>
                                   view
                                </th>
                            </tr>
                        </thead>
                        <tbody> 
                            {
                               items.map((item) => {
                                    return <DashboardSyncCard
                                        key={ item.id }
                                        id={ item.id }
                                        img={ syncImg }
                                        item={ item }                                        
                                    />
                                })
                            }                           
                        </tbody>
                    </table>                    
                </div>
                {  pageInfo &&
                    <Pagination  meta={pageInfo} key={ pageInfo.key } onPageChange={ onPageChange } />
                }
            </div>        
        )
    }
}

export default WithSortingAndPagination(DashboardSyncPage);