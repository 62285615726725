import React, { Component } from 'react';
import api from '@/middlewares/api';
import PropTypes from 'prop-types';

const allowedTypes = ["image/png", "image/jpeg", "image/gif"];
const allowedTypesString = allowedTypes.join(', ')

export default class ProfileAvatar extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.state = {
            isDisabledRemove: false,
            isLoading: false           
        }
    }
    componentDidMount() {
        this.updateAvatar();
       

    }
    updateAvatar() { 
        const flag =  (this.props.avatarUrl) ? false: true;
        this.setState({
            isDisabledRemove: flag,
        })
       
    }
    componentDidUpdate(prevProps) {
        /* eslint-disable eqeqeq */
        if(prevProps.avatarUrl != this.props.avatarUrl) {
            this.updateAvatar();
        }
        /* eslint-enable eqeqeq */
    }
    isValidFile(file) {
        if(allowedTypes.indexOf(file.type) === -1) {            
            return false;
        }
        if(file.size > 3 * 1024 * 1024){
            return false;
        }
        return true;
    }
    onChange(ev) {
        if(ev.target.files && (ev.target.files.length > 0))  {           
            //console.log(ev.target.files[0]);
            if(! this.isValidFile(ev.target.files[0]) ) return;
            this.setState({
                isLoading: true,
            });
            let data = new FormData();
            data.append('avatar', ev.target.files[0]);
            api.post('profile/avatar/update', data)
            .then((response) => {
                console.log('res', response.data); 
                const profile = response.data.data;
                this.props.updateUserData(profile, 'avatar');
                this.setState({
                    isLoading: false,
                });
                
            })
            .catch((err) => {
                this.setState({
                    isLoading: false,
                }); 
                if(! err.response) return;
                if(err.response.status === 422) {
                   console.log(err.response);                       
                }        
            });
        } 
    }
    onRemove() {
        api.delete('profile/avatar/delete')
        .then((response) => {            
            const profile = response.data.data;  
            this.props.updateUserData(profile, 'avatar');            
            
        })
        .catch((err) => { 
            console.log(err.response); 
        });
        
    }
    render() { 
        const { isDisabledRemove,  isLoading}  = this.state;
        const { userAvatar } = this.props;       
        const avatarStyle = { backgroundImage: "url(" +  userAvatar  + ")"};     
        return (
            <div className="avatar-form">
                <div className="avatar-form__img-wrap">
                    <div className="avatar-form__img" style={avatarStyle}>
                    </div>
                </div>
                <div className="avatar-form__info-wrap">
                    <div className="avatar-form__actions">
                        <label className="avatar-form__act avatar-form__act_upload" disabled={ isLoading }>
                            <input type="file" 
                            accept={ allowedTypesString }
                            onChange={this.onChange} 
                            />
                            <span role="button" className="btn btn-primary">Upload new Photo</span>
                        </label>
                        <div className="avatar-form__act">
                            <button type="button" className="btn btn-second" 
                                disabled={ isDisabledRemove }
                                onClick={this.onRemove}>
                                    Remove
                            </button>
                        </div>
                    </div>
                    <p className="avatar-form__info">
                        You can upload Jpg, Gif or Png image files
                            Max size of 3mb.
                    </p>
                </div>
            </div>
        )
    }
}

ProfileAvatar.propTypes = { 
    avatarUrl: PropTypes.string, 
    userAvatar: PropTypes.string,  
    updateUserData: PropTypes.func.isRequired,

}  
