import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import {ROUTESLIST} from '@/static/urls.js';
import api from '@/middlewares/api';

// import Pagination from '@/components/pagination/index.js';
// import HeaderOrder from '@/components/tables/header-order.js';
// import WithSortingAndPagination from '@/components/hoc/with-sorting.js';
import DashboardCrateCard from './crate-card.js';
import './index.scss';

//import { samples } from './data.js';

class DashboardCratesPage extends Component {
    constructor(props) {
        super(props);  
        this.state = {
            items: []
        }               
    } 

    componentDidMount() {
        this.fetchCrates();
    }

    fetchCrates() {
        api.get('user/dashboard/crates')
        .then((response) => {  
            if(response.data && Array.isArray(response.data)) {
                this.setState({
                    items: response.data
                });
            } else {
                this.setState({
                    items: []
                });
            } 
        })
        .catch((err) => {
            console.log(err.response);        
        });
    }
    
    render() {  
       // const { nav, onSortingClick } = this.props;  
        const { items } = this.state;  
        return (
            <div> 
                <h3 className="dashboard-header dashboard-header_small dashboard-header_row">
                    <span>My Crates</span>
                    <Link className="btn btn-primary"to={ ROUTESLIST.ADD_CRATE }>Add Crate</Link>
                </h3> 
                <div className="dashboard-table__wrap">
                <table className="dashboard-table dashboard-table_imgs">
                        <thead className="dashboard-table">
                            <tr>
                                <th>
                                    New Song
                                </th>                                
                                <th> 
                                    <div className="db-multi">
                                        <div className="db-multi__flow">
                                            Sampled Songs
                                        </div>
                                        <div className="db-multi__fixed">
                                            Status
                                        </div>
                                    </div>
                                </th>                                
                            </tr>
                        </thead>
                        <tbody>
                              {
                                  items.map((item) => 
                                    <DashboardCrateCard key={item.id}
                                        id={item.id}
                                        item={ item }
                                    />
                                )
                              }             
                        </tbody>
                    </table>           
                </div>                
            </div>        
        )
    }
}
export default DashboardCratesPage;
//export default WithSortingAndPagination(DashboardCratesPage);