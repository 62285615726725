import React, { Component } from 'react';
import { stopEnterSubmit } from '@/utils/forms.js';
import FormFieldBottom from './field-bottom';

export default class PasswordField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentType: 'password'
        }
        this.handleChangeType = this.handleChangeType.bind(this);        
    }

    handleChangeType() {
        this.setState((prevState) => {
            return {
                currentType: (prevState.currentType === "password") ? "text" : "password"
            }
        })
    }

    render() {
        const { label, input, placeholder, required, meta, note, correct} = this.props;
        const { currentType } = this.state;
        const classes = (meta.error) ? "form-field form-field_password error" : "form-field form-field_password";        
        const btnIcon = (currentType === "password") ? "as-icon as-icon_pass-open" : "as-icon as-icon_pass-close";

        return (
            <div className={ classes }>
                <label className={ (required) ? "label label_required" : "label"}>{ label }</label>
                <div className="form-field__input-wrap">
                    <input {...input} 
                        placeholder={ placeholder }
                        type={ currentType } 
                        onKeyDown={ stopEnterSubmit }
                        className="input" />
                    <button type="button" className="btn-icon" onClick={ this.handleChangeType }>
                        <i className={ btnIcon }></i>
                    </button>
                </div>                
                <FormFieldBottom 
                    meta={ meta }
                    note={ note }
                    correct={ correct }
                />    
            </div>
        )
    }
}
