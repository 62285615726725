import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { ROUTESLIST } from '@/static/urls';

import './card.scss';

export default class ShopCard extends Component {
    render() {
        const {id, image, title, author, tags, format, bpm, size } = this.props; 
        const children = this.props.children;
        const url =  ROUTESLIST.SHOP_ITEM;
        const classes = (this.props.addedClass) ? 'shop-card ' + this.props.addedClass : 'shop-card';
        return (
            <div className={classes}>
                <div className="shop-card__content">
                    <div className="shop-card__col">
                        <div className="shop-card__img" tabIndex="0" aria-haspopup="true">
                            <img src={image} alt={ title } />
                        </div> 
                    </div>                                       
                    <div className="shop-card__col shop-card__col_info">
                        <div className="shop-card__author">
                            { author }
                        </div>                    
                        <Link to={ url } className="shop-card__title">
                            { title }
                        </Link>
                        <div className="shop-card__tags">
                            { tags }
                        </div>
                        <ul className="shop-card__info">
                            <li><i className="as-icon as-icon_file_format"></i><span>{ format }</span></li>
                            <li><i className="as-icon as-icon_brend-bpm"></i><span>{ bpm }</span></li>
                            <li><i className="as-icon as-icon_download"></i><span>{ size }</span></li>
                        </ul>  
                        {children}
                    </div>
                </div>
            </div>
        )
    }
}
