import React from 'react';

import './modal-card.scss';

export default function CrateFormModalCart(props) {
    //TODO 
    if(! props.item)  return null;
    const { image, title, artist, genres, written_by, executive_producer} = props.item;
    const imgStyle = { backgroundImage: `url('${image}')`}
    return (
        <div className="modal-card">
            <div className="modal-card__col modal-card__col_img">
                <div className="modal-card__img" style={ imgStyle }>                    
                </div>
            </div>
            <div className="modal-card__col modal-card__col_info">
                <div className="modal-card__basic">{ artist }</div>
                <h6 className="modal-card__title">{ title }</h6>
                <div className="modal-card__basic">{ genres }</div>                
                <ul className="modal-card__features">
                    <li className="modal-card__feature">
                        <strong>Writen By</strong>
                        <span>{ written_by }</span>
                    </li>
                    <li>
                        <strong>Executive Producer</strong>
                        <span>{ executive_producer }</span>
                    </li>
                </ul>
               
            </div>
        </div>
    )
}
