import React, { Component } from 'react';
import { Select, MenuItem }   from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import FormFieldBottom from './field-bottom';

const itemClasses = {
    select : {
        root: 'field-select',        
        //selectMenu: 'filter-select__opts',
    },   
    menu: {
        classes: {
            paper: 'field-select__opts-wrap',
            list: 'field-select__opts',
        }
    }
    
}

export default class SelectField extends Component {
    render() {
        const { label, input, placeholder, required, meta, options, note, correct } = this.props
        //console.log(this.props.input)
        const classes = (meta.error) ? "form-field form-select error" : "form-field form-select";        
        
        return (
            <div className={ classes }>
                <label className={ (required) ? "label label_required" : "label"}>{ label }</label>                             
                <Select classes={itemClasses.select}                                                        
                    {...input}
                    variant="outlined"                    
                    MenuProps={itemClasses.menu}
                    IconComponent={KeyboardArrowDownIcon}                  
                    >
                    <MenuItem value="">
                        { placeholder }
                    </MenuItem>
                    {
                        options.map((item) => 
                            <MenuItem 
                            key={item.id}
                            value={item.id}>{item.name}</MenuItem>
                        )
                    }                    
                </Select>
                <FormFieldBottom 
                    meta={meta}
                    note={note}
                    correct={correct}
                />    
            </div>
        )
    }

}