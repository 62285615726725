import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';

import { ROUTESLIST } from '@/static/urls';

import './card.scss';

export default class CrateCard extends Component {
    constructor(props) {
        super(props);  
        this.state = {
            videoVisible: false
        }  
        this.openVideo = this.openVideo.bind(this);
        this.endVideoHandler = this.endVideoHandler.bind(this);
        this.readyHandler = this.readyHandler.bind(this);
        this.player = null;           
    } 

    openVideo() {
        this.setState({
            videoVisible: true
        });
        if(this.player) this.player.playVideo();
    }

    endVideoHandler() {
        this.setState({
            videoVisible: false
        })
    }
    readyHandler(event) {
        //console.log(event.target);
        this.player = event.target;
        
    }

    render() {
        const {id, image_url, song_title, artistName, video } = this.props.item;         
        const url =  ROUTESLIST.CRATES_CRATE + id;
        const styles = {  backgroundImage: "url(" +  image_url  + ")" }
        const { videoVisible } = this.state;
        const mediaClasses = (videoVisible) ? 'crate-card__media crate-card__media--open' 
                : 'crate-card__media crate-card__media--close';
        return (
            <div className="crate-card">
                <div className="crate-card__content">
                    <div className={ mediaClasses }>
                        {
                            video && 
                            <YouTube                            
                                videoId={video}
                                containerClassName="card-video" 
                                onEnd={ this.endVideoHandler } 
                                onReady={ this.readyHandler } 
                                opts={{
                                    width: '',
                                    height: '',
                                    frameBorder: "0"
                                }}                             
                            />
                        }
                        <div className="crate-card__img has-hover-btns" 
                            style={ styles} tabIndex="0" aria-haspopup="true">                            
                            <div className="hover-btns">
                                <div className="hover-btns__inner">                               
                                    <button className="hover-btn card__play" type="button" 
                                        onClick={ this.openVideo } aria-label="play">
                                        <i className="as-icon as-icon_video-play"></i>
                                    </button>                                                            
                                </div>
                            </div>
                        </div>   
                    </div>
                                     
                    <div className="crate-card__info">                    
                        <Link to={ url } className="sample-card__title">
                            { song_title }
                        </Link>
                        <div className="crate-card__author">
                            by {artistName }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

/** not use now
<button className="hover-btn sample-card__mark" type="button" aria-label="favourite">
    <i className="as-icon as-icon_like"></i>
</button>
 <button className="hover-btn sample-card__mark" type="button" aria-label="share">
    <i className="as-icon as-icon_share"></i>
</button>
*/
