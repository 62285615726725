import React, { Component } from 'react';
import { Field, reduxForm, change, stopSubmit } from 'redux-form';
import { connect } from 'react-redux';
import { useStripe, useElements , Elements, CardNumberElement, CardCvcElement,  CardExpiryElement} from '@stripe/react-stripe-js';

import { fetchStripeKey, getStripePromise } from '@/actions/payment';
import api from '@/middlewares/api';

import FormField from '@/components/forms/form-field';
import {options, fields} from './stripe-help';
import brandImg from './pay_brand.svg'

class AddPaymentMethodForm extends Component {
    constructor(props) {
        super(props);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.checkCode = this.checkCode.bind(this);
        this.closeHandler = this.closeHandler.bind(this);
        this.state = {
            isProcess: false,
        }
        this.codeData = null;
    }

    closeHandler() {
        this.setState({isProcess: false});
        this.props.onClose();
    }
    validateFields(data) {
        let flag = true;
        let errors = {};
        fields.forEach((item) => {
            if(typeof data[item.key] === "undefined") {
                flag = false;
                errors[item.key] = item.error;
            }
        });
        if (! flag) {
            const { dispatch, form } = this.props;            
           dispatch(stopSubmit(form, errors)); 
        }
        return flag;
    }

    extractData(data) {
        const { userData } = this.props;
        
        let result = {
            "address": {
                "city": data.city,
                "country": (this.codeData && this.codeData.country_code) ? this.codeData.country_code : data.country,
                "line1": data.address,                
                "postal_code": data.postal_code,
                "state": data.state
            },
            name: data.name
        };
        if( userData.email ) result.email = userData.email;
        if( userData.phone ) result.phone = userData.phone;
        return result;
    }

    checkCode(event, newValue) { 
        if(newValue && (newValue.length === 5)) {
            api.get('user/payment/billing-address/search', {params: { code: newValue}})
            .then((response) => {               
                console.log(response.data);
                if( Array.isArray(response.data) && (response.data.length > 0)) {
                    const data = response.data[0];
                    this.codeData = data;
                    const { dispatch, form } = this.props;
                    dispatch(change(form, 'state', data.state));  
                    dispatch(change(form, 'city', data.locality)); 
                    dispatch(change(form, 'country', data.country));                     
                }
            })
            .catch((err) => {
               console.log(err.response);        
            });
        }
    }   
    handleFormSubmit(data) { 
        this.setState({isProcess: true });       
        if(! this.validateFields(data)) {
            this.setState({isProcess: false });
            return;
        }
        const {stripe, elements} = this.props;
        const billing = this.extractData(data);
        stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement),
            billing_details: billing
        }).then((stripeRes) => {
            console.log(stripeRes);
            if(stripeRes.paymentMethod) {
                api.post('user/add-payment-method', 
                    {payment_method: stripeRes.paymentMethod.id}
                )
                .then(() => {  
                    this.setState({isProcess: false });             
                    this.props.onClose(true); 
                })
                .catch((err) => {
                    this.setState({isProcess: false });
                    console.log(err.response);
                    const { dispatch, form } = this.props;
                    dispatch(stopSubmit(form, { '_error' : err.response.data.message}));         
                });
                //TODO
                //stripeRes.paymentMethod.id 
            } else if(stripeRes.error) {
                this.setState({isProcess: false });
                const { dispatch, form } = this.props;            
                dispatch(stopSubmit(form, { '_error' : stripeRes.error.message})); 
            }
        })   
    }

    render() {
        const { handleSubmit, submitting,  error } = this.props; 
        const { isProcess }  = this.state;   
        return (
            
            <form onSubmit={handleSubmit((data) => { 
                return this.handleFormSubmit(data);
            }) } className="modal-form"
            noValidate>
                <h3 className="head-middle">New Payment Method</h3>
            <div className="lined-blc">
                <span className="lined-blc__txt">Add billing address</span>
            </div>    
            <div className="modal-form__blc"> 
                <Field                            
                    name="country"
                    component={FormField}                                        
                    type="text"
                    required={true}
                    placeholder=""
                    label="Country"                            
                /> 
                 <div className="form-row">
                    <div className="form-col_2">
                        <Field                            
                            name="postal_code"
                            component={FormField}                                        
                            type="text"
                            required={true}
                            placeholder=""
                            label="Zip"
                            onChange={this.checkCode}                            
                        />          
                    </div>
                    <div className="form-col_2">
                        <Field                            
                            name="state"
                            component={FormField}                                        
                            type="text"
                            required={true}
                            placeholder=""
                            label="State"                            
                        />                                    
                    </div>                    
                </div>
                <Field                            
                    name="address"
                    component={FormField}                                        
                    type="text"
                    required={true}
                    placeholder="Your Address"
                    label="Address"                            
                /> 
                <Field                            
                    name="city"
                    component={FormField}                                        
                    type="text"
                    required={true}
                    placeholder="Enter City"
                    label="City"                            
                />
               
            </div> 

            <div className="lined-blc">
                <span className="lined-blc__txt">Add Payment Method</span>
            </div> 
            <div className="modal-form__blc">
                <Field                            
                    name="name"
                    component={FormField}
                    required={true}                                        
                    type="text"
                    placeholder="Name on Card"
                    label="Card Holder Name"                            
                /> 
                <div className="form-field">
                    <label className="label label_required">Credit Card Number</label>                
                    <CardNumberElement
                        options={ options}
                        />
                       
                    <div className="form-field__note empty"></div>    
                </div>
                
                <div className="form-row">
                    <div className="form-col_2">
                        <div className="form-field">
                            <label className="label label_required">Expiration Date</label> 
                            <CardExpiryElement 
                                options={ options}
                            />
                            <div className="form-field__note empty"></div>
                        </div>
                    </div>
                    <div className="form-col_2">
                        <div className="pay-iconed">
                            <div className="pay-iconed__col">
                                <div className="form-field">
                                    <label className="label label_required">CVC</label> 
                                    <CardCvcElement 
                                        options={ options}
                                    />
                                    <div className="form-field__note empty"></div>
                                </div>
                            </div> 
                            <div className="pay-iconed__col-icon">
                                <span className="as-icon as-icon_card-cvv">
                                </span>
                            </div>   
                        </div>
                    </div>
                </div>
            </div>
            {
                error &&
                <div className="form-field">                
                    <div className="form-field__note error">{ error }</div>
                </div>
            }
            <div className="pay-brand">
                <div className="pay-brand__icon">
                    <img src={ brandImg } alt=""/>
                </div>
                <div className="pay-brand__info">
                    <p className="pay-brand__info-top">All payments are</p>
                    <p className="pay-brand__info-bottom">powered by <strong>Stripe</strong></p>
                </div>
            </div>
            <div className="submit-row">
                <button className="btn btn-primary" disabled={ submitting || isProcess }
                        type="submit">Save</button>
                <button className="btn btn-text" onClick={ this.closeHandler }
                        type="button">Cancel</button>          
                
            </div>
        </form>
       
        )
    }
}
const AddPaymentMethodFormWrapped =  reduxForm({
    form: 'dashboard-payment-method-add-form' 
  })(AddPaymentMethodForm);

//export default AddPaymentMethodFormWrapped;

const CheckoutForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();
 const { onClose, userData } = props;
    return (
        <AddPaymentMethodFormWrapped onClose={ onClose} stripe={ stripe } 
            elements={ elements} userData={ userData }
                />
      );

}

class StripeWrapForm extends Component {    

    componentDidMount() {
        if(! this.props.isStripeLoaded) {
            this.props.fetchStripe();
        }
    }
    render() {
        const {stripePkKey, onClose, userData} = this.props;
        if(! stripePkKey) return null;
        const stripe = getStripePromise(stripePkKey);
        
        return (
            <Elements stripe={ stripe }>
                <CheckoutForm onClose={ onClose} userData={ userData }
                />
            </Elements>
        );
        
    }
}

function mapStateToProps(state) { 
    let  userData;  
    if( state.auth.user) {
        const { fullName, personal_email, personal_phone, 
             } = state.auth.user.profile;              
        userData = {
            name: fullName,
            email: personal_email,            
            phone: personal_phone  
        }
    }  else {
        userData = null;
    }    
    return {      
        stripePkKey: state.payment.stripePkKey,
        isStripeLoaded: state.payment.stripeIsLoaded, 
        userData: userData         
    };
}
function mapDispatchToProps(dispatch) {
    return {
        fetchStripe: () => {
            return dispatch( fetchStripeKey() );
        },     
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StripeWrapForm);
