import React, { Component } from 'react';
import { Field, reduxForm, getFormValues  } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { ROUTESLIST } from '@/static/urls';
import api, { setErrors } from '@/middlewares/api';
import FormField from '@/components/forms/form-field';
import WordsLimitedField from '@/components/forms/words-limited-field';

import bgImg from './crate-step3.jpg';

const maxFieldsets = 3;

class AddCrateStep3 extends Component {

    constructor(props) {
        super(props);
        this.stepSubmit = this.stepSubmit.bind(this);
        this.addFieldset = this.addFieldset.bind(this);
        this.goBack = this.goBack.bind(this);
        this.state = {
            nextNumber: 1,
            fieldsets: [0]
        }
    }

    goBack() {
        const {prevStep, rawValues} = this.props;
        prevStep(rawValues);
    }

    addFieldset() {
        if(this.state.nextNumber >= maxFieldsets) return;
        this.setState((prevState) => {
            const number = prevState.nextNumber;
            const fieldsets = [...prevState.fieldsets, number];
            return {
                nextNumber: number + 1,
                fieldsets: fieldsets
            }
        });
    }


    stepSubmit(data) { console.log(data);  
        return new Promise((resolve, reject) => {
            api.post('crate/step/three', data)
            .then((response) => {               
                this.props.nextStep(data);
                return resolve(null);
            })
            .catch((err) => {
                if(! err.response) return;
                if(err.response.status === 422) {
                    return reject(setErrors(err.response, null, true));                            
                }            
            });
        });        
    }
    // mentions [
    //     [
    //         content => ""
    //         url => ""
    // ]
    // ]
    
    componentDidMount() {
        const { mentions } = this.props.initialValues;
        console.log('mount', this.props.initialValues);
        if( mentions && (mentions.length)) {
            let fields = [];
            for(let i = 0; i < mentions.length; i++) {
                fields.push(i);
            }
            this.setState({
                nextNumber: mentions.length,
                fieldsets: fields
            })
        }
    }
    
    render() {
        const secStyle = { backgroundImage: "url(" +  bgImg  + ")"};
        const { handleSubmit,  submitting } = this.props; 
        const {fieldsets, nextNumber }= this.state; 
        return (
            <section className="entity-sec banner-filter banner-filter_fw" style={ secStyle }>
                <div className="content">
                    <div className="entity-sec__top">  
                        <h2 className="head-shadow">ADD A NEW SONG</h2>
                        <h3 className="head">
                            Step 3 of 3: Facts
                        </h3>
                        <div className="form-step__top-desc">                            
                        </div>
                    </div>
                    <div className="form-step__body">
                        <form onSubmit={handleSubmit((data) => { 
                                return this.stepSubmit(data);
                            }) } className="form-layout"
                            noValidate>
                            {
                                fieldsets.map((item) => {
                                    return (
                                        <div className="form-step__fieldset" key={item}> 
                                            <Field                            
                                                name={ 'mentions[' +  item + '][content]'}
                                                component={WordsLimitedField} 
                                                maxWords={ 250 }
                                                label="Provide here facts & citations you found"                            
                                            />   
                                            <Field                            
                                                name={ 'mentions[' +  item + '][url]'}
                                                component={FormField}                                        
                                                type="text"
                                                placeholder="Placeholder"
                                                label="Link to the source of information (media or social networks)"                            
                                            />
                                        </div>
                                    );
                                } )
                            } 
                            { (nextNumber < maxFieldsets) &&
                                <div className="add-fieldset__blc"> 
                                    <button className="btn btn__add-fieldset" onClick={ this.addFieldset }
                                        type="button">
                                            <i className="as-icon as-icon_add-rounded"></i>
                                            <span>Add an Additional Fact</span>
                                    </button> 
                                </div>
                            } 
                            <div className="submit-row_txt">
                                <p className="as-text">
                                    By clicking Confirm button you are agreeing to the 
                                    <Link to={ ROUTESLIST.STATIC_TERMS } className="link-thin"
                                        target="_blank" rel="noopener noreferrer"
                                        >Terms and Conditions.
                                    </Link>
                                </p>                                
                            </div>
                            <div className="submit-row submit-row_txt">  
                                    <button className="btn btn-second" onClick={ this.goBack }
                                        type="button">Back</button>                                  
                                    <button className="btn btn-primary" disabled={ submitting }
                                        type="submit">Next</button>
                            </div>
                        </form>                        
                    </div>
                </div>
            </section>            
        )
    }
}

const AddCrateStep3Form =  reduxForm({
    form: 'add-crate-step3',
    enableReinitialize: true,   
  })(AddCrateStep3);

//export default AddCrateStep3Form;
const mapState = (state) => {
    return {
        rawValues: getFormValues('add-crate-step3')(state)
    }
}
export default connect(mapState)(AddCrateStep3Form);
