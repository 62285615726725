import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';

import history from '@/store/history';
import api, { getUserDevice, setErrors } from '@/middlewares/api';
import { ROUTESLIST } from '@/static/urls';

import FormField from '@/components/forms/form-field';
import PasswordField from '@/components/forms/password-field';
// import SelectField from '@/components/forms/select-field';
import WithAuthRedirect from '@/components/hoc/with-auth-redirect';
import AffialateService from '@/services/affiliate.js';

import BgImage from '@/assets/images/pages/signup_bg.png';

const bgStyles = {
	backgroundImage: `url(${BgImage})`,    
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	backgroundSize: 'cover'
}

class SignupPage extends Component {

    constructor(props) {
        super(props);        
        this.submitForm = this.submitForm.bind(this);
        this.state = {
            affilateId: null,
            options: [],
        }
    }

    fetchAffiliateOptions() {
        api.get('approved-affiliate-list')
        .then((response) => {           
           const data = response.data;
           if (Array.isArray(data)) {
                const list = data.map((el) => {
                    return {
                        id: el.id,
                        name: el.username
                    }
                });
                this.setState({
                    options: list
                });
           }            
        })
        .catch((err) => {
            console.log(err);         
        });
    }

    submitForm(data) {  
        data['device_name'] = getUserDevice();
        const affilateIp = AffialateService.getId('ip');
        if (affilateIp)  {
            data['ip_address'] = affilateIp;            
        }        
        if (this.state.affilateId)  {
            data['affiliate_id'] = this.state.affilateId;            
        } else if (! data['affiliate_id']) {
            delete data['affiliate_id'];
        }         
        return new Promise((resolve, reject) => {
            api.post('register', data)
            .then((response) => {
                AffialateService.removeId('ip');
                AffialateService.removeId('id');
                //console.log(response.data);                 
                history.replace(ROUTESLIST.AFTER_REGISTER);
                return resolve(null);                 
            })
            .catch((err) => {
                if(! err.response) return;
                if(err.response.status === 422) {
                    return reject(setErrors(err.response));                            
                }            
            })
        });        
    } 
    
    componentDidMount() {
        const id = AffialateService.getId('id');
        if (id) {
            this.setState({affilateId: id});            
        } /*else {
            this.fetchAffiliateOptions();
        } */
    }
    
    render() {
        const { pristine, submitting, handleSubmit } = this.props;  
        //const { affilateId, options} = this.state; 
        //const isShowSelect = false; //((! affilateId) && (options.length > 0)); 
        return (
            <main className="page">
                <section className="form-section form-section--radial" style={bgStyles}>
                    <div className="content">
                        <h3 className="head-middle">Create an Account</h3>
                        <div className="review-video">
                            <div className="review-video__inner">
                                <iframe src="https://www.youtube.com/embed/2EQXfl_EsaU?autoplay=1"
                                    title="YouTube video player" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                    frameBorder="0" 
                                    width="100%" 
                                    height="100%" 
                                    allowFullScreen >
                                </iframe>
                            </div>
                        </div> 
                    </div> 
                    <div className="content-tight"> 
                        <form onSubmit={  handleSubmit((data) => { 
                                return this.submitForm(data);
                        })  } 
                            className="signup-form" noValidate>                           
                                   
                                    <Field                            
                                        name="email"
                                        component={ FormField }
                                        type="email"
                                        placeholder="Type Your Email"
                                        label="Email"                         
                                    />
                                
                                    <Field                            
                                        name="password"
                                        component={ PasswordField }
                                        type="password"                                        
                                        label="Password" 
                                        placeholder="Type Your Password"                           
                                    />
                                    <Field                            
                                        name="password_confirmation"
                                        component={ PasswordField }                                                                
                                        label="Confirm Password" 
                                        placeholder="Confirm Your Password"                           
                                    /> 
                                    { /* isShowSelect &&
                                        <Field                            
                                            name="affiliate_id"
                                            component={SelectField}                                            
                                            options={options}
                                            placeholder="Select affiliate username"
                                            label="Affiliate Username"                                                                       
                                        />  */ 
                                    }                                                                    
                            <div className="form-field center">
                                Creating an account means you’re ok <br/> 
                                with the <Link to={ ROUTESLIST.STATIC_TERMS } className="link-thin">Terms and Conditions.</Link>
                            </div>
                            <div className="form-submit-row">
                                <button 
                                    className="btn btn-primary btn-fw" 
                                    disabled={ pristine || submitting }
                                    type="submit"
                                >
                                    Create Account Now
                                </button> 
                            </div>                                                       
                        </form>
                        <div className="form-field center">                        
                            Already have an account?  <Link to="/login" className="link">Log In.</Link> 
                        </div>
                    </div>
                </section>        
            </main>
        )
    }
}

const signupFormPage =  reduxForm({
    form: 'signup-form' 
  })(SignupPage);

export default WithAuthRedirect(signupFormPage);
