import React, { Component } from 'react';
import MomentUtils from '@date-io/moment';
//import { InputAdornment } from '@material-ui/core';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
//import moment from 'moment';
//https://material-ui.com/ru/components/material-icons/

import FormFieldBottom from './field-bottom';

export default class DurationPickerField extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);        
    }

    handleChange(date, value) {       
        if(date && date._isValid) {
            this.props.input.onChange(value);
        }
        
    }
    render() {
        const { label, input, required, meta,  note, correct, placeholder } = this.props
        //console.log(this.props.input)
        //const value = (input.value) ?  moment(input.value, "mm:ss") : null;
        const value = (input.value) ?  input.value : "00:00";  
        const classes = (meta.error) ? "form-field form-duration error" : "form-field form-duration";        
        // value={''}
        //             onChange={handleDateChange}
        return (
            <div className={ classes }>
                <label className={ (required) ? "label label_required" : "label"}>{ label }</label>  
                <MuiPickersUtilsProvider utils={MomentUtils}>                          
                    <KeyboardTimePicker
                        ampm={false}
                        openTo="minutes"
                        views={["minutes", "seconds"]}
                        format="mm:ss"
                        inputVariant="outlined" 
                        inputValue={ value }       
                        placeholder={ placeholder }                                            
                        onChange={ this.handleChange }  
                        keyboardIcon={ <AccessTimeIcon /> }
                        invalidDateMessage=""
                        InputProps={{
                            //endAdornment: <InputAdornment position="end"><AccessTimeIcon/></InputAdornment>,
                            className: 'duration-input'
                        }}                        
                        DialogProps={{
                            className: 'duration-dialog'
                        }}
                    />
                </MuiPickersUtilsProvider> 
                <FormFieldBottom 
                    meta={meta}
                    note={note}
                    correct={correct}
                />    
            </div>
        )
    }

}