export const StatusesClassesMap = {
    "New Project": "success",
    "Request Sent": "pending",
    "Quote Received": "pending",
    "Quote Approved": "success",
    "Denied": "fail",
    "Confirmation Letter Sent": "pending",
    "Not issued": "pending",
    "1st Half Independent (Not Paid)": "pending",
    "1st Half Independent (Paid)": "success",
    "Back Half Independent - Unpaid": "pending",
    "Back Half Independent - Paid": "success",
    "1st Half Corporate (Not Paid)": "pending",
    "1st Half Corporate (Paid)": "success",
    "Back Half Corporate - Unpaid": "pending",
    "Back Half Corporate - Paid": "success",
    "Back Half": "pending",
    "First Half": "pending",
    "Pending": "pending",
    "Failed": "fail",
    "Sent": "pending",
    "Paid": "success",
    "default": ""
};