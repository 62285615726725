import React, { Component } from 'react';

export default class DashboardMechanicalPage extends Component {
    render() {       
        return (
            <div>
                DashboardMechanicalPage
            </div>
        )
    }
}