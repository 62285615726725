import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import {ROUTESLIST} from '@/static/urls.js';

export default class DashboardSyncCard extends Component {
    render() {
        const {id, img, item } = this.props; 
        const { 
            track_title,           
            clearance, 
            clearance_status, 
            order_status,
            clearance_status_class,
            order_status_class} 
            = item; 
        
        return ( 
            <tr className="db-sample-card dashboard-table__card">
                <td className="db-sample-card__info">
                    <div className="db-sample-card__row">
                        <div className="db-sample-card__col">
                            <img src={ img} alt={track_title} />
                        </div>
                        <div className="db-sample-card__col">
                            <span className="title">{ track_title }</span> 
                            <span className="info">Clearance: {clearance}</span>                          
                        </div>
                    </div>
                </td>
                <td className="db-sample-card__status">
                    <span className={'status status_' + clearance_status_class }>{ clearance_status }</span>
                </td>
                <td className="db-sample-card__status">
                    <span className={'status status_' + order_status_class }>{ order_status }</span>
                </td>
                <td className="db-sample-card__actions">                   
                    <Link to={ROUTESLIST.DASHBOARD_ONE_SYNC + id} className="btn btn-act-iconed">
                        <i className="as-icon as-icon_nav-right"></i>
                    </Link>           
                </td>
            </tr>
        )
    }
}