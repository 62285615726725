import { StatusesClassesMap } from '@/static/statuses.js';
import { extractDataWithMap, formatDate } from './common';
//import { formatMoneyFromString } from './forms';
import { formatMoney} from './common';

export const extractDasboardCard = (item) => { 
    const {id, publishing_master_other, clearance_status, order_status, track_title} = item;
    const clearance_status_class = (StatusesClassesMap[clearance_status]) ? 
        StatusesClassesMap[clearance_status] : "";
    const order_status_class = (StatusesClassesMap[order_status]) ? 
        StatusesClassesMap[order_status] : "";   
    let clearance;
    switch (publishing_master_other) {
        case 'master': {
            clearance = 'Master Use';
            break;
        }
        case 'publishing': {
            clearance = 'Publishing Use';
            break;
        }
        default: {
            clearance = 'Other';
        }       
    }     
    return {
        id,
        track_title,        
        clearance, 
        clearance_status, 
        order_status,
        clearance_status_class,
        order_status_class,        
    }
};

const syncMap = [
    {server: 'type_use', label: 'Type of Use', type: 'string'},
    {server: 'clearance_status', label: 'CLEARANCE Status', type: 'status'},
    {server: 'order_status', label: 'Order Status', type: 'status'},
    {server: 'track_title', label: 'Track Title', type: 'string'},
    {server: 'artists', label: 'Artist', type: 'arr_names', key: 'name'},
    {server: 'category', label: 'Category of Use', type: 'obj_name', key: 'name'},
    {server: 'media', label: 'Media', type: 'obj_name', key: 'name'},
    {server: 'featured_background', label: 'Featured/Background', type: 'obj_name', key: 'name'},
    {server: 'term', label: 'Term', type: 'obj_name', key: 'name'},
    {server: 'territory', label: 'Territory', type: 'obj_name', key: 'name'},
    {server: 'option', label: 'Options', type: 'obj_name', key: 'name'},
    {server: 'labels', label: 'Record Labels', type: 'arr_names', key: 'name'},
    //artists
    //{server: 'distribution', label: '', type: 'obj_name', key: 'name'},
    {server: 'project_title', label: 'Name of the Project', type: 'string'},
    {server: 'project_budget', label: 'Project Budget', type: 'string'},
    {server: 'project_synopsis', label: 'Project Synopsis', type: 'raw'},
    {server: 'description_of_use', label: 'Describe the Scene', type: 'raw'},
    {server: 'length_use', label: 'Length of Master Recording / Composition Use', type: 'string'},
    {server: 'release_air_date', label: 'Release/Air Date', type: 'string'},
    {server: 'proposed_free', label: 'Proposed Fee', type: 'string'},    
    
    
];
const getUse = (data) => {
   switch(data.publishing_master_other) {
     case "publishing": {
      return "Publishing use";
     }
     case "master": {
      return "Master use";
     }
     default: {
      return "Other";
     }
   }
} 
export const arrangeOneInfo = (data) => {
    const {id, track_title} = data;  //console.log('sync', data);
    data.project_budget = '$ ' + formatMoney(data.project_budget, false);
    data.proposed_free = '$ ' + formatMoney(data.proposed_free, false);
    data.type_use = getUse(data);
    data.release_air_date = formatDate(data.release_air_date);
    return {
        id: id,
        title: track_title,
        //features: []
        features: extractDataWithMap(data, syncMap)
    } 
}
