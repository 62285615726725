import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class EmailVerifyPage extends Component {
    render() {
        return (
            <main className="page">
                <div className="content" style={{ padding: '80px 0' }}>
                    <h2 className="head-shadow">Ample Samples</h2>
                    <h3 className="head">Your email has been successfully verified. <br/> Please <Link to="/login" className="link">Log In.</Link></h3>
                    <div className="form-field center">

                    </div>
                </div>
            </main>
        )
    }
}
