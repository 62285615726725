import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

import FormField from '@/components/forms/form-field';
import api, { setErrors} from '@/middlewares/api';

class ProfileCompanyForm extends Component {
    constructor(props) {
        super(props);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    handleFormSubmit(data) {        
        return new Promise((resolve, reject) => {
            api.post('profile/company', data)
            .then((response) => {               
                this.props.updateUserData(response.data.data, 'profile');
                return resolve(null);
            })
            .catch((err) => {
                if(! err.response) return;
                if(err.response.status === 422) {
                    return reject(setErrors(err.response));                            
                }            
            })
        }); 
    }
    render() {
        const { handleSubmit, submitting } = this.props;
        return (
            <form onSubmit={handleSubmit((data) => { 
                return this.handleFormSubmit(data);
            }) } className="profile-form"
            noValidate>
            <div className="form-row">
                <div className="form-col_2">
                    <Field                            
                        name="label_name"
                        component={FormField}                                        
                        type="text"
                        placeholder="Name of your Label"
                        label="Name of your Label"                            
                    />                    
                </div>                                
                <div className="form-col_2">                    
                    <Field                            
                        name="distribution_company"
                        component={FormField}                                        
                        type="text"
                        placeholder="Company Distributing Your Project"
                        label="Company Distributing Your Project"                            
                    />               
                </div>
            </div>
            <div className="form-row">
                <div className="form-col_2">
                    <Field                            
                        name="business_address"
                        component={FormField}                                        
                        type="text"
                        placeholder="Your Business Address"
                        label="Your Business Address"                            
                    />                                
                </div>                                
                <div className="form-col_2"> 
                    <Field                            
                        name="agreement_signer"
                        component={FormField}                                        
                        type="text"
                        placeholder="Who Will Sign Sample Agreements?"
                        label="Who Will Sign Sample Agreements?"                            
                    />                                  
                    
                </div>
            </div>
            <div className="form-row form-row_bottom">
                <div className="form-col_2">
                    <Field                            
                        name="payer_name"
                        component={FormField}                                        
                        type="text"
                        placeholder="Party Responsible For Making Payment?"
                        label="Party Responsible For Making Payment?"                            
                    />                    
                </div>
                <div className="form-col_2">
                    <Field                            
                        name="payer_email"
                        component={FormField}                                        
                        type="email"
                        placeholder="Party Responsible For Making Payment Email?"
                        label="Party Responsible for Making Payment Email?"                            
                    />                    
                </div>
            </div>
            <div className="submit-row">
                <button className="btn btn-primary" disabled={ submitting }
                        type="submit">Save Changes</button> 
                
            </div>
        </form>
        )
    }
}

const ProfileCompanyFormWrapped =  reduxForm({
    form: 'dashboard-profile-company-form' 
  })(ProfileCompanyForm);

ProfileCompanyFormWrapped.propTypes = {
    //...RFPropsTypes,
    updateUserData: PropTypes.func.isRequired,
}  


export default ProfileCompanyFormWrapped;
