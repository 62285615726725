import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ROUTESLIST } from '@/static/urls';

const isActive = (path, match, location) => !!(match || path === location.pathname);

const MainMenu = (props) => {
    const { onChangeUrl } = props;
    return (
        <Fragment>
            <li className="main-menu__item">
                <NavLink 
                    exact to={ ROUTESLIST.HOME } 
                    onClick={ onChangeUrl } 
                    className="main-menu__link" 
                    isActive={ isActive.bind(this, ROUTESLIST.HOME) } 
                >
                    HOME
                </NavLink>
            </li>
            <li className="main-menu__item">
                <NavLink 
                    to={ ROUTESLIST.CLEAR_SAMPLE }  
                    onClick={ onChangeUrl } 
                    isActive={ isActive.bind(this, ROUTESLIST.CLEAR_SAMPLE) } 
                    className="main-menu__link"
                >
                    CLEAR SAMPLES
                </NavLink>
            </li>
            <li className="main-menu__item">      
                <NavLink 
                    to={ ROUTESLIST.UPLOAD_REVIEW } 
                    onClick={ onChangeUrl } 
                    isActive={ isActive.bind(this, ROUTESLIST.UPLOAD_REVIEW) }  
                    className="main-menu__link"
                >
                    REVIEW
                </NavLink>
            </li>     
            <li className="main-menu__item">
                <a href="https://sampleexchange.theamplesamples.com"
                    className="main-menu__link"
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                    Sample Exchange
                </a>                
            </li>
            <li className="main-menu__item">      
                <NavLink 
                    to={ ROUTESLIST.STATIC_TESTIMONIALS } 
                    onClick={ onChangeUrl } 
                    isActive={ isActive.bind(this, ROUTESLIST.STATIC_TESTIMONIALS) }  
                    className="main-menu__link"
                >
                    TESTIMONIALS
                </NavLink>
            </li>                   
            <li className="main-menu__item"> 
                <a className="main-menu__link" href="https://grooves.theamplesamples.com" target="_blank" rel="noopener noreferrer">Grooves</a>
            </li>            
        </Fragment>
    );
}

MainMenu.propTypes = {
    onChangeUrl: PropTypes.func
}
  
MainMenu.defaultProps = {
    onChangeUrl: () => {}
}

export default MainMenu;
