import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import FormField from '@/components/forms/form-field';
import AudioUpload from '@/components/forms/audio-upload';
import SelectField from '@/components/forms/select-field';
import DurationPickerField from '@/components/forms/duration-picker';
import DatePickerField from '@/components/forms/date-picker';

const relOptions = [
    {value: 'c1', label: 'BOTH' },
    {value: 'c2', label: 'Option 2' },
    {value: 'c3', label: 'Option 3' },
];

class UploadMechStep2 extends Component {

    constructor(props) {
        super(props);
        this.stepSubmit = this.stepSubmit.bind(this);
    }

    stepSubmit(data) {
        console.log(data);
        this.props.nextStep();
    }
    
    render() {        
        const { handleSubmit } = this.props;
        return (
            <section className="upload-form__sec">
                <div className="content">
                    <div className="upload-form__top">  
                        <h2 className="head-shadow">CLEAR MECHANICAL</h2>
                        <h3 className="head">
                            Step 2 of 2: YOUR SONG
                        </h3>                       
                    </div>
                    <div className="form-step__body">
                        <form onSubmit={handleSubmit((data) => { 
                                return this.stepSubmit(data);
                            }) } className="form-layout"
                            noValidate>
                            <div className="form-row">
                                <div className="form-col_2 full-height">
                                    <Field                            
                                        name="name1"
                                        component={AudioUpload}
                                        required={true}
                                        type="text"
                                        placeholder="Set Artist First and Last Name"
                                        label="Upload Your Song"                            
                                    />
                                </div>                               
                                <div className="form-col_2">
                                    <Field                            
                                        name="name2"
                                        component={FormField}                                        
                                        type="text"
                                        placeholder="Set Artist First and Last Name"
                                        label="Name of Your Artist"                            
                                    /> 
                                    <Field                            
                                        name="name3"
                                        component={FormField}                                        
                                        type="text"
                                        placeholder="Song Name"
                                        label="Song(s) you would like to cover (remake)"                            
                                    />                                       
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-col_2">
                                    <Field                            
                                        name="name4"
                                        component={FormField}
                                        required={true}
                                        type="text"
                                        placeholder="Set Artist First and Last Name"
                                        label="A copy or copies of this song/ these songs"                            
                                    />
                                    
                                </div>                               
                                <div className="form-col_2">
                                    <Field                            
                                        name="name5"
                                        component={FormField}                                        
                                        type="text"
                                        placeholder="Set Artist First and Last Name"
                                        label="Writers of the song you’d like to cover (remake)"                            
                                    />                                    
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-col_2">
                                    <Field                            
                                        name="name6"
                                        component={FormField}                                       
                                        type="text"
                                        placeholder="Publishers name"
                                        label="Publishers of the song you’d like to remake?"                            
                                    />
                                    
                                </div>                               
                                <div className="form-col_2">
                                    <Field                            
                                        name="name7"
                                        component={ DurationPickerField} 
                                        placeholder="Set Time"
                                        label="What is the running time of your song?"                            
                                    />                                    
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-col_2">
                                    <Field                            
                                        name="name8"
                                        component={SelectField}                                        
                                        options={ relOptions }
                                        placeholder="Select"
                                        label="Will you be releasing a music video / lyric video?"                            
                                    />
                                    
                                </div>                               
                                <div className="form-col_2">
                                    <Field                            
                                        name="name9"
                                        component={DatePickerField}                                        
                                        type="text"                                        
                                        label="What is your intended release date?"                            
                                    />                                    
                                </div>
                            </div>
                            
                            <div className="submit-row submit-row_right">    
                                    <button className="btn btn-primary"
                                        type="submit">Next</button> 
                            </div>
                        </form>                        
                    </div>
                </div>
            </section>           
        )
    }
}

const UploadMechStep2Form =  reduxForm({
    form: 'upload-mech-step2' 
  })(UploadMechStep2);

export default UploadMechStep2Form;