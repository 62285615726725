import React, { Component } from 'react';
import './index.scss';

import bgImg from './shopone-bg.jpg';
import poster from './shop1.jpg';

import { aboutItems, downloadItems } from './data.js';

export default class ShopItemPage extends Component {
    render() {
        const secStyle = { backgroundImage: "url(" +  bgImg  + ")"};
        return (
            <main className="page product-page">
                <section className="entity-sec banner-filter" style={ secStyle }>
                    <div className="content">
                        <div className="entity-sec__top">  
                            <h2 className="head-shadow">ESCAPISM</h2>
                            <h3 className="head">
                                Lazy Lo-Fi Dubs
                            </h3>
                        </div>
                        <div className="product-blc">
                            <div className="product-blc__top">
                                <div className="product-blc__col product-blc__col_img">
                                    <div className="product-blc__img has-hover-btns" tabIndex="0" aria-haspopup="true">
                                        <img src={poster} alt="" />
                                        <div className="hover-btns">
                                            <div className="hover-btns__inner"> 
                                                <button className="hover-btn" type="button" aria-label="play">
                                                    <i className="as-icon as-icon_video-play"></i>
                                                </button>
                                            </div>
                                        </div>            
                                    </div>
                                </div>
                                <div className="product-blc__col product-blc__col_about">
                                    <h4 className="product-blc__head">
                                        ABOUT THIS Sample
                                    </h4>
                                    <ul className="product-blc__attrs">
                                    {
                                        aboutItems.map((item) => {
                                            return (
                                                <li key={item.id}>
                                                    <span className="label">{item.label}</span>
                                                    <span className="value">{item.value}</span>
                                                </li>
                                            );
                                        })
                                    }
                                    </ul>
                                    <div className="product-blc__actions">
                                        <span className="product-price">$36.95</span>
                                        <button type="button" className="btn-buy btn-buy_product">
                                            <i className="as-icon as-icon_cart"></i>
                                            <span className="text">Add to Cart</span>     
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="product-blc__bottom">
                                <div className="product-blc__col product-blc__col_pack">
                                    <h4 className="product-blc__subhead">
                                    Download contains:
                                    </h4>
                                    <ul className="product-blc__pack-items">
                                    {
                                        downloadItems.map((item) => {
                                            return (
                                                <li key={item.id}>
                                                    {item.value}
                                                </li>
                                            );
                                        })
                                    }
                                    </ul>
                                </div>
                                <div className="product-blc__col product-blc__col_desc">
                                    <h4 className="product-blc__subhead">
                                        Desription
                                    </h4>
                                    <p>
                                    Prime Loops presents our latest release: ESCAPISM - Lazy Lo-Fi Dubs!<br/>
                                    Escapism is all about capturing those deep and hazy melodies mixed with that Lo-Fi groove that gets 
                                    your head nodding. Smooth Rhodes licks, atmospheric vocal shots, swinging drum grooves. organic SFX, 
                                    guitar riffs, warm basslines and more are all inside and ready to get you going and set an instant 
                                    vibe! This is Lo-Fi and Boom Bap at its finest!
                                    </p>
                                    <p>
                                    Inside there are four construction kits, containing 42 loops, for you to get stuck into and get 
                                    a vibe going right away; drum and percussion loops, horns, basses, keys, pads, synths and more, 
                                    it's all in there! On top of that, there is a folder of 25 drum and percussion loops, 
                                    ready to drag and drop into your next project for instant results, as well as 42 one-shots 
                                    to get your own beats going if you're feeling inspired. As if that wasn't enough, there's also an 
                                    instrument folder containing 38 loops and hits, from bass stabs and wobbles, to reverbed-guitar 
                                    licks and filtered Rhodes. There are also 8 vocal loops and 32 crackling FX, perfect for adding 
                                    layers of interest to your beats!  
                                    </p>
                                </div>
                            </div>
                        </div>    
                       

                    </div>
                </section>                
            </main>
        )
    }
}
