const hasDocumentCookie = () => {  
    return typeof document === 'object' && typeof document.cookie === 'string';
}

const getDomain = () => {    
    const url = document.location.hostname;
    if (! url) return '';
    const parts = url.split('.');
    if (parts.length > 2) parts.splice(0, parts.length - 2);
    return parts.join('.');
    //developer.mozilla.org
}

const setCookies = (name, value, options) => {
    const attributes = Object.assign({}, options);
    let stringifiedAttributes = '';
    for (let attributeName in attributes) {
        if (!attributes[attributeName]) {
            continue;
        }
        stringifiedAttributes += '; ' + attributeName;
        if (attributes[attributeName] === true) {
            continue;
        }
        //stringifiedAttributes += '=' + attributes[attributeName].toString().split(';')[0]
        stringifiedAttributes += '=' + attributes[attributeName];
    }
    document.cookie = encodeURIComponent(name) + '=' + encodeURIComponent(value) + stringifiedAttributes;
}

const CookiesService = {
    set(name, value, days) {
        if (! hasDocumentCookie()) return; 
        const maxAge = (days > 0) ? days * 24 * 3600 : 90 * 24 * 3600;
        
        const options = {
            sameSite: 'Lax',
            path: '/',
            'Max-Age':  maxAge,
        };
        const domain = getDomain();
        if (domain) {
            options.Domain = domain;
        }
        setCookies(name, value, options);
    },
    remove(name) {         
        if (! hasDocumentCookie()) return; 
        let date = new Date(Date.now() - 86400e3);
        date = date.toUTCString();
        const options = {
            sameSite: 'Lax',
            path: '/',            
            expires: date
        };
        const domain = getDomain();
        if (domain) {
            options.Domain = domain;
        }
        setCookies(name, 0, options);
        
    },
    get(name) {
        /* eslint-disable no-empty*/
        if (typeof document === 'undefined' || (arguments.length && !name)) {
          return;
        }    
        const cookies = document.cookie ? document.cookie.split('; ') : []
        const jar = {}
        for (let i = 0; i < cookies.length; i++) {
            let parts = cookies[i].split('=');
            let value = parts.slice(1).join('=');        
            try {
                let found = decodeURIComponent(parts[0]);
                jar[found] = decodeURIComponent(value);    
                if (name === found) {
                    break;
                }
            } catch (e) { }
        }    
        return name ? jar[name] : jar;
        /* eslint-enable no-empty */
      }
};


const getAfiiliateKey = (key) => {
    return (key == 'id') ? 'as_affiate_id' : 'as_affiate_ref';
}

const AffialateService = {
    setId(data, key = 'ip') {
        const value = data; //extractId(data);
        if (! value) return;
        const cookieKey = getAfiiliateKey(key);
        const old = CookiesService.get(cookieKey);
        if (old) return;
        CookiesService.set(cookieKey, value, 90);
    },
    removeId(key = 'ip') {
        const cookieKey = getAfiiliateKey(key);
        CookiesService.remove(cookieKey);
    },
    getId(key = 'ip') { 
        const cookieKey = getAfiiliateKey(key);
        return CookiesService.get(cookieKey);
    }
};

export default AffialateService;