import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

function  WithSortingAndPagination(WrappedComponent) {
      class WithSearchRoute extends Component {
        constructor(props) {
          super(props);
          this.onSortingClick = this.onSortingClick.bind(this);
          this.onPageChange = this.onPageChange.bind(this); 
          this.getSearchParams = this.getSearchParams.bind(this);          
          this.state = {
              sort_col: '',
              sort_direction: '',              
          };
        }

        /* eslint-disable eqeqeq */
        componentDidUpdate(prevProps) {
          if(prevProps.location.search != this.props.location.search) {
            this.parseSearch();
          }
        }      

        onSortingClick(col, direction) { 
          const { location, history } = this.props;
          const query =  new URLSearchParams( location.search );
          const nextList = [];
          for(let pair of query.entries()) {
            if((pair[1] == 'asc') || (pair[1] == 'desc')) {

            }  else {
              nextList.push([pair[0], pair[1]]);
            }          
          }
          if((col) && ((direction == 'asc') || (direction == 'desc'))) {
              nextList.push([col, direction]);
          }
          let search  = '';
          if(nextList.length > 0) {
            const next = new URLSearchParams( nextList );
            search = '?' + next.toString();
          }
          history.push({
            pathname: location.pathname,
            search: search
          });
          
        }

        parseSearch() {
          const { location } = this.props;
          const query =  new URLSearchParams( location.search );
          let sort;
          for(let pair of query.entries()) {
            if((pair[1] == 'asc') || (pair[1] == 'desc')) {
              sort = pair;
              break;
            }            
          } 
          if(sort) {
            this.setState({sort_col: sort[0], sort_direction: sort[1]});
          } else {
            this.setState({sort_col: '', sort_direction: ''});
          }
        }
        /* eslint-enable eqeqeq */

        getSearchParams() {            
            let search = this.props.location.search;           
            return search;           
        }

        onPageChange(page) {          
          const { location, history } = this.props;
          const query =  new URLSearchParams( location.search );
          query.set('page', page);
          let search = '?' + query.toString();
          history.push({
            pathname: location.pathname,
            search: search
          });
        }

        render() {          
          return <WrappedComponent nav={this.state} 
            getSearchParams={ this.getSearchParams }
            onPageChange={ this.onPageChange }
            onSortingClick={ this.onSortingClick } 
            {...this.props} />;
        }
      };
    return withRouter(WithSearchRoute);
}

export default WithSortingAndPagination;




