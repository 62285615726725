import p1 from '@/assets/images/testimonials/kobieBrown1.jpg';
import p2 from '@/assets/images/testimonials/jefRobinson1.jpg';
//import p3 from '@/assets/images/testimonials/test3.jpg';
import p4 from '@/assets/images/testimonials/jamesMcmillan1.jpg';

import b1 from '@/assets/images/brands/sony-logo.jpg';
import b2 from '@/assets/images/brands/mbk-logo.jpg';
//import b3 from '@/assets/images/brands/mbk-logo.jpg';
import b4 from '@/assets/images/brands/art_vert.png';

import video1 from './images/swan2.mp4';
import video2 from './images/cascade1.mp4';

export const testimonials = [
    {
        id: 'test1',
        person: {
            name: 'Kobie Brown',
            img: p1,
            position: 'Vice President',
            positionUnder: 'Licensing/ Sony Music',
            brend: b1,
        },
        body: {
            title: 'OUR CLIENTS Says',
            quote: `They understand sample questions deserve simple and reliable answers.`,
            sign: 'Kobie Brown',
            text: `Music Licensing / Sony Music`,
            video: video1
        }
    },
    {
        id: 'test4',
        person: {
            name:   'James McMillan',
            img:    p4,
            position:   'CEO',
            positionUnder: 'ART@WAR',
            brend:  b4,
        },
        body: {
            title:  'OUR CLIENTS Says',
            quote:  `Convenience and accuracy are cherished commodities, and they happen to be what Ample Samples™ delivers every time`,
            sign:   'James McMillan',
            text:   `Art@War`,
            video:  video2
        }
    },
    {
        id: 'test2',
        person: {
            name:   'Jeff Robinson',
            img:    p2,
            position:   'CEO',
            positionUnder: 'MBK Entertainment',
            brend:  b2,
        },
        body: {
            title: 'OUR CLIENTS Says',
            quote: `They don't just clear samples. They know music and understand its history and culture`,
            sign:   'Jeff Robinson',
            text:   `MBK Entertainment`,
            video:  video2
        }
    },
]