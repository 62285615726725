import React, { Component } from 'react';

export default class HeaderOrder extends Component {  
    constructor(props) {
        super(props);
        this.onClickUp = this.onClickUp.bind(this);
        this.onClickDown = this.onClickDown.bind(this);        
    }  
    onClickUp() {      
        this.props.onChange(this.props.value, 'asc');
    }
    onClickDown() {   
        this.props.onChange(this.props.value, 'desc');
    }
    render() {
        const {label} = this.props;
        const classes = this.getClasses();
        return (
            <div className="header-order">
                <span className="header-order__label">{ label }</span>
                <span className={ classes }>  
                    <button type="button" className="btn btn-sort btn-sort_up" onClick={ this.onClickUp }>
                        <i className="as-icon as-icon_sort-up"></i>
                        </button> 
                    <button type="button" className="btn btn-sort btn-sort_down" onClick={ this.onClickDown }>
                        <i className="as-icon as-icon_sort-down"></i>
                    </button>                
                </span>
            </div>
        );
    }
//     sort_col
// sort_direction
// page
    getClasses() { 
        switch (this.props.active) {
            case 'asc': {
                return 'header-order__btns btns-sort btns-sort_asc';
            }
            case 'desc': {
                return 'header-order__btns btns-sort btns-sort_desc';
            }
            default: {
                return 'header-order__btns btns-sort';
            }
        }

    }
}