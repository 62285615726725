import ar1 from '@/assets/images/temp/artist1.jpg';
import ar2 from '@/assets/images/temp/artist2.jpg';
import ar3 from '@/assets/images/temp/artist3.jpg';
import ar4 from '@/assets/images/temp/artist4.png';
import ar5 from '@/assets/images/temp/artist5.jpg';

import al1 from '@/assets/images/temp/albom1.jpg';
import al2 from '@/assets/images/temp/albom2.jpg';
import al3 from '@/assets/images/temp/albom3.jpg';
import al4 from '@/assets/images/temp/albom4.jpg';
import al5 from '@/assets/images/temp/albom5.jpg';
import al6 from '@/assets/images/temp/albom6.jpg';
import al7 from '@/assets/images/temp/albom7.jpg';
import al8 from '@/assets/images/temp/albom8.jpg';
import al9 from '@/assets/images/temp/albom9.jpg';
import al10 from '@/assets/images/temp/albom10.jpg';

import sg1 from '@/assets/images/temp/single1.jpg';
import sg2 from '@/assets/images/temp/single2.jpg';
import sg3 from '@/assets/images/temp/single3.jpg';
import sg4 from '@/assets/images/temp/single4.jpg';
import sg5 from '@/assets/images/temp/single5.jpg';
import sg6 from '@/assets/images/temp/single6.jpg';
import sg7 from '@/assets/images/temp/single7.jpg';
import sg8 from '@/assets/images/temp/single8.jpg';


export const artists = [
    {
        id: 'ar1',
        image: ar1,
        name: 'Jay-Z',
        tags: 'Rap, Hip-Hop'
    },
    {
        id: 'ar2',
        image: ar2,
        name: 'Jay-Jay Johanson',
        tags: 'Indie'
    },
    {
        id: 'ar3',
        image: ar3,
        name: 'Jay-Way',
        tags: 'Rap, Hip-Hop'
    },
    {
        id: 'ar4',
        image: ar4,
        name: 'Jay Critch',
        tags: 'Rap, Hip-Hop'
    },
    {
        id: 'ar5',
        image: ar5,
        name: 'Jay Sean',
        tags: 'R&B, Hip-Hop, Soul'
    },
];
export const alboms = [
    {
        id: 'al1',
        image: al1,
        title: 'Lek Nation',
        authors: 'Jay Critch',
        year: '2020',
    },
    {
        id: 'al2',
        image: al2,
        title: 'American Gangster',
        authors: 'Jay-Z',
        year: '1996',
    },
    {
        id: 'al3',
        image: al3,
        title: 'The Blueprint 3',
        authors: 'Jay-Z',
        year: '1998',
    },
    {
        id: 'al4',
        image: al4,
        title: 'Clique',
        authors: 'Kanye West, Jay-Z, Big Sean',
        year: '1999',
    },
    {
        id: 'al5',
        image: al5,
        title: 'Deja Vu',
        authors: 'Beyoncé, Jay-Z',
        year: '2002',
    },
    {
        id: 'al6',
        image: al6,
        title: 'Collision Course',
        authors: 'Linkin Park, Jay-Z',
        year: '2003',
    },
    {
        id: 'al7',
        image: al7,
        title: '4:44',
        authors: 'Jay-Z',
        year: '2005',
    },
    {
        id: 'al8',
        image: al8,
        title: 'Higher',
        authors: 'Just Blaze, Baauer, Jay-Z',
        year: '2007',
    },
    {
        id: 'al9',
        image: al9,
        title: 'Jay Clyde The Band',
        authors: 'Jay Clyde',
        year: '2010',
    },
    {
        id: 'al10',
        image: al10,
        title: 'Jay & Silent Bob Reboot ',
        authors: 'Original Motion Picture',
        year: '2011',
    },

];
export const singles = [
    {
        id: 'sg1',
        image: sg1,
        title: 'Myspace Layouts The Missing Element',
        source: 'Download Anything Now A Days',
        duration: '07:17',
    },
    {
        id: 'sg2',
        image: sg2,
        title: 'Myspace Layouts The Missing Element',
        source: 'Download Anything Now A Days',
        duration: '07:17',
    },
    {
        id: 'sg3',
        image: sg3,
        title: '5 Tips For Offshore Software Development',
        source: 'Facts Why Inkjet Printing',
        duration: '00:34',
    },
    {
        id: 'sg4',
        image: sg4,
        title: '5 Tips For Offshore Software Development',
        source: 'Facts Why Inkjet Printing',
        duration: '00:34',
    },
    {
        id: 'sg5',
        image: sg5,
        title: 'Baby Monitor Technology',
        source: 'Compatible Inkjet Cartridge',
        duration: '07:15',
    },
    {
        id: 'sg6',
        image: sg6,
        title: 'Baby Monitor Technology',
        source: 'Compatible Inkjet Cartridge',
        duration: '07:15',
    },
    {
        id: 'sg7',
        image: sg7,
        title: 'Help Finding Information Online',
        source: 'Home Audio Recording For Everyone',
        duration: '05:09',
    },
    {
        id: 'sg8',
        image: sg8,
        title: 'Help Finding Information Online',
        source: 'Home Audio Recording For Everyone',
        duration: '05:09',
    },

];
export const videos = [    
    {
        id: 'vd1',
        src: "https://www.youtube.com/embed/RM7lw0Ovzq0",
        title: 'Compatible Inkjet Cartridge',
        author: 'Jay-Z',
        desc: 'Over time, even the most sophisticated, memory packed computer can begin to run slow if we don’t do something to prevent it. The reason why has less to do with how computers are made and how',       
    },
    {
        id: 'vd2',
        src: "https://www.youtube.com/embed/RM7lw0Ovzq0",
        title: 'Compatible Inkjet Cartridge',
        author: 'Jay-Z',
        desc: 'Over time, even the most sophisticated, memory packed computer can begin to run slow if we don’t do something to prevent it. The reason why has less to do with how computers are made and how', 
    },
    {
        id: 'vd3',
        src: "https://www.youtube.com/embed/RM7lw0Ovzq0",
        title: 'Compatible Inkjet Cartridge',
        author: 'Jay-Z',
        desc: 'Over time, even the most sophisticated, memory packed computer can begin to run slow if we don’t do something to prevent it. The reason why has less to do with how computers are made and how', 
    },
    {
        id: 'vd4',
        src: "https://www.youtube.com/embed/RM7lw0Ovzq0",
        title: 'Compatible Inkjet Cartridge',
        author: 'Jay-Z',
        desc: 'Over time, even the most sophisticated, memory packed computer can begin to run slow if we don’t do something to prevent it. The reason why has less to do with how computers are made and how', 
    },

]; 

//<iframe width="560" height="315" src="https://www.youtube.com/embed/RM7lw0Ovzq0" 
//frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; 
//picture-in-picture" allowfullscreen></iframe>