import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './card.scss';

export default class SingleCard extends Component {
    render() {       
        const {id, image, title, source, duration} = this.props; 
        const url =  '/single';      
        return (
            <div className="single-card">
                <div className="single-card__content">
                    <div className="single-card__img-col">
                        <div className="single-card__img has-hover-btns" tabIndex="0" aria-haspopup="true">
                            <img src={image} alt={ title } /> 
                            <div className="hover-btns">
                                <div className="hover-btns__inner">                               
                                    <button className="hover-btn" type="button" aria-label="play">
                                        <i className="as-icon as-icon_video-play"></i>
                                    </button>                               
                                </div>
                            </div>                       
                        </div> 
                    </div>                   
                    <div className="single-card__info">                
                        <Link to={ url } className="single-card__title">
                            { title }
                        </Link>
                        <div className="single-card__sourse">
                           { source }
                        </div>
                    </div>
                    <div className="single-card__duration">
                        { duration }
                    </div>
                    <div className="single-card__actions">
                        <button className="btn-icon" type="button" aria-label="favourite">
                            <i className="as-icon as-icon_like"></i>
                        </button>
                        <button className="btn-icon" type="button" aria-label="share">
                            <i className="as-icon as-icon_share"></i>
                        </button>
                    </div>  
                </div>
            </div>
        )
    }
}
