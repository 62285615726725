import React, { Component } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import { getOptionLabel, openList, closeList, getOptions, changeInputHandler, 
    changeHandler,  componentDidMountPlaceholder, componentDidUpdatePlaceholder,
    getImgMenuOption } from '@/utils/autocomplete.js';

import FormFieldBottom from './field-bottom';

const itemClasses = {
    input : {
        root: 'as-select as-many-autocomplete',
        paper: 'as-select__opts-wrap as-select__opts-imgs',       
    },
}

export default class ManyWithImageAutocompleteField extends Component {
    constructor(props) {
        super(props);
        this.changeHandler = changeHandler.bind(this);
        this.openList = openList.bind(this);
        this.closeList = closeList.bind(this);
        this.changeInputHandler = changeInputHandler.bind(this);
        this.getOptions = getOptions.bind(this);
        this.componentDidMountPlaceholder = componentDidMountPlaceholder.bind(this);
        this.componentDidUpdatePlaceholder = componentDidUpdatePlaceholder.bind(this);
        this.getOptionsDebonced =  debounce((query) => {
            this.getOptions(query);
        }, 200);       
        this.state= {
            options: [],
            open: false,
            currentPlaceholder: null,
            processedQuery: ''
        }        
    }

    componentDidMount() { 
        this.componentDidMountPlaceholder(); 
    }

    componentDidUpdate(prevProps) {
        this.componentDidUpdatePlaceholder(prevProps);
    }
    
    render() {
        const { label, required, meta, note, correct } = this.props;        
        const classes = (meta.error) ? "form-field form-autocomplete error" : "form-field form-autocomplete";        
        const val = this.props.input.value || [];// value={ val } 
        const { options, open, currentPlaceholder, processedQuery } = this.state;
        const loading = open && options.length === 0;
        const loadingText = (processedQuery.length > 0) ? 'Loading' :  'Start typing...';
        return (
            <div className={ classes }>
                <label className={ (required) ? "label label_required" : "label"}>{ label }</label>                             
                <Autocomplete   
                    classes={itemClasses.input}                
                    freeSolo
                    multiple
                    autoSelect={true}
                    defaultValue={ val }
                    onChange={this.changeHandler}
                    getOptionLabel={ getOptionLabel }
                    renderOption={ getImgMenuOption } 
                    options={options}
                    loading={loading}
                    open={open}
                    onOpen={ this.openList }
                    onClose={ this.closeList }
                    loadingText={ loadingText }
                    renderInput={(params) => (
                    <TextField {...params} 
                        variant="outlined"
                        onChange={ this.changeInputHandler } 
                        placeholder={ currentPlaceholder }                         
                    />
                    )}
                />
                <FormFieldBottom 
                    meta={meta}
                    note={note}
                    correct={correct}
                />    
            </div>
        )
    }
   
}

ManyWithImageAutocompleteField.propTypes = {
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    correct: PropTypes.string,
    note: PropTypes.string,
    label: PropTypes.string,
    url: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool                        
};
