import React, { Component } from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ROUTESLIST } from '@/static/urls';

import  LatestCratesBlock from '@/components/blocks/last-crates';

class CratesPage extends Component {
    render() {
        const {isAuth} = this.props;
        return (
            <main className="page">
                <section className="top-banner" id="top-banner-crates">
                    <div className="content">
                        <div className="top-banner__wrap">
                            <div className="top-banner__content">
                                <h2 className="head-shadow">The Crates</h2>
                                <h3 className="head">
                                    CONTRIBUTE TO THE CRATES BY SHOWCASING 
                                    YOUR KNOWLEDGE OF SAMPLES AND SONGS.
                                </h3>
                                <div className="top-banner__btns">
                                {isAuth
                                    ? <Link className="btn btn-primary" to={ROUTESLIST.UPLOAD_ADD_CRATE}>Add to the Crates</Link>
                                    : <Link className="btn btn-primary" to={{pathname: ROUTESLIST.LOGIN, 
                                        state: { redirectUrl:  ROUTESLIST.UPLOAD_ADD_CRATE}}}>Add to the Crates</Link>
                                }                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="cs-sec cs-sec_last-crates">
                    <div className="content">
                        <h3 className="cs-sec__title">Latest Additions</h3>
                        <div className="cs-sec__desc">
                            <p>Check out the latest additions to THE CRATES and join the conversation</p>
                        </div> 
                        <LatestCratesBlock />                    
                    </div>
                </section>
            </main>
        )
    }
}

function mapStateToProps(state) {  
    return { 
         isAuth: state.auth.isAuth  
    };
}

export default connect(mapStateToProps, null)(CratesPage);
