import React, { Component } from 'react';

import './item.scss';

export default class AlbomItem extends Component {
    render() { 
        const {id, number, title, duration } = this.props;   
        
        if (number === 2) {
            return (
                <div className="albom-item">
                    <div className="albom-item__number albom-item__number_btn">
                        <button className="btn-song-act" type="button" aria-label="pause">
                            <i className="as-icon as-icon_player-pause"></i>
                        </button>
                    </div>
                    <div className="albom-item__title">{ title }</div>
                    <div className="albom-item__duration">
                      <span className="mini-progress"><span 
                        style={{ width: '42%'}} className="mini-progress__ready"></span></span> 
                      03:17 / { duration }
                    </div>
                    <div className="albom-item__btns">
                        <button className="btn-icon" type="button" aria-label="favourite">
                            <i className="as-icon as-icon_like"></i>
                        </button>
                        <button className="btn-icon" type="button" aria-label="share">
                            <i className="as-icon as-icon_share"></i>
                        </button>
                    </div>
                </div>
            );

        } else {
            return (
                <div className="albom-item">
                    <div className="albom-item__number">{ number }</div>
                    <div className="albom-item__title">{ title }</div>
                    <div className="albom-item__duration">{ duration }</div>
                    <div className="albom-item__btns">
                        <button className="btn-icon" type="button" aria-label="favourite">
                            <i className="as-icon as-icon_like"></i>
                        </button>
                        <button className="btn-icon" type="button" aria-label="share">
                            <i className="as-icon as-icon_share"></i>
                        </button>
                    </div>
                </div>
            );

        }
        
        
    }
}
{/* <div className="albom-card__content">
                    <Link to={ url } className="albom-card__img">
                        <img src={image} alt={ title } />                        
                    </Link>                    
                    <div className="albom-card__info">               
                        <Link to={ url } className="albom-card__title">
                            { title }
                        </Link>
                        <div className="albom-card__authors">
                           by { authors }
                        </div>
                        <div className="albom-card__year">
                           { year }
                        </div>
                    </div>
                </div> */}