import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { withRouter } from "react-router";
import DashboardTabs from '../tabs';
import SingleSyncInfoPanel from './infoPanel';
import SingleSyncTermsPanel from '../components/termsPanel';
import api from '@/middlewares/api';

import {ROUTESLIST} from '@/static/urls.js';

import { arrangeOneInfo } from '@/utils/syncs.js';
import { arrangeTerms } from '@/utils/terms.js';
import syncImg  from '@/assets/images/defaults/sync-big1.png';



class DashboardOneSyncPage extends Component {
    constructor(props) {
        super(props);        
        //TODO setCount data can change
        this.state = {           
            tabs: [
                {id: 'about', isCount: false, label: 'About'},
                {id: 'data', isCount: false, label: 'Terms'},
            ],
            isLoaded: false,
            item: null,
            terms: null,            
        }    
    }
    componentDidMount() {
        this.fetchItem();
        this.fetchTerms()
    }
    fetchItem() {
        const { match } = this.props;
        const id = match.params.id;
        if(! id) return;
        api.get('user/dashboard/clear-sync/show/' + id)
        .then((response) => { console.log('sync', response.data);
            const item = arrangeOneInfo(response.data);
            this.setState({item: item, isLoaded: true})
        })
        .catch((err) => {
            console.log(err, err.response);        
        });     
    }

    fetchTerms() {
        const { match } = this.props;
        const id = match.params.id;
        if(! id) return;
        api.get('user/dashboard/clear-sync/terms/show/' + id)
        .then((response) => { //console.log('sample terms', response.data);
            const terms = arrangeTerms(response.data);
            this.setState({
                terms: terms
            });
        })
        .catch((err) => {
            console.log(err.response);        
        });     
    }

    render() { 
        const { tabs, item, isLoaded, terms } = this.state;  
        if(! item ) return null;   
        return (
            <div> 
            <h3 className="dashboard-header dashboard-header_small dashboard-header_left-link">
                <Link to={ROUTESLIST.DASHBOARD_SYNC} className="btn btn-act-iconed">
                    <i className="as-icon as-icon_nav-left"></i>
                </Link> 
                {
                    isLoaded &&
                    <span>{item.title}</span> 
                }  
                               
            </h3>
            <div className="db-single-img">
                <img src={ syncImg } alt="" />
            </div>
            <DashboardTabs 
                    items={tabs}
                    panels={
                        [
                            <SingleSyncInfoPanel item={item} />,
                            <SingleSyncTermsPanel terms={terms} />
                        ]
                    }
            /> 
             
        </div> 
        )
    }
}
export  default withRouter(DashboardOneSyncPage);