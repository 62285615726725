import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import Recaptcha from 'react-google-invisible-recaptcha';

import api, { setErrors} from '@/middlewares/api';
import { phonePattern } from '@/utils/forms.js';
import history from '@/store/history';
import { ROUTESLIST } from '@/static/urls';

import MainModal from '@/components/modals/main';
import FormField from '@/components/forms/form-field';
import WordsLimitedField from '@/components/forms/words-limited-field';

import BgImage from '@/assets/images/pages/send_review.jpg'



const bgStyles = {
	backgroundImage: `url(${BgImage})`,    
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	backgroundSize: 'cover'
}

class ReviewForm extends Component {

	constructor(props) {
        super(props)        
        this.state = {          
			isOpenModal: false,
			sending: false,
			isOpenDisclaimer: false,
			isCheckedDisclaimer: false,			
        } 
		this.isRecaptcha =  (process.env.REACT_APP_RECAPTCHA_KEY) ? true : false;
		this.recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;
        this.closeModal = this.closeModal.bind(this);
		this.submitHandler = this.submitHandler.bind(this);
		this.openDisclaimer = this.openDisclaimer.bind(this);
		this.closeDisclaimer = this.closeDisclaimer.bind(this);
		this.disclaimerChange = this.disclaimerChange.bind(this);
    }

	closeModal() {
		this.setState({isOpenModal: false});
		this.props.reset();
		history.push(ROUTESLIST.HOME);		
	}

	closeDisclaimer() {
		this.setState({isOpenDisclaimer: false});
	}

	openDisclaimer() {
		this.setState({isOpenDisclaimer: true});
	}

	disclaimerChange() {
		this.setState((prevState) => {
			return {
				isCheckedDisclaimer: !prevState.isCheckedDisclaimer
			}			
		});
	}

	onRecaptchaResolved() {
		
	}
	
	recaptchaSubmit(data) {
		return new Promise((resolve, reject) => {
			this.recaptcha.execute()
			.then((token) => { 
				this.setState({sending: true});
				api.post('send-review', data)
				.then((response) => {               
					this.setState({
						isOpenModal: true,
						sending: false,	
					});
					return resolve(null);
				})
				.catch((err) => {
					this.setState({sending: false});
					if (! err.response) return;
					if (err.response.status === 422) {
						return reject(setErrors(err.response));                            
					}            
				});
			})
			.catch(() => {
				return resolve(null);
			});
		});
	}

	simpleSendData(data) {
		this.setState({sending: true});
        return new Promise((resolve, reject) => {
            api.post('send-review', data)
            .then((response) => {               
                this.setState({
					isOpenModal: true,
					sending: false,	
				});
                return resolve(null);
            })
            .catch((err) => {
				this.setState({sending: false});
                if (! err.response) return;
                if (err.response.status === 422) {
                    return reject(setErrors(err.response));                            
                }            
            });
        }); 
	}

	submitHandler(data) {
		if (! this.state.isCheckedDisclaimer) return;
		if (this.isRecaptcha) {
			return this.recaptchaSubmit(data);
		} else {
			return this.simpleSendData(data);
		}
    }

	render() {
		const {isOpenModal, sending, isOpenDisclaimer, isCheckedDisclaimer} = this.state;
		const { handleSubmit, submitting } = this.props;
		const { isRecaptcha, recaptchaKey } = this;
		return (
			<main className="page upload-page">
				<section className="upload-form__sec" style={bgStyles}>
					<div className="content">
						<div className="upload-form__top">  
							<h2 className="head-shadow">Sample Review</h2>
							<h3 className="head">
								REVIEW YOUR SAMPLES QUICKLY & ACCURATELY
							</h3>
							<div className="upload-form__top-msg form-layout">
								<p>We answer the who, what, when, where, how and why of samples.</p>
							</div> 
							<div className="review-video">
								<div className="review-video__inner">
									<iframe src="https://www.youtube.com/embed/BDBd79wb_jI?autoplay=1"
										title="YouTube video player" 
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
										frameBorder="0" 
										width="100%" 
										height="100%" 
										allowFullScreen >
									</iframe>
								</div>
							</div>                      
						</div>
						<div className="form-step__body">
							<form onSubmit={handleSubmit((data) => { 
									return this.submitHandler(data);
								}) } className="form-layout"
								noValidate>									
								<div className="form-row">  
									<div className="form-col_2">
										<Field                            
											name="firstname"
											component={FormField}
											required={true}
											type="text"											
											label="First Name"                            
										/>										
									</div>
									<div className="form-col_2">
										<Field                            
											name="lastname"
											component={FormField}
											required={true}
											type="text"											
											label="Last Name"                            
										/>										
									</div>
								</div>
								<div className="form-row">   
									<div className="form-col_2">
										<Field                            
											name="phone"
											component={FormField}
											required={true}
											type="text"	
											{...phonePattern}   										
											label="Phone Number"                            
										/>										
									</div>
									<div className="form-col_2">
										<Field                            
											name="email"
											component={FormField}
											required={true}
											type="email"											
											label="Email Address"                            
										/>										
									</div>
								</div>
								<Field                            
									name="company_name"
									component={FormField}                                        
									type="text"
									required={true}									
									label="Company Name"                            
								/> 
								<Field                            
									name="notes"
									component={WordsLimitedField}  
									required={true}                              
									maxWords={ 200 }									
									label="How can we help you?"                            
								/>
								<div className="submit-row_txt">
									<p className="as-text">									
										Please read our
										<button type="button" className="link-thin" onClick={ this.openDisclaimer }											
											>disclaimer
										</button> in order to submit your request 
									</p>
									<div className="submit-row__btn-col">
										<button className="btn btn-primary" disabled={ submitting || sending || (! isCheckedDisclaimer) }
											type="submit">SEND</button> 
									</div>
								</div>
							</form>
						</div>
					</div>
				</section>
				{ isRecaptcha &&
					<Recaptcha
						style={ {display: 'none'} }
						ref={ ref => this.recaptcha = ref }
						sitekey={ recaptchaKey }
						onResolved={ this.onRecaptchaResolved } />
				}
				<MainModal open={ isOpenModal } onClose={ this.closeModal } >                    
                    <h4 className="main-modal__big-head">YOUR REQUEST IS SENT</h4>
					<p className="main-modal__subhead">WE WILL BE IN TOUCH WITH YOU WITHIN A HOUR</p>
                    <div className="main-modal__act">
                        <button className="btn btn-primary" type="button" onClick={ this.closeModal }>OK</button>                        
                    </div>
                </MainModal>
				<MainModal open={ isOpenDisclaimer } onClose={ this.closeDisclaimer } > 
					<p className="main-modal__msg single">
					The content of our sample review reports are for informational purposes only and should not be taken as legal advice. 
					Such content is based solely upon information provided to us and/or our internal professional 
					analysis. Your reliance on any such content shall be solely at your own risk. **  
					Nothing in our reports shall absolve you of any obligations to obtain sample 
					licenses and consents, and/or make any payments in connection therewith. 
					You shall hold Ample Samples, Inc. harmless of any wrongdoing which is based on our sample 
					review reports.
					</p>
					<p className="main-modal__msg single">
						<label className="checkbox-field">
							<input  type="checkbox" className="checkbox-field__input" checked={ isCheckedDisclaimer } onChange={ this.disclaimerChange } />       
								<span className="checkbox-field__control" />
								<span className="checkbox-field__label">I accept</span>
						</label>
					</p>
                    <div className="main-modal__act">
                        <button className="btn btn-primary" type="button" onClick={ this.closeDisclaimer }>OK</button>                        
                    </div>
                </MainModal>
			</main>
		)
	}
}
const ReviewFormPage =  reduxForm({
    form: 'upload-review',    
  })(ReviewForm);

export default ReviewFormPage;