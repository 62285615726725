import React, { Component } from 'react';
import {Link} from 'react-router-dom'; 
import { connect } from 'react-redux';

import { authLogout, fetchUserOrdersCount }  from '@/actions/auth';
import {ROUTESLIST} from '@/static/urls.js';

class DashboardAside extends Component {
    constructor(props) {
        super(props);
        // ROUTESLIST.DASHBOARD_ONE_SAMPLE
        this.firstNavList = [ROUTESLIST.DASHBOARD_SAMPLES, ROUTESLIST.DASHBOARD_SYNC, ROUTESLIST.DASHBOARD_MECHANICAL];
        this.secondNavList = [ROUTESLIST.DASHBOARD_PROFILE, ROUTESLIST.DASHBOARD_PAYMENTS, ROUTESLIST.DASHBOARD_SECURITY];
        //this.invoiceNavList = [ROUTESLIST.DASHBOARD_INVOICES, ROUTESLIST.DASHBOARD_INVOICE];
        this.handleLogout = this.handleLogout.bind(this);
    }

    componentDidMount() {
        if( typeof  this.props.userOrderCnt !== "number") {
            this.props.fetchUserOrdersCount();
        }        
    }

    handleLogout() {
        this.props.fetchLogout();
    }

    getMainNavClasses(link, currentLink) {
        return (link === currentLink) ? 'nav-main__item single nav-main__item_active'
        : 'nav-main__item single';
    }
    getSubNavClasses(link, currentLink) {
        return (link === currentLink) ? 'nav-sub__item nav-sub__item_active'
        : 'nav-sub__item';
    }
    getMainNavSubClasses(list, currentLink) {
        return (list.indexOf(currentLink) !== -1) ? 'nav-main__item has-children nav-main__item_active'
        : 'nav-main__item has-children';
    } 
    
    cleanLink() {
        //TODO replace regex (/[\d]*)$
        return this.props.currentUrl.pathname.replace(/(\/[\d]*)$/, '');
    }

    render() {         
        const { userName, userAvatar, userOrderCnt}  = this.props;  
        const link = this.cleanLink();         
        return (
            <aside className="dashboard-aside">
                <div className="dashboard-aside__wrap">
                    <div className="dashboard-aside__user">
                        <div className="dashboard-aside__user-avatar"
                            style={ { backgroundImage: "url(" +  userAvatar + ")"} }>                        
                        </div>
                        <div className="dashboard-aside__user-name"> 
                            { userName }                     
                        </div>
                    </div>
                    <nav className="dashboard-aside__nav">
                        <ul className="nav-main">
                            <li className={ this.getMainNavSubClasses(this.firstNavList, link) }>
                                <Link to={ROUTESLIST.DASHBOARD_SAMPLES} className="nav-main__link">
                                    <i className="as-icon as-icon_sample-big"></i>
                                    <span>My Samples</span>                                    
                                </Link>
                                <ul className="nav-sub">
                                    <li  className={ this.getSubNavClasses(ROUTESLIST.DASHBOARD_SAMPLES, link) }>
                                        <Link to={ROUTESLIST.DASHBOARD_SAMPLES} className="nav-sub__link"> 
                                            <i className="as-icon as-icon_sample"></i>
                                            <span>Samples</span>
                                        </Link>
                                    </li>
                                    <li className={ this.getSubNavClasses(ROUTESLIST.DASHBOARD_SYNC, link) }>
                                        <Link to={ROUTESLIST.DASHBOARD_SYNC} className="nav-sub__link">
                                            <i className="as-icon as-icon_folder-sync"></i>
                                            <span>Sync</span>
                                        </Link>
                                    </li>
                                   
                                </ul>
                            </li>
                            <li className={ this.getMainNavSubClasses(this.secondNavList, link) }>
                                <Link to={ROUTESLIST.DASHBOARD_PROFILE} className="nav-main__link">
                                    <i className="as-icon as-icon_settings"></i>
                                    <span>Account Settings</span>                                    
                                </Link>
                                <ul className="nav-sub">
                                    <li className={ this.getSubNavClasses(ROUTESLIST.DASHBOARD_PROFILE, link) }>
                                        <Link to={ROUTESLIST.DASHBOARD_PROFILE} className="nav-sub__link">
                                            <i className="as-icon as-icon_user"></i>
                                            <span>Profile</span>
                                        </Link>
                                    </li>
                                    <li className={ this.getSubNavClasses(ROUTESLIST.DASHBOARD_PAYMENTS, link) }>
                                        <Link to={ROUTESLIST.DASHBOARD_PAYMENTS}  className="nav-sub__link">
                                            <i className="as-icon as-icon_payment"></i>
                                            <span>Payments</span>
                                        </Link>
                                    </li>
                                    <li className={ this.getSubNavClasses(ROUTESLIST.DASHBOARD_SECURITY, link) }>
                                        <Link to={ROUTESLIST.DASHBOARD_SECURITY} className="nav-sub__link">
                                            <i className="as-icon as-icon_lock"></i>
                                            <span>Security</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li> 
                            <li className={ this.getMainNavClasses(ROUTESLIST.DASHBOARD_INVOICES, link) }>
                                <Link to={ROUTESLIST.DASHBOARD_INVOICES} className="nav-main__link">
                                    <i className="as-icon as-icon_invoices"></i>
                                    <span>Orders</span>
                                    <span className="invoices_cnt">{ userOrderCnt }</span>
                                </Link>
                            </li>
                            <li className="nav-main__item">
                                <button type="button" className="nav-main__link" onClick={this.handleLogout}>
                                    <i className="as-icon as-icon_logout"></i>
                                    <span>Logout</span>
                                </button>
                            </li>
                        </ul>
                    </nav>

                </div>
                
            </aside>
        )
    }
}
function mapStateToProps(state) { 
    const { userOrderCnt } = state.auth;
    if(state.auth.user) {
        return {
            userOrderCnt,
            userAvatar: state.auth.user.avatar,
            userName: state.auth.user.displayName
        }

    } else {
        return {
            userOrderCnt,
            userAvatar: null,
            userName: null
        }
    }    
}
function mapDispatchToProps(dispatch) {
    return {
        fetchLogout: () => {
            dispatch(authLogout(true))
        },
        fetchUserOrdersCount: () => {
            dispatch(fetchUserOrdersCount())
        }     
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardAside);

//ROUTESLIST.DASHBOARD_CRATES

/*
 <li className={ this.getSubNavClasses(ROUTESLIST.DASHBOARD_MECHANICAL, link) }>
    <Link to={ROUTESLIST.DASHBOARD_MECHANICAL} className="nav-sub__link">
        <i className="as-icon as-icon_brand-mech"></i>
        <span>Mechanical</span>
    </Link>
</li>

*/