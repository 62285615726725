import React, { Component } from 'react';
import { Field, reduxForm} from 'redux-form';
import PropTypes from 'prop-types';

import { phonePattern } from '@/utils/forms.js';
import api, { setErrors} from '@/middlewares/api';

import FormField from '@/components/forms/form-field';

class ProfilePersonalForm extends Component {
    constructor(props) {
        super(props);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    handleFormSubmit(data) {
        //console.log(data);              
        return new Promise((resolve, reject) => {
            api.post('profile/personal', data)
            .then((response) => {
                //console.log('res', response.data); 
                this.props.updateUserData(response.data.data, 'profile');
                return resolve(null);
            })
            .catch((err) => {
                if(! err.response) return;
                if(err.response.status === 422) {
                    return reject(setErrors(err.response));                            
                }            
            })
        }); 
    }
    
    render() {
        const { handleSubmit, submitting } = this.props;        
        return (
            <form 
                onSubmit={handleSubmit((data) => { 
                return this.handleFormSubmit(data);
            }) } className="profile-form"
            noValidate>
            <div className="form-row">
                <div className="form-col_2">
                    <Field                            
                        name="first_name"
                        component={FormField}                                        
                        type="text"
                        
                        placeholder="Your First Name"
                        label="First Name"                            
                    />                    
                </div>                                
                <div className="form-col_2">                    
                    <Field                            
                        name="last_name"
                        component={FormField}                                        
                        type="text"
                        placeholder="Your Last Name"
                        label="Last Name"                            
                    />               
                </div>
            </div>
            <div className="form-row">
                <div className="form-col_2">
                    <Field                            
                        name="personal_email"
                        component={FormField}                                        
                        type="text"
                        placeholder="some@email.com"
                        label="Your Email Address"                            
                    />                                
                </div>                                
                <div className="form-col_2"> 
                    <Field                            
                        name="personal_phone"
                        component={FormField}
                        {...phonePattern}                                        
                        type="text"                       
                        label="Your Phone Number"                            
                        note="E.g. (XXX) XXX–XXXX"                        
                    />                                  
                    
                </div>
            </div>            
            <div className="submit-row">
                <button className="btn btn-primary" disabled={ submitting }
                        type="submit">Save Changes</button> 
                
            </div>
        </form>       
        )
    }
}
const ProfilePersonalFormWrapped =  reduxForm({
    form: 'dashboard-profile-personal-form',
    enableReinitialize: true,

  })(ProfilePersonalForm);

ProfilePersonalFormWrapped.propTypes = {
    //...RFPropsTypes,
    updateUserData: PropTypes.func.isRequired,
}  

export default ProfilePersonalFormWrapped;
