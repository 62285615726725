export const capitalizeWord = (word) => {
    if(word.length < 1) return word;
    return word.charAt(0).toUpperCase() + word.slice(1);
}

export const getFullName = (data) => {
    let names = [];
    if(data.first_name) {
        names.push(data.first_name);
    }
    if(data.last_name) {
        names.push(data.last_name);
    }
    return (names.length > 0) ? names.join(' ') : null;
}