import React, { Component } from 'react';

export default class VideoLinkShow extends Component {
    render() {       
        const { url } = this.props; 
        if(! url) return null;        
        const source = url + '?&mode=opaque&wmode=transparent&controls=0&modestbranding=1'+
        '&rel=0&autohide=1&showinfo=1&color=white&iv_load_policy=3&theme=light&wmode=transparent&mute=1';
        //allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;picture-in-picture"
        
        return (
            <div className="video-block">
                <iframe src={ source } 
                    frameBorder="0"
                    allowFullScreen></iframe>
            </div> 
        )
    }
}
