import React, { Component } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import WithNotAuthRedirect from '@/components/hoc/with-not-auth-redirect';

import {ROUTESLIST} from '@/static/urls.js';
import './index.scss';
import './tables.scss';

import DashboardAside from './aside';

import DashboardInvoicesPage from './invoices/index';
import DashboardMechanicalPage from './mechanical/index';
import DashboardPaymentsPage from './payments/index';
import DashboardProfilePage from './profile/index';
import DashboardSamplesPage from './samples/index';
import DashboardOneSamplePage from './one-sample/index';
import DashboardSecurityPage from './security/index';
import DashboardOneSyncPage from './one-sync/index';
import DashboardSyncPage from './sync/index';
import DashboardCratesPage from './crates/index';
import DashboardSingleInvoicePage from './invoices/single'; 

class DashboardPage extends Component {
    render() {  
        const { location, isCurrentUserChecked } = this.props;        
        return (
            <main className="page dashboard-page">               
                <div className="content">
                { isCurrentUserChecked &&
                    <div className="dashboard">
                        <DashboardAside currentUrl={ location }
                        />                       
                        <div className="dashboard-content">
                        <Switch>
                            <Redirect exact from={ROUTESLIST.DASHBOARD} to={ROUTESLIST.DASHBOARD_SAMPLES} /> 
                            <Route path={ROUTESLIST.DASHBOARD_ONE_SAMPLE + ':id'}>
                                <DashboardOneSamplePage/>
                            </Route>
                            <Route path={ROUTESLIST.DASHBOARD_SAMPLES}>
                                <DashboardSamplesPage/>
                            </Route>
                            <Route path={ROUTESLIST.DASHBOARD_ONE_SYNC + ':id'}>
                                <DashboardOneSyncPage/>
                            </Route>                            
                            <Route path={ROUTESLIST.DASHBOARD_SYNC}>
                                <DashboardSyncPage/>
                            </Route> 
                            <Route path={ROUTESLIST.DASHBOARD_MECHANICAL}>
                                <DashboardMechanicalPage/>
                            </Route> 
                            <Route path={ROUTESLIST.DASHBOARD_CRATES}>
                                <DashboardCratesPage/>
                            </Route>  
                            <Route path={ROUTESLIST.DASHBOARD_PROFILE}>
                                <DashboardProfilePage/>
                            </Route> 
                            <Route path={ROUTESLIST.DASHBOARD_PAYMENTS}>
                                <DashboardPaymentsPage/>
                            </Route> 
                            <Route path={ROUTESLIST.DASHBOARD_SECURITY}>
                                <DashboardSecurityPage/>
                            </Route> 
                            <Route path={ROUTESLIST.DASHBOARD_INVOICE + ':id'}>
                                <DashboardSingleInvoicePage/>
                            </Route> 
                            <Route path={ROUTESLIST.DASHBOARD_INVOICES}>
                                <DashboardInvoicesPage/>
                            </Route>  
                        </Switch>                        
                        </div>
                    </div> 
                }                   
                </div>    
            </main>
        )
    }
}


export default WithNotAuthRedirect(DashboardPage);
 