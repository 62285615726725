import { createStore, applyMiddleware, compose } from 'redux';
import {routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';


import history from './history';
import rootReducer from '../reducers';

const initialState = {};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
        rootReducer(history), 
        initialState,
        composeEnhancers(
          applyMiddleware(
            routerMiddleware(history),
            thunk,
        ),
  ),
);

export default store;