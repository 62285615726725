import React, { Component } from 'react';
import { Field, reduxForm, getFormValues, getFormSubmitErrors } from 'redux-form';
import { connect } from 'react-redux';
import api, { setErrors, arrangeData, convertDateToDb } from '@/middlewares/api';
import { normalizeMoney, cleanMoney } from '@/utils/forms.js';

import SelectField from '@/components/forms/select-field';
import FormField from '@/components/forms/form-field';
import RadioField from '@/components/forms/radio-field';
import DatePickerField from '@/components/forms/date-picker';
import WordsLimitedField from '@/components/forms/words-limited-field';

class UploadSyncStep4 extends Component {

    constructor(props) {
        super(props);
        this.stepSubmit = this.stepSubmit.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    goBack() {
        const {prevStep, rawValues} = this.props;
        prevStep(rawValues);
    }

    stepSubmit(data) {          
        let formData = arrangeData(data);
        formData.release_air_date = convertDateToDb(formData.release_air_date);
        formData.proposed_free = cleanMoney(formData.proposed_free);
        if(formData.publishing_or_master_sync_used === "other") {
            delete formData.publishing_or_master_sync_used;
        } else {
            delete formData.sync_other_used;
        }
        
        return new Promise((resolve, reject) => {
            api.post('clear/sync/step/four', formData)
            .then((response) => {               
                this.props.nextStep(formData, data);
                return resolve(null);
            })
            .catch((err) => {
                if(! err.response) return;
                if(err.response.status === 422) {
                    return reject(setErrors(err.response));                            
                }            
            });
        });
    }

    render() {        
        const { handleSubmit, submitting, syncOptions, syncTerritories, radioFieldsError, savingAll } = this.props;
        const textClasses = this.props.rawValues && (this.props.rawValues.publishing_or_master_sync_used === "other") ?
             "addition-field addition-field_show" : "addition-field addition-field_hide";  
        return (
            <section className="upload-form__sec" style={this.props.bgImg}>
                <div className="content">
                    <div className="upload-form__top">  
                        <h2 className="head-shadow">CLEAR SYNC</h2>
                        <h3 className="head">
                            Step 4 of 4: Release Info
                        </h3>                       
                    </div>
                    <div className="form-step__body">
                        <form onSubmit={handleSubmit((data) => { 
                                return this.stepSubmit(data);
                            }) } className="form-layout"
                            noValidate>
                            <div className="form-row">
                                <div className="form-col_2">
                                    <Field                            
                                        name="territory"
                                        component={SelectField}
                                        options={syncTerritories}
                                        placeholder="Select Territory"
                                        label="Territory"
                                        required={true}  
                                        note="State the geographical areas where you would like the rights to use the recording in your project."                           
                                    />                                    
                                </div>                               
                                <div className="form-col_2">
                                    <Field                            
                                        name="release_air_date"
                                        component={DatePickerField}                                       
                                        placeholder="enter MM/DD/YY"
                                        label="Release/Air Date"
                                        required={true}  
                                        note="State the date your project will be released or first aired."                           
                                    />                                 
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-col_2">
                                    <Field                            
                                        name="option"
                                        component={SelectField}
                                        options={syncOptions}
                                        placeholder="Options"
                                        label="Options"
                                        required={true} 
                                        note="If you would like the option to purchase ADDITIONAL rights in the future."                            
                                    />                                    
                                </div>                               
                                <div className="form-col_2">
                                    <Field                            
                                        name="proposed_free"
                                        component={FormField}                                        
                                        placeholder="Set Fee"
                                        label="Proposed Fee"
                                        normalize={normalizeMoney}
                                        required={true}  
                                        note="State the fee you would like to offer in order to obtain the rights you are seeking."                           
                                    />                                 
                                </div>
                            </div>
                            <div className="upload-form__checks">
                                <h5 className="label-single label-single_required">Type of use</h5>
                                    <Field                            
                                        name="publishing_or_master_sync_used"
                                        component={RadioField}                                       
                                        value="master"
                                        label="Sound Recording and Composition Used" 
                                        type="radio"                                                                  
                                    /> 
                                    <Field                            
                                        name="publishing_or_master_sync_used"
                                        component={RadioField}                                       
                                        value="publishing"
                                        label="Composition Used" 
                                        type="radio"                                                                  
                                    />
                                    <Field                            
                                        name="publishing_or_master_sync_used"
                                        component={RadioField}                                       
                                        value="other"
                                        label="Other" 
                                        type="radio"                                                                  
                                    />
                                    {
                                        radioFieldsError &&
                                        <div className="form-field__note form-field__note_other error">{ radioFieldsError }</div>
                                    }     

                            </div>
                            <div className={ textClasses }>
                                <Field                            
                                    name="sync_other_used"                                        
                                    component={WordsLimitedField}  
                                    required={true}                              
                                    maxWords={ 75 }
                                    placeholder=""
                                    label="Please explain"                                                                
                                />
                            </div>
                            <div className="submit-row submit-row_txt"> 
                                    <button className="btn btn-second" onClick={ this.goBack }
                                        type="button">Back</button>                                
                                    <button className="btn btn-primary" disabled={ submitting || savingAll }
                                        type="submit">Next</button>
                            </div>
                        </form>                        
                    </div>
                </div>
            </section>           
        )
    }
}

const UploadSyncStep4Form =  reduxForm({
    form: 'upload-sync-step4',
    enableReinitialize: true  
  })(UploadSyncStep4);

//export default UploadSyncStep4Form;
const mapState = (state) => {
    const errors = getFormSubmitErrors('upload-sync-step4')(state);
    return {
        rawValues: getFormValues('upload-sync-step4')(state),
        radioFieldsError: (errors.publishing_or_master_sync_used) ? errors.publishing_or_master_sync_used : null
    }
}
export default connect(mapState)(UploadSyncStep4Form);

 