import React, { Component } from 'react';
import {connect} from "react-redux";
import { Redirect } from "react-router-dom";
import {ROUTESLIST} from '@/static/urls.js';

function  WithAuthRedirect(WrappedComponent) {
    class HocAuthRedirect extends Component { 
        render() {  
          const {isCurrentUserAuth, isCurrentUserChecked} = this.props;
          if(isCurrentUserChecked){ 
              if(isCurrentUserAuth)  {         
                  return (
                      <Redirect to={ROUTESLIST.HOME} /> 
                  );
              } else {
                return <WrappedComponent  {...this.props} />;
              }
          } else {
             return null;
          }          
          
        }
    };
    function mapStateToProps(state) {
      return {
          isCurrentUserAuth: state.auth.isAuth,
          isCurrentUserChecked: state.auth.isChecked        
      };
  }  
  return connect(mapStateToProps, null)(HocAuthRedirect);
}

export default WithAuthRedirect;




