import React, { Component } from 'react';
import './index.scss';

const networks = {
    linkedin: 'https://www.linkedin.com/shareArticle?url=@u',
    facebook: 'https://www.facebook.com/sharer/sharer.php?u=@u',
    twitter: 'https://twitter.com/intent/tweet?&url=@u',
};

const isIOS = () => {
    return navigator.userAgent.match(/ipad|ipod|iphone/i) 
    ||  (navigator.platform.toUpperCase().indexOf('MAC') >= 0);
};

const selectTextarea = (el) => {
    if(isIOS()) {
       let  range = document.createRange();
        range.selectNodeContents(el);
        let selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        el.setSelectionRange(0, 999999);

    } else {
        el.select();
    }
};

const clipboardText = (text) => {
    let dummy = document.createElement("textarea"); 
    dummy.setAttribute('readonly', false);
    dummy.setAttribute('contenteditable', true);
    dummy.style.position = 'fixed';
    dummy.style.left = '-9999px';  
    // dummy.style.height = '0px';
    document.body.appendChild(dummy);    
    dummy.value = text;
    dummy.focus();
    selectTextarea(dummy);
    document.execCommand("copy");
    dummy.blur();
    document.body.removeChild(dummy);    
};

const getUrl = (key) => {
    const url = window.location.href;
    return networks[key].replace(/@u/g, encodeURIComponent(url));
};

export default class SocialSticky extends Component {
    constructor(props) {
        super(props);        
        this.shareHandler = this.shareHandler.bind(this);
    } 

    shareHandler(event) {        
        const key = event.currentTarget.getAttribute('data-share');
        if (! key) return false;
        const url = getUrl(key);
        window.open(
            url,
            '_blank'
        );
    }

    clipboardHandler() {
        clipboardText(window.location.href);
    }

    render() { 
        return (
            <div className="social-sticky">
                <ul className="social-sticky__inner">
                    <li className="social-sticky__item">
                        <span className="social-sticky__head">SHARE</span>
                    </li>
                    <li className="social-sticky__item">
                        <button onClick={ this.shareHandler } data-share="facebook" type="button" className="social-sticky__link">
                            <i className="as-icon as-icon_sc-facebook-2"></i>
                        </button>
                    </li>
                    <li className="social-sticky__item">
                        <button onClick={ this.shareHandler } data-share="twitter" type="button" className="social-sticky__link">
                            <i className="as-icon as-icon_sc-twitter"></i>
                        </button>
                    </li>
                    <li className="social-sticky__item">
                        <button onClick={ this.shareHandler } data-share="linkedin" type="button" className="social-sticky__link">
                            <i className="as-icon as-icon_sc-linkedin"></i>
                        </button>
                    </li>
                    <li className="social-sticky__item social-sticky__has-tooltip">
                        <button onClick={ this.clipboardHandler } type="button" className="social-sticky__link">
                            <i className="as-icon as-icon_link"></i>
                            <span className="social-sticky__tooltip">Copy Link</span>
                        </button>
                    </li>
                </ul>
            </div>
        )
    }
}
