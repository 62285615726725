import React, { Component, Fragment } from 'react';

import PayModal from './pay-modal';

export default class SingleInvoceInfo extends Component {  
    constructor(props) {
        super(props);
        this.state = {
            invoice: null,
        }
        this.openPayments = this.openPayments.bind(this);
        this.closePayments = this.closePayments.bind(this);                 
    } 

    openPayments() {        
        if(! this.props.item) return;
        const {id, number, balance, baseAmount} = this.props.item;
        this.setState({
            invoice: {
                id, number, balance, baseAmount                
            }
        });
    }
    closePayments(needUpdate) {
        //todo fetch        
        this.setState({ invoice: null,});
        if(needUpdate) {
            this.props.forceUpdate();
        }
    }
    
    render() {
        const { item } = this.props;
        if(! item ) return null;
        const { number, requests, balance, money, needPay, promo, promoValue} = item;
        const { invoice } = this.state;
        return (
            <div> 
            <h3 className="dashboard-header dashboard-header_small ">
                Orders #{ number }                
            </h3> 
            <div className="dashboard-table__wrap">
                <table className="dashboard-table">
                    <thead className="dashboard-table">
                        <tr>
                            <th>
                                Sampled Song  
                            </th>                                
                            <th>
                                Phase
                            </th>
                            <th>
                                UNIT PRICE
                            </th>                            
                        </tr>
                    </thead>
                    <tbody> 
                        {
                            requests.map((item) => {
                                const {id, price, title, artist, phase, note} = item;   
                                if(note )  {
                                    return  (
                                        <Fragment key={id}>
                                            <tr className="dashboard-table__card-top">
                                                <td>
                                                    <div className="text-rows">
                                                        <h6 className="text-rows__title">{ title }</h6>
                                                        <p className="text-rows__sub">{ artist }</p>
                                                    </div>
                                                </td>
                                                <td>{ phase }</td>
                                                <td className="db-price-col">$ { price }</td>                                       
                                            </tr>
                                            <tr className="dashboard-table__card-bottom">
                                                <td colSpan="3">
                                                    <div className="note-text">
                                                       <span class="note-text__label">Notes: </span> { note }
                                                    </div>                                                    
                                                </td>
                                            </tr>
                                        </Fragment>
                                    );
                                }                          
                                else {
                                    return (
                                        <tr key={id} className="dashboard-table__card">
                                            <td>
                                                <div className="text-rows">
                                                    <h6 className="text-rows__title">{ title }</h6>
                                                    <p className="text-rows__sub">{ artist }</p>
                                                </div>
                                            </td>
                                            <td>{ phase }</td>
                                            <td className="db-price-col">$ { price }</td>                                       
                                        </tr>
                                    );
                                }
                            })
                        }           
                    </tbody>
                </table>                                    
            </div>
            <div className="dashboard-right-table__wrap">
                <table  className="dashboard-right-table">
                    <tbody>
                        {
                            money.map((item) => {
                                const {id, label, value} = item;
                                return (
                                    <tr key={id}>
                                        <td>{ label }</td>
                                        <td className="db-price-col">{ value }</td>                                        
                                    </tr>
                                );
                            })
                        }
                        {
                            promo &&
                            <tr>
                                <td>Used Promo Code</td>
                                <td className="db-price-col">
                                    { promo } <span className="db-price-green">(${ promoValue })</span>
                                </td>                                        
                            </tr>
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            { 
                                needPay ?
                                <td>Balance Due</td>
                                :<td>Balance Paid</td>
                            }                            
                            <td className="db-price-col">
                                $ { balance }
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div> 
            {
                needPay &&
                <div className="invoice-info-btns">
                    <button type="button" className="btn btn-primary" onClick={this.openPayments} >Go To Payment</button>
                </div>  

            }
            <PayModal invoice={ invoice } onClose={ this.closePayments }
            />         
        </div>        
        )
    }
}

