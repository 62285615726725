import React, { Component } from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import api, { setErrors, arrangeData} from '@/middlewares/api';

import SelectField from '@/components/forms/select-field';
import DurationPickerField from '@/components/forms/duration-picker';

class UploadSyncStep3 extends Component {

    constructor(props) {
        super(props);
        this.stepSubmit = this.stepSubmit.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    goBack() {
        const {prevStep, rawValues} = this.props;
        prevStep(rawValues);
    }    

    stepSubmit(data) {       
        const formData = arrangeData(data);
        return new Promise((resolve, reject) => {
            api.post('clear/sync/step/three', formData)
            .then((response) => {               
                this.props.nextStep(formData, data);
                return resolve(null);
            })
            .catch((err) => {
                if(! err.response) return;
                if(err.response.status === 422) {
                    return reject(setErrors(err.response));                            
                }            
            });
        });
    }
//     category
// media
// featured_background
// length_use
// term



    render() {        
        const { handleSubmit, submitting, syncCategories, syncMedias, syncFeaturedBackground, 
            syncTerms } = this.props;
        return (
            <section className="upload-form__sec" style={this.props.bgImg}>
                <div className="content">
                    <div className="upload-form__top">  
                        <h2 className="head-shadow">CLEAR SYNC</h2>
                        <h3 className="head">
                            Step 3 of 4: Using Info
                        </h3>                       
                    </div>
                    <div className="form-step__body">
                        <form onSubmit={handleSubmit((data) => { 
                                return this.stepSubmit(data);
                            }) } className="form-layout"
                            noValidate>
                            <div className="form-row">
                                <div className="form-col_2">
                                    <Field                            
                                        name="category"
                                        component={SelectField}
                                        options={ syncCategories }
                                        required={true} 
                                        placeholder="Select Category"
                                        label="Category of Use"
                                        note="What type of production is your project? (Commercial, Trailer, Film, Video Game, TV Show, Social Media, etc.)"                            
                                    />                                    
                                </div>                               
                                <div className="form-col_2">
                                    <Field                            
                                        name="media"
                                        required={true} 
                                        component={SelectField}
                                        options={ syncMedias }
                                        placeholder="Select Media"
                                        label="Media" 
                                        note="Media channels through which you wish to secure rights to release your project"                           
                                    />                                 
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-col_2">
                                    <Field                            
                                        name="featured_background"
                                        component={SelectField}
                                        required={true} 
                                        options={ syncFeaturedBackground }
                                        placeholder="Select"
                                        label="Featured/Background"
                                        note="Describe the way in which the song will be presented."                            
                                    />    
                                    
                                </div>                               
                                <div className="form-col_2">
                                    <Field                            
                                        name="length_use"
                                        component={DurationPickerField}                                        
                                        type="text"
                                        required={true}                                        
                                        label="Length of Master Recording / Composition Use"
                                        note="State the length of the recording use in your project."                            
                                    />                                    
                                </div>
                            </div>
                            <Field                            
                                name="term"
                                component={SelectField}
                                options={ syncTerms }
                                placeholder="Select"
                                label="Term"
                                required={true} 
                                note="State how long you would like the rights to use the master recording in your project. NOTE: Rights in perpetuity allow the licensee to use the song in the project forever; HOWEVER, rights in perpetuity usually garner higher fees."                            
                            />    
                            <div className="submit-row submit-row_txt">  
                                    <button className="btn btn-second" onClick={ this.goBack }
                                        type="button">Back</button>                                 
                                    <button className="btn btn-primary" disabled={ submitting }
                                        type="submit">Next</button>
                            </div>
                        </form>                        
                    </div>
                </div>
            </section>           
        )
    }
}

const UploadSyncStep3Form =  reduxForm({
    form: 'upload-sync-step3',
    enableReinitialize: true     
  })(UploadSyncStep3);

//export default UploadSyncStep3Form;

const mapState = (state) => {
    return {
        rawValues: getFormValues('upload-sync-step3')(state)
    }
}
export default connect(mapState)(UploadSyncStep3Form);