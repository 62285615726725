import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';

import api, { setErrors } from '@/middlewares/api';
import { ROUTESLIST } from '@/static/urls';

import FormField from '@/components/forms/form-field';
import PasswordField from '@/components/forms/password-field';

class ResetPasswordFormComponent extends Component {
    constructor(props) {
        super(props);        
        this.submitForm = this.submitForm.bind(this);       
    }
    submitForm(data) {        
        const {token, hideForm } = this.props;
        let server = Object.assign({}, data);        
        server.token = token;
        return new Promise((resolve, reject) => {
            api.post('reset-password', server)
            .then((response) => {
                hideForm();
                return resolve(null);                 
            })
            .catch((err) => {
                if(! err.response) return;
                if((err.response.status === 422) || ((err.response.status === 404))) {
                    return reject(setErrors(err.response));                            
                }            
            })
        });        
    }       
    render() {
        const { handleSubmit, pristine, submitting } = this.props;
        return (
            <section className="form-section">
            <div className="content-tight">
                <h3 className="head-middle">Reset Your Password</h3>
                <form onSubmit={ handleSubmit((data) => { 
                        return this.submitForm(data);
                    }) } 
                    className="login-form" noValidate >
                    <Field                            
                        name="email"
                        component={ FormField }
                        type="email"                                
                        label="Email"                            
                    />
                    <Field                            
                        name="password"
                        component={ PasswordField }                                                            
                        label="Password"                            
                    />
                    <Field                            
                        name="password_confirmation"
                        component={ PasswordField }                                                  
                        label="Confirm Password"                            
                    />      
                   
                    <div className="form-submit-row">
                        <button className="btn btn-primary btn-fw" disabled={ pristine || submitting }
                            type="submit">Send</button> 
                    </div>
                </form>    
                <div className="form-field center">
                     <Link to={ ROUTESLIST.LOGIN } className="link">Login</Link> 
                </div>
            </div>
        </section>   
        )
    }
}

const ResetPasswordForm =  reduxForm({
    form: 'reset-password-form',
    enableReinitialize: true 

  })( ResetPasswordFormComponent);

export default ResetPasswordForm;
