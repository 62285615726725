import React, { Component } from 'react'

export default class ContactPage extends Component {
    render() {
        return (
            <main className="page">
                <div className="content">
                    <h2 className="head-shadow">Contact Us</h2>
                    <h3 className="head">Not found</h3>
                </div>
            </main>
        )
    }
}
