import React, { Component } from 'react';
import { connect } from 'react-redux';
import api from '@/middlewares/api';
import { showError } from '@/actions/notification';

class AddCommentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
            submited: false
        };
        this.textChange = this.textChange.bind(this);        
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    textChange(event) {
        const val = event.target.value;
        this.setState({
            text: val           
        });
    }
    handleSubmit(event) {
        event.preventDefault();
        const comment = this.state.text;
        if(! comment ) return;
        this.setState({ submited: true }) 
        const { orderId, onSend, showNotification } = this.props;
        api.post('user/dashboard/order/add-comment',
        {
            comment,
            order_id: orderId
        })
        .then((response) => { console.log('add comment', response.data);
            this.setState({
                text: '',
                submited: false
            });
            onSend(); 
        })
        .catch((err) => {
            showNotification(showError, 'OOPS!',
                (err.response) ? err.response.data.message : ''
            );
            console.log(err);        
        });
        return false; 
    }

    render() {
        const { text, submited} = this.state;
        const { userAvatar } = this.props;
        return (
            <div className="comment-form__block">
                <figure className="comment-form__avatar">
                    <img src={ userAvatar } alt="" />
                </figure>
                <form onSubmit={this.handleSubmit} className="comment-form">
                    <textarea name="comment" value={text} 
                        className="comment-form__input"
                        onChange={this.textChange}                   
                        placeholder="Write your comment here"></textarea>
                    <button type="submit" className="comment-form__btn" disabled={ submited || (! text)} >Send</button>
                </form>
            </div>    
        )
    }
}

function mapStateToProps(state) {
    return {        
        userAvatar: (state.auth.user) ? state.auth.user.avatar: null
    };
}
function mapDispatchToProps(dispatch) {
    return {        
        showNotification: (callback, msg, title) => {
            return dispatch( callback(msg, title ) );
        }       
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddCommentForm);