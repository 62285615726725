import React, { Component } from 'react'

export default class SampleTermsPanel extends Component {

    render() {
        const { terms } = this.props;
        if(! terms) return null;
        const { quotes, licensors } = terms;
        return (
            <div>
                <h3 className="dashboard-header dashboard-header_small">TERMS of CLEARANCE</h3>
                {
                    quotes.map((item) => {
                        const { id, title, body} = item;
                        return (
                            <section className="db-item-info" key={id}>
                                <h4>{title}</h4>
                                {
                                    body && 
                                    <div dangerouslySetInnerHTML={{
                                        __html: body
                                      }}></div>
                                }
                            </section>
                        );
                    })
                }
                <section className="db-item-info db-item-info_terms" >
                    <h4>Final Documents Received</h4>
                    {
                        licensors.map((item) => {
                            const {id, name, files} = item;
                            return (
                                <div key={id} className="licensors__block"> 
                                    <div className="licensors__name">{name}</div>
                                    <ul className="licensors__files">
                                        {
                                            files.map((file) => {
                                                const {id, name, link, date} = file;
                                                return (
                                                    <li key={id}>
                                                        <span className="licensors__file-icon"><i className="as-icon as-icon_file-pdf"></i></span>
                                                        <span className="licensors__file-name">{name}</span>
                                                        <span className="licensors__file-date">{date}</span>
                                                        <span className="licensors__file-link"><a href={link} download={name}><i className="as-icon as-icon_download-light"></i></a></span>
                                                    </li>
                                                );
                                            })
                                        }                                    
                                    </ul>
                                </div>
                            );
                        })
                    }
                </section>
               
            </div>
            
        )
    }
}
