// common function for autocomplete fields
import React from 'react';
import api from '@/middlewares/api';
import emptyAvatar from '@/assets/images/default-avatar.png';

// for one { getOptionLabel, openList, closeList, getOptions, changeInputHandler, changeHandler, OneBlurHandler}
//
//for many { getOptionLabel, openList, closeList, getOptions, changeInputHandler, changeHandler,  componentDidMountPlaceholder, componentDidUpdatePlaceholder}

export const getOptionLabel = (option) => {
    if(typeof option === "string") {
        return option;
    } else if( option.id) {
        return option.name;
    }
    return '';
}
export const openList = function() { 
    this.setState({open: true});
};

export const closeList = function() {
    this.setState({
        open: false,
        options: []
    });
}
export const getOptions = function(query)  {
    if (typeof this.state.processedQuery !== "undefined") this.setState({processedQuery: query}); 
    const url = this.props.url + '?query=' +  encodeURI(query);
    api.get(url)
    .then((data) => { 
        if (data.data.length)  { 
            let list = data.data;
            list.sort((a, b) => {
                if(a.name < b.name) { return -1; }
                if(a.name > b.name) { return 1; }
                return 0;
            });              
            this.setState({
                options: list
            });
        } else {
            this.closeList();
        } 
        if (typeof this.state.processedQuery !== "undefined") this.setState({processedQuery: ''});
    })
    .catch((err) => {
        console.log(err);
        this.closeList();
        if (typeof this.state.processedQuery !== "undefined") this.setState({processedQuery: ''});
    });
}   
export const changeInputHandler = function(event) {  
    if (event.target.value !== "" && event.target.value !== null) {           
        this.getOptionsDebonced(event.target.value);
    }       
}

export const changeHandler = function(event, value) { 
    this.props.input.onChange(value);       
}

export const  OneBlurHandler = function(event) {
    if((! this.props.input.value) && (event.target.value)) {
        this.props.input.onChange(event.target.value);
    } else if((typeof this.props.input.value === 'string') && 
            (this.props.input.value !== event.target.value)) {
        this.props.input.onChange(event.target.value);       
    }      
}
// onlyMany

export const componentDidMountPlaceholder = function() {       
    if((! this.props.input.value) || (! this.props.input.value.length)) {
        if(this.props.placeholder) {
            this.setState({
                currentPlaceholder: this.props.placeholder
            });
        }
    } 
}
export const componentDidUpdatePlaceholder = function (prevProps) {
    if(this.props.input.value !== prevProps.input.value ) {
        let placeholder = null;
        if((! this.props.input.value) || (! this.props.input.value.length)) {
            if(this.props.placeholder) {
                placeholder = this.props.placeholder;
            }
        } 
        if( placeholder !== this.state.currentPlaceholder) {
            this.setState({
                currentPlaceholder: placeholder
            });
        }
    }        
}
//only with images

export const getImgMenuOption = function(opt) { 
    const avatar = (opt.image_url) ? opt.image_url : emptyAvatar;
    const name = opt.name;
    return (
        <div className="img-opt">
            <img className="img-opt__img" src={ avatar } alt="" />
            <span className="img-opt__label">{ name }</span>
        </div>
    )
}
