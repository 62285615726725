import moment from 'moment';
import { StatusesClassesMap } from '@/static/statuses.js';
import { formatMoney } from '@/utils/common';


const isNeedPay = (status) => {
    const st = status.toLowerCase();
    switch (st) {
        case 'paid': 
        return false;
        default: 
        return true;
    }
}

export const extractInvoceCardData = (data) => {
    const {amount, id, date, status, units, balance_due	} = data;
    const status_class = (StatusesClassesMap[status]) ? 
        StatusesClassesMap[status] : "";
    let frontDate = new moment(date);
    const number = '#' + id;
    const needPay = isNeedPay(status);
    return {
        date: frontDate.format('DD MMM YYYY'),
        id, 
        amount,
        status,
        balance_due,	
        units,
        status_class,
        number,
        needPay        
    }
}

/* eslint-disable eqeqeq */
const getPhase = (obj) => {
    const {master_sampled_used, publishing_sampled_used, phase} = obj;
    let str;
    if (master_sampled_used && publishing_sampled_used) {
        str = 'Mixed Use';
    } else if (master_sampled_used) {
        str = 'Master Use';        
    } else {
        str = 'Publishing Use';        
    }
    if (phase.trim().toLowerCase() == 'first half') {
        str += ' - Phase 1';
    } else {
        str += ' - Phase 2';
    }
    return str;
}

const getPhaseSync = (obj) => {
    const {publishing_master_other, phase} = obj;
    let str;
    if (publishing_master_other === 'master') {        
        str = 'Master Use';   
    } else if (publishing_master_other === 'publishing') {
        str = 'Publishing Use';    
    } else {
        str = 'Other';        
    }
    if (phase.trim().toLowerCase() == 'first half') {
        str += ' - Phase 1';
    } else {
        str += ' - Phase 2';
    }
    //if (phase) {
    //    str += ' - ' + phase;
    //} 

    // if (! phase) {
    //     str += ' - Phase 1';
    // } else {
    //     str += ' - Phase 2';
    // }
    return str;
}
/* eslint-enable eqeqeq */

const arangeRequests = (requests) => {
    let result = [];
    if(! Array.isArray(requests)) return result;
    requests.forEach((item) => {
        const {id, price, sample, sync, note} = item;
        let r = {id, 
            price: formatMoney(price, true), 
            type: null,
            note
        };
        ;
        if (sample) {
            r.type = 'sample';
            r.title = sample.sampled_name;
            r.artist = sample.artist_name;
            r.phaseOr = sample.phase;
            r.phase = getPhase(sample);
        }
        if(sync) {
            r.type = 'sync';
            r.title = sync.track_title;
            r.artist = '';
            r.phaseOr = sync.phase;
            r.phase = getPhaseSync(sync);
        }
        //todo sync
        result.push(r);
    });
    return result;
}
const extractInvoceMoney = (item) => { 
    const list = [
        {label: "SUBTOTAL", server: "subtotal", prefix: "$ "},
        {label: "DISCOUNT", server: "discount", prefix: "$ "},
        {label: "SUBTOTAL LESS DISCOUNT", server: "subtotal_less_discount", prefix: "$ "},
        {label: "TAX RATE %", server: "tax_rate", prefix: "% "},
        {label: "TOTAL TAX", server: "total_tax", prefix: "$ "},
        {label: "SHIPPING/HANDLING", server: "shipping_handling", prefix: "$ "},
    ];
    let res = [];
    list.forEach((el, index) => {
        if(typeof item[el.server] != "undefined") {
            res.push({
                id: 'i_' + index,
                label: el.label,
                value: (el.prefix) ? el.prefix + item[el.server] : item[el.server]
            })
        }
    });
    return res;
}


export const arrangeInvoceData = (data) => { 
    let promoValue = 0;
    if (data.used_code) {
        promoValue = data.subtotal_less_discount + data.total_tax + data.shipping_handling
            - data.balance_due;
        promoValue = formatMoney(promoValue, true);        
    }
   
    //money format  
    data.subtotal = formatMoney(data.subtotal, true);
    data.discount = formatMoney(data.discount, true);
    data.subtotal_less_discount = formatMoney(data.subtotal_less_discount, true);
    data.shipping_handling = formatMoney(data.shipping_handling, true);
    data.total_tax = formatMoney(data.total_tax, true);
    if( !((typeof data.tax_rate == "string") || (typeof data.tax_rate == "number"))) {
        data.tax_rate = 0;
    }
    
    const {id, requests, balance_due, status, used_code} = data;    
    return {
        id,
        number: id,
        requests: arangeRequests(requests),
        balance: formatMoney(balance_due, true),
        money: extractInvoceMoney(data),
        needPay: isNeedPay(status),
        promo: used_code,
        promoValue: promoValue,
        baseAmount:  data.subtotal_less_discount

    };
}

export const arrangeCommentData = (data) => {
    const { created_at } = data;
    const date = new moment(created_at);
    data.writtenAt = (date.isValid()) ? date.format('DD MMM YYYY [at ] h:mm A') : "";
    return data;
}