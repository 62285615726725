import { createRef } from 'react';

export const scrollTopHandler = function() {   
    this.scrollBottomElem.current.scrollLeft = this.scrollTopElem.current.scrollLeft;    
}

export const scrollBottomHandler = function() {    
    this.scrollTopElem.current.scrollLeft = this.scrollBottomElem.current.scrollLeft;    
}

export const initScrollRefs = (_this) => { 
    _this.scrollTopElem = createRef();
    _this.scrollBottomElem = createRef();
    _this.scrollTopHandler = scrollTopHandler.bind(_this);
    _this.scrollBottomHandler = scrollBottomHandler.bind(_this);   
}