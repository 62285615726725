import React, { Component } from 'react';

import api from '@/middlewares/api';
import CrateCard from '@/components/crate/card';
import Loader from '@/components/loader/index';

import { extractDataForCommonCard } from '@/utils/crates.js';

export default class LatestCratesBlock extends Component {
    constructor(props) {
        super(props);  
        this.state = {
            items: [],
            isLoaded: false
        }               
    } 

    componentDidMount() {
        this.fetchCrates();
    }

    fetchCrates() {
        const { limit } = this.props;
        api.get('crate/latest-additions')
        .then((response) => {  
            if(response.data && Array.isArray(response.data)) {
                let data = response.data;
                if (limit) {
                    data = data.slice(0, limit);
                }
                let items = data.map((item) => {
                    const result = extractDataForCommonCard(item);
                    return result;
                })

                this.setState({
                    items: items,
                    isLoaded: true
                });
                //console.log('last', response.data);
            } else {
                this.setState({
                    items: [],
                    isLoaded: true
                });
            } 
        })
        .catch((err) => {
            console.log(err.response);        
        });
    }
    
    render() {
        const { items, isLoaded } = this.state;
        return (
            <div className="crates-blc"> 
                <Loader isOpen={ ! isLoaded } type="inner" />
                {
                    items.map((item) => 
                        <CrateCard 
                            key={ item.id }
                            item={ item }
                        />
                    )
                }               
            </div>   
        )
    }
}