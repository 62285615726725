import React from 'react';
import PropTypes from 'prop-types';

export default function ModalRadioBtn(props) {
    const { icon, value, currentValue, name, title, note, onChange } = props;
    return (
        <div className="modal-radio__wrap">
        <label className="modal-radio">
            <input type="radio" value={value} name={name} checked={ value === currentValue} onChange={ () => onChange(value) } />
            <span className="modal-radio__check">
            </span>
            <span className="modal-radio__label">
                <span className="modal-radio__name">
                    <i className={icon}></i>
                    { title }
                </span>
                <span className="modal-radio__note">
                    { note }
                </span>
            </span>
        </label>
        </div>
    )
}

ModalRadioBtn.propTypes = {
    icon:  PropTypes.string,
    value:  PropTypes.string,
    currentValue:  PropTypes.string,
    title:  PropTypes.string,
    note:  PropTypes.string, 
    onChange:  PropTypes.func.isRequired,
    name: PropTypes.string,
};
