import api from '@/middlewares/api';
import {loadStripe} from '@stripe/stripe-js';
import { cardsIcons } from '@/static/payments.js';

export const AC_PAYMENT = {
    SET_STRIPE_KEY: "AC_PAYMENT_SET_STRIPE_KEY",
    FLUSH_PAYMENTS: "AC_PAYMENT_FLUSH_PAYMENTS",
    SET_PAYMENTS: "AC_PAYMENT_SET_PAYMENTS" 
};

let isLoadedStripe = false;
let stripe;

export const getStripePromise = (key) => {
    if(! isLoadedStripe) {
        stripe = loadStripe(key);
        isLoadedStripe = true;
    }
    return stripe;
};

export const fetchStripeKey = () => {    
    // const key = "pk_test_TYooMQauvdEDq54NiTphI7jx";    
    return (dispatch) => {  
        
        return api.get('user/payment-get-data')           
            .then((response) => {
                console.log(response);
                dispatch({
                    type: AC_PAYMENT.SET_STRIPE_KEY,
                    payload: response.data.stripe_public_key,
                });   
            })
            .catch((err) => {  
                console.log('stripe problem', err.response);
            });            
    };
}

export const fetchPaymentMethods = () => {
    return (dispatch) => {  
        return api.post('user/get-payment-methods')
        .then((response) => {               
            console.log('methods', response.data);
            if(! response.data) return;
            let updated = {
                defaultMethodId: (response.data.default_payment_method_id) ?
                    response.data.default_payment_method_id : null  
            };
            let methods = [];
            if(response.data.payment_methods && Array.isArray(response.data.payment_methods)) {
                response.data.payment_methods.forEach((item) => {
                    item.isCard = true;
                    item.meta = (cardsIcons[item.card.brand]) ? cardsIcons[item.card.brand] : cardsIcons.default;
                    item.isDefault = (item.id === updated.defaultMethodId);
                    item.label = `${item.meta.name} ending in ${item.card.last4}`;
                    methods.push(item);
                });
            }
            updated.methods = methods;
            console.log('payments methods', updated);
            dispatch({
                type: AC_PAYMENT.SET_PAYMENTS,
                payload: updated,
            });           
        });
    };
}

export const flushPaymentMethods = () => {
    return {
        type: AC_PAYMENT.FLUSH_PAYMENTS
    }
}