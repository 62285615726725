import React, { Component } from 'react';
import './index.scss';

import { items, videos } from './data.js';
//import { ROUTESLIST } from '@/static/urls';
import bgImg from './crates.jpg';
import albomImg from './albom_sm.png';

import  AlbomItem from '@/components/albom/item';
import VideoCard from '@/components/video/card';

export default class AlbomPage extends Component {
    render() {
        const secStyle = { backgroundImage: "url(" +  bgImg  + ")"};
        return (
            <main className="page">
                <section className="entity-sec" style={ secStyle }>
                    <div className="content">
                        <div className="entity-sec__top">                            
                                <h2 className="head-shadow">Jay-Z</h2>
                                <h3 className="head">
                                    the blueprint 3
                                </h3>
                                <div className="entity-sec__top-desc">
                                The Blueprint 3 is the eleventh studio album by American rapper Jay-Z, 
                                released September 8, 2009, on Roc Nation, through distribution from 
                                Asylum Records & Atlantic Records. It is the third Blueprint album, 
                                preceded by The Blueprint (2001) and The Blueprint2: The Gift & 
                                The Curse (2002). Production for the album took place during 2008 
                                to 2009 at several recording studios and was handled by Kanye West, 
                                No I.D., The Neptunes, Jeff Bhasker, Al Shux, Jerome "J-Roc" Harmon, 
                                The Inkredibles, Swizz Beatz, and Timbaland.
                                </div>
                        </div>
                        <div className="albom-article"> 
                            <div className="albom-article__col albom-article__col_img">
                                <div className="albom-article__img">
                                    <img src={ albomImg } alt="" />
                                </div>
                                <div className="albom-article__labels">
                                    <span className="labels-title">Label:</span>
                                    <span className="label">Sony</span>
                                </div>
                            </div>
                            <div className="albom-article__col albom-article__col_info">
                                <div className="albom-items">
                                    {items.map((item) =>
                                        <AlbomItem key={ item.id }                                       
                                            id={item.id}
                                            number={item.number}
                                            title={item.title}
                                            duration={item.duration}
                                            />
                                    )} 
                                </div>   
                            </div>
                        </div>
                    </div>
                </section>
                <section className="related-sec">
                    <div className="content">
                        <h3 className="related-sec__title">Reated Music Videos</h3>
                        <div className="related-sec__items">
                            <div className="videos-blc">
                                        {videos.map((item) =>
                                            <VideoCard key={ item.id }
                                                id={item.id}
                                                src={item.src}
                                                title={item.title}
                                                author={item.author}
                                                desc={item.desc}
                                                    />                                               
                                        )} 
                                </div>
                            </div>
                    </div>
                </section>              
            </main>
        )
    }
}
