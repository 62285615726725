import React, { Component } from 'react';

import { stopEnterSubmit } from '@/utils/forms.js';
import FormFieldBottom from './field-bottom';

export default class FormField extends Component {
    render() {
        const { label, type, input, placeholder, required, meta, note, correct} = this.props;
        
        const classes = (meta.error) ? "form-field error" : "form-field";        
        return (
            <div className={ classes }>
                <label className={ (required) ? "label label_required" : "label"}>{ label }</label>
                <input {...input} 
                    placeholder={placeholder}
                    type={type} 
                    onKeyDown={ stopEnterSubmit }
                    className="input" />
                <FormFieldBottom 
                    meta={meta}
                    note={note}
                    correct={correct}
                />    
            </div>
        )
    }
}
