import React, { Component } from 'react';
import {connect} from "react-redux";
import { Redirect } from "react-router-dom";
import {ROUTESLIST} from '@/static/urls.js';
import api from '@/middlewares/api';

import Loader from '@/components/loader/index';
import NotFoundBlock from '@/components/blocks/not-found';
import logoImg from '@/assets/images/logo-slogan.png';
import BgImg from '@/assets/images/pages/pricing.jpg';

class ClosedPage extends Component {
    constructor(props) {
        super(props);          
        this.state = {
            isLoaded: false,
            html: null,
            isFound: true,            
        }
    }

    componentDidMount() {
        //if (this.props.isAuth) {
            this.fetchPage();
        //}
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.slug !== prevProps.match.params.slug) {
        // if ((prevProps.isAuth !== this.props.isAuth) || 
        //     (this.props.match.params.slug !== prevProps.match.params.slug)) {
            this.fetchPage();
        }
    }

    fetchPage() {        
        const slug = this.props.match.params.slug;
        this.setState({
            isLoaded: false,
            html: null,   
        });
        api.get('page/auth/' + slug)
        .then((response) => {             
            this.setState({
                isLoaded: true,
                isFound: true, 
                html: response.data,                        
            });
        })
        .catch((err) => {
            /* eslint-disable eqeqeq */
            if (err.response) {
                const code = err.response.code || err.response.status;
                if (code == 404) {
                    this.setState({
                        isLoaded: true,
                        isFound: false,                        
                    });
                }
            } 
            /* eslint-enable eqeqeq */                   
        });
    }

    render() {
        // const { needRedirect } = this.props;
        // if ( needRedirect ) return this.renderRedirect();
        // else return this.renderPage(); 
        return this.renderPage();         
    }

    renderRedirect() {
        const url = this.props.match.url;
        return (
            <Redirect to={{ 
                    pathname: ROUTESLIST.LOGIN, 
                    state: { redirectUrl:  url}
                }}
            /> 
        )
    };

    renderPage() { 
        const {isLoaded, html, isFound}  = this.state; 
        const inner = (isFound) ? this.renderInner(html) : (<NotFoundBlock/>);
        return (
            <main className="closed-page">
                <div className="content">
                    <Loader isOpen={ ! isLoaded } type="inner" />                 
                </div>
                { inner }
            </main>
        );
    }

    renderInner(html) {
        if (! html) return null;
        const slug = this.props.match.params.slug;
        if (slug == 'corporate-terms-and-conditions') {
            const style = {backgroundImage: `url(${BgImg})`}            
            return (
                <>
                    <div className="content">
                        <div className="closed-page__inner">
                            <div className="closed-page__img">
                                <img src={logoImg} alt="Ample Samples" />
                            </div>                            
                        </div>
                    </div>
                    <div className="closed-page__bg" style={ style }>
                        <div className="content">
                            <div className="closed-page__body"
                                dangerouslySetInnerHTML={{
                                    __html: html
                                }}>                    
                            </div>
                        </div>
                    </div>
                </>
            )

        } else {
            return (
                <div className="content">
                    <div className="closed-page__inner">
                        <div className="closed-page__img">
                            <img src={logoImg} alt="Ample Samples" />
                        </div>
                        <div className="closed-page__body"
                            dangerouslySetInnerHTML={{
                                __html: html
                            }}>                    
                        </div>
                    </div>
                </div>
            )
        }
        
    }
}

function mapStateToProps(state) {
    return {
        needRedirect: (state.auth.isChecked && (!state.auth.isAuth)) ? true: false, 
        isAuth: (state.auth.isChecked && state.auth.isAuth)                   
    };
}

export default connect(mapStateToProps, null)(ClosedPage);