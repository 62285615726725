import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './card.scss';

export default class ArtistCard extends Component {
    render() {
        const {id, image, name, tags } = this.props; 
        const url =  '/artist';      
        return (
            <div className="artist-card">
                <div className="artist-card__content">
                    <Link to={ url } className="artist-card__img">
                        <img src={image} alt={ name } />                        
                    </Link>                    
                    <div className="artist-card__info">                    
                        <Link to={ url } className="artist-card__name">
                            { name }
                        </Link>
                        <div className="artist-card__tags">
                           { tags }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
