import api from '@/middlewares/api';

export const AC_SELECTS = {
    UPLOAD: "AC_SELECTS__UPLOAD",
    EMPTY: "AC_SELECTS__EMPTY",      
};

export const STORE_SELECTS_KEYS = {
    SYNC: "STORE_SELECTS_SYNC",
    CRATES: "STORE_SELECTS_CRATES",
};

const modules = {
    [STORE_SELECTS_KEYS.SYNC] : {
        url : "data/clear-samples",
        options : {
            "syncCategories" : "categories",
            "syncMedias" : "medias",
            "syncFeaturedBackground" : "featured_background",
            "syncTerms" : "terms",
            "syncOptions" : "options",
            "syncTerritories" : "territories"
        }
    },
    [STORE_SELECTS_KEYS.CRATES] : {
        url : "data/crate",
        options : {
            "crateGenres" : "genres",            
        }
    }
};
//data/crates

const extractData = (data) => {
    if((! data) || (typeof data !== 'object')) return [];
    if(Array.isArray(data) ) return data;
    let result = [];
    Object.keys(data).forEach((key) => {
        result.push({
            id: key,
            name: data[key],
        });
    });
    return result;
};


export const fetchSelects = (selectsKey) => {
    const module = modules[selectsKey];
    if(! module) return { type: AC_SELECTS.EMPTY };
    return (dispatch) => {        
        return api.get( module.url )           
            .then((response) => {
                console.log(selectsKey, response.data);
                let payload = {};
                Object.keys(module.options).forEach((key) => {
                    payload[key] = extractData(response.data[module.options[key]]);
                });
                dispatch({
                    type: AC_SELECTS.UPLOAD,
                    payload: payload,
                    key: selectsKey,
                });
            })
            .catch((err) => {  console.log(err);                
            });            
    };
}