import React from 'react';
import { CSSTransition } from 'react-transition-group';

import './index.scss';
import loaderImg from './loader.png';
import PropTypes from 'prop-types';

const Loader = (props) => {
    const {type, isOpen} = props;
    const nodeRef = React.useRef(null)      
    if(type === "inner") {
        return (            
            <CSSTransition in={isOpen} timeout={300} unmountOnExit nodeRef={nodeRef}
            classNames="loading">
                <div className="as-loader-part" ref={nodeRef}>
                    <img src={ loaderImg} alt="loading" />
                </div>
            </CSSTransition>
        )
    } 
    return (            
        <CSSTransition in={isOpen} timeout={300} unmountOnExit nodeRef={nodeRef}
        classNames="loading">
            <div className="as-loader-full" ref={nodeRef}>
                <img src={ loaderImg} alt="loading" />
            </div>
        </CSSTransition>
    )
};

Loader.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    type: PropTypes.oneOf(['inner', 'full'])
};
Loader.defaultProps = {
    type: 'full'
};

export default Loader;