import React, { Component } from 'react';
import { Select, MenuItem }   from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import FormFieldBottom from './field-bottom';

const itemClasses = {
    select : {
        root: 'field-select field-multi-select',        
        //selectMenu: 'filter-select__opts',
    },   
    menu: {
        classes: {
            paper: 'field-select__opts-wrap',
            list: 'field-select__opts',
        }
    }
    
}

export default class MultiSelectField extends Component {

    constructor(props) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this);
        
    }

    /* eslint-disable eqeqeq */
    changeHandler(event) {
        console.log(event.target.value);
        let values = event.target.value.filter((el) => { 
            return (el != '');
        });;        
        if(values.length == 0) {
            values.push('');
        }
        this.props.input.onChange(values);       
    }

    /* eslint-enable eqeqeq */   

    render() {
        const { label, input, placeholder, required, meta, options, note, correct } = this.props
       
        const classes = (meta.error) ? "form-field form-select error" : "form-field form-select";        
        
        return (
            <div className={ classes }>
                <label className={ (required) ? "label label_required" : "label"}>{ label }</label>                             
                <Select classes={itemClasses.select}                                                        
                    {...input}
                    value={input.value || [''] }
                    variant="outlined"                    
                    MenuProps={itemClasses.menu}
                    IconComponent={KeyboardArrowDownIcon}
                    multiple={true} 
                    onChange={this.changeHandler} 
                    displayEmpty={true}              
                    >
                    <MenuItem value="">
                        { placeholder }
                    </MenuItem>
                    {
                        options.map((item) => 
                            <MenuItem 
                            key={item.id}
                            value={item.id}>{item.name}</MenuItem>
                        )
                    }                    
                </Select>
                <FormFieldBottom 
                    meta={meta}
                    note={note}
                    correct={correct}
                />    
            </div>
        )
    }

}