import React, { Component } from 'react';
import { stopSubmit } from 'redux-form';

import DragAndDrop from './drag-and-drop';
import FormFieldBottom from './field-bottom';

const allowedTypes = ["image/png", "image/jpeg"];
const allowedTypesString = allowedTypes.join(', ');

export default class ImgUpload extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleDropedFile = this.handleDropedFile.bind(this);
        this.state = {fileName: ''};
    }
    
    handleChange(ev) { 
        if(ev.target.files && (ev.target.files.length > 0))  {
            if(! this.validateFile(ev.target.files[0])) return;
            this.props.input.onChange(ev.target.files[0]); 
            this.setState({fileName: ev.target.files[0].name});
        } 
    }
    
    handleDropedFile(ar) {
        if(ar && (ar.length > 0) && ar[0]) {
            if(this.validateFile(ar[0])) {
                this.props.input.onChange(ar[0]); 
                this.setState({fileName: ar[0].name}); 
            }
        }       
    }

    validateFile(file) { 
        if(allowedTypes.indexOf(file.type) === -1) {
            this.setError('The file must be an image of supported types.');
            return false;
        }
        if(this.props.maxSize) {
            if(file.size > this.props.maxSize) {
                this.setError( (this.props.maxSizeError) ? this.props.maxSizeError :
                'The image may not be greater than ' + this.props.maxSize + ' bytes.');
                return false;
            }
        }
        return true;
    }

    setError(message) {
        let error = {}; 
        error[this.props.input.name] = message;
        const meta = this.props.meta; 
        meta.dispatch(stopSubmit(meta.form, error)); 
    }  
    
    componentDidMount() {        
        const val = this.props.input.value;
        if(Object.prototype.toString.call(val).toLowerCase() === '[object file]') {
            this.setState({fileName: val.name});
        }
    }

    render() { 
        const { label, required, meta, note, correct} = this.props;
        const fileName = this.state.fileName;  
        const classes = (meta.error) ? "form-field error" : "form-field";        
        return (
            <div className={ classes }>
                <label className={ (required) ? "label label_required" : "label"}>{ label }</label>
                <DragAndDrop classes="form-upload form-upload_img"
                    handleDroped={ this.handleDropedFile } >                                  
                    <div className="form-upload__content">
                        <div className="form-upload__icon">
                            <i className="as-icon as-icon_picture"></i>
                        </div>
                        <div className="form-upload__text">
                            Drop Your Image Here or 
                            <label className="form-upload__input">  
                                Browse
                            <input 
                                accept={ allowedTypesString }
                                onChange={this.handleChange}
                                type="file" 
                                />
                            </label>
                            {
                                fileName && 
                                <div className="form-upload__filename">{fileName}</div>
                            }
                        </div>
                        <div className="form-upload__mime">
                            Supported Files: Jpg, Jpeg, Png
                        </div>
                    </div>               
                </DragAndDrop>
                <FormFieldBottom 
                    meta={meta}
                    note={note}
                    correct={correct}
                />  
            </div>
        )
    }


}