import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { ROUTESLIST } from '@/static/urls';
import ShopCard from './card.js';


export default class ShopFullCard extends Component {
    render() {
        const {id, image, title, author, tags, format, bpm, size, price } = this.props; 
        const url =  ROUTESLIST.SHOP_ITEM;
        return (
            <ShopCard 
                addedClass="shop-card_with-btn" 
                id={id}
                image={image}
                title={title}
                author={author}
                tags={tags}
                format={format}
                bpm={bpm}
                size={size}                
                    >
                <div className="btn-buy__blc">
                <Link to={ url } className="btn-buy btn-buy_card">
                    <i className="as-icon as-icon_cart"></i><span className="price">${price}</span>     
                </Link>
                </div>         

            </ShopCard>    
        )
    }
}
