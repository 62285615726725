import React, { Component } from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import api, { setErrors, arrangeData} from '@/middlewares/api';
import { normalizeMoney, cleanMoney } from '@/utils/forms.js';

import FormField from '@/components/forms/form-field';
import ManyAutocompleteField from '@/components/forms/many-autocomplete';
import ManyWithImagesAutocompleteField from '@/components/forms/many-with-image-autocomplete';
import WordsLimitedField from '@/components/forms/words-limited-field';
import { FORMS } from '@/static/common.js';

class UploadSyncStep2 extends Component {

    constructor(props) {
        super(props);
        this.stepSubmit = this.stepSubmit.bind(this);       
        this.goBack = this.goBack.bind(this);
    }

    stepSubmit(data) {
        console.log('formData', data);
        const formData = arrangeData(data, null, ["label", "artists"]);
        formData.project_budget = cleanMoney(formData.project_budget);
        return new Promise((resolve, reject) => {
            api.post('clear/sync/step/two', formData)
            .then((response) => {               
                this.props.nextStep(formData, data);
                return resolve(null);
            })
            .catch((err) => {
                if(! err.response) return;
                if(err.response.status === 422) {
                    return reject(setErrors(err.response));                            
                }            
            });
        });
    }

    goBack() {
        const {prevStep, rawValues} = this.props;
        prevStep(rawValues);
    }
// track_title
// track_link
// label
// artists
// project_title
// project_budget
// project_synopsis
// description_of_use

    render() {        
        const { handleSubmit, submitting } = this.props; 
        return (
            <section className="upload-form__sec" style={this.props.bgImg}>
                <div className="content">
                    <div className="upload-form__top">  
                        <h2 className="head-shadow">CLEAR SYNC</h2>
                        <h3 className="head">
                            Step 2 of 4: Projeсt Info
                        </h3>                       
                    </div>
                    <div className="form-step__body">
                        <form onSubmit={handleSubmit((data) => { 
                                return this.stepSubmit(data);
                            }) } className="form-layout"
                            noValidate>
                            <div className="form-row">
                                <div className="form-col_2" >
                                     <Field                            
                                     name="track_title"
                                     component={FormField}
                                     required={true}                                        
                                     type="text"
                                     placeholder="Name of the master recording"
                                     label="Track Title"
                                     note="Name of the master recording you would like to license."                            
                                 /> 
                                </div>                               
                                <div className="form-col_2">                                   
                                    <Field                            
                                        name="track_link"
                                        component={FormField}                                        
                                        type="text"
                                        required={true}
                                        placeholder="Link on YouTube"
                                        label="YouTube Link"
                                        note="Copy / Paste URL"                             
                                    />                                       
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-col_2">
                                    <Field                            
                                        name="label"                                        
                                        required={true}
                                        label="Record Label"
                                        component={ManyAutocompleteField}
                                        url="clear/label/search"                                       
                                        placeholder={ FORMS.PLACEHOLDER_TBD }                            
                                    />
                                    
                                </div>                               
                                <div className="form-col_2">
                                    <Field                            
                                        name="artists"                                       
                                        required={true}                                       
                                        component={ManyWithImagesAutocompleteField}
                                        url="artist/search" 
                                        placeholder="Set artist first and last name"
                                        label="Artist"
                                        note="Name of Artist(s) who performed the song"                             
                                    />                                    
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-col_2">
                                    <Field                            
                                        name="project_title"
                                        component={FormField} 
                                        required={true}                                      
                                        type="text"
                                        placeholder="Name of the project"
                                        label="Name of the Project"
                                        note="Title of your Film, TV Show, Video Game, or Social Media Project."                             
                                    />
                                    
                                </div>                               
                                <div className="form-col_2">
                                    <Field                            
                                        name="project_budget"
                                        component={FormField}                                        
                                        type="text"
                                        required={true}
                                        placeholder="Budget in $"
                                        normalize={normalizeMoney}
                                        label="Project Budget"
                                        note="State overall budget for your film project or media project"                             
                                    />                                    
                                </div>
                            </div>                           
                            <div className="form-row">
                                <div className="form-col_2">
                                    <Field                            
                                        name="project_synopsis"                                        
                                        component={WordsLimitedField}  
                                        required={true}                              
                                        maxWords={ 150 }
                                        placeholder="Wish to use the song"
                                        label="Project Synopsis"
                                        note="Brief synopsis of the project for which you wish to use the song"                             
                                    />
                                    
                                </div>                               
                                <div className="form-col_2">
                                    <Field                            
                                        name="description_of_use"
                                        component={WordsLimitedField}  
                                        required={true}                              
                                        maxWords={ 150 } 
                                        placeholder="Start typing…"                                    
                                        label="Describe the Scene"
                                        note="Describe how and where in your project the song will be used. E.g. if your production is a film, give a description of a scene for which you would like to use the song."                             
                                    />                                    
                                </div>
                            </div>                            
                            <div className="submit-row submit-row_txt">  
                                    <button className="btn btn-second" onClick={ this.goBack }
                                        type="button">Back</button>   
                                    <button className="btn btn-primary" disabled={ submitting }
                                        type="submit">Next</button> 
                            </div>                            
                        </form>                        
                    </div>
                </div>
            </section>           
        )
    }
}

const UploadSyncStep2Form =  reduxForm({
    form: 'upload-sync-step2',
    enableReinitialize: true 
  })(UploadSyncStep2);

const mapState = (state) => {
    return {
        rawValues: getFormValues('upload-sync-step2')(state)
    }
}
export default connect(mapState)(UploadSyncStep2Form);