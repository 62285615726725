import React, { Component } from 'react';

export default class Pagination extends Component {
    countPages() {        
        const { current_page, last_page} = this.props.meta;
        let previousPages = [];
        let nextPages = [];
        const offset = 1;
        for(let i = current_page - offset; i < current_page; i++) {
            if( i > 0) {
                previousPages.push(i);
            }
        }
        for(let i = current_page + 1; i <= current_page + offset ; i++) {
            if( i <= last_page) {
                nextPages.push(i);
            }
        }
        const isPrev = (previousPages.length > 0) ? true : false;
        const isNext = (nextPages.length > 0) ? true : false;
        let isLast;
        if( isNext) {
            isLast = true;
            if( nextPages[nextPages.length - 1] === last_page) {
                isLast = false;
            } else if (nextPages[nextPages.length - 1] === (last_page - 1)) {
                nextPages.push(last_page);
                isLast = false;
            }

        } else {
            isLast = false;
        }        
        return {
            current: current_page,
            lastPage: last_page,
            isLast,
            isNext,
            isPrev,
            nextPages,
            previousPages
        }

    }
    isEmptyRender() {
        const {meta} = this.props;
        if(! meta) return true;
        if(meta.last_page === 1) return true;
        return false;
    }

    render() {
       
        if( this.isEmptyRender() ) return null;
        const {current, lastPage, isLast, isNext, isPrev, nextPages, previousPages } = this.countPages();
        const { onPageChange } = this.props;
        return (
            <div className="pagination">
                {
                    isPrev &&
                    <button type="button" onClick={() => onPageChange( current - 1)}
                        className="btn pagination__arrow pagination__arrow_prev">
                            <i className="as-icon as-icon_pgn-prev"></i>
                    </button>                  

                }
                {
                    previousPages.map((number) => {
                        return  <button type="button" onClick={() => onPageChange( number )}
                            key={ number } className="btn pagination__link">
                                { number }
                            </button>
                    })
                }                
                <button type="button" className="btn pagination__link pagination__link_active">{ current }</button>
                {
                    nextPages.map((number) => {
                        return  <button type="button" onClick={() => onPageChange( number )}
                         key={ number } className="btn pagination__link">
                             { number }
                        </button>
                    })
                }   
                {
                    isLast &&
                    <span className="btn pagination__link">...</span>                    
                }
                {
                    isLast && 
                    <button type="button" onClick={() => onPageChange( lastPage )}
                        className="btn pagination__link">
                            { lastPage }
                    </button>
                }
                {
                    isNext && 
                    <button type="button" onClick={() => onPageChange( current + 1 )}
                        className="btn pagination__arrow pagination__arrow_next">
                            <i className="as-icon as-icon_pgn-next"></i>
                    </button>
                }
            </div>
        );
    }
}