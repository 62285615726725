const getUnsavedImage = (file) => {
    return new Promise((resolve, reject) => {        
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = function()
        {   
            resolve(reader.result);
        }
        reader.readAsDataURL(file);        
    });
}

export const  extractUnsavedCrate = async (item, genresList) => {
    let image = null;
    if(item.image) {
        try {
            image = await getUnsavedImage(item.image);
        } catch(ex) {
            console.log('file read problem', ex);           
        }
    }
    const { executive_producer, song_title, artist_name, written_by, genres} = item;
    const artist = (artist_name && artist_name.id) ? artist_name.name : artist_name;
    let list = [];    
    if(genres) {
        genres.forEach(genre => {
            if(genresList[genre]) {
                list.push(genresList[genre].name);
            }
        });
    }
    return {        
        image: image,
        title: song_title,
        artist: artist,
        genres: list.join(', '),
        written_by: written_by,
        executive_producer : executive_producer,
    };
};

export const  extractSavedCrate = (data) => {
    //TODO
    const { executive_producer, id, song_title, artist, written_by, genres, image_url} = data;
    let list = [];
    if(genres) {
        genres.forEach(genre => {
            if(genre.name) {
                list.push(genre.name);
            }
        });
    }
    const artistName = (artist.name) ? artist.name : null;
    return { 
        id: id,       
        image: image_url,
        title: song_title,
        artist: artistName,
        genres: list.join(', '),
        written_by: written_by,
        executive_producer : executive_producer,
    };
};