import React, { Component } from 'react';
import history from '@/store/history';
import {ROUTESLIST} from '@/static/urls.js';
import AffialateService from '@/services/affiliate.js';
import api from '@/middlewares/api';

// https://app.theamplesamples.com/account/affiliate?id=100
const extractParam = (search) => {
    const params = new URLSearchParams(search);
    return params.get('id');
}

export default class AffilatePage extends Component {

    componentDidMount() { 
        const id = extractParam(this.props.location.search);         
        if (id) {
            AffialateService.setId(id, 'id');
        }
        api.get('get-ip')
        .then((response) => {       
            if (response.data) {
                AffialateService.setId(response.data, 'ip');
            }
        });
        history.replace(ROUTESLIST.SIGNUP);

    }

    render() { 
        return (
        <div>...</div>
        )
    }
}
