import React, { Component } from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';

import FormField from '@/components/forms/form-field';
import AudioUpload from '@/components/forms/audio-upload';
import ManyAutocompleteField from '@/components/forms/many-autocomplete';
import WithSavedFile from '@/components/hoc/with-saved-file';
import { FORMS } from '@/static/common.js';
import api, { setErrors, arrangeData } from '@/middlewares/api';


class UploadSampleStep2 extends Component {

    constructor(props) {
        super(props);
        this.stepSubmit = this.stepSubmit.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    goBack() {
        const {prevStep, getRawFileData} = this.props;
        let rawValues = this.props.rawValues;
        rawValues['raw_audio'] = getRawFileData(); 
        prevStep(rawValues);
    }

    stepSubmit(data) {
        //console.log('formData', data);            
        let formData = arrangeData(data, null, ["song_writers", "song_publishers"]);
        if(typeof formData['raw_audio'] !== "undefined")  delete formData['raw_audio'];
        data['raw_audio'] = this.props.getRawFileData();         
        return new Promise((resolve, reject) => {
            api.post('clear/sample/step/two', formData)
            .then((response) => {                                     
                this.props.nextStep(formData, data);
                return resolve(null);
            })
            .catch((err) => {
                if(! err.response) return;
                if(err.response.status === 422) {
                    return reject(setErrors(err.response));                            
                }            
            });
        }); 
    }

    render() {        
        const { handleSubmit, submitting, setRawFileData } = this.props;
        const { raw_audio } = this.props.initialValues; 
        return (
            <section className="upload-form__sec" style={this.props.bgImg}>
                <div className="content">
                    <div className="upload-form__top">  
                        <h2 className="head-shadow">CLEAR SAMPLES</h2>
                        <h3 className="head">
                            Step 2 of 4: YOUR SONG
                        </h3>                       
                    </div>
                    <div className="form-step__body">
                        <form onSubmit={handleSubmit((data) => { 
                                return this.stepSubmit(data);
                            }) } className="form-layout"
                            noValidate>
                            <div className="form-row">
                                <div className="form-col_2 full-height">
                                    <Field 
                                        className="form-field--red-label"                                                                   
                                        name="song_url"
                                        fileName="song"
                                        cachedData={ raw_audio }
                                        uploadUrl="clear/sample/upload-music-file"
                                        component={AudioUpload}
                                        required={true}                                                                          
                                        label="Upload Your Song (Must be Complete with Vocals)"
                                        type="file"                                        
                                        saveRawFileData={ setRawFileData } 
                                        note="10 MB maximum file size."
                                        maxFileSize="10"                           
                                    />
                                </div>                               
                                <div className="form-col_2">
                                    <Field                            
                                        name="artist_name"
                                        component={FormField}
                                        required={true}                                        
                                        type="text"
                                        placeholder="Set Artist First and Last Name"
                                        label="Name of Your Artist"                            
                                    /> 
                                    <Field                            
                                        name="song_new_name"
                                        component={FormField} 
                                        required={true}                                       
                                        type="text"
                                        placeholder="Name of Your New Song"
                                        label="Name of Your New Song"                            
                                    />                                       
                                </div>
                            </div>                                                
                            <Field                            
                                name="song_writers"
                                component={ManyAutocompleteField}
                                url="clear/writer/search"                                       
                                placeholder={ FORMS.PLACEHOLDER_TBD }                                
                                label="Who are the writers of your song?"                            
                            /> 
                            <Field                            
                                name="song_publishers"
                                component={ManyAutocompleteField}
                                url="clear/publisher/search"                                       
                                placeholder={ FORMS.PLACEHOLDER_TBD }                                
                                label="Who are the publishers of your song?"                            
                            />
                            <div className="submit-row submit-row_txt">  
                                    <button className="btn btn-second" onClick={ this.goBack }
                                        type="button">Back</button>      
                                    <button className="btn btn-primary" disabled={ submitting }
                                        type="submit">Next</button> 
                            </div>
                        </form>                        
                    </div>
                </div>
            </section>           
        )
    }
}

const UploadSampleStep2Form =  reduxForm({
    form: 'upload-sample-step2',    
    asyncBlurFields: [ 'song_url' ],
    enableReinitialize: true  
  })(WithSavedFile(UploadSampleStep2));

const mapState = (state) => {
    return {
        rawValues: getFormValues('upload-sample-step2')(state)
    }
}
export default connect(mapState)(UploadSampleStep2Form);
//export default UploadSampleStep2Form;