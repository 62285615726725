export const AC_NOTIFICATION = {
    SHOW: "AC_NOTIFICATION_SHOW",
}

export const showError = (msg, title) => {
    return {
        type: AC_NOTIFICATION.SHOW,
        payload: {
            status: 'error',
            title: title || 'Error',
            msg: msg || ''
        }
    };
};

export const showSuccess = (msg, title) => {
    return {
        type: AC_NOTIFICATION.SHOW,
        payload: {
            status: 'success',
            title: title || 'Success',
            msg: msg || ''
        }
    };
};
