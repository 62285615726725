import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';
//app
import auth from './auth';
import selects from './selects';
import payment from './payment';
import notification from './notification';


export default (history) => combineReducers({
    router: connectRouter(history),
    form: formReducer,
    auth: auth, 
    selects: selects, 
    payment: payment,
    notification: notification  
  });