import React, { Component } from 'react'

export default class AfterRegisterPage extends Component {
    render() {
        return (
            <main className="page" style={{ padding: '80px 0' }}>
                <div className="content">
                    <h2 className="head-shadow">Ample Samples</h2>
                    <h3 className="head">We have sent you a verification code to your email.<br/>
                     Please check and verify in order to access your account. <br/>
                     Thank you!</h3>
                </div>
            </main>
        )
    }
}
