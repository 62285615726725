//imports img for add-sample:
import sampleBgImg1 from '@/assets/images/add-sample/Sample1.jpg'
import sampleBgImg2 from '@/assets/images/add-sample/Sample2.jpg'
import sampleBgImg3 from '@/assets/images/add-sample/Sample3.jpg'
import sampleBgImg4 from '@/assets/images/add-sample/Sample4.jpg'
//imports img for add-sync:
import syncBgImg1 from '@/assets/images/add-sync/Sync1.jpg'
import syncBgImg2 from '@/assets/images/add-sync/Sync2.jpg'
import syncBgImg3 from '@/assets/images/add-sync/Sync3.jpg'
import syncBgImg4 from '@/assets/images/add-sync/Sync4.jpg'

export const maxImageSize = 2 * 1024 * 1024;
export const maxImageSizeErrorMsg = 'The image may not be greater than 2 megabytes.';
export const maxImageSizeNote = null;

export const bgIMGForStepsSample = {
  step1: {
      backgroundImage: `url(${sampleBgImg1})`,    
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    },
  step2: {
      backgroundImage: `url(${sampleBgImg2})`,    
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    },
  step3: {
      backgroundImage: `url(${sampleBgImg3})`,    
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    },
  step4: {
      backgroundImage: `url(${sampleBgImg4})`,    
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    }
}
export const bgIMGForStepsSync = {
  step1: {
      backgroundImage: `url(${syncBgImg1})`,    
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    },
  step2: {
      backgroundImage: `url(${syncBgImg2})`,    
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    },
  step3: {
      backgroundImage: `url(${syncBgImg3})`,    
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    },
  step4: {
      backgroundImage: `url(${syncBgImg4})`,    
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    }
}
 