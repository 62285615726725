import React, { Component } from 'react';

function  WithSavedFile(WrappedComponent) {
    return class extends Component {
        constructor(props) {
          super(props);
          this.fileData = null;
          this.setRawData = this.setRawData.bind(this);
          this.getRawData = this.getRawData.bind(this);
        }
        setRawData(data) {
          this.fileData = data;
        }
        getRawData() {
          return this.fileData;
        }
        render() {          
          return <WrappedComponent  setRawFileData={ this.setRawData }
              getRawFileData={ this.getRawData }
            {...this.props} />;
        }
      };
}

export default WithSavedFile;




