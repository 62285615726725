import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import { flushPaymentMethods } from '@/actions/payment';
import SavedInfoModal from '@/components/modals/saved';

import api from '@/middlewares/api';
import { cardsIcons } from '@/static/payments.js';

import './index.scss';


import MethodCard from './method-card';
import AddPaymentMethodForm from './add-method';

class DashboardPaymentsPage extends Component {

    constructor(props) {
        super(props);
        this.openAddModal = this.openAddModal.bind(this);
        this.closeAddModal = this.closeAddModal.bind(this);
        this.removeMethod = this.removeMethod.bind(this);
        this.updateDefaultMethod = this.updateDefaultMethod.bind(this);
        this.onCloseInfoModal = this.onCloseInfoModal.bind(this);
        this.state = {
            openAddModal: false,
            methods: [],
            defaultMethodId: '',
            isOpenInfoModal: false,
        }
    }

    onCloseInfoModal() {
        this.setState(
            {
                isOpenInfoModal: false,
            }
        );
    }

    removeMethod(id) {        
        api.delete('user/remove-payment', { data: { id: id } })
        .then((response) => {               
            this.setState({ isOpenInfoModal: true });
            this.fetchAllMethods();
            this.props.flushPaymentMethods(); 
        })
        .catch((err) => {
            console.log(err.response);        
        });
    }

    updateDefaultMethod(id) {
        api.post('user/update-default-payment-method', {payment_method: id})
        .then((response) => {              
            this.setState({ isOpenInfoModal: true });
            this.fetchAllMethods(); 
            this.props.flushPaymentMethods(); 
        })
        .catch((err) => {
            console.log(err.response);        
        });
    }

    fetchAllMethods() {
        api.post('user/get-payment-methods')
        .then((response) => {               
            //console.log('methods', response.data);
            if(! response.data) return;
            let updated = {
                defaultMethodId: (response.data.default_payment_method_id) ?
                    response.data.default_payment_method_id : ''  
            };
            let methods = [];
            if(response.data.payment_methods && Array.isArray(response.data.payment_methods)) {
                response.data.payment_methods.forEach((item) => {
                    item.isCard = true;
                    item.meta = (cardsIcons[item.card.brand]) ? cardsIcons[item.card.brand] : cardsIcons.default;
                    item.isDefault = (item.id === updated.defaultMethodId);
                    methods.push(item);
                })
            }
            updated.methods = methods;
            this.setState(updated);
        })
        .catch((err) => {
            console.log(err.response);        
        });
    }

    openAddModal() {
        this.setState({ openAddModal: true});
    }

    closeAddModal(isUpdating = false) {        
        this.setState({ openAddModal: false});
        if ( isUpdating && (typeof isUpdating === 'boolean')) {
            this.fetchAllMethods();
            this.props.flushPaymentMethods(); 
            this.setState({ isOpenInfoModal: true });
        }        
    }

    componentDidMount() {
        this.fetchAllMethods();        
    }
    
    render() { 
        const { methods, isOpenInfoModal } = this.state;
        return (
        <div>
            <h3 className="dashboard-header">Billing</h3> 
            <div className="dashboard-blc">
                <h4 className="dashboard-blc__head">Payment Methods</h4>
                <div className="dashboard-blc__desc"></div>
                <div className="dashboard-blc__items">
                    {
                        methods.map((item) => 
                            <MethodCard key={item.id}
                                id={item.id}
                                isDefault={item.isDefault}
                                isCard={item.isCard}
                                item={item}
                                setDefault={ this.updateDefaultMethod }
                                removeMethod= { this.removeMethod }
                            />
                        )
                    }
                </div>                  
            </div>
            <div className="dashboard-blc">
                <button className="btn btn-round-iconed"
                    type="button" onClick={this.openAddModal}>
                        <i className="as-icon as-icon_plus-stroke"></i>
                        <span>Add an Additional Payment Method </span>
                </button>         
            </div>
             <Modal
                open={this.state.openAddModal}
                onClose={this.closeAddModal}                
            >
                <div className="content modal-wild">
                    <div className="modal-wild__content">
                        <button type="button" className="btn btn-text modal-wild__close" onClick={this.closeAddModal}>
                            <i className="as-icon as-icon_close"></i>
                        </button>
                        <AddPaymentMethodForm 
                            onClose={this.closeAddModal}
                        />
                    </div>                    
                </div>  
            </Modal>
            <SavedInfoModal open={ isOpenInfoModal } onClose={ this.onCloseInfoModal }/>
         </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        flushPaymentMethods: () => {
            return dispatch( flushPaymentMethods() );
        },
    };    
}

export default connect(null, mapDispatchToProps)(DashboardPaymentsPage);


/*
<div className="dashboard-blc">
    <h4 className="dashboard-blc__head">Tax information</h4> 
    <div className="dashboard-blc__desc">
    The address below will be used to determine tax and VAT/GST exemption eligibility.
    </div>
    <div className="dashboard-blc__items">
        <div className="tax-info">
            <button className="btn btn-icon">
                <i className="as-icon as-icon_pencil"></i>
            </button>
            <div className="tax-info__data">
                <p>Some Somerson</p>
                <p>1931 Peachtree Rd Ne</p>
                <p>Atlanta, Georgia 30309</p>
                <p>United States</p>
            </div>
        </div>
    </div> 
</div>

*/
