import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './card.scss';

export default class VideoCard extends Component {
    render() {       
        const {id, src, title, author, desc} = this.props; 
        const url =  '/video'; 
        const source = src + '?&mode=opaque&wmode=transparent&controls=0&modestbranding=1'+
        '&rel=0&autohide=1&showinfo=1&color=white&iv_load_policy=3&theme=light&wmode=transparent&mute=1';
        //allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;picture-in-picture"
        return (
            <div className="video-card">
                <div className="video-card__content"> 
                    <div className="video-card__col video-card__col_video">
                        <div className="video-block">
                            <iframe  src={ source } 
                                frameBorder="0"
                                allowFullScreen></iframe>
                        </div> 
                    </div>                                
                    <div className="video-card__col video-card__col_info"> 
                        <div className="video-card__author">
                           { author }
                        </div>                   
                        <Link to={ url } className="video-card__title">
                            { title }
                        </Link>
                        <div className="video-card__desc">
                           { desc }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
