import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'; 
import {Link} from 'react-router-dom';
import { Select, MenuItem }   from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { fetchPaymentMethods } from '@/actions/payment';
import { showError, showSuccess } from '@/actions/notification';
import {ROUTESLIST} from '@/static/urls.js';
import api from '@/middlewares/api';


import MainModal from '@/components/modals/main';

//const methods = [];
const itemClasses = {
    select : {
        root: 'pay-select',
    },   
    menu: {
        classes: {
            paper: 'pay-select__opts-wrap as-select__opts-wrap',
            list: 'pay-select__opts as-select__opts',
        }
    }
    
}

const countTotal = (amount, baseAmount, promo) => { 
    let all = amount;
    let discount = null;
    if (promo) {
        const { discount_type } = promo;
        const val = +promo.amount;
        if (discount_type == 'percent') {
            discount = baseAmount * val / 100; 
        } else {
           discount =  val;
        }
        all = baseAmount - discount; 
        all += amount - baseAmount; 
        all = Math.ceil(all * 100) / 100;
        all = all.toFixed(2);
        discount = discount.toFixed(2);
    }

    return {
        all: all,
        discount: discount
    }
}

class PayModal extends Component {
    constructor(props) {
        super(props);
        this.onSubmitClick = this.onSubmitClick.bind(this); 
        this.onChangeMethod = this.onChangeMethod.bind(this);
        this.renderValue = this.renderValue.bind(this); 
        this.onClose = this.onClose.bind(this);
        this.onChangePromo = this.onChangePromo.bind(this); 
        this.openPromo = this.openPromo.bind(this); 
        this.onSubmitPromo = this.onSubmitPromo.bind(this);
        this.state = {
            method: null,
            isOpenModal: false,
            isSubmiting: false,
            isOpenPromo: false,
            promoCode: '',
            promoError: null,
            promo: null

        }
        this.valuesMap = {};              
    } 

    onClose() {
        this.setState({
            isOpenPromo: false,
            promoCode: '',
            promoError: null,
            promo: null
        });
        this.props.onClose();
    }

    onChangeMethod(event) {       
        this.setState({
            method: event.target.value
        });
    }

    onChangePromo(event) {
        this.setState({
            promoCode: event.target.value,
            promoError: null
        });
    }

    openPromo() {
        this.setState({
            isOpenPromo: true
        });
    }

    checkIsInvoice() {
        if(this.props.invoice !== null) {
            this.setState({ isOpenModal: true });
        } else {
            this.setState({ isOpenModal: false });
        }
    }

    onSubmitPromo() {
        const {isSubmiting, promoCode} = this.state;
        if (isSubmiting || (! promoCode)) return;
        const { invoice } = this.props;
        this.setState({ isSubmiting: true });
        api.post('apply-promo', {code: promoCode, order_id: invoice.id})
        .then((response) => { 
            if (response.data && response.data.id) {
                this.setState({
                    promo: response.data, 
                    isSubmiting: false,
                    isOpenPromo: false 
                });
            } else {
                this.setState({ isSubmiting: false });
            }        
            
        })
        .catch((err) => {           
            console.log(err.response.data);
            if (err.response && err.response.data) {
                const errors = err.response.data.errors;
                if (errors && errors.code) {
                    this.setState({ promoError: errors.code[0] });
                } else {
                    this.setState({ promoError: err.response.data.message });
                }
            }           
            this.setState({ isSubmiting: false });     
        });   
    }

    onSubmitClick() {
        //promocode_id
       
        const { method } = this.state;
        const { invoice, onClose, showNotification } = this.props;
        if ((! method) || (! invoice)) return;
        this.setState({ isSubmiting: true });
        let data = {
            payment_method_id: method,
            order_id: invoice.id
        };
        if (this.state.promo) {
            data.promocode_id = this.state.promo.id;
        }
        api.post('billing/make-payment', data)
        .then((response) => { //console.log('pay order', response.data);            
            this.setState({ isSubmiting: false });
            showNotification(showSuccess, 'PAYMENT SUCCESSFUL!', 
                'THANK YOU FOR YOUR PAYMENT. WE’LL SEND A RECEIPT FOR YOUR RECORDS.'
            );
            this.setState({
                isOpenPromo: false,
                promoCode: '',
                promoError: null,
                promo: null
            });
            onClose(true);
        })
        .catch((err) => {
            showNotification(showError, 'PAYMENT FAILED!',
                'OOPS! LOOKS LIKE WE WERE NOT ABLE TO PROCESS YOUR PAYMENT. PLEASE ENTER A DIFFERENT CARD.'
            );
            console.log(err.response.data.message);
            this.setState({ isSubmiting: false });     
        });   
    }

    updateMethodsMap() {
       const methods = this.props.paymentsMethods;
       methods.forEach((el, index) => {
            this.valuesMap[el.id] = index;
        }); 
    }

    checkDefaultMethod() {       
       if(this.props.defaultMethod) {
           const id = this.props.defaultMethod;
           this.setState({
                method: id,
           });
       }
    }

    getMethodById(id) { 
        const index = this.valuesMap[id];
        if((typeof index !== "undefined") && this.props.paymentsMethods[index]) {
            const method = this.props.paymentsMethods[index];
            return (method.id === id) ? method : null;
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.invoice !== prevProps.invoice) {
            this.checkIsInvoice();
        }
        if(this.props.methodsIsLoaded && (! prevProps.methodsIsLoaded)) {            
            this.updateMethodsMap();
            this.checkDefaultMethod();           
        }
    }
    
    componentDidMount() {      
        if(! this.props.methodsIsLoaded) {
            this.props.fetchPaymentMethods();
        } else {
            this.checkDefaultMethod();
            this.updateMethodsMap();
        }        
    }
    
    
    render() {         
        const { isOpenModal } = this.state;     
        return (
            <MainModal open={ isOpenModal } onClose={this.onClose}>
                <div className="process-pay">
                    { 
                        this.renderItemInfo()
                    }                    
                    {
                        this.renderSelect()
                    }
                    {
                        this.renderPromo()
                    }
                    {
                        this.renderSubmit()
                    } 
                </div>
            </MainModal>
        );
    }

    renderItemInfo() {
        const { invoice } = this.props;
        if (! invoice) return null;
        const { number, balance, baseAmount} = invoice;
        const total = countTotal(balance, baseAmount, this.state.promo);       
        return (
            <div className="process-pay__item">      
                <h5>Invoice #AS_{ number }</h5>
                <span className="amount">${ total.all }</span>
                {
                    total.discount &&
                    <span className="promo-amount">Applied Promo Code: -${ total.discount }</span>
                }                
            </div>
        );
    }

    renderValue(value) {
        const method = this.getMethodById(value); 
        if(! method) return value; 
        const { meta, label, isCard} = method;
        return (            
            <div className="pay-select__value">
                <div className="pay-select__value-col"><i className={ 'as-icon ' + meta.icon}></i></div>
                <div className="pay-select__value-col"> 
                    {
                       (isCard) ?
                       <Fragment>
                            <span className="label">{ label }</span>
                            <span className="note">Expires: 12/2023{ meta.exp_month }/{ meta.exp_year}</span>                           
                       </Fragment>
                       :
                       <Fragment>
                            <span className="label">{ method.userInfo }</span>
                            <span className="note">{ method.label }</span>
                       </Fragment>
                    }                    
                    
                </div>
            </div>
        );
    }

    renderPromo() {
        const { isSubmiting, promoCode, isOpenPromo, promoError } = this.state;        
        if (! isOpenPromo) {
            return (
                <div className="pay-promo">
                    <button type="button" onClick={this.openPromo} className="link">Have a Promo Code?</button>
                </div>
            );
            // Applied Promo Code: <strong>-${{ promoCodeValue }}</strong> 
        }
        const classes = (promoError) ? "form-field error" : "form-field";
        const disabled = isSubmiting || (! promoCode);
        return (
            <div className="pay-promo"> 
                <div className={ classes }>
                    <label className="label">Enter your promocode</label>
                    <input 
                        name="promo"                                                
                        type="text"
                        value={promoCode}  
                        onChange={this.onChangePromo}
                        className="input" />
                    {
                        (promoError)
                        ? <div className="form-field__note error">{promoError}</div>
                        : <div className="form-field__note empty"></div>
                    }
                </div>                
                <button type="button" className="btn btn-primary" disabled={ disabled } onClick={this.onSubmitPromo}>
                    Validate the Promo Code
                </button>
            </div>    
        );
    }

    renderSubmit() {
        const { isSubmiting } = this.state;
        return (
            <Fragment>
            <div className="process-pay__submit">
                    <button type="button" className="btn btn-primary" disabled={ isSubmiting } onClick={this.onSubmitClick}>
                            Pay
                    </button>
                </div>
                <Link to={ROUTESLIST.DASHBOARD_PAYMENTS}  className="link-medium-wild">
                        MORE PAYMENT METHODS                       
                </Link>
            </Fragment>
        );
    }

    renderSelect() {
        const { paymentsMethods } = this.props;
        if(paymentsMethods.length === 0) return (
            <Link to={ROUTESLIST.DASHBOARD_PAYMENTS}  className="link-medium-wild">
                        ADD PAYMENT METHOD                       
            </Link>
        );
       
        const {  method } = this.state;
        return (
            <Fragment>
                <h6 className="process-pay__label">Payment Method</h6>
                <div className="as-select pay-select__field">
                    <Select classes={itemClasses.select}                                                        
                        value={method} 
                        variant="outlined"                             
                        onChange={ this.onChangeMethod } 
                        MenuProps={itemClasses.menu}
                        IconComponent={KeyboardArrowDownIcon}
                        renderValue={this.renderValue} 
                        >
                        {
                            paymentsMethods.map((item) => 
                                <MenuItem 
                                key={item.id}
                                value={item.id}>{ item.label }</MenuItem>
                            )
                        }                    
                    </Select>                   
                </div>              
            </Fragment>
        )
    }
}

function mapStateToProps(state) {    
    return {      
        methodsIsLoaded: state.payment.methodsIsLoaded,
        paymentsMethods: state.payment.methods, 
        defaultMethod: state.payment.defaultMethod       
    };
}
function mapDispatchToProps(dispatch) {
    return {
        fetchPaymentMethods: () => {
            return dispatch( fetchPaymentMethods() );
        },
        showNotification: (callback, msg, title) => {
            return dispatch( callback(msg, title ) );
        }       
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PayModal);