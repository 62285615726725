import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import WithNotAuthRedirect from '@/components/hoc/with-not-auth-redirect';
import history from '@/store/history';
import { ROUTESLIST } from '@/static/urls';
import api from '@/middlewares/api';
import { extractFields } from '@/utils/forms';
import MainModal from '@/components/modals/main';
import UploadSampleStep1 from './step1';
import UploadSampleStep2 from './step2';
import UploadSampleStep3 from './step3';
import UploadSampleStep4 from './step4';

//data for background-style for all steps:
import { bgIMGForStepsSample } from '../static';

const stepsFields = {
    1: ['name', 'email', 'label', 'distribution', 'business_address', 'phone_number',
     'party_responsible_payment', 'author_sign_sample_agreement', 'party_payment_email'],
    2: ['song_url', 'artist_name', 'song_new_name', 'song_writers', 'song_publishers', 'raw_audio'],
    3: ['sampled_name', 'sampled_link', 'sampled_labels', 'sampled_writers', 'sampled_publishers', 
    'sampled_artist_name', 
        ],  
    4: ['description', 'deadline', 'type_of_used']
};

class AddSamplePage extends Component {
    constructor(props) {
        super(props)        
        this.state = {
          page: 1,
          isOpenModal: false,
          savingAll: false
        }
        this.formData = {};
        this.rawData = {};
        this.nextStep =  this.nextStep.bind(this);
        this.prevStep = this.prevStep.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.nextLoop = this.nextLoop.bind(this);        
    }

    saveRawValues(rawData) {
        if (rawData) {
            Object.keys(rawData).forEach((key) => {
                this.rawData[key] = rawData[key];
            });
        } 
        //console.log('rawData', this.rawData);
    }

    prevStep(rawData) {
        this.saveRawValues(rawData);
        this.setState((prevState) => {
            if(prevState.page > 1) {                    
                return {
                    page: prevState.page - 1
                }                
            } 
        });
    }

    closeModal() {
        this.setState({isOpenModal: false});
        history.push(ROUTESLIST.DASHBOARD);
    }

    nextStep(data, rawData) {
        if(data) {
            Object.keys(data).forEach((key) => {
                this.formData[key] = data[key];
            });
        } 
        this.saveRawValues(rawData);        
        if (this.state.page === 4)  {
            if (this.state.savingAll) return; 
            this.setState({savingAll: true});           
            api.post('clear/sample/save', this.formData)
            .then((response) => {                                    
                this.setState({isOpenModal: true, savingAll: false});
            })
            .catch((err) => {
                console.log(err.response); 
                this.setState({savingAll: false});            
            });  
        } else {
            this.setState((prevState) => {
                if(prevState.page < 4) {                    
                    return {
                        page: prevState.page + 1
                    }                
                }               
                return {isOpenModal: true};
            });
        } 
    }

    nextLoop() {        
        const newFormData = {};
        stepsFields[1].forEach((key) => {
            newFormData[key] = this.formData[key];
        });
        this.formData = newFormData;
        this.rawData = extractFields(this.rawData, stepsFields[1]);
        this.setState({isOpenModal: false, page: 2});       
    }

    render() {         
        const content = this.renderContent();  
        const { isOpenModal } = this.state;
        return (
            <main className="page upload-page">
                {
                    content
                } 
                <MainModal open={isOpenModal } onClose={this.closeModal} >                    
                    <h4 className="main-modal__head">Your sample request added successfully!</h4>
                    <div className="main-modal__btns">
                        <button type="button" className="btn btn-primary" onClick={this.nextLoop}>
                            Add more
                        </button>                                              
                        <Link to={ROUTESLIST.DASHBOARD} className="btn btn-second">
                            Complete
                        </Link>
                    </div>
                </MainModal>
            </main>
        )
    }

    renderContent() {
        const step = this.state.page;
        let stepValues;    
        switch(step) {
            case 1 :
                const { firstUserData } = this.props; //
                stepValues = Object.assign({}, firstUserData, extractFields(this.rawData, stepsFields[1]));
                return (
                    <UploadSampleStep1 
                        nextStep={ this.nextStep } 
                        initialValues={ stepValues } 
                        bgImg={ bgIMGForStepsSample.step1 } 
                    /> 
                );
            case 2 :
                stepValues = extractFields(this.rawData, stepsFields[2]); 
                return (
                    <UploadSampleStep2 
                        nextStep={ this.nextStep } 
                        prevStep={ this.prevStep }
                        initialValues={ stepValues } 
                        bgImg={ bgIMGForStepsSample.step2 } 
                    /> 
                );
            case 3 :
                stepValues = extractFields(this.rawData, stepsFields[3]); 
                return (
                    <UploadSampleStep3 
                        nextStep={ this.nextStep } 
                        prevStep={ this.prevStep }
                        initialValues={ stepValues } 
                        bgImg={ bgIMGForStepsSample.step3 } 
                    /> );
            case 4 :
                stepValues = extractFields(this.rawData, stepsFields[4]);
                const savingAll = this.state.savingAll; 
                return (
                    <UploadSampleStep4 
                        nextStep={ this.nextStep }
                        prevStep={ this.prevStep } 
                        initialValues={ stepValues } 
                        bgImg={ bgIMGForStepsSample.step4 }
                        savingAll={ savingAll }
                    /> 
                );
            default: 
                return null;    
        }  
    }
}

function mapStateToProps(state) {
    let firstUserData = {}; 
    if( state.auth.user) { 
        const { fullName, personal_email, personal_phone, label_name, distribution_company,
            business_address, agreement_signer, payer_name, payer_email } = state.auth.user.profile;              
        firstUserData = {
            name: fullName,
            email: personal_email,
            business_address: business_address,
            phone_number: personal_phone,     
            author_sign_sample_agreement: agreement_signer,
            party_responsible_payment: payer_name,
            label: label_name,
            distribution: distribution_company,
            party_payment_email: payer_email
        }
    }    
    return {
        firstUserData: firstUserData
    };
}

export default WithNotAuthRedirect(connect(mapStateToProps, null)(AddSamplePage));
