import sh1 from '@/assets/images/temp/shop1.jpg';
import sh2 from '@/assets/images/temp/shop2.jpg';
import sh3 from '@/assets/images/temp/shop3.jpg';
import sh4 from '@/assets/images/temp/shop4.jpg';
import sh5 from '@/assets/images/temp/shop5.jpg';
import sh6 from '@/assets/images/temp/shop6.png';
import sh7 from '@/assets/images/temp/shop7.png';
import sh8 from '@/assets/images/temp/shop8.png';
import sh9 from '@/assets/images/temp/shop9.jpg';
import sh10 from '@/assets/images/temp/shop10.png';

export const samples = [
    {
        id: '1',
        img: sh1,
        title: 'Dirt Off Your Shoulder',
        author: 'Jay-Z',
        tags: 'Hip Hop',
        format: '24 Bit WAV Format',
        bpm: '85BPM',
        size: '956MB Unzipped',
        price: 36.95,
    },
    {
        id: '2',
        img: sh2,
        title: 'Dirt Off Your Shoulder',
        author: 'Jay-Z',
        tags: 'Hip Hop',
        format: '24 Bit WAV Format',
        bpm: '85BPM',
        size: '956MB Unzipped',
        price: 36.95,
    },
    {
        id: '3',
        img: sh3,
        title: 'Dirt Off Your Shoulder',
        author: 'Jay-Z',
        tags: 'Hip Hop',
        format: '24 Bit WAV Format',
        bpm: '85BPM',
        size: '956MB Unzipped',
        price: 36.95,
    },
    {
        id: '4',
        img: sh4,
        title: 'Dirt Off Your Shoulder',
        author: 'Jay-Z',
        tags: 'Hip Hop',
        format: '24 Bit WAV Format',
        bpm: '85BPM',
        size: '956MB Unzipped',
        price: 36.95,
    },
    {
        id: '5',
        img: sh5,
        title: 'Dirt Off Your Shoulder',
        author: 'Jay-Z',
        tags: 'Hip Hop',
        format: '24 Bit WAV Format',
        bpm: '85BPM',
        size: '956MB Unzipped',
        price: 36.95,
    },
    {
        id: '6',
        img: sh6,
        title: 'Dirt Off Your Shoulder',
        author: 'Jay-Z',
        tags: 'Hip Hop',
        format: '24 Bit WAV Format',
        bpm: '85BPM',
        size: '956MB Unzipped',
        price: 36.95,
    },
    {
        id: '7',
        img: sh7,
        title: 'Dirt Off Your Shoulder',
        author: 'Jay-Z',
        tags: 'Hip Hop',
        format: '24 Bit WAV Format',
        bpm: '85BPM',
        size: '956MB Unzipped',
        price: 36.95,
    },
    {
        id: '8',
        img: sh8,
        title: 'Dirt Off Your Shoulder',
        author: 'Jay-Z',
        tags: 'Hip Hop',
        format: '24 Bit WAV Format',
        bpm: '85BPM',
        size: '956MB Unzipped',
        price: 36.95,
    },
    {
        id: '9',
        img: sh9,
        title: 'Dirt Off Your Shoulder',
        author: 'Jay-Z',
        tags: 'Hip Hop',
        format: '24 Bit WAV Format',
        bpm: '85BPM',
        size: '956MB Unzipped',
        price: 36.95,
    },
    {
        id: '10',
        img: sh10,
        title: 'Dirt Off Your Shoulder',
        author: 'Jay-Z',
        tags: 'Hip Hop',
        format: '24 Bit WAV Format',
        bpm: '85BPM',
        size: '956MB Unzipped',
        price: 36.95,
    },
];

export const filters = [
    {
        name: 'genre',
        label: 'Genre:',
        options: [
            {value: '1', label: 'Hip Hop'},
            {value: '2', label: 'option 2'},
            {value: '3', label: 'option 3'},            
        ]
    },
    {
        name: 'bpm',
        label: 'BPM:',
        options: [
            {value: '1', label: '57-164'},
            {value: '2', label: 'option 2'},
            {value: '3', label: 'option 3'},     

        ]
    },
    {
        name: 'type',
        label: 'TYPE:',
        options: [
            {value: '1', label: 'option 1'},
            {value: '2', label: 'option 2'},
        ]
    },
    {
        name: 'samples',
        label: 'SAMPLES:',
        options: [
            {value: '1', label: 'SFX'},
            {value: '2', label: 'option 2'},
            {value: '3', label: 'option 3'},     
        ]
    },
];
// export const emptyFilters = {
//     genre: null,
//     bpm: null,
//     type: null,
//     samples: null
// };

export const emptyFilters = {
    genre: null,
    bpm: null,
    type: null,
    samples: null
};