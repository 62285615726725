import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';

import { ROUTESLIST } from '@/static/urls';
import FormField from '@/components/forms/form-field';
import OneAutocompleteField from '@/components/forms/one-autocomplete';
import { phonePattern } from '@/utils/forms.js';
import { FORMS } from '@/static/common.js';
import api, { setErrors, arrangeData} from '@/middlewares/api';

class UploadSampleStep1 extends Component {

    constructor(props) {
        super(props);
        this.stepSubmit = this.stepSubmit.bind(this);
    }

    stepSubmit(data) {        
        const formData = arrangeData(data, ["distribution", "label"]);
        return new Promise((resolve, reject) => {
            api.post('clear/sample/step/one', formData)
            .then((response) => {               
                this.props.nextStep(formData, data);
                return resolve(null);
            })
            .catch((err) => {
                if(! err.response) return;
                if(err.response.status === 422) {
                    return reject(setErrors(err.response));                            
                }            
            });
        }); 
    }

    render() {        
        const { handleSubmit, submitting } = this.props; //party_payment_email
        return (
            <section className="upload-form__sec" style={this.props.bgImg}>
                <div className="content">
                    <div className="upload-form__top">  
                        <h2 className="head-shadow">CLEAR SAMPLES</h2>
                        <h3 className="head">
                            Step 1 of 4: CLIENT INFO
                        </h3>                       
                    </div>
                    <div className="form-step__body">
                        <form onSubmit={handleSubmit((data) => { 
                                return this.stepSubmit(data);
                            }) } className="form-layout"
                            noValidate>                              
                            <div className="form-row">
                                <div className="form-col_2">
                                    <Field                            
                                        name="name"
                                        component={FormField}
                                        required={true}
                                        type="text"
                                        placeholder="Set First and Last Name"
                                        label="Contact Name"                            
                                    />
                                    
                                </div>                               
                                <div className="form-col_2">
                                    <Field                            
                                        name="email"
                                        component={FormField}                                        
                                        type="email"
                                        required={true}
                                        placeholder="some@email.com"
                                        label="Your email address"                            
                                    />                                    
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-col_2"> 
                                    <Field                            
                                        name="label"
                                        component={OneAutocompleteField}
                                        required={false}
                                        type="text"
                                        url="clear/label/search"
                                        placeholder={ FORMS.PLACEHOLDER_TBD }
                                        label="Name of your Label"                            
                                    />
                                </div>                               
                                <div className="form-col_2">
                                    <Field                            
                                        name="distribution"
                                        component={ OneAutocompleteField }                                        
                                        type="text"
                                        required={ true }
                                        url="clear/distributor/search"
                                        placeholder={ FORMS.PLACEHOLDER_TBD }
                                        label="Company distributing your project"                            
                                    />                             
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-col_2">
                                    <Field                            
                                        name="business_address"
                                        component={FormField}                                       
                                        type="text"
                                        required={true}
                                        placeholder="Your Business Address"
                                        label="Business Address" 
                                        note="Example: 555 Main Street, Anytown, NY, 10019"                           
                                    />
                                    
                                </div>                               
                                <div className="form-col_2">
                                    <Field                            
                                        name="phone_number"
                                        component={FormField}                                       
                                        type="text"
                                        required={true}
                                        {...phonePattern}                                       
                                        label="Your Phone Number"                            
                                    />                                    
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-col_2">
                                    <Field                            
                                        name="party_responsible_payment"
                                        component={FormField}                                       
                                        type="text"
                                        required={true}
                                        placeholder="Party Responsible For Making Payment?"
                                        label="Party Responsible for Making Payment"                            
                                    />
                                </div>                               
                                <div className="form-col_2">
                                    <Field                            
                                        name="author_sign_sample_agreement"
                                        component={FormField}                                        
                                        type="text"
                                        required={true}
                                        placeholder="Set First and Last Name"
                                        label="Who will sign Sample Agreement"                            
                                    />                                    
                                </div>
                            </div> 
                            <Field                            
                                name="party_payment_email"
                                component={FormField}                                        
                                type="email"
                                required={true}
                                placeholder="some@email.com"
                                label="Party Responsible for Making Payment Email Address"                            
                            />                                    
                            <div className="submit-row_txt">
                                <p className="as-text">
                                    By clicking Next button you are agreeing to the 
                                    <Link to={ ROUTESLIST.STATIC_TERMS } className="link-thin"
                                        target="_blank" rel="noopener noreferrer"
                                        >Terms and Conditions.
                                    </Link> 
                                </p>
                                <div className="submit-row__btn-col">
                                    <button className="btn btn-primary" disabled={ submitting }
                                        type="submit">Next</button> 
                                </div>
                            </div>
                        </form>                        
                    </div>
                </div>
            </section>           
        )
    }
}

const UploadSampleStep1Form =  reduxForm({
    form: 'upload-sample-step1',
    enableReinitialize: true
  })(UploadSampleStep1);

export default UploadSampleStep1Form;