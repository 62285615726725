import React, { Component } from 'react';

import Loader from '@/components/loader/index';
//import Notification from '@/components/notification/index';

export default class TesterPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenFixed: false,
            isOpenInner: false,
            //isOpenNote: true,
        }
        this.openFixed = this.openFixed.bind(this);
        this.openInner = this.openInner.bind(this);
        this.onClose = this.onClose.bind(this);
    } 
    onClose() {
        //this.set
    }   
    openFixed() {
        this.setState({
            isOpenFixed: true
        });
        setTimeout(() => {
            this.setState({
                isOpenFixed: false
            });
        }, 10000)
    }
    openInner() {
        this.setState({
            isOpenInner: true
        });
        setTimeout(() => {
            this.setState({
                isOpenInner: false
            });
        }, 10000)
    }
    render() {
        const { isOpenFixed, isOpenInner } = this.state;
        return (
            <div className="content">                
                TODO
                <div className="form-field">
                    <button className="btn" onClick={this.openFixed}> Open Fixed loader</button>
                </div>
                <Loader isOpen={ isOpenFixed } />
                <div className="form-field">
                    <button className="btn" onClick={this.openInner}> Open Inner loader</button>
                </div>

                <div>
                    <Loader isOpen={ isOpenInner } type="inner" />
                    {
                        (! isOpenInner) &&

                        <p>
                            already loaded items
                            <br/>
                            already loaded items
                            <br/>
                            already loaded items
                            <br/>
                            already loaded items
                            <br/>
                            already loaded items
                            <br/>
                            already loaded items
                            <br/>
                        </p>

                    }
                </div>

            </div>
        )
    }
}
