import React, { Component } from 'react';
import api from '@/middlewares/api';
import { stopSubmit } from 'redux-form';
import { CancelToken } from "axios";
import { FORMS } from '@/static/common';
import { formatBytes } from '@/utils/forms';
import DragAndDrop from './drag-and-drop';
import FormFieldBottom from './field-bottom';

const allowedTypes = ["audio/mpeg", "audio/wav", "audio/mp4", "audio/flac",
 "audio/wma", "audio/aiff", "audio/alac", "audio/ape", "audio/mp3", "audio/aac", "audio/ogg"];
const allowedTypesString = allowedTypes.join(', ');

export default class AudioUpload extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleDropedFile = this.handleDropedFile.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.setProgress = this.setProgress.bind(this);
        this.state = {
            fileName: '',
            status: 'empty', //statuses: filled empty progress
            size: '',
            progress: ''
        };
        this.cancel = null;
    }

    componentDidMount() {
        if(this.props.cachedData) {
            const file = this.props.cachedData;
            this.setState({
                fileName: file.fileName,
                status: 'filled', 
                size: file.size,
            });
            this.props.saveRawFileData(file);
        }
    }
    
    handleChange(ev) { 
        if(ev.target.files && (ev.target.files.length > 0))  {
            if(! this.validateFile(ev.target.files[0])) return;           
            this.uploadFile(ev.target.files[0]);            
        } 
    }
    setProgress(progressEvent) {
        const value =  Math.round((progressEvent.loaded * 100) / progressEvent.total) + '%';
        this.setState({
            progress: value,
        })
    }

    uploadFile(file) {       
        let formData = new FormData();
        formData.append(this.props.fileName, file);
        this.setState({
            fileName: file.name,
            status: 'progress', //filled empty progress
            size: formatBytes(file.size),
            progress: '0%'
        });
        api( {
            method: 'post',
            url: this.props.uploadUrl,
            data: formData,
            cancelToken: new CancelToken((c) => {
                this.cancel = c;
            }),
            onUploadProgress: this.setProgress
        })
        .then((response) => { 
            const name = this.props.input.name;
            if(response.data && response.data[name]) {
                this.props.input.onChange(response.data[name]); 
                this.setState({                    
                    status: 'filled',
                    progress: ''
                });
                this.props.saveRawFileData({
                    fileName: file.name,
                    size: formatBytes(file.size),
                });
            } else {
                this.handleDelete();
            }
        })
        .catch((err) => {
            if(! err.response) return;
            let msg = FORMS.DEFAULT_ERROR;
            if(err.response.status === 422) { 
                if(err.response.data.errors && err.response.data.errors[this.props.fileName]) {
                    msg = err.response.data.errors[this.props.fileName][0];
                } else {
                    msg = err.response.data.message;
                }                                                               
            }            
            this.setError(msg);
            this.handleDelete(true);             
        });

    }
    setError(msg) { 
        let error = {}; 
        error[this.props.input.name] = msg;
        const meta = this.props.meta; 
        meta.dispatch(stopSubmit(meta.form, error));
    }
    handleDelete(saveValue) {
        this.setState({
            fileName: '',
            status: 'empty',
            size: '',
            progress: ''
        })
        if(! saveValue) {
            this.props.input.onChange(null);
        }
        this.props.saveRawFileData(null);
    }

    handleCancel() {
        if(typeof this.cancel !== "function") return;
        this.cancel();
        this.setState({
            fileName: '',
            status: 'empty',
            size: '',
            progress: ''
        });
        //TODO
        this.props.input.onChange(null);
    }

    handleDropedFile(ar) {
        if(ar && (ar.length > 0) && ar[0]) {
            if(this.validateFile(ar[0])) {
                this.uploadFile(ar[0]);
            }
        }       
    }

    validateFile(file) {
        if(allowedTypes.indexOf(file.type) === -1) {
            this.setError('File type is not allowed');
            return false;
        }
        let max = this.props.maxFileSize || 200;

        if(file.size > 1024 * 1024 * max ) {
            this.setError('Max file size is ' + max +' MB');
            return false;
        }
        return true;
    }

    renderEmpty() {
        return (
            <div className="form-upload__content">
                <label className="form-upload__input-area">
                    <div className="form-upload__icon">
                        <i className="as-icon as-icon_folder-music"></i>
                    </div>
                    <div className="form-upload__text">
                        Drop Your Audio File Here or
                        <span className="form-upload__input">  
                            Browse
                        <input 
                            accept={ allowedTypesString }
                            onChange={this.handleChange}
                            type="file" 
                            />
                       </span>                  
                    </div>
                </label>    
                <div className="form-upload__mime">
                    Supported Files: Wav, Aiff or MP3
                </div>
            </div>          
        )
    }

    renderProgress() {
        const {fileName, size, progress } = this.state;
        return (
            <div className="form-upload__content form-upload__content_fill">
                <div className="form-upload__middle">
                    <div className="form-upload__row">
                        <div className="form-upload__col form-upload__col_left">
                        <div className="form-upload__row">
                            <div className="form-upload__col form-upload__col_left">
                                <i className="as-icon as-icon_folder-music as-small"></i>
                            </div>
                            <div className="form-upload__col">
                                <div className="form-upload__filename">{fileName}</div>                               
                            </div>
                        </div> 
                        </div>
                        <div className="form-upload__col">
                            <button className="form-upload__input" onClick={ this.handleCancel } type="button"> 
                                Cancel
                            </button>
                        </div>
                    </div>
                    <div className="form-upload__row">
                        <div className="form-upload__col form-upload__col_left">
                            <span className="form-upload__info">{ size }</span>
                        </div>
                        <div className="form-upload__col">
                        <span className="form-upload__info_white">{ progress }</span>
                        </div>
                    </div>
                    <div className="form-upload__progress">
                        <div className="form-upload__progress-done" style={ {width: progress}}
                        ></div>
                    </div>
                </div>
            </div>
        )
    }

    renderFull() {
        const {fileName, size } = this.state;
        return (
            <div className="form-upload__content form-upload__content_fill">
                <div className="form-upload__middle">
                    <div className="form-upload__row">
                        <div className="form-upload__col form-upload__col_left">
                        <div className="form-upload__row">
                            <div className="form-upload__col form-upload__col_left">
                                <i className="as-icon as-icon_folder-music as-middle"></i>
                            </div>
                            <div className="form-upload__col">
                                <div className="form-upload__filename">{fileName}</div>
                                <span className="form-upload__info">{ size }</span>
                            </div>
                        </div> 
                        </div>
                        <div className="form-upload__col">
                            <button className="form-upload__input" onClick={ this.handleDelete } type="button">
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() { 
        const { label, required, meta, note, correct, className} = this.props;      
        let classes = (meta.error) ? "form-field error" : "form-field"; 
        if (className) {
            classes += ' ' + className;
        }
        const status = this.state.status;
        let content;
        switch(status) {
            case 'progress':
                content = this.renderProgress();
                break;            
            case 'filled': 
                content = this.renderFull();
                break;            
            default: 
               content = this.renderEmpty();              
        }
        return (
            <div className={ classes }>
                <label className={ (required) ? "label label_required" : "label"}>{ label }</label>
                <DragAndDrop classes="form-upload form-upload_audio"
                    handleDroped={ this.handleDropedFile } >                                  
                    { content }  
                </DragAndDrop>
                <FormFieldBottom 
                    meta={meta}
                    note={note}
                    correct={correct}
                />  
            </div>
        )
    }
}
