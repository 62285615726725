import React, { Component } from 'react';

import { connect } from 'react-redux';
import { authUpdatePartual }  from '@/actions/auth';
import SavedInfoModal from '@/components/modals/saved';

import './index.scss';

//authUpdatePartual

import ProfileCompanyForm from './company';
import ProfilePersonalForm from './personal';
import ProfileAvatar from './avatar';

class DashboardProfilePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenInfoModal: false,
        }
        this.updateUserDataHandler = this.updateUserDataHandler.bind(this);
        this.onCloseInfoModal = this.onCloseInfoModal.bind(this);
    }

    updateUserDataHandler(data, part) { 
        this.props.updateUserData(data, part);
        this.setState(
            {
                isOpenInfoModal: true,
            }
        );
    }

    onCloseInfoModal() {
        this.setState(
            {
                isOpenInfoModal: false,
            }
        );
    }


    render() { 
        const { personalValues, avatarUrl, userAvatar, companyValues} = this.props
        const { isOpenInfoModal } = this.state;
        return (
            <div>
               <h3 className="dashboard-header">Profile Settings</h3> 
               <div className="dashboard-blc">
                    <h4 className="dashboard-blc__head">Profile Picture</h4>
                    <ProfileAvatar
                        avatarUrl={ avatarUrl }
                        userAvatar={ userAvatar } 
                        updateUserData={ this.updateUserDataHandler } 
                    />
               </div>
               <div className="dashboard-blc">
                    <h4 className="dashboard-blc__head">Personal details</h4>
                    <ProfilePersonalForm 
                        initialValues={ personalValues  }
                        updateUserData={ this.updateUserDataHandler }
                    />
               </div>
               <div className="dashboard-blc">
                    <h4 className="dashboard-blc__head">Company details</h4>
                    <div className="dashboard-blc__desc">Tell us more about your company.</div>
                    <ProfileCompanyForm 
                        initialValues={ companyValues }
                        updateUserData={ this.updateUserDataHandler }
                    />
               </div>
               <SavedInfoModal open={ isOpenInfoModal } onClose={ this.onCloseInfoModal }/>
            </div>
        )
    }
}

function mapStateToProps(state) {          
    if( state.auth.user) {
        const {first_name, last_name, personal_email, personal_phone, 
            avatar_url, label_name, distribution_company, business_address, 
            agreement_signer, payer_name, payer_email} = state.auth.user.profile;
        const personalValues = {
            first_name, last_name, personal_email, personal_phone
        };
        const companyValues = {
            label_name, distribution_company, business_address, agreement_signer,  payer_name, payer_email
        }
        return {
            personalValues: personalValues,
            avatarUrl: avatar_url,
            userAvatar: state.auth.user.avatar,
            companyValues: companyValues
        }
    }    
    else {
        return {
            personalValues: {},
            avatarUrl: null,
            userAvatar: null,
            companyValues: null
        };
    }
}
function mapDispatchToProps(dispatch) {
    return {
        updateUserData: (data, part) => {
            return dispatch(authUpdatePartual(data, part))
        },     
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardProfilePage);

// {
// 	"profile": {
// 		"first_name": "test2",
// 		"last_name": "dsgg",
// 		"personal_email": "wqewe@test.com",
// 		"personal_phone": "5456576879",
// 		"avatar_url": "/storage/2021/01/22/VaMgtLvtju11cg4rPmBPzRLHRlg2v2cGu9wjhUpA.png",
// 		"label_name": null,
// 		"distribution_company": null,
// 		"business_address": null,
// 		"agreement_signer": null,
// 		"payer_name": null
// 	}
// }