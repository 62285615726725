import { AC_NOTIFICATION } from '@/actions/notification';

const defaultState = {
   status: "info",
   title: "",
   msg: "",
   timestamp: 0
};

export default (state = defaultState, action) => {
    switch(action.type) {
        case AC_NOTIFICATION.SHOW: {
            let newState = Object.assign({}, defaultState, action.payload);
            newState.timestamp = new Date().getTime();
            return newState;
        }       
        default: {
            return state;
        }
    }
}
