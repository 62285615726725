import React, { Component } from 'react';

import WithAccordion from '@/components/hoc/with-accordion.js';

class FooterMenuColumn extends Component {
    render() {       
        const {classList, title, children, isContentOpen, toggleContent} = this.props;
        const currentClassList = (isContentOpen) ? 
            classList + ' page-footer__col_open' : classList + ' page-footer__col_close';
        return (
            <div className={ currentClassList }>
                <h4 className="page-footer__col-head" onClick={ toggleContent }>
                    <span>{ title }</span>
                    <i className="as-icon as-icon_plus-small" role="button"></i>
                </h4> 
                <div className="page-footer__col-body">
                    { children }
                </div>                    
            </div>
        )
    }
}

export default WithAccordion(FooterMenuColumn);
