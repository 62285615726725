import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import {ROUTESLIST} from '@/static/urls.js';

export default class InvoiceCard extends Component {
    render() {
        const {id, item, onProcessPay} = this.props; 
        const { date, amount, status, units, status_class, number, needPay }  = item;   
        //TODO pay
        return ( 
            <tr className="invoice-card dashboard-table__card">
                <td className="invoice-card__number">
                    {number}
                </td>
                <td className="invoice-card__info">                    
                    <span className="date">{date}</span>
                </td>
                <td className="invoice-card__amount">
                    $ {amount}
                </td>
                <td className="invoice-card__amount">
                    { units }
                </td>
                <td className="invoice-card__status">
                    <span className={ 'status status_' + status_class }>{ status }</span>
                </td> 
                <td className="invoice-card__pay">
                    {
                        needPay &&
                        <button type="button" className="btn btn-primary" 
                            onClick={ () => { onProcessPay(id) }} >
                                Pay Now
                        </button>
                    }
                </td>               
                <td className="invoice-card__actions">
                    <Link to={ROUTESLIST.DASHBOARD_INVOICE + id} className="btn btn-act-iconed"><i className="as-icon as-icon_eye"></i></Link>
                    
                </td>
            </tr>
        )
    }

    
}
//todo  <button type="button" className="btn btn-act-iconed"><i className="as-icon as-icon_arrow-download"></i></button>
/* <td className="invoice-card__pay"> 
                    { 
                        needPay &&
                        <button type="button" className="btn btn-primary" onClick={ onProcessPay }>Pay</button>
                    }
                </td> */
                //<button type="button" className="btn btn-act-iconed"><i className="as-icon as-icon_arrow-download"></i></button>