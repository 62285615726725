import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';

import history from '@/store/history';
import api, { setErrors } from '@/middlewares/api';
import { ROUTESLIST } from '@/static/urls';

import FormField from '@/components/forms/form-field';
import WithAuthRedirect from '@/components/hoc/with-auth-redirect';

class ForgotPasswordPage extends Component { 
    constructor(props) {
        super(props);        
        this.submitForm = this.submitForm.bind(this);
        this.closePage = this.closePage.bind(this);
        this.state = {
            isOpenForm: true
        }
    }

    closePage() {
        this.setState({ isOpenForm: true });
        history.replace(ROUTESLIST.HOME);
    }

    submitForm(data) {        
        console.log(data);         
        return new Promise((resolve, reject) => {
            api.post('forgot-password', data)
            .then((response) => {               
                this.setState({ isOpenForm: false });
                return resolve(null);                 
            })
            .catch((err) => {
                if(! err.response) return;
                if((err.response.status === 422) || ((err.response.status === 404))) {
                    return reject(setErrors(err.response));                            
                }            
            })
        });        
    }    

    render() {  
        const { handleSubmit, pristine, submitting} = this.props;
        const { isOpenForm } = this.state;
        return (
            <main className="page">
                {   isOpenForm &&
                    <section className="form-section">
                        <div className="content-tight">
                            <h3 className="head-middle">Forgot Your Password?</h3>
                            <p>Please Enter your email</p>
                            <form onSubmit={handleSubmit((data) => { 
                                return this.submitForm(data);
                                }) } 
                                className="login-form" noValidate>
                            <Field                            
                                name="email"
                                component={FormField}
                                type="text"
                                placeholder="some@email.com"
                                label="Email"                            
                            />
                                
                                <div className="form-submit-row">
                                    <button className="btn btn-primary btn-fw" disabled={pristine || submitting}
                                        type="submit">Send</button> 
                                </div>
                            </form>    
                            <div className="form-field center">
                                Back to <Link to={ROUTESLIST.LOGIN} className="link">Login.</Link> 
                            </div>
                        </div>
                    </section>
                } 
                { 
                    (! isOpenForm) &&
                    <div className="content" style={{ padding: '80px 0' }}>
                        <h2 className="head-shadow">Ample Samples</h2>
                        <h3 className="head">We have sent you a reset password link to your email.<br/>
                        Please check your email. <br/>
                        Thank you!</h3>  
                        <div className="form-submit-row">
                        <button className="btn btn-primary btn-fw" onClick={ this.closePage }
                            type="button">Ok</button> 
                        </div>                  
                    </div>
                } 
            </main>
        );       
    }
}

const ForgotPasswordFormPage =  reduxForm({
    form: 'forgot-password-form' 
  })(ForgotPasswordPage);

  export default WithAuthRedirect(ForgotPasswordFormPage);


