import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showError } from '@/actions/notification';

import api from '@/middlewares/api';
//import Loader from '@/components/loader/index';

import AddComment from './add';

import './index.scss';

class CommentsBlock extends Component {
    constructor(props) {
        super(props);  
        this.state = {
            items: [],
            isLoaded: false,
            isDisableComment: false,           
        };
        this.submitAddComment = this.submitAddComment.bind(this);               
    } 

    componentDidMount() {
        this.fetchComments();
    }

    submitAddComment(value) {
        //store.dispatch(showError('Some server error', 'Sorry'));
        const data = {
            content: value,
            crate_id: this.props.itemId
        };
        const result = new Promise((resolve, reject) => {
            //setTimeout(() => { resolve(null)}, 5000 );
            this.setState({ isDisableComment: true });
            api.post('crate/comment/add', data)
            .then((response) => {  
                console.log(response);
                resolve(null); 
                this.setState({ isDisableComment: false });
            })
            .catch((err) => {
                console.log(err.response); 
                reject(null);
                this.props.showError('Some server error', 'Sorry');
                this.setState({ isDisableComment: false });       
            });
        });        
        return result;
    }

    fetchComments() { //crate/comment/add

        // api.get('crate/latest-additions')
        // .then((response) => {  
        //     if(response.data && Array.isArray(response.data)) {
        //         let items = response.data.map((item) => {
        //             const result = extractDataForCommonCard(item);
        //             return result;
        //         })

        //         this.setState({
        //             items: items,
        //             isLoaded: true
        //         });
        //         //console.log('last', response.data);
        //     } else {
        //         this.setState({
        //             items: [],
        //             isLoaded: true
        //         });
        //     } 
        // })
        // .catch((err) => {
        //     console.log(err.response);        
        // });
    }
    
    render() {
        //const { isLoaded } = this.state;
        const { isDisableComment } = this.state;
        const {isAuth, userAvatar } = this.props;
        return (
            <div className="comments">
                <header className="comments__header">
                    <strong>31 Comments</strong>
                    <div className="comments__hr"></div>
                </header>
                <AddComment submitAddComment={this.submitAddComment}
                    isAuth={isAuth}
                    userAvatar={userAvatar}
                    isDisableComment={isDisableComment}                    
                 />        
            </div>   
        )
    }
}
const MapStateToProps = (state) => {
    return {
        isAuth: state.auth.isAuth,        
        //userName: (state.auth.user) ? state.auth.user.displayName: null,
        userAvatar: (state.auth.user) ? state.auth.user.avatar: null
    }
};

export default connect(MapStateToProps, { showError })(CommentsBlock);
//<Loader isOpen={ ! isLoaded } type="inner" />