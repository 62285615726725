import React, { Component } from 'react';
import MomentUtils from '@date-io/moment';
//import { InputAdornment } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import moment from 'moment';
//https://material-ui.com/ru/components/material-icons/

//endAdornment: <InputAdornment position="end"><CalendarTodayIcon/></InputAdornment>,

import FormFieldBottom from './field-bottom';

export default class DatePickerField extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);        
    }

    handleChange(date, value) {      
        if(date && date._isValid) {
            this.props.input.onChange(value); console.log(value);
        }        
    }

    render() {
        const { label, input, placeholder, required, meta, note, correct } = this.props;
        const value = (input.value) ?  moment(input.value, "MM/DD/YY") : null;
        const classes = (meta.error) ? "form-field form-datepicker error" : "form-field form-datepicker"; 
        return (
            <div className={ classes }>
                <label className={ (required) ? "label label_required" : "label"}>{ label }</label>  
                <MuiPickersUtilsProvider utils={MomentUtils}>                          
                    <KeyboardDatePicker
                        disableToolbar                       
                        format="MM/DD/YY"
                        value={ value } 
                        inputVariant="outlined"
                        placeholder={ placeholder }                                            
                        onChange={ this.handleChange }                     
                        invalidDateMessage=""
                        keyboardIcon={ <CalendarTodayIcon /> }
                        InputProps={{                                                       
                            className: 'datepicker-input'
                        }}                        
                        DialogProps={{
                            className: 'datepicker-dialog'
                        }}
                    />
                </MuiPickersUtilsProvider> 
                <FormFieldBottom 
                    meta={meta}
                    note={note}
                    correct={correct}
                />    
            </div>
        )
    }

}