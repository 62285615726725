import React, { Component } from 'react';
import { Field, reduxForm, getFormValues, getFormSubmitErrors } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { ROUTESLIST } from '@/static/urls';
import WordsLimitedField from '@/components/forms/words-limited-field';
import RadioField from '@/components/forms/radio-field';
import DatePickerField from '@/components/forms/date-picker';
import api, { setErrors, arrangeData, convertDateToDb} from '@/middlewares/api';
import './index.scss';

class UploadSampleStep4 extends Component {

    constructor(props) {
        super(props);
        this.stepSubmit = this.stepSubmit.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    goBack() {
        const {prevStep, rawValues} = this.props;
        prevStep(rawValues);
    }

    stepSubmit(data) { 
        let formData = arrangeData(data);
        formData.deadline = convertDateToDb(formData.deadline);
        if ((data.type_of_used === 'master') || (data.type_of_used === 'both')) {
            formData.master_sampled_used = 1;
        } 
        if ((data.type_of_used === 'publish') || (data.type_of_used === 'both')) {
            formData.publishing_sampled_used = 1;
        } 
        delete formData.type_of_used;
       
        return new Promise((resolve, reject) => {
            api.post('clear/sample/step/four', formData)
            .then((response) => {                                    
                this.props.nextStep(formData, data);
                return resolve(null);
            })
            .catch((err) => {
                if(! err.response) return;
                if(err.response.status === 422) {
                    return reject(setErrors(err.response));                            
                }            
            });
        });
    } 
    
    render() {        
        const { handleSubmit, submitting, radioFieldsError, savingAll } = this.props; 
        return (
            <section className="upload-form__sec" style={this.props.bgImg}>
                <div className="content">
                    <div className="upload-form__top">  
                        <h2 className="head-shadow">CLEAR SAMPLES</h2>
                        <h3 className="head">
                            Step 4 of 4: THE SAMPLE DESCRIPTION
                        </h3>                       
                    </div>
                    <div className="form-step__body">
                        <form onSubmit={handleSubmit((data) => { 
                                return this.stepSubmit(data);
                            }) } className="form-layout"
                            noValidate>
                            <Field                            
                                name="description"
                                component={WordsLimitedField}  
                                required={true}                              
                                maxWords={ 150 }
                                placeholder="Tell us how you used the sample."
                                label="Description of the Sample Used"                            
                            />
                             <Field                            
                                name="deadline"
                                component={DatePickerField}                                       
                                placeholder="enter MM/DD/YY"
                                label="Preferred Clearance Deadline"                                       
                            />
                            <div className="upload-form__checks upload-form__checks_radio">
                                <Field                            
                                    name="type_of_used"
                                    component={RadioField}                                       
                                    value="master"
                                    label={
                                        <>
                                            <span>Master Sample Used (I've sampled directly from a pre-existing recording)</span>
                                            
                                        </>
                                    }       
                                    type="radio"                                                                  
                                /> 
                                <Field                            
                                    name="type_of_used"
                                    component={RadioField}                                       
                                    value="publish"
                                    label={
                                        <>
                                            <span>Publishing Sample Used (I've re-recorded, replayed and/or re-sung part, or all, of another song)</span>
                                            
                                        </>
                                    }
                                    type="radio"                                                                  
                                /> 
                                <Field                            
                                    name="type_of_used"
                                    component={RadioField}                                       
                                    value="both"
                                    label={
                                        <>                                            
                                            <span>Master & Publishing Use (I’ve used a significant amount of the master and 
                                                composition in my new recording. Example: “A Boy Is a Gun” by Tyler, the Creator 
                                                samples “Bound” by Ponderosa Twins Plus One)
                                            </span>
                                        </>
                                    } 
                                    type="radio"                                                                  
                                /> 
                                {
                                    radioFieldsError &&
                                    <div className="form-field__note form-field__note_other error">{ radioFieldsError }</div>
                                }  
                            </div>                           

                            <div className="submit-row_txt">
                                <p className="as-text">
                                    By clicking Next button you are agreeing to the 
                                    <Link to={ ROUTESLIST.STATIC_TERMS } className="link-thin"
                                        target="_blank" rel="noopener noreferrer"
                                        >Terms and Conditions.
                                    </Link> 
                                </p>                                
                            </div>
                            <div className="submit-row submit-row_txt">  
                                    <button className="btn btn-second" onClick={ this.goBack }
                                        type="button">Back</button> 
                                    <button className="btn btn-primary" disabled={ submitting || savingAll }
                                        type="submit">Next</button>     
                            </div>
                        </form>                        
                    </div>
                </div>
            </section>           
        )
    }
}

const UploadSampleStep4Form =  reduxForm({
    form: 'upload-sample-step4' 
  })(UploadSampleStep4);

const mapState = (state) => {   
    const errors = getFormSubmitErrors('upload-sample-step4')(state);    
    return {
        rawValues: getFormValues('upload-sample-step4')(state),
        radioFieldsError: (errors.master_sampled_used || errors.publishing_sampled_used) ? 'Sample Used is required.' : null
    }
}
export default connect(mapState)(UploadSampleStep4Form);
//export default UploadSampleStep4Form;