import React, { useState } from 'react';

function AddComment(props) {  
  const { submitAddComment,  userAvatar, isAuth, isDisableComment } = props;
    
    const [text, setText] = useState('');
    if (! isAuth) return null; 
    const changeHandler = (event) => {
        setText(event.target.value);
    }

    const clickHandler = (event) => {
        event.preventDefault();
        if(! text) return false;
        const res = submitAddComment(text);
        res.then(() => {
           setText('');
        });
        return false;
    };
    
    const isDisabled = isDisableComment || (! text);

    return (
      <div className="comments-add">
          <div className="comments-add__col-avatar">
            <img src={userAvatar} alt="" className="comment-avatar"/>
          </div>
          <div className="comments-add__col-form">
              <form className="comments-add__form" onSubmit={ clickHandler }>
                  <textarea className="comments-add__input"
                    value={text}
                    onChange={changeHandler}
                    placeholder="Write you’r comment here"
                  />
                <button type="submit" className="btn comments-add__btn" disabled={ isDisabled } >Send</button>
                </form>
          </div>
          
      </div>
    );
}

export default AddComment;
