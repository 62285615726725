import React, { Component, Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';

import api, { setErrors} from '@/middlewares/api';
import SavedInfoModal from '@/components/modals/saved';
import PasswordField from '@/components/forms/password-field';

class ChangePasswordForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            isQuery: false,
        }
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    onCloseModal() {
        this.setState(
            {
                isOpenModal: false,
            }
        );
    }

    handleFormSubmit(data) { 
        this.setState(
            {
                isQuery: true,
            }
        );       
        return new Promise((resolve, reject) => {
            api.post('user/password', data)
            .then((response) => {
                this.setState(
                    {
                        isOpenModal: true,
                        isQuery: false,
                    }
                );
                this.props.reset();    
                return resolve(null);
            })
            .catch((err) => {
                this.setState(
                    {
                        isQuery: false,
                    }
                );  
                if(! err.response) return;
                if(err.response.status === 422) {
                    return reject(setErrors(err.response));                            
                }                            
            })
        });         
    }

    render() {
        const { handleSubmit, pristine, submitting } = this.props; 
        const { isOpenModal, isQuery } =  this.state;
        return (
            <Fragment>
                <form onSubmit={handleSubmit((data) => { 
                    return this.handleFormSubmit(data);
                }) } className="profile-form"
                noValidate>
                <div className="form-row">
                    <div className="form-col_2">
                        <Field                            
                            name="current_password"
                            component={ PasswordField } 
                            label="Current Password"                            
                        />                    
                    </div> 
                </div>
                <div className="form-row">
                    <div className="form-col_2">
                        <Field                            
                            name="password"
                            component={ PasswordField }
                            label="New Password"                            
                        />                                
                    </div>
                </div> 
                <div className="form-row">
                    <div className="form-col_2">
                        <Field                            
                            name="password_confirmation"
                            component={ PasswordField }  
                            label="Confirm New Password"                            
                        />                                
                    </div>
                </div>                
                <div className="submit-row">
                    <button className="btn btn-primary" disabled={ isQuery }
                            type="submit">Update Password
                    </button> 
                    
                </div>
            </form>
            <SavedInfoModal open={ isOpenModal } onClose={ this.onCloseModal }/>            
        </Fragment>
        )
    }
}

const ChangePasswordFormWrapped =  reduxForm({
    form: 'dashboard-security-password-form' 
  })(ChangePasswordForm);

export default ChangePasswordFormWrapped;
