import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ROUTESLIST } from '@/static/urls';
import './index.scss';

import api from '@/middlewares/api';
import Loader from '@/components/loader/index';


import VideoLinkShow from '@/components/video/link';
import CrateSongCard from '@/components/crate/song-card';
import CrateCard from '@/components/crate/card';
import CommentsBlock from '@/components/blocks/comments';

import { extractItem, extractDataForCommonCard } from '@/utils/crates.js';


export default class OneCratePage extends Component {
    constructor(props) {
        super(props);  
        this.state = {
            item: null,
            id: null,
            isLoaded: false,
            cardsItems: null,
        }               
    } 

    componentDidMount() {
        this.fetchCrate();
    }

    fetchCrate() {
        const id = (this.props.match.params.id) ? this.props.match.params.id : 0;
        console.log(id);
        if(! id) return;
        api.get('crate/show', { params: {
            id: id
        }})
        .then((response) => { console.log('crate', response.data);
            const item = extractItem(response.data);
            let list = null;
            if(response.data.artist_crates && response.data.artist_crates.length > 0 ) {
                list = response.data.artist_crates.map((item) => {
                    const result = extractDataForCommonCard(item);
                    return result;
                })
            }
            this.setState({
                item: item,
                id: id,
                isLoaded: true,
                cardsItems: list
            })
        })
        .catch((err) => {
            console.log(err.response);  
            this.setState({               
                isLoaded: true,
            })      
        });
    }


    render() {
        const { item, isLoaded, id } = this.state;
        if(! isLoaded) return (
            <Loader isOpen={ ! isLoaded } type="inner" />
        ); 
        if(! item) return null;      
        const { image, video, features, songs, mentions, artist, title } = item;
        const mentionsBlc = this.renderMentions(mentions);
        const secStyle = { backgroundImage: "url(" +  image  + ")"};
        const moreBlk = this.renderMore(artist);
        return (
            <main className="page one-crate-page">
                <section className="entity-sec banner-filter banner-filter_hide" style={ secStyle }>
                    <div className="content">
                        <div className="entity-sec__top">                            
                                <h2 className="head-shadow">{ artist }</h2>
                                <h3 className="head">
                                    { title }
                                </h3>
                        </div>
                        <div className="one-crate__wrap">
                            <div className="one-crate__col">
                                <div className="one-crate__blc"> 
                                    <h4 className="one-crate__blc-title">ABOUT THIS SONG</h4>
                                    <div className="one-crate__blc-body">
                                        <div className="one-crate__video">
                                            <img src={ image } alt="" />                                             
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="one-crate__blc">
                                    <h4 className="one-crate__blc-title one-crate__blc-title_features">ABOUT THIS SONG</h4>
                                    <div className="one-crate__blc-body">                                       
                                        {features.map((item, index) =>
                                            <p className="one-crate__feature" key={index}>
                                                <span className="label">{ item.label }</span>
                                                <span className="value">{item.value}</span>
                                            </p>                                               
                                        )}
                                      
                                    </div>
                                </div>
                                {
                                    video &&
                                    <div className="one-crate__blc">
                                        <h4 className="one-crate__blc-title">Related Music Videos</h4>
                                        <VideoLinkShow 
                                            url={ video }
                                        />
                                    </div>

                                }
                                {
                                    mentionsBlc
                                }
                                                       
                            </div>
                            <div className="one-crate__col">
                                <div className="one-crate__blc">
                                    <h4 className="one-crate__blc-title">Samples Based on This Song</h4>
                                    <Link className="btn btn-primary btn-fw one-crate__el-wrap" to={ ROUTESLIST.UPLOAD_ADD_CRATE_SAMPLE + id } >Add Your Sample to This Song</Link>
                                    <div className="one-crate__blc-body aside-shop-wrap">
                                        {songs.map((item) =>
                                                <CrateSongCard key={ item.id }                                                     
                                                    item={item}                                                    
                                                        />                                               
                                            )} 

                                    </div>
                                </div>
                                { moreBlk }
                            </div>
                        </div> 
                        <CommentsBlock itemId={id} />       
                    </div>
                </section>        
            </main>
        )
    }

    renderMore(artist) {
        const { cardsItems } = this.state;
        if(! cardsItems ) return null;
        return (
            <div className="one-crate__blc">
                <h4 className="one-crate__blc-title">More { artist } on Ample Samples</h4>
                <div className="one-crate__blc-body aside-crates-wrap crates-blc">  
                    {
                        cardsItems.map((item) => 
                            <CrateCard 
                                key={ item.id }
                                item={ item }
                            />
                        )
                    }                                    
                </div>
            </div>
        );
    }

    renderMentions(mentions) {
        if((! mentions) || (mentions.length === 0)) return null;
        return (
            <div className="one-crate__blc">                                    
                <h4 className="one-crate__blc-title">Facts About this Song</h4>
                <div className="one-crate__blc-body">
                    {
                        mentions.map((el) => {
                            return (
                                <div key={el.id}>
                                    <div className="as-quote as-quote_block">
                                        {el.content}
                                    </div>
                                    {
                                        el.url &&
                                        <p className="as-quote__sign">- <span className="red">{ el.url }</span></p>
                                    }
                                </div>
                            )
                        })
                    }                           
                </div>
            </div>            
        );
    }
}