import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';

import { ROUTESLIST } from '@/static/urls';
import { phonePattern } from '@/utils/forms.js';
import FormField from '@/components/forms/form-field';

class UploadMechStep1 extends Component {

    constructor(props) {
        super(props);
        this.stepSubmit = this.stepSubmit.bind(this);
    }

    stepSubmit(data) {
        console.log(data);
        this.props.nextStep();
    }
    
    render() {        
        const { handleSubmit } = this.props;
        return (
            <section className="upload-form__sec">
                <div className="content">
                    <div className="upload-form__top">  
                        <h2 className="head-shadow">CLEAR MECHANICAL</h2>
                        <h3 className="head">
                            Step 1 of 2: CLIENT INFO
                        </h3>                       
                    </div>
                    <div className="form-step__body">
                        <form onSubmit={handleSubmit((data) => { 
                                return this.stepSubmit(data);
                            }) } className="form-layout"
                            noValidate>
                            <div className="form-row">
                                <div className="form-col_2">
                                    <Field                            
                                        name="name1"
                                        component={FormField}
                                        required={true}
                                        type="text"
                                        placeholder="Set Artist First and Last Name"
                                        label="Contact Name"                            
                                    />
                                    
                                </div>                               
                                <div className="form-col_2">
                                    <Field                            
                                        name="name2"
                                        component={FormField}                                        
                                        type="text"
                                        placeholder="Set Artist First and Last Name"
                                        label="Your e-mail Address"                            
                                    />                                    
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-col_2">
                                    <Field                            
                                        name="name3"
                                        component={FormField}
                                        required={true}
                                        type="text"
                                        placeholder="Set Artist First and Last Name"
                                        label="Name of your Label"                            
                                    />
                                    
                                </div>                               
                                <div className="form-col_2">
                                    <Field                            
                                        name="name4"
                                        component={FormField}                                        
                                        type="text"
                                        placeholder="Set Artist First and Last Name"
                                        label="Company Distributing Your Project"                            
                                    />                                    
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-col_2">
                                    <Field                            
                                        name="name5"
                                        component={FormField}                                       
                                        type="text"
                                        placeholder="Set Artist First and Last Name"
                                        label="Your Business Address"                            
                                    />
                                    
                                </div>                               
                                <div className="form-col_2">
                                    <Field                            
                                        name="name6"
                                        component={FormField}                                       
                                        type="text"
                                        {...phonePattern}
                                        placeholder="Set Artist First and Last Name"
                                        label="Your Phone Number"                            
                                    />                                    
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-col_2">
                                    <Field                            
                                        name="name7"
                                        component={FormField}                                       
                                        type="text"
                                        placeholder="Set Artist First and Last Name"
                                        label="Party Responsible For Making Payment?"                            
                                    />
                                    
                                </div>                               
                                <div className="form-col_2">
                                    <Field                            
                                        name="name8"
                                        component={FormField}                                        
                                        type="text"
                                        placeholder="Set Artist First and Last Name"
                                        label="Who Will Sign Sample Agreements?"                            
                                    />                                    
                                </div>
                            </div>
                            <div className="submit-row_txt">
                                <p className="as-text">
                                    By clicking Confirm button you are agreeing to the 
                                    <Link to={ ROUTESLIST.STATIC_TERMS } className="link-thin"
                                        target="_blank" rel="noopener noreferrer"
                                        >Terms and Conditions.
                                    </Link>
                                </p>
                                <div className="submit-row__btn-col">
                                    <button className="btn btn-primary"
                                        type="submit">Next</button> 
                                </div>
                            </div>
                        </form>                        
                    </div>
                </div>
            </section>           
        )
    }
}

const UploadMechStep1Form =  reduxForm({
    form: 'upload-mech-step1' 
  })(UploadMechStep1);

export default UploadMechStep1Form;