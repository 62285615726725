import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { ROUTESLIST } from '@/static/urls';

//import Subscription from './subscription';
import FooterMenuColumn from './menu-column.js';
import './index.scss';

const getYear = () => {
    const now = new Date();
    return now.getFullYear();
}
//Domain Knowledge FAQ

export default class Footer extends Component {
    render() {
        const year = getYear();
        return (
            <footer className="page-footer">
                <div className="content">
                    <div className="page-footer__wrap">
                        <FooterMenuColumn classList="page-footer__col" title="OVERVIEW">
                            <ul className="page-footer__links">
                                <li><Link to={ROUTESLIST.CLEAR_SAMPLE}>Music Clearance</Link></li>
                                <li><Link to={ROUTESLIST.STATIC_TERMS}>Terms & Conditions</Link></li>
                                <li><Link to={ROUTESLIST.CLOSED_PAGE + 'corporate-terms-and-conditions'}>Pricing</Link></li>
                                <li><Link to={ROUTESLIST.STATIC_TESTIMONIALS}>Interviews</Link></li>                                
                            </ul>
                        </FooterMenuColumn>
                        <FooterMenuColumn classList="page-footer__col" title="MENU">
                           <ul className="page-footer__links">
                                <li><Link to={ROUTESLIST.HOME}>About us</Link></li>
                                <li><a href="mailto:info@thesampl.es">Contact us</a></li> 
                                <li><a href="https://grooves.theamplesamples.com" target="_blank" rel="noopener noreferrer">Grooves</a></li>
                                <li><Link to={ROUTESLIST.UPLOAD_REVIEW}>Sample Review</Link></li>
                            </ul>         
                        </FooterMenuColumn>
                        <FooterMenuColumn classList="page-footer__col page-footer__col_socials" title="Socials">
                             <ul className="page-footer__links">
                                <li><a href="https://www.youtube.com/channel/UCX83YyEkoLn3RXnV5pnR0Dg" target="_blank" rel="noopener noreferrer">Youtube</a></li>
                                <li><a href="https://twitter.com/theamplesamples" target="_blank" rel="noopener noreferrer">Twitter</a></li>  
                                <li><a href="https://www.instagram.com/theamplesamples/" target="_blank" rel="noopener noreferrer">Instagram</a></li>
                                <li><a href="https://www.linkedin.com/in/ample-samples%E2%84%A2-28b253160/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>  
                            </ul>   
                        </FooterMenuColumn>
                    </div>
                    <div className="page-footer__cr">
                        Copyright © { year } Ample Samples&trade;. All rights reserved.
                    </div>
                </div>
            </footer>
        )
    }
}
