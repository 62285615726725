export const aboutItems = [
    {
        id: 'a1',
        label: 'Beat By',
        value: 'ESCAPISM',
    },
    {
        id: 'a2',
        label: 'TITLE',
        value: 'Lazy Lo-Fi Dubs',
    },
    {
        id: 'a3',
        label: 'Genre',
        value: 'Hip-Hop',
    },
    {
        id: 'a4',
        label: 'File Format',
        value: '24 Bit WAV Format',
    },
    {
        id: 'a5',
        label: 'BPM',
        value: '70-86',
    },
    {
        id: 'a6',
        label: 'File Size',
        value: '458MB',
    },
    {
        id: 'a7',
        label: 'Licenze',
        value: '100% Royalty-Free',
    },    
];

export const downloadItems = [
    {
        id: 'd1',
        value: '187 x Total Files',       
    },
    {
        id: 'd2',
        value: '4 x Construction Kits',       
    },
    {
        id: 'd3',
        value: '25 x Drum and Percussion Loops',       
    },
    {
        id: 'd4',
        value: '42 x Drum One-Shots',       
    },
    {
        id: 'd5',
        value: '38 x Instrument Loops',       
    },
    {
        id: 'd6',
        value: '32 x SFX',       
    },
    {
        id: 'd7',
        value: '8 x Vocals', 
    },
];