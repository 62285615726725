import React, { Component } from 'react';
import { Link } from 'react-router-dom';

//import { ROUTESLIST } from '@/static/urls';
import { getQueryParam } from '@/utils/common';

import ArtistCard from '@/components/artist/card';
import AlbomCard from '@/components/albom/card';
import SingleCard from '@/components/single/card';
import VideoCard from '@/components/video/card';

import './index.scss';
import { artists, alboms, singles, videos } from './data.js';

export default class SearchPage extends Component {
    render() {        
        const query = getQueryParam(this.props.location.search, 'query');
        return (
            <main className="page search-results-page">
                <div className="search-info">
                    <div className="content">
                        <p>
                            Search results for: 
                            <span className="search-info__query">{ query }</span> 
                        </p>
                    </div>
                </div>
                <section className="search-sec">
                    <div className="content">
                        <div className="search-sec__header">
                            <h4 className="search-sec__title">
                                Artists
                                <sup className="search-sec__cnt">1 346</sup>
                            </h4>
                            <Link to="/samples" className="search-sec__link">
                                View All
                            </Link>
                        </div>
                        <div className="search-sec__results">
                            <div className="artists-blc">
                            {artists.map((item) =>
                                    <ArtistCard key={ item.id }
                                        id={item.id}
                                        image={item.image}
                                        name={item.name}
                                        tags={item.tags}
                                            />
                                )} 
                            </div>
                        </div>
                   </div> 
                </section>
                <section className="search-sec">
                    <div className="content">
                        <div className="search-sec__header">
                            <h4 className="search-sec__title">
                                Albums
                                <sup className="search-sec__cnt">1 346</sup>
                            </h4>
                            <Link to="/samples" className="search-sec__link">
                                View All
                            </Link>
                        </div>
                        <div className="search-sec__results">
                            <div className="alboms-blc">
                                {alboms.map((item) =>
                                        <AlbomCard key={ item.id }
                                            id={item.id}
                                            image={item.image}
                                            title={item.title}
                                            authors={item.authors}
                                            year={item.year}
                                                />
                                    )} 
                            </div>
                        </div>
                   </div> 
                </section>
                <section className="search-sec">
                    <div className="content">
                        <div className="search-sec__header">
                            <h4 className="search-sec__title">
                                Singles
                                <sup className="search-sec__cnt">1 346</sup>
                            </h4>
                            <Link to="/samples" className="search-sec__link">
                                View All
                            </Link>
                        </div>
                        <div className="search-sec__results">
                            <div className="singles-blc">
                                    {singles.map((item) =>
                                        <SingleCard key={ item.id }
                                            id={item.id}
                                            image={item.image}
                                            title={item.title}
                                            source={item.source}
                                            duration={item.duration}
                                                />
                                    )} 
                            </div>
                        </div>
                   </div> 
                </section>
                <section className="search-sec">
                    <div className="content">
                        <div className="search-sec__header">
                            <h4 className="search-sec__title">
                                    Music Videos
                                <sup className="search-sec__cnt">99 999 799</sup>
                            </h4>
                            <Link to="/samples" className="search-sec__link">
                                View All
                            </Link>
                        </div>
                        <div className="search-sec__results">
                            <div className="videos-blc">
                                    {videos.map((item) =>
                                        <VideoCard key={ item.id }
                                            id={item.id}
                                            src={item.src}
                                            title={item.title}
                                            author={item.author}
                                            desc={item.desc}
                                                />                                               
                                    )} 
                            </div>
                        </div>
                   </div> 
                </section>
                
            </main>
        )
    }
}
