import React, { Component } from 'react';

import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { testimonials } from './data.js';

import './index.scss';

import TestimonialCard from './itemCard';
import TestimonialBody  from './itemBody';
import SocialSticky from '@/components/social-sticky/index';

export default class TestimonialsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: testimonials[0],
        }; 
        this.changeActive = this.changeActive.bind(this);       
    }
    changeActive(id) {
        //TODO
        console.log(id);
        const newElement = testimonials.find((item) => {
            return (item.id === id);
        });
        console.log(newElement);
        this.setState({current: newElement});
    }


    render() {
        const current = (this.state.current) ? this.state.current.id : null;
        return (
            <main className="page">
                 <section className="top-banner banner-filter banner-filter_fw" id="top-banner-testimonials">
                    <div className="content">
                        <div className="top-banner__wrap">
                            <div className="top-banner__content">
                                <h2 className="head-shadow">Testimonials</h2>
                                <h3 className="head">
                                    These are the stories of people who have 
                                    had their samples cleared quickly and accurately by Ample Samples ™
                                </h3>                                
                            </div>
                        </div>
                    </div>
                </section>
                <div className="testimonials">
                    <div className="content">
                        <div className="testimonials-cards">
                            <div className="testimonials-cards__wrap"> 
                                                
                                    {
                                        testimonials.map((item) => 
                                            <TestimonialCard
                                                key={item.id}
                                                onClick={() => this.changeActive(item.id)}                                        
                                                name={item.person.name}
                                                image={item.person.img}
                                                position={item.person.position}
                                                positionUnder={item.person.positionUnder}
                                                brend={item.person.brend}
                                                isActive={(current === item.id)}
                                            />
                                        )

                                    }                            
                            </div>                        
                        </div>
                        <div className="testimonial-content">
                        <SwitchTransition>
                        <CSSTransition
                            key={this.state.current.id}
                            classNames="testimonial-anim"
                                timeout={{                                    
                                    enter: 700,
                                    exit: 200,
                                   }}>  
                            { 
                               this.state.current &&
                                <TestimonialBody 
                                    key={this.state.current.id}
                                    testimonials={testimonials}
                                />
                            }
                        </CSSTransition>
                        </SwitchTransition> 
                        </div>
                    </div>
                </div>
                <SocialSticky />                
            </main>
        )
    }
}
