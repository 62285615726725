import React from 'react';
import PropTypes from 'prop-types';

const FormFieldBottom = (props) => {
    const {meta, correct, note} = props;
    if (meta.error) {
        return (<div className="form-field__note error">{meta.error}</div>);
    } else if (correct && meta.touched) {
        return (<div className="form-field__note valid">{correct}</div>);
    } else if (note) {
        return (<div className="form-field__note note">{note}</div>);
    } 
    return (<div className="form-field__note empty"></div>);
};

FormFieldBottom.propTypes = {
    meta: PropTypes.object.isRequired,
    correct: PropTypes.string,
    note: PropTypes.string,
}

export default FormFieldBottom;