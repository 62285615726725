import React, { Component } from 'react';
import './home.scss';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

//import { releases } from './data.js';
import { ROUTESLIST } from '@/static/urls';

//import  LatestCratesBlock from '@/components/blocks/last-crates';
import SocialSticky from '@/components/social-sticky/index';

class HomePage extends Component {
    render() {

        const { isAuth } = this.props;
        
        return (
            <main className="page">
                <section className="home-banner">
                    <div className="content">
                        <h2 className="head-shadow">Ample Samples</h2>
                        <h3 className="head">Clear your samples quickly & accurately</h3>
                        <div className="home-banner__quote">
                            <p>
                                "It is perfectly obvious to the listener that in musical terms, the two songs are 
                                virtually identical."
                            </p>
                            <div className="home-banner__quote-author">– Judge Richard Owen</div>
                        </div>
                        <div className="home-banner__info">
                            <p>
                                In August of 1976 Judge Richard Owen found that George Harrison’s #1 hit, 
                                “My Sweet Lord”, had subconsciously infringed on the copyright of “He’s So Fine” 
                                by The Chiffons. Harrison was forced to fork over nearly $1.6M in 
                                earnings to the publishers of “He’s So Fine” as a result. 
                            </p>
                            <p> 
                                <strong>Bright Tunes Music Corp.</strong> v. <strong>Harrisongs Music, Ltd.</strong> was the first of the music 
                                industry’s major infringement lawsuits. Don’t let assumptions or errors in your sample 
                                clearance process lead your song or project to become one of the latest releases to hold 
                                such an unceremonious honor. 
                            </p>                            
                        </div>
                        <div className="home-banner__btns">
                            <Link className="btn btn-primary" to={ROUTESLIST.CLEAR_SAMPLE}>Clear Your Samples!</Link>
                        </div>
                    </div>
                </section>
                <section className="home-section home-section_right-img">
                    <div className="home-section__col">
                        <div className="home-section__text">
                            <div className="home-section__wrap">
                                <h3 className="home-section__title">How it Works?</h3>
                                <div className="home-section__body">
                                    <p>
                                        Create an account and follow our four easy steps as part of engaging Ample Samples™ 
                                        to begin clearing the sample or samples in your new song(s). 
                                    </p>
                                    <p>
                                        Make and initial payment for our services and the clearance process 
                                        for your project will begin.
                                    </p>
                                    <p>
                                        We will negotiate based on your budget and the nature of your sample use 
                                        and update you as the clearances for your project progresses from start 
                                        to finish.
                                    </p>
                                </div>
                                <footer className="home-section__btns">
                                    {   isAuth
                                        ? <Link className="btn btn-primary" to={ROUTESLIST.CLEAR_SAMPLE}>START NOW</Link>
                                        : <Link className="btn btn-primary" to={ROUTESLIST.SIGNUP}>START NOW</Link>
                                    }  
                                </footer>
                            </div>               
                        </div>                    
                    </div>
                    <div className="home-section__col">
                        <div className="home-section__img" id="home-section-bg1"></div>                    
                    </div>
                </section>
                <SocialSticky />
            </main>
        )
    }
}
function mapStateToProps(state) {  
    return { 
         isAuth: state.auth.isAuth  
    };
}

export default connect(mapStateToProps, null)(HomePage);
/*
<section className="home-section home-section_left-img">
    <div className="content">
        <h2 className="head-shadow">Ample Samples</h2>
        <h3 className="head">Clear your samples quickly & accurately</h3>
    </div>
</section>
mklink /d "d:\OpenServer\domains\as1.loc\backend\public\assets" "d:\OpenServer\domains\as1.loc\frontend\public\assets"
*/
