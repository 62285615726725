import React, { Component } from 'react';

export default class TestimonialCard extends Component {
    render() {
        const {onClick, name, image, position, positionUnder, brend, isActive } = this.props;
        const classes = (isActive) ? 'testimonial-card testimonial-card_active' : 'testimonial-card';
        return (
            <div className={ classes }> 
                <div className="testimonial-card__inner" role="button" onClick={onClick}>
                    <div className="testimonial-card__img">
                        <img src={image} alt={name} />
                    </div>
                    <div className="testimonial-card__brend">
                        <img src={brend} alt="" />
                    </div>
                    <div className="testimonial-card__name">
                        {name}
                    </div>
                    <div className="testimonial-card__position">
                        {position}
                    </div>
                    <div className="testimonial-card__position">
                        {positionUnder}
                    </div>
                </div>
            </div>
        )
    }
}
