import { StatusesClassesMap } from '@/static/statuses.js';
import moment from 'moment';

export const getQueryParam = (search, key) => {
    if(! search ) return null;
    const pairs = search.substring(1).split('&');
    let pair;
    for(let i = 0; i < pairs.length; i++) {
        pair = pairs[i].split('=');
        if(pair[0] === key) {
            return pair[1] ? decodeURI(pair[1]) : null;
        }
    }
    return null;
}

export const getQueryParams = (search) => {
    let obj = {};
    if(! search ) return obj;
    const pairs = search.substring(1).split('&');
    let pair;
    for(let i = 0; i < pairs.length; i++) {
        pair = pairs[i].split('=');
        obj[pair[0]] = (pair[1]) ? decodeURI(pair[1]) : null;        
    }
    return obj;
}

export const replaceQueryParams = (search, params, returnQuery = false) => {
    let reply = getQueryParams(search);
    Object.keys(params).forEach((key) => {
        reply[key] = params[key];
    });
    if(returnQuery) {
        let query = [];
        Object.keys(reply).forEach((key) => {
           query.push(encodeURI( key + '=' + reply[key]));
        });
        if(query.length > 0) {
            return '?' + query.join('&');
        } else {
            return '';
        }
    } else {
        return reply;
    }
}

/* eslint-disable no-useless-escape, eqeqeq */
export const getYoutubeId = (url) => {
    const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    if (match && match[2].length == 11) {
        return match[2];
    } else {
        return false;
    }
}
/* eslint-enable no-useless-escape, eqeqeq */

export const extractVideoURL = (url) => {
    if((! url) || (typeof url !== "string")) {
        return null;
    }
    if(url.indexOf('youtube.com/') === -1) return null;
    const id = getYoutubeId(url);
    if(! id) return null;
    return 'https://www.youtube.com/embed/' + id;
}

export const extractPaninationMeta = (data) => {
    if(! data) return null;
    const { meta } = data;
    if(meta) {
        const time = new Date();
        meta.key = time.getTime().toString(16);
        return meta;    
    } 
    return null;   
}

export const extractDataWithMap = (data, map) => {
    let result = [];
    const id = data.id;
    map.forEach((el) => {
        if(typeof data[el.server] == "undefined") return;
        let val = '';
        switch(el.type) {
            case 'string':
            case 'raw': {
                val = data[el.server];
                break;
            }
            case 'status': {
                const v = data[el.server];
                const cl =  (StatusesClassesMap[v]) ? StatusesClassesMap[v] : '';
                val = '<span class="status status_' + cl +'">'+ v +'</span>';
                break;
            }
            case 'arr_names': {
                const v = data[el.server];
                let names = [];
                if(Array.isArray(v)) {
                    v.forEach((obj) => {
                        if(obj[el.key]) {
                            names.push(obj[el.key]);
                        }
                    });
                }
                val = names.join(', ');
                break;
            } 
            case 'obj_name': {
                const v = data[el.server];
                if(v && (typeof v[el.key] !== "undefined")) {
                    val = v[el.key];
                }
                break;
            }  
            default: {
                
            }           
        }
        result.push({id: el.server + '_' + id, label: el.label, value: val});
    });
    return result;
}

export const fillWithZero = (val, cnt) => {
    let res = val.toString();
    while(res.length < cnt) {
        res = '0' + res;
    }
    return res;
}

/* eslint-disable eqeqeq */
export const formatMoney = (value, withCent = false) => {
    let res = '';
    let val = value;
    let rest;
    if((val == 0) || (val === null)) return '0';
    if(withCent) {
        rest = val % 100;
        val = (val - rest) / 100;
        res = '.' + fillWithZero(rest, 2);
        if(val == 0) {
            res = '0' + res;
        }
    }
    while( val > 0) {
        rest = val % 1000;
        val = (val - rest) / 1000;        
        if(val > 0) {
            res =  fillWithZero(rest, 3) + res;
            res = ',' + res;
        } else {
            res =  rest + res;
        }
    }     
    return res;
}
/* eslint-enable eqeqeq */

export const formatDate = (date) => {
    let d = moment(date);
    if(!d.isValid()) return '';
    return d.format('D MMM YYYY');
}