import React, { Component } from 'react';
//import { capitalizeWord } from '@/utils/words.js';



export default class DashboardCrateCard extends Component {
    render() {  
        const {id, item } = this.props; 
        const { image_url, song_title, sampled_songs}  = item;
        //const statusClasses = 'status status_' + status;
        return ( 
            <tr className="db-crate-card dashboard-table__card">
                <td className="db-crate-card__body">
                    <div className="db-crate-card__img">
                        <img src={ image_url } alt={ song_title } />
                    </div>                    
                    <h4>{ song_title }</h4>                   
                </td>
                <td className="db-crate-card__children">
                    {
                        sampled_songs.map((child) => {
                            return this.renderChild(child)
                        })
                    }
                </td>
            </tr>
        )
    }

    renderChild(child) {
        const { id, status, song_title, artist_name } = child;
        const statusClasses = 'status status_' + status;
        return (
            <div className="db-multi" key={ id }>
                <div className="db-multi__flow">
                    <h4 className="title">{ song_title}</h4>
                    <div className="info">{ artist_name }</div>
                </div>
                <div className="db-multi__fixed">
                    <span className={statusClasses}>{ status }</span>
                </div>
                
            </div>
        )
    }

   
}

/*

<td className="db-sample-card__info">
                    <div className="db-sample-card__row">
                        <div className="db-sample-card__col">
                            <img src={ img} alt={title} />
                        </div>
                        <div className="db-sample-card__col">
                            <span className="title">{ title }</span>
                            <span className="info">Clearance: {clearance}</span>
                        </div>
                    </div>
                </td>
                <td className="db-sample-card__status">
                    <span className={statusClasses}>{ status }</span>
                </td>
                <td className="db-sample-card__fee">
                    ${fee} {feeP}
                </td>
                <td className="db-sample-card__actions">
                    {
                        isDetail ?
                        <Link to={ROUTESLIST.DASHBOARD_ONE_SAMPLE + id} className="btn btn-act-iconed">
                            <i className="as-icon as-icon_nav-right"></i>
                        </Link>                        
                        :
                        <span>N/A</span>
                    }
                                      
                </td>









*/