import React, { Component } from 'react';

export default class DashboardTabs extends Component {
    constructor(props) {
        super(props);        
        this.toggleTab = this.toggleTab.bind(this);
        this.state = {           
           active: -1,
           items: [],          
        }              
    }
    toggleTab(index) { 
       this.setItems(index);
    } 
    setItems(active) {
        const { items } = this.props;
        const updated = items.map((item, index) => {
            let res = Object.assign({}, item);
            res.isActive = (active === index) ? true : false; 
            return res;
        });
        
        this.setState({
            active: active,
            items: updated,            
        });
    }

    componentDidMount() {
        this.setItems((this.props.items.length > 0) ? 0 : -1);       
    }
    componentDidUpdate(prevProps) {
        /* eslint-disable eqeqeq */
        if((prevProps.items != this.props.items) || ( prevProps.updatedKey !== this.props.updatedKey)) {
            const active = this.state.active;
            this.setItems(active);
        }
        /* eslint-enable eqeqeq */
    }
    
    render() {
        const { panels } = this.props;        
        const { active, items } = this.state; 
       
        return (
            <div className="dashboard-tabs">
                <div className="dashboard-tabs__nav" >
                    {  
                        items.map((item, index) => {
                            const { label, isCount, count, isActive} = item;
                            let classes = (isCount) ? 'btn dashboard-tabs__btn dashboard-tabs__btn_count' :
                                'btn dashboard-tabs__btn';                               
                            if(isActive) classes += ' dashboard-tabs__btn_active'
                            return (
                                <button type="button" 
                                    key={ item.id }
                                    className={ classes } 
                                    onClick={() => this.toggleTab(index)}
                                >
                                    { label }
                                    {
                                        isCount &&
                                        <span className="count">{ count }</span>
                                    }
                                </button>

                            );
                        })
                    }                    
                </div>
                <div className="dashboard-tabs__content" >
                    {
                        (active > -1) && panels[active] &&
                        panels[active]
                    }
                </div>
            </div>
        )
    }
}
