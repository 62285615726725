import React, { Component } from 'react';


export default class CrateSongCard extends Component {
    render() {
        const {image, title, artist, genres, features } = this.props.item; 
        
        return (
            <div className="shop-card crate-song-cart">
                <div className="shop-card__content">
                    <div className="shop-card__col">
                        <div className="shop-card__img" tabIndex="0" aria-haspopup="true">
                            <img src={image} alt={ title } />
                        </div> 
                    </div>                                       
                    <div className="shop-card__col shop-card__col_info">
                        <div className="shop-card__author">
                            { artist }
                        </div>                    
                        <div className="shop-card__title">
                            { title }
                        </div>
                        <div className="shop-card__tags">
                            { genres }
                        </div> 
                        <ul className="shop-card__info">
                            {
                                features.map((item) =>
                                    <li key={item.key}>
                                        <span className="label">{ item.label }</span>
                                        <span className="value">{item.value}</span>
                                    </li>                                               
                                )
                            }
                        </ul>                        
                    </div>
                </div>
            </div>
        )
    }
}
