import React from 'react';

export default function CommentCard(props) {
    const { writtenAt, content, user } = props.item;
    const { user_name, avatar_url } = user; 
    return (
        <div className="comment-card">
            <figure className="comment-card__info">
                <div className="comment-card__img">
                    <img src={ avatar_url } alt="" />
                </div>
                <figcaption>
                    <h6>{ user_name }</h6>
                    <span>{ writtenAt }</span>
                </figcaption>
            </figure>
            <div className="comment-card__content" dangerouslySetInnerHTML={{__html: content}}>                
            </div>
        </div>
    )
}
