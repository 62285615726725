import React, { useEffect } from 'react';
import './assets/sass/main.scss';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import {Helmet} from "react-helmet";


import store from './store';
import history from './store/history';
import { ROUTESLIST } from './static/urls';
//import { useDispatch } from 'react-redux';

import Header from './components/header';
import Footer from './components/footer';
import ScrollToTop from './components/header/scrollRoute';
import Notification from './components/notification/index';
//pages
import HomePage from './pages/home/index';
import ContactPage from './pages/static-pages/contact';
import TermsPage from './pages/static-pages/terms';
import TestimonialsPage from './pages/static-pages/testimonials/index';
import SamplePage from './pages/sample';
import SearchPage from './pages/search/index';
import ClosedPage from './pages/static-pages/closed/index';
//account
import SignupPage from './pages/account/signup';
import LoginPage from './pages/account/login';
import ForgotPasswordPage from './pages/account/forgot-password';
import ResetPasswordPage from './pages/account/reset-password/index';

import DashboardPage from './pages/dashboard/index';
//crates
import CratesPage from './pages/crates/static/index';
import OneCratePage from './pages/crates/single-crate/index';
import OneCrateSampledPage from './pages/crates/single-sampled/index';
//forms
import ClearSamplePage from './pages/clear-sample/index';
import AfterRegisterPage from './pages/account/after-register';
import EmailVerifyPage from './pages/account/emails-verify';
import AlbomPage from './pages/albom/index';
import ShopPage from './pages/shop/index';
import ShopItemPage from './pages/shop-item/index';
import AddCratePage from './pages/forms/add-crate/index';
import AddCrateToExistPage from './pages/forms/add-crate-sample/index';
import AddSamplePage from './pages/forms/add-sample/index';
import AddSyncPage from './pages/forms/add-sync/index';
import AddMechPage from './pages/forms/add-mech/index';
import ReviewFormPage from  './pages/forms/review/index';
import AffilatePage from './pages/account/affilate';

//errors page
import Notfound from './pages/notfound';
// tester page 

import TesterPage from './pages/tester';

import { checkUserAuth } from './actions/auth';

function App() { 

  useEffect(() => { store.dispatch(checkUserAuth()) }, [] );
  return (
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <React.Fragment>
                <Helmet>                
                    <title>Ample Samples</title>
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Ample Samples" />
                    <meta property="og:url" content="https://app.theamplesamples.com" />
                    <meta property="og:image" content="https://lh3.googleusercontent.com/pw/AIL4fc_i_3dN3rxopQO9M4hi3853JzFTvwysBwTzy75UicrLPX68E-09Ya0TJnreKF31D5RyfAWCGj63afQU1yidaju7Dyqy59dehoPGGQ6z45XBlfwqKNPmWnfWTWKkqJcM5c6kTgNRAbDYEiHpHx6F0rw=w1200-h627-s-no?authuser=0" />
                    <meta property="og:description" content="Clear your samples quickly & accurately !" />
                </Helmet>
                <ScrollToTop />
                <Header />                
                <Switch>
                    <Route exact path={ROUTESLIST.HOME} component={HomePage} />
                    <Route path={ROUTESLIST.STATIC_CONTACT} component={ContactPage} />   
                    <Route path={ROUTESLIST.STATIC_TESTIMONIALS} component={TestimonialsPage} />  
                    <Route path={ROUTESLIST.STATIC_TERMS} component={TermsPage} />  
                    <Route path={ROUTESLIST.SAMPLE} component={SamplePage} />   
                    <Route path={ROUTESLIST.SEARCH} component={SearchPage} />   
                    <Route path={ROUTESLIST.SIGNUP} component={SignupPage} /> 
                    <Route path={ROUTESLIST.LOGIN} component={LoginPage} /> 
                    <Route path={ROUTESLIST.DASHBOARD} component={DashboardPage} />
                    <Route path={ROUTESLIST.CLOSED_PAGE + ':slug'} component={ClosedPage} />                    

                    <Route path={ROUTESLIST.CRATES} component={CratesPage} />
                    <Route path={ROUTESLIST.CRATES_CRATE + ':id'} component={OneCratePage} /> 
                    <Route path={ROUTESLIST.CRATES_SAMPLED + ':id'} component={ OneCrateSampledPage} />

                    <Route path={ROUTESLIST.UPLOAD_ADD_CRATE_SAMPLE + ':id'} component={AddCrateToExistPage} />
                    <Route path={ROUTESLIST.UPLOAD_ADD_CRATE} component={AddCratePage} />
                    <Route path={ROUTESLIST.UPLOAD_SAMPLE} component={AddSamplePage} />
                    <Route path={ROUTESLIST.UPLOAD_SYNC} component={AddSyncPage} />
                    <Route path={ROUTESLIST.UPLOAD_MECH} component={AddMechPage} />
                    <Route path={ROUTESLIST.UPLOAD_REVIEW} component={ReviewFormPage} />
                    
                    <Route path={ROUTESLIST.ALBOM} component={AlbomPage} />
                    <Route path={ROUTESLIST.SHOP} component={ShopPage} />
                    <Route path={ROUTESLIST.SHOP_ITEM} component={ShopItemPage} />
                    <Route path={ROUTESLIST.CLEAR_SAMPLE} component={ClearSamplePage } />                    
                    <Route path={ROUTESLIST.AFTER_REGISTER} component={AfterRegisterPage} />
                    <Route path={ROUTESLIST.EMAIL_VERIFY} component={EmailVerifyPage } />
                    <Route path={ROUTESLIST.ACCOUNT_FORGOT_PASSWORD} component={ForgotPasswordPage  } /> 
                    <Route path={ROUTESLIST.ACCOUNT_RESET_PASSWORD + ':token'} component={ResetPasswordPage  } /> 
                    <Route path="/account/affiliate" component={AffilatePage} />
                   
                    <Route path="/tester" component={TesterPage}  />             
                    <Route path="*" component={Notfound} />                   
                </Switch>
                <Footer /> 
                <Notification />                
            </React.Fragment>
        </ConnectedRouter>
    </Provider>
  );
}

export default App;

